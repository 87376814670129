export const PRACTICE_TYPE_HELPER_TEXT = ` <b>Full-Time:</b> An ongoing position, typically 32-40 hours per week.<br/>
        <b>Part-Time:</b> Reduced hours, often under 32 per week, offering flexibility.<br/>
        <b>Locum:</b> Temporary coverage role for set periods, filling staffing gaps.<br/>
        <b>Telemedicine:</b> Remote role delivering care via virtual platforms.<br/>
        <b>PRN:</b> On-call role with flexible, as-needed shifts.<br/>
        <b>Travel:</b> Temporary relocation to provide care where needed.`

export const ACADEMIC_TYPE_HELPER_TEXT = `<b>Yes:</b> Implies it has an associated university.<br/>
 <b>Hybrid:</b> Includes residencies.<br/>
  <b>No:</b> Regular physician jobs, community, private practice, group.`

export const SEARCH_STATUS_HELPER_TEXT = `If you're not actively looking, you can still use MyStethi, however recruiters may not be able to view your profile.`
