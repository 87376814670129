export const defaultProps = {
  name: 'Jobs',
  columns: [
    { name: 'id', header: 'Id' },
    { name: 'title', header: 'Title' },
    { name: 'employerName', header: 'Employer' },
    { name: 'link', header: 'External URL' },
    { name: 'email', header: 'Recruiter Email' },
    { name: 'createdAt', header: 'Created' },
  ],
  searchableFields: ['title', 'employerName', 'email'],
}
