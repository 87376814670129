import { ProductAnalyticsEventTypes } from 'src/redux/productAnalytics'

export const mapUrlForAnalytics = url => {
  if (/\/jobs\/\d+/.test(url)) {
    return {
      url: 'page_view/JobDetail',
      properties: { jobId: url.split('/').pop() },
    }
  } else {
    return { url: 'page_view' + url }
  }
}

export const mapEmailEvent = ({ searchParams, email }) => {
  // Extract URL parameters
  if (searchParams.get('clickFrom') === 'email') {
    if (searchParams.get('userFilterAlertId')) {
      const event = {
        eventName: 'user_filter_alert_email_click',
        eventType: ProductAnalyticsEventTypes.BUTTON_CLICK,
        eventData: { userId: email },
      }
      return event
    }
    if (searchParams.get('campaignName')) {
      const event = {
        eventName: 'mailchimpCampaignClick',
        eventType: ProductAnalyticsEventTypes.BUTTON_CLICK,
        eventData: { campaignName: searchParams.get('campaignName') },
      }
      return event
    } else {
      return null
    }
  }
}
