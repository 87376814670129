import React, { useState } from 'react'
import { Typography, Link, Tooltip, Box } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

const GenericFieldHeader = ({
  name,
  field,
  optional = false,
  handleReset,
  showClear,
  tooltipText,
}) => {
  const [open, setOpen] = useState(false)

  const handleTooltipToggle = () => setOpen(!open)

  return (
    <Box display='flex' alignItems='center' sx={{ width: 'fit-content' }}>
      <Typography
        variant='subtitle1'
        sx={{
          fontStyle: 'italic',
          color: 'gray',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {name} {optional ? ' (Optional)' : ''}
        {tooltipText && (
          <Tooltip
            title={<Box sx={{ whiteSpace: 'pre-line' }}>{tooltipText.replace(/\n/g, '<br/>')}</Box>}
            arrow
            open={open}
            onClose={() => setOpen(false)}
            disableHoverListener
            disableFocusListener
            disableTouchListener
          >
            <Box
              component='span'
              onClick={handleTooltipToggle}
              sx={{
                ml: 0.5,
                display: 'inline-flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <InfoOutlinedIcon fontSize='small' color='action' />
            </Box>
          </Tooltip>
        )}
      </Typography>
      {showClear && (
        <Link
          component='button'
          variant='body2'
          onClick={() => handleReset(field)}
          sx={{ ml: 2, textDecoration: 'underline', cursor: 'pointer' }}
        >
          Clear
        </Link>
      )}
    </Box>
  )
}

export default GenericFieldHeader
