import React from "react"
import { Typography, Grid } from "@mui/material"

const PrivacyPolicy = () => {
  return (
    <Grid container justifyContent="center">
      <Grid
        container
        style={{ backgroundColor: "white", width: "75%", padding: "20px" }}
        spacing={3}
      >
        <Grid container justifyContent="center" style={{ paddingTop: "10px" }}>
          <Grid item>
            <Typography variant="h2">Your Privacy Rights</Typography>
          </Grid>
        </Grid>

        <Grid item>
          <Typography variant="h2"> Introduction</Typography>
          <Typography variant="body1">
            MyStethi, LLC (“MyStethi” or “we” or “us”) has created this Privacy Policy in order to disclose its use of  consumer data collected about you. This Privacy Policy is effective as of November 9, 2023. Any  questions regarding this policy should be directed by email to register@mystethi.com. You may also  contact MyStethi at 8615 Winding Walk, Austin, TX 78757. The following discloses MyStethi’s  information gathering and dissemination practices for its social networking applications and website.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h2">
            {" "}
            Information Collected and Received
          </Typography>
          <Typography variant="body1">
            MyStethi may collect or receive information about each person who registers with the MyStethi website  and/or applications including, but not limited to, username, password, first and last name, email address,  street address, gender, occupation, and interests. MyStethi also collects information on the pages you  access and other information you may volunteer, such as survey information. MyStethi may also collect  user location information, as further described in the “Global Positioning System” paragraph below.
            {" "}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h2">Uses of Information</Typography>
          <Typography variant="body1" display="block">
            <b>WE WILL NEVER SELL YOUR EMAIL ADDRESS, OR ANY OTHER PERSONAL
              INFORMATION, TO ANY THIRD PARTY WITHOUT YOUR PERMISSION. EVER.</b>
          </Typography>{" "}
          <Typography variant="body1" display="block">
            Except as described in this section, we will not disclose your personal information to any third party  without notifying you of our intent to share the information and giving you an opportunity to prevent your  information from being shared.
          </Typography>{" "}
          <Typography variant="body1" display="block">
            From time to time, we may partner with companies based on the interests of our users. These partner  companies will never see your email address or any other information that could identify you or be used  to contact you directly.
          </Typography>{" "}
          <Typography variant="body1" display="block">
            Any exceptions to this policy of sharing your name, address or email address with a partner company will
            be done only with your permission.
          </Typography>{" "}
          <Typography variant="body1" display="block">
            MyStethi reserves the right to disclose information when required by law.
          </Typography>{" "}
          <Typography variant="body1" display="block">
            We occasionally use other companies to perform services necessary to our operations. In the course of  providing these services, those companies may have access to your personal information. By contractual  agreement, those companies must treat your information in accordance with this Privacy Policy.  However, we will not be liable for any damages that may result from the misuse of your personal  information by these companies.
          </Typography>{" "}
          <Typography variant="body1" display="block">
            MyStethi may use your IP address to help diagnose problems with MyStethi’s server and to administer  MyStethi’s web site. Your IP address may also be used to help identify you and your online profile and to  gather broad demographic information.
          </Typography>{" "}
          <Typography variant="body1" display="block">
            The MyStethi site registration form requires users to give MyStethi contact information (such as your  name and email address) and demographic information (such as your zip code, age, or income level).  MyStethi uses your contact information from the registration form to send you information about  MyStethi and promotional material from some of MyStethi’s partners. Your contact information may also  be used to contact you when necessary. MyStethi may use demographic and/or profile data to tailor your  experience on the MyStethi website and/or applications, show you content that MyStethi thinks you may
            be interested in, and display content according to your preferences. The demographic and profile data will  be shared with third parties ONLY on an aggregate basis. Furthermore, MyStethi shall have the right to  retain records of all data pertaining to use of the website and applications including, but not limited to,  usage, activity logs, and click-throughs. MyStethi may disclose such data to third parties provided it is  grouped with other MyStethi users’ data and is presented in an aggregate form.
          </Typography>{" "}
          <Typography variant="body1" display="block">
            We may also disclose, on an anonymous basis, statements made by our users
          </Typography>{" "}
        </Grid>

        <Grid item>
          <Typography variant="h2"> Children’s Privacy</Typography>
          <Typography variant="body1">
            We do not knowingly collect any information from anyone who we know to be under the age of 13. If  you are under the age of 18, you should use this website only with the involvement of a parent or  guardian and should not submit any personal information to us. If we discover that a person under the age  of 13 has provided us with any personal information, we will use commercially reasonable efforts to  delete such person's personal information from all MyStethi systems.
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="h2"> Global Positioning System</Typography>
          <Typography variant="body1">
            Global Positioning System (GPS) tracking technology may need to be enabled in MyStethi products and  services in order to determine the location (latitude and longitude) of users of the MyStethi products and  services. This information is transmitted to MyStethi, MyStethi’s users, and temporarily stored by  MyStethi. MyStethi does not provide this information to any other third party. This information is used in  conjunction with MyStethi’s applications.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h2"> Security</Typography>
          <Typography variant="body1">
            MyStethi uses industry-standard technologies when transferring and receiving consumer data exchanged  between MyStethi and other companies to help ensure its security. This site has security measures in  place to protect the loss, misuse, and alteration of the information under MyStethi’s control. MyStethi’s  servers are backed up regularly and protected by security systems.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h2"> Cookies</Typography>
          <Typography variant="body1">
            “Cookies” are small pieces of information that are stored by your browser on your computer's hard drive.  The MyStethi site uses cookies to keep track of your session, shopping cart, and advertising delivery.  MyStethi may also use cookies to deliver content specific to your interests and to save your password.  MyStethi may use an outside ad company to display ads on the MyStethi site. These ads may also contain  cookies. While MyStethi uses cookies in other parts of its web site, cookies received with banner ads are  collected by MyStethi’s ad company and MyStethi does not have access to this information.
            We may use standard Internet technology, such as web beacons and other similar technologies, to track  your use on our sites and software. We also may include web beacons in promotional e-mail messages or  newsletters to determine whether messages have been opened and acted upon. The information we obtain  in this manner enables us to customize the services we offer our users to deliver targeted advertisements  and to measure the overall effectiveness of our online advertising, content, programming, or other  activities.
          </Typography>
          <Typography variant="body1">
            We may allow third parties, including our authorized service providers, advertising companies, and ad  networks, to display advertisements on our site and/or software. MyStethi and these companies may use  tracking technologies, such as cookies, to collect information about users who view or interact with these  advertisements and connect to the MyStethi properties and/or software. Our properties do not provide any  personal information to these third parties. This information allows MyStethi and these companies to  deliver targeted advertisements and gauge their effectiveness.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h2"> Links</Typography>
          <Typography variant="body1">
            MyStethi may create links to other web sites. MyStethi will make a reasonable effort to link only to sites  that meet similar standards for maintaining each individual's right to privacy. However, many other sites  that are not associated or authorized by MyStethi may have links leading to our site. MyStethi cannot  control these links and MyStethi is not responsible for any content appearing on these sites.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h2"> Amendments</Typography>
          <Typography variant="body1">
            MyStethi may amend this policy at any time. If MyStethi is going to use personally identifiable  information collected through the MyStethi site in a manner materially different from that stated at the  time of collection, MyStethi will notify users via email and/or by posting a notice on the MyStethi site for  thirty (30) days prior to such use. This Privacy Policy may not be otherwise amended except in a writing  that specifically refers to this Privacy Policy and is physically signed by both parties.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h2">Successors and Assigns</Typography>
          <Typography variant="body1">
            This Privacy Policy inures to the benefit of successors and assigns of MyStethi.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h2">Contacts</Typography>
          <Typography variant="body1">
            If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this  web site, you can contact register@mystethi.com. Upon request, MyStethi will provide you with access to  information (e.g., name, address, phone number) that MyStethi collects and maintains about you. This site  gives you the following options for changing and modifying information previously provided: (i) email:  register@mystethi.com; or (ii) visit mystethi.com.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h2">Opt-Out</Typography>
          <Typography variant="body1">
            The MyStethi site provides users the opportunity to opt-out of receiving communications from MyStethi,  except transactional correspondence. To opt-out of receiving our communications, you can (i) send email  to register@mystethi.com; or (ii) send postal mail to: MyStethi, LLC, 8615 Winding Walk, Austin, TX  78757.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PrivacyPolicy
