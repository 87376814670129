import React from 'react'
import { Autocomplete, Chip, TextField, Typography, useTheme } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

const GenericMultipleSelect = ({
  value,
  options,
  label,
  name,
  handleChange,
  disabled,
  required = false,
  ...props
}) => {
  const theme = useTheme()

  return (
    <Autocomplete
      multiple
      id={name}
      name={name}
      options={options} // Only allow predefined options
      disabled={disabled}
      value={value || []}
      getOptionLabel={option => option || ''}
      size='small'
      filterSelectedOptions
      onChange={(event, newValue) => {
        // Filter out invalid values (not in the options list)
        const filteredValues = newValue.filter(val => options.includes(val))
        handleChange({ target: { name, value: filteredValues } })
      }}
      popupIcon={
        <ArrowDropDownIcon
          sx={{
            color: disabled ? theme.palette.action.disabled : 'black',
            marginRight: '8px', // Adjust distance from edge
          }}
        />
      } // Custom arrow styling
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant='outlined'
            label={option}
            {...getTagProps({ index })}
            sx={{
              '& .MuiChip-label': {
                color: theme.palette.primary.dark,
              },
              '&.MuiChip-root.Mui-disabled': {
                backgroundColor: theme.palette.action.disabledBackground,
              },
            }}
          />
        ))
      }
      renderOption={(props, option, { inputValue }) => {
        // Highlight the part of the option that matches the input
        const matches = option.toLowerCase().indexOf(inputValue.toLowerCase())
        const beforeMatch = option.substring(0, matches)
        const match = option.substring(matches, matches + inputValue.length)
        const afterMatch = option.substring(matches + inputValue.length)

        return (
          <li {...props}>
            <Typography variant='body2'>
              {beforeMatch}
              <span style={{ fontWeight: 'bold', color: theme.palette.primary.main }}>{match}</span>
              {afterMatch}
            </Typography>
          </li>
        )
      }}
      renderInput={params => (
        <TextField
          {...params}
          variant='outlined'
          size='small'
          label={
            <span>
              {label}
              {required && <span style={{ marginLeft: '2px' }}>*</span>}
            </span>
          }
          sx={{
            '& .MuiInputBase-root': {
              paddingRight: '40px', // Ensures spacing for dropdown arrow
            },
            '& .MuiInputBase-input': {
              color: theme.palette.text.primary,
            },
            '& .MuiAutocomplete-popupIndicator': {
              padding: '0',
            },
            '& fieldset': disabled && { border: 'none' },
          }}
        />
      )}
      {...props}
    />
  )
}

export default GenericMultipleSelect
