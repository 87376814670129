import React from 'react'
import { useSelector } from 'react-redux'
import Grid from '@mui/material/Grid'
import { useStyles } from 'common/styles'
import InterestForm from './InterestForm'
import Success from './Success'
import About from './About'

const HospitalRegister = () => {
  const classes = useStyles()
  const formSubmitted = useSelector(state => state.signupHospital.formSubmitted)
  return (
    <Grid container justifyContent='center' className={classes.registrationPhysicianContainer}>
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        md={6}
        className={classes.registerBackground}
      >
        <Grid
          sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}
          item
          style={{ margin: '50px' }}
        >
          <About />
        </Grid>
      </Grid>
      {formSubmitted === false ? (
        <Grid item xs={12} md={6} className={classes.registerContent}>
          <InterestForm />
        </Grid>
      ) : (
        <Grid
          container
          xs={12}
          md={6}
          alignItems='center'
          justifyContent='center'
          className={classes.registerSuccess}
        >
          <Success />
        </Grid>
      )}
    </Grid>
  )
}

export default HospitalRegister
