import * as React from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Select from 'components/DialogField/Select'
import MultiChip from 'components/DialogField/MultiChip'
import Text from 'components/DialogField/Text'
import PhoneNumber from 'components/DialogField/PhoneNumber'
import PhoneNumberOld from 'components/DialogField/PhoneNumberOld'
import Money from 'components/DialogField/Money'
import WholeNumber from 'components/DialogField/WholeNumber'
import AutoComplete from 'components/DialogField/AutoComplete'
import DatePickerField from 'components/DialogField/DatePicker'
import { TextEditor } from 'components/TextEditor/TextEditor'
import RatingField from 'components/DialogField/RatingField'
import PhotoUpload from 'components/DialogField/PhotoUpload'
import FileUpload from 'components/DialogField/FileUpload'
import SwitchField from 'components/DialogField/SwitchField'
import GenericComponent from 'components/DialogField/GenericComponent'

const singleValue = ({ id }, values) => {
  // retrieve the value (which sometimes is an integer correlated with an enum) or the value (which is the display value)
  switch (id) {
    default: {
      return values[id]
    }
  }
}
const multiValue = ({ id, filter, items }, values) =>
  values[id]
    ? (filter ? filter(items, values) : items).filter(i => values[id].find(i2 => i2 === i.value))
    : []

export const getValue = (field, values, shouldGetValue) =>
  getValueFunction(field)(field, values, shouldGetValue)

const getValueFunction = props => {
  const [, valueFunction] = getFieldMapping(props)
  return valueFunction
}

const getFieldMapping = ({ type }) => fieldMapping[type] || fieldMapping['default']

const fieldMapping = {
  text: [Text, singleValue],
  number: [Text, singleValue],
  datepicker: [DatePickerField, singleValue],
  select: [Select, singleValue],
  autocomplete: [AutoComplete, singleValue],
  multichip: [MultiChip, multiValue],
  default: [Text, singleValue],
  phoneNumber: [PhoneNumber, singleValue],
  phoneNumberOld: [PhoneNumberOld, singleValue],
  money: [Money, singleValue],
  wholenumber: [WholeNumber, singleValue],
  div: ['div', singleValue],
  textEditor: [CKEditor, singleValue],
  rating: [RatingField, singleValue],
  span: ['span', singleValue],
  photoUpload: [PhotoUpload, singleValue],
  fileUpload: [FileUpload, singleValue],
  switch: [SwitchField, singleValue],
  genericComponent: [GenericComponent, singleValue],
}

export default function DialogField({ hidden, ...props }) {
  if (hidden) {
    return <></>
  }
  if (props.type === 'textEditor') {
    return <TextEditor {...props} />
  }
  const [Field] = getFieldMapping(props)
  if (props.type === 'div') {
    return <div />
  }
  return <Field {...props} />
}
