import React, { useCallback } from 'react'
import { Card } from '@mui/material'
import { useHeaderHeight } from 'data/hooks'
import { checkEmpty, hostAddress } from 'data/helpers'
import Title from 'components/Title'
import Buttons from 'pages/Users/Profile/Buttons'
import useStyles from '../styles'
import { FieldData } from './index'
import ContactPhotoCard from 'components/ContactPhotoCard'

export const UserCard = ({ user, fieldData, shouldShowEditButtons }) => {
  const classes = useStyles({
    headerHeight: useHeaderHeight(175),
    flexCol: { display: 'flex', flexDirection: 'column' },
    flexRow: { display: 'flex', flexDirection: 'row' },
  })
  const resume = checkEmpty(user.resume) ? {} : user.resume
  const { key, fileName } = resume
  const handleResumeClick = useCallback(() => {
    fetch(hostAddress(`/api/storage/resume?key=${key}&fileName=${fileName}`), {
      method: 'GET',
      headers: new Headers({ 'content-type': 'application/json' }),
    }).then(response => response.blob().then(blob => window.open(URL.createObjectURL(blob))))
  }, [key, fileName])

  const visibleFields = shouldShowEditButtons
    ? fieldData
    : Object.fromEntries(Object.entries(fieldData).filter(([, v]) => v.value != null))

  return (
    <Card className={classes.rowContainer} style={{ border: 0 }}>
      <ContactPhotoCard profileImage={user.profileImage} size={475} />
      <div className={classes.card}>
        <div className={classes.colContainer}>
          <Title text={[user.firstName, user.lastName].join(' ')} />
          <FieldData user={visibleFields} inverted='false' />
          <div className={classes.rowContainer} style={{ paddingTop: 10 }}>
            {shouldShowEditButtons && <Buttons user={user} />}
            {resume.key && (
              <a className={classes.resumeButton} onClick={() => handleResumeClick()}>
                View Resumé
              </a>
            )}
          </div>
        </div>
      </div>
    </Card>
  )
}
