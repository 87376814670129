import React from 'react'
import PropTypes from 'prop-types'
import CardMedia from '@mui/material/CardMedia'
import PortraitPhoto from 'static/images/profile-image-placeholder.jpg'
import { checkEmpty, imageAddress } from 'data/helpers'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() =>
  createStyles({
    card: ({ marginBottom }) => ({
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      marginBottom: `${marginBottom}px`,
      boxShadow: 'none',
    }),
    media: ({ pictureSize }) => ({
      height: pictureSize + 'px',
      width: pictureSize + 'px',
    }),
    content: {
      padding: 0,
      width: '100%',
      height: '100%',
    },
  }),
)

export default function ContactPhotoCard({
  image = PortraitPhoto, // Unused if 'profileImage' is provided
  title = 'Photo',
  profileImage = undefined,
  marginBottom = 0,
  size = 100, // Size of the image in pixels [height and width]
}) {
  const classes = useStyles({ marginBottom, pictureSize: size })

  const parsedProfileImage = checkEmpty(profileImage) ? {} : profileImage
  const { key, fileName } = parsedProfileImage

  const img = key => (!checkEmpty(key) ? imageAddress(key, fileName) : image)

  return (
    <div className={classes.card}>
      <div className={classes.content}>
        <CardMedia className={classes.media} image={img(key)} title={title} />
      </div>
    </div>
  )
}

ContactPhotoCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
}
