import React from 'react'
import { Grid, Typography, Divider } from '@mui/material'
import ListDataItem from 'components/Listing/ListDataItem'
import RealtorCard from './RealtorCard'

const RealtorContent = ({ item = {} }) => {
  const fields = {}
  const name = item.firstName + ' ' + item.lastName

  return (
    <Grid container sx={{ padding: '20px 20px', height: 'auto' }}>
      <Grid container spacing={1} alignItems='center' direction='row' xs={12}>
        <Grid container item xs={9}>
          <Typography variant='h4'>
            <b>{name}</b>
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ margin: '10px 0px' }}>
        <Divider sx={{ height: '1px', marginBottom: '20px' }} />
      </Grid>
      <Grid container xs={12} direction='row'>
        <Grid container sm={12} md={6} rowSpacing={1}>
          {Object.keys(fields).map((field, index) => (
            <Grid item sm={12} md={6} key={index}>
              {fields[field].show && (
                <ListDataItem
                  icon={fields[field].icon}
                  boolValue={fields[field].value}
                  text={fields[field].text}
                />
              )}
            </Grid>
          ))}
        </Grid>
        <Grid container sm={12} md={12}>
          <RealtorCard realtor={item} />
        </Grid>
        {item.incentive && (
          <Grid item xs={12} justifyContent='flex-start' sx={{ marginTop: '10px' }}>
            <Typography variant='h6'>Provider Incentive </Typography>
            <Typography variant='body2'>{item.incentive}</Typography>
          </Grid>
        )}
        {item.description && (
          <Grid item xs={12} justifyContent='flex-start' sx={{ marginTop: '10px' }}>
            <Divider sx={{ height: '1px', margin: '20px 0px' }} />
            <Typography variant='body2'>{item.description}</Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default RealtorContent
