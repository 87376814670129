import React from 'react'
import { Typography } from '@mui/material'

const Success = () => {
  return (
    <Typography variant='h4'>
      Thanks for your interest! We'll be in touch within 48 hours.
    </Typography>
  )
}

export default Success
