import { fetchDispatch } from 'data/helpers'

export const TRACK_EVENT_LOADING = 'analytics/TRACK_EVENT_LOADING'
export const TRACK_EVENT_SUCCESS = 'analytics/TRACK_EVENT_SUCCESS'
export const TRACK_EVENT_FAILURE = 'analytics/TRACK_EVENT_FAILURE'

export const trackEventLoading = () => ({ type: TRACK_EVENT_LOADING })
export const trackEventSuccess = () => ({ type: TRACK_EVENT_SUCCESS })
export const trackEventFailure = payload => ({ type: TRACK_EVENT_FAILURE, payload })

export const ProductAnalyticsEventTypes = {
  PAGE_VIEW: 1,
  ACTION: 2,
  BUTTON_CLICK: 3,
  TOUR_EVENT: 4,
}

export const fetchTrackEvent = event => {
  return async dispatch => {
    dispatch(trackEventLoading())
    try {
      // Don't await the fetch call here, as we don't care whether it succeeds or not
      // and we are trying to avoid long response times from holding up our UI.
      // We error handling on the server to notify us when things go wrong.
      fetchDispatch({
        path: '/api/product_analytics/event',
        values: event,
        method: 'POST',
      })
      dispatch(trackEventSuccess())
    } catch (error) {
      dispatch(trackEventFailure(error.response))
    }
  }
}

const initialState = {
  loading: false,
  errors: [],
}

export const productAnalyticsReducer = (state = initialState, action) => {
  const { payload } = action

  switch (action.type) {
    case TRACK_EVENT_LOADING:
      return {
        ...state,
        loading: true,
      }
    case TRACK_EVENT_SUCCESS:
      return {
        ...state,
        errors: [],
        loading: false,
      }
    case TRACK_EVENT_FAILURE:
      return {
        ...state,
        loading: false,
        errors: payload,
      }
    default:
      return {
        ...state,
      }
  }
}
