import * as React from 'react'
import DatePicker from '@mui/lab/DatePicker'
import { isError } from 'data/helpers'

const DatePickerField = ({ onChange, value, error, forceErrorMargin = false, ...other }) => {
  return (
    <DatePicker
      margin='dense'
      inputVariant='outlined'
      format='MM/dd/yyyy'
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
      value={value}
      onChange={React.useCallback(
        (e, value) => {
          onChange({ target: { value } })
        },
        [onChange],
      )}
      error={isError(error)}
      helperText={forceErrorMargin ? error || ' ' : error && error} // Forces a constant helper text margin
      {...other}
    />
  )
}

export default DatePickerField
