import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { postSignupHospital } from 'src/redux/signupHospital'

const initialFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  message: '',
  phone: null,
  hospital: '',
  messahe: '',
  formSubmitted: false,
  success: false,
}

export const useFormControls = () => {
  const [values, setValues] = useState(initialFormValues)
  // Should this be in state?
  const [errors, setErrors] = useState({})

  const dispatch = useDispatch()

  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    if ('firstName' in fieldValues)
      temp.firstName = !fieldValues.firstName && 'This field is required.'

    if ('lastName' in fieldValues)
      temp.lastName = !fieldValues.lastName && 'This field is required.'

    if ('hospital' in fieldValues)
      temp.hospital = !fieldValues.hospital && 'This field is required.'

    if ('email' in fieldValues) {
      temp.email = !fieldValues.email && 'This field is required.'
      if (fieldValues.email)
        temp.email = !/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email) && 'Email is not valid.'
    }
    if ('message' in fieldValues) temp.message = !fieldValues.message && 'This field is required.'

    setErrors({
      ...temp,
    })
  }
  const handleInputValue = e => {
    const { name, value } = e.target
    setValues({
      ...values,
      [name]: value,
    })
    validate({ [name]: value })
  }
  const formIsValid = (fieldValues = values) => {
    const isValid =
      fieldValues.firstName &&
      fieldValues.lastName &&
      fieldValues.email &&
      fieldValues.hospital &&
      fieldValues.message &&
      Object.values(errors).every(x => x === false)

    return isValid
  }
  const handleFormSubmit = async e => {
    e.preventDefault()
    if (formIsValid()) {
      dispatch(postSignupHospital({ ...values }))
    }
  }

  return {
    handleInputValue,
    handleFormSubmit,
    formIsValid,
    errors,
    values,
  }
}
