import React from 'react'
import { Button, Card, CardActions, CardHeader, CardMedia, Grid, Paper, Link } from '@mui/material'
import EmailIcon from '@mui/icons-material/Email'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import ListDataItem from 'components/Listing/ListDataItem'
import { getRealtorProfileImage, getLocation } from './helpers'

const RealtorCard = ({ realtor }) => {
  const profileImage = getRealtorProfileImage(realtor.profileImage)
  const name = realtor.firstName + ' ' + realtor.lastName
  const location = getLocation(realtor.city, realtor.state)
  return (
    <Paper elevation={5} sx={{ width: '100%' }}>
      <Card
        sx={{
          minWidth: 275,
          padding: '20px',
        }}
      >
        <Grid container direction={'row'} spacing={1} wrap='nowrap'>
          <Grid item>
            <CardMedia
              sx={{
                width: '100%',
                height: '70px',
              }}
              component='img'
              src={profileImage}
              image={profileImage}
              title='Realtor Photo'
            />
          </Grid>
          <Grid container wrap='nowrap'>
            <CardHeader
              style={{ overflowWrap: 'break-word' }}
              title={name}
              subheader={
                <Grid container direction='column'>
                  <Grid item>{location}</Grid>
                  <Grid>
                    {realtor.website && (
                      <Link
                        href={realtor.website}
                        underline='always'
                        color='#1B1B1B'
                        target='_blank'
                        and
                        rel='noopener noreferrer'
                      >
                        {realtor.website}
                      </Link>
                    )}
                  </Grid>
                </Grid>
              }
            />
          </Grid>
        </Grid>
        <Grid container item justifyContent='space-between' direction='row' xs={12}>
          <Grid container item xs={12} justifyContent='flex-start'>
            {realtor.email && <ListDataItem text={realtor.email} icon={<EmailIcon />} />}
          </Grid>
          <Grid container item justifyContent='flex-start' xs={12}>
            {realtor.phone && <ListDataItem text={realtor.phone} icon={<PhoneIphoneIcon />} />}
          </Grid>
          {realtor.email && (
            <CardActions>
              <a href={`mailto:${realtor.email}`}>
                <Button variant='contained' size='medium'>
                  Contact
                </Button>
              </a>
            </CardActions>
          )}
        </Grid>
      </Card>
    </Paper>
  )
}

export default RealtorCard
