import * as React from 'react'
import { useTheme, CircularProgress, Button, CardMedia, Grid } from '@mui/material'
import { useDispatch } from 'react-redux'
import PortraitPhoto from 'static/images/profile-image-placeholder.jpg'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { fetchUpdateCandidate } from 'src/redux/candidate'
import { checkEmpty, imageAddress } from 'data/helpers'
import { useDialogState } from 'data/dialog/actions'

const useStyles = makeStyles(() =>
  createStyles({
    media: ({ pictureSize }) => ({
      height: pictureSize + 'px',
      width: pictureSize + 'px',
    }),
  }),
)
const PhotoUpload = ({ candidateId, dialogId, candidateProfileImage, loading }) => {
  const [, setState] = useDialogState(dialogId)
  const size = 100
  const classes = useStyles({ pictureSize: size })
  const { layout } = useTheme()
  const title = 'Photo'
  const dispatch = useDispatch()
  const parsedProfileImage = checkEmpty(candidateProfileImage) ? {} : candidateProfileImage
  const { key, fileName } = parsedProfileImage
  const img = candidateProfileImage ? imageAddress(key, fileName) : PortraitPhoto
  const buttonText = checkEmpty(candidateProfileImage) ? 'Upload Photo' : 'Replace Photo'
  const handleChange = React.useCallback(event => {
    const uploadFiles = event.target.files
    if (uploadFiles) {
      const arrFiles = Array.from(uploadFiles)
      const file = arrFiles.length > 0 ? arrFiles[0] : undefined
      dispatch(
        fetchUpdateCandidate({
          id: candidateId,
          profileImageFile: file,
        }),
      )
      setState(prev => ({ ...prev, profileImage: file }))
    }
  })
  return (
    <Grid container alignItems='center' direction='column'>
      <CardMedia image={img} title={title} className={classes.media} />
      <br />
      <input
        name='photoselect'
        type='file'
        accept='*'
        id='photoselect'
        style={{ display: 'none' }}
        onChange={handleChange}
      />
      <label htmlFor='photoselect'>
        <Button variant='outlined' size='small' component='span' disabled={loading}>
          {loading ? (
            <CircularProgress size={layout.progressSize} className={classes.submitProgress} />
          ) : (
            buttonText
          )}
        </Button>
      </label>
    </Grid>
  )
}

export default PhotoUpload
