import * as React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Grid, Typography } from '@mui/material'
import DialogButton from 'components/Dialog/DialogButton'
import * as AlertDialog from 'components/Dialog/AlertDialog'
import AlertTable from './AlertTable'
import AlertRecordsPage from './AlertRecordsPage'

export default function Alerts() {
  const [searchParams] = useSearchParams()
  const alertId = searchParams.get('alertId')
  const { userFilters = [] } = useSelector(state => state.filters)
  const { employerUserId } = useSelector(state => state.identity)

  const hasAvailableAlerts = employerUserId
    ? userFilters.some(k => k.filterType.name === 'Provider')
    : userFilters.length > 0

  return (
    <Grid container alignItems='center' alignContent='center' justifyContent='center' spacing={3}>
      {alertId ? (
        <Grid item xs={11}>
          <AlertRecordsPage />
        </Grid>
      ) : (
        <>
          <Grid
            container
            item
            xs={11}
            direction='row'
            justifyContent='space-between'
            sx={{ marginTop: '20px' }}
          >
            <Grid item>
              <DialogButton
                disabled={!hasAvailableAlerts}
                Module={AlertDialog}
                size='small'
                tooltip=''
                initialValues={{}}
              >
                Add Alert
              </DialogButton>
              {!hasAvailableAlerts && (
                <Typography variant='body2' color='textSecondary' sx={{ marginLeft: '10px' }}>
                  Please save an eligible filter before adding an alert.
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid container item xs={11} direction='column' spacing={2} alignItems='center'>
            <Grid item>
              <Typography variant='body1' color='textSecondary'>
                You can add email alerts based on the filters you have saved to get emails sent to
                you notifying you of new items that fit your criteria!
              </Typography>
            </Grid>
            <Grid item>
              <AlertTable />
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  )
}
