import * as React from 'react'
import { useSearchParams, Link as RouterLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  Breadcrumbs,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material'
import { fetchUserFilterAlerts } from 'src/redux/filters'
import { transformAlertsRecordData } from './helpers'
import { fetchTrackEvent, ProductAnalyticsEventTypes } from 'src/redux/productAnalytics'

export const AlertRecordsPage = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const { userId, email } = useSelector(state => state.identity)
  const { userFilterAlerts = [] } = useSelector(state => state.filters)
  const alertId = searchParams.get('alertId')

  const alertRecords =
    userFilterAlerts.filter(
      record => record.userFilterId.toString() === alertId && Number(record.recordCount) > 0,
    ) || []

  React.useEffect(() => {
    dispatch(fetchUserFilterAlerts(userId))
  }, [alertId])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleAlertRecordClick = () => {
    dispatch(
      fetchTrackEvent({
        eventName: 'user_filter_alert_record_click',
        eventType: ProductAnalyticsEventTypes.BUTTON_CLICK,
        userId: email,
      }),
    )
  }
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const columns = [
    { id: 'runDate', label: 'Run Date' },
    { id: 'results', label: 'Results' },
  ]

  const formattedResults = transformAlertsRecordData({ alertRecords, handleAlertRecordClick })
  return (
    <Grid container direction='column' alignItems='center' spacing={3}>
      <Grid container item alignItems='flex-start' xs={11} sx={{ marginTop: '20px' }}>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link component={RouterLink} to='/users/alerts' color='inherit'>
            Alerts
          </Link>
          <Typography color='textPrimary'>
            {alertRecords.length > 0 && alertRecords[0].userFilterName} Records
          </Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={11}>
        <Paper
          sx={{
            width: '100%',
            display: 'table',
            tableLayout: 'fixed',
            overflow: 'scroll',
            marginBottom: '50px',
          }}
        >
          <TableContainer>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead sx={{ '&.MuiTableCell-stickyHeader': { backgroundColor: 'black' } }}>
                <TableRow key='alert-records-header'>
                  {columns.map(column => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      sx={{
                        '&.MuiTableCell-stickyHeader': {
                          backgroundColor: theme.palette.secondary.light,
                        },
                      }}
                    >
                      <Typography variant='body1'>{column.label}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {formattedResults
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(record => {
                    return (
                      <TableRow hover role='checkbox' tabIndex={-1} key={record.id}>
                        {columns.map(column => {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {record[column.id]}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component='div'
            count={alertRecords.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    </Grid>
  )
}

export default AlertRecordsPage
