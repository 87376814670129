import React from 'react'
import { TextField, useTheme } from '@mui/material'

const GenericTextField = ({ key, label, value, handleChange, disabled, ...props }) => {
  const theme = useTheme()
  return (
    <TextField
      key={key}
      onChange={event => handleChange(event)}
      name={key}
      size='small'
      label={label}
      value={value || ''}
      multiline={false}
      rows={1}
      variant='outlined'
      autoComplete='none'
      disabled={disabled}
      fullWidth
      sx={{
        '& .MuiInputBase-input.Mui-disabled': {
          WebkitTextFillColor: theme.palette.primary.dark,
        },
        '& fieldset': disabled && { border: 'none' },
      }}
      {...props}
    />
  )
}

export default GenericTextField
