import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  Button,
  Box,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Radio,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
  Tooltip,
  FormHelperText,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import GenericSectionHeader from '../GenericComponents/GenericSectionHeader'
import { fetchUpdateUserFilter } from 'src/redux/filters'

export const UserFilterSelector = ({
  userJobFilters,
  alertCadenceOptions,
  onClose,
  jobId,
  handleSuccessfulCompletion,
}) => {
  const [alertCadence, setAlertCadence] = useState('')
  const [showCadenceError, setShowCadenceError] = useState(false)
  const { userFilters } = useSelector(state => state.filters)
  const { userId } = useSelector(state => state.identity)
  const [selectedUserFilter, setSelectedUserFilter] = useState('') // initialize to empty string
  const dispatch = useDispatch()

  const handleExistingFilterSelect = event => {
    const filterId = event.target.value
    const selectedFilter = userFilters.find(filter => filter.id === filterId)

    // Update selected filter ID and sync alertCadence with the selected filter's alertCadence
    setSelectedUserFilter(filterId)
    setAlertCadence(selectedFilter?.alertCadence || '') // Set alertCadence to the selected filter's value or empty if not defined
  }

  const handleCadenceChange = event => {
    setAlertCadence(event.target.value)
    if (showCadenceError) setShowCadenceError(false) // Clear error on selection
  }

  const handleLinkUserFilter = () => {
    if (!alertCadence) {
      setShowCadenceError(true) // Show error if alertCadence is not set
      return
    }

    // Reset error if alertCadence is valid
    setShowCadenceError(false)
    dispatch(
      fetchUpdateUserFilter({
        query: { userId: userId, id: selectedUserFilter, alertCadence, jobId },
        onSuccess: handleSuccessfulCompletion,
      }),
    )
  }

  const handleDeleteFilter = filterId => {
    dispatch(
      fetchUpdateUserFilter({
        query: { userId: userId, id: filterId, jobId, deleteJob: true },
        onSuccess: handleSuccessfulCompletion,
      }),
    )
    onClose()
  }

  return (
    <>
      {/* Show associated job filters if available */}
      {userJobFilters && userJobFilters.length > 0 && (
        <Grid item xs={12}>
          <GenericSectionHeader title='Current Filters and Alerts for This Job' />
          <Typography variant='body2' color='textSecondary' sx={{ mb: 2 }}>
            This job has the following filters and alerts set up:
          </Typography>
          <List dense>
            {userJobFilters.map(filter => (
              <React.Fragment key={filter.id}>
                <ListItem
                  secondaryAction={
                    <Tooltip title='Delete Filter for Job'>
                      <IconButton
                        edge='end'
                        aria-label='delete'
                        onClick={() => handleDeleteFilter(filter.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  }
                >
                  <ListItemText
                    primary={
                      <Box display='flex' justifyContent='space-between' width='100%'>
                        <span>{filter.name}</span>
                        <span>{filter.alertCadence || 'Not Set'}</span>
                      </Box>
                    }
                  />
                </ListItem>
                <Divider component='li' sx={{ height: '1px' }} />
              </React.Fragment>
            ))}
          </List>
          <Typography variant='body2' color='textSecondary' sx={{ mt: 2 }}>
            You can add another filter below.
          </Typography>
        </Grid>
      )}

      {/* Select Existing Filter Dropdown */}
      <Grid item xs={12}>
        <FormControl fullWidth>
          <Select value={selectedUserFilter} onChange={handleExistingFilterSelect} displayEmpty>
            <MenuItem value=''>Select Existing Filter</MenuItem>
            {userFilters.map(filter => (
              <MenuItem key={filter.id} value={filter.id}>
                {filter.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <GenericSectionHeader title='Set Email Notification' />
        <FormControl component='fieldset' error={showCadenceError}>
          <FormLabel component='legend'>
            Send notifications when new candidates fit the criteria:
          </FormLabel>
          <RadioGroup
            row
            aria-label='alert cadence'
            name='alertCadence'
            value={alertCadence}
            onChange={handleCadenceChange}
          >
            {alertCadenceOptions.map((option, index) => (
              <FormControlLabel
                key={`${option.id}-${index}`}
                value={option.name}
                control={<Radio />}
                label={option.name}
              />
            ))}
          </RadioGroup>
          {showCadenceError && <FormHelperText>Please select an alert cadence</FormHelperText>}
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Box display='flex' justifyContent='flex-end'>
          <Button variant='text' color='primary' onClick={onClose} sx={{ marginRight: '5px' }}>
            Setup Later
          </Button>
          <Button variant='contained' color='primary' onClick={handleLinkUserFilter}>
            Set Filter and Alert for Job
          </Button>
        </Box>
      </Grid>
    </>
  )
}

export default UserFilterSelector
