import React from 'react'
import { hasChanged } from 'data/helpers'

export const useValues = ({ open, initialValues = {} }) => {
  const [values, setValues] = React.useState(initialValues)
  const [touched, setTouched] = React.useState({})

  React.useEffect(() => {
    open && setValues(initialValues) // Re-initialize values when necessary
    open && setTouched({})
  }, [open, JSON.stringify(initialValues)])

  return [values, setValues, hasChanged(values, initialValues), touched, setTouched]
}
