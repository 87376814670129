import React from 'react'
import {
  CircularProgress,
  Button,
  TextField,
  Divider,
  Grid,
  Paper,
  Typography,
  useTheme,
  Link,
} from '@mui/material'
import { size } from 'lodash'
import useComponentSize from '@rehooks/component-size'
import { useSelector } from 'react-redux'
import { useContentHeight, useHandleChangeRoute } from 'data/hooks'
import { useWidth, useLandscape } from 'data/layout/actions'
import { useStyles } from 'common/styles'
import { useHandleSendSupportEmail } from 'data/contact/actions'
import { validateEmail } from 'data/helpers'

export default function Support() {
  const buttonRef = React.useRef(null)
  const ref = React.useRef(null)
  const width = useWidth()
  const landscape = useLandscape()
  let componentSize = useComponentSize(ref)
  const marginTop = (useContentHeight() - (componentSize.height + 8)) / 2
  const classes = useStyles({ landscape, marginTop, width })

  const { supportFormSubmitted } = useSelector(state => state.contact)

  const [values, setValues] = React.useState({ firstName: null, lastName: null, email: null })
  const [btnDisabled, setBtnDisabled] = React.useState(true)
  const [{ errors, loading }, setState] = React.useState({
    errors: {},
    loading: false,
  })

  const handleChangeRoute = useHandleChangeRoute()
  const handleSendSupportEmail = useHandleSendSupportEmail()

  const handleChange = React.useCallback(
    name => e => {
      const value = e && e.target && e.target.value
      if (name === 'email') {
        if (!validateEmail(value)) {
          errors.email = 'Invalid email format.'
        } else {
          delete errors.email
        }
      }
      setValues(prev => ({ ...prev, [name]: value }))
      setState(prev => ({ ...prev, errors: errors }))
    },
    [setState, setValues],
  )

  const handleBlur = () => {
    if (!values.email) {
      setBtnDisabled(true)
      errors.email = 'Email is Required'
    } else if (values.email) {
      delete errors.email
    }
    if (!values.firstName) {
      setBtnDisabled(true)
      errors.firstName = 'First name is Required'
    } else if (values.firstName) {
      delete errors.firstName
    }
    if (!values.lastName) {
      setBtnDisabled(true)
      errors.lastName = 'Last name is Required'
    } else if (values.lastName) {
      delete errors.lastName
    }
    if (size(errors) === 0) {
      setBtnDisabled(false)
    }
    setState(prev => ({ ...prev, errors: errors }))
  }

  const { layout } = useTheme()
  if (supportFormSubmitted) {
    return (
      <Paper ref={ref} className={classes.supportContainer}>
        <Grid container alignItems='center' spacing={1}>
          <Grid item xs={12}>
            <Typography align='center' variant='h4'>
              Thanks for contacting our Support Team!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        <Grid
          container
          alignItems='center'
          spacing={landscape ? 3 : 1}
          style={{ padding: '5 5', textAlign: 'center' }}
        >
          <Grid item xs={landscape ? 6 : 12}>
            <Typography variant='body1'>Someone will reply back to you within 24 hours!</Typography>
            <Link
              onClick={handleChangeRoute('/')}
              color='inherit'
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              underline='hover'
            >
              <Typography variant='body1'>Go to home page</Typography>
            </Link>
          </Grid>
        </Grid>
      </Paper>
    )
  } else {
    return (
      <Paper ref={ref} className={classes.supportContainer}>
        <Grid container alignItems='center' spacing={1}>
          <Grid item xs={12}>
            <Typography align='center' variant='h5'>
              Contact MyStethi's Support Team
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        <Grid container alignItems='center' rowSpacing={2} direction='column'>
          {loading && (
            <CircularProgress size={layout.progressSize} className={classes.submitProgress} />
          )}
          <Grid item>
            <Typography variant='body1'>
              <p>
                Having technical issues? Email us at{' '}
                <a href={'mailto:support@mystethi.com?subject=MyStethi Support'}>
                  support@mystethi.com
                </a>
                {` `}or fill out the form below and we will get back to you within 24 hours.
              </p>
            </Typography>
            <TextField
              variant='outlined'
              id='email'
              label='Email'
              required
              placeholder='Enter your email'
              type='email'
              fullWidth
              onChange={handleChange('email')}
              onBlur={handleBlur}
              error={errors['email'] !== undefined}
              // Forces a constant helper text margin
              helperText={errors['email'] || ' '}
              disabled={loading}
            />
            <TextField
              variant='outlined'
              id='firstName'
              label='First Name'
              required
              fullWidth
              placeholder='First Name'
              onBlur={handleBlur}
              onChange={handleChange('firstName')}
              error={errors['firstName'] !== undefined}
              // Forces a constant helper text margin
              helperText={errors['firstName'] || ' '}
              disabled={loading}
            />
            <TextField
              variant='outlined'
              id='lastName'
              label='Last Name'
              required
              fullWidth
              placeholder='Last Name'
              onBlur={handleBlur}
              onChange={handleChange('lastName')}
              error={errors['lastName'] !== undefined}
              // Forces a constant helper text margin
              helperText={errors['lastName'] || ' '}
              disabled={loading}
            />
            <TextField
              id='message'
              required
              label='More information'
              placeholder='Details around the issue you are experiencing'
              multiline
              rows={3}
              maxRows={10}
              variant='outlined'
              onBlur={handleBlur}
              onChange={handleChange('message')}
              fullWidth
              error={errors['message'] !== undefined}
              helperText={errors['message'] || ' '}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={6}>
            <Button
              ref={buttonRef}
              variant='contained'
              color='secondary'
              onClick={handleSendSupportEmail({ values, setState })}
              disabled={btnDisabled}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Paper>
    )
  }
}
