import React from 'react'
import { Grid, Button, Typography, Box, TextField } from '@mui/material'
import { capitalizeFirstLetter } from '../../common/helpers'

export const HousingForm = ({
  formSubmitted,
  handleSubmit,
  formData,
  handleChange,
  errors,
  handleCreateAccount,
  signedIn,
}) => {
  return (
    <Grid item xs={12} md={6}>
      {!formSubmitted ? (
        <Box
          sx={{
            p: 4,
            border: '1px solid #008080',
            borderRadius: 2,
          }}
        >
          <Typography variant='h4' sx={{ mb: 4, color: '#008080', textAlign: 'center' }}>
            Get in Touch for Relocation Assistance
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label='First Name'
                  name='firstName'
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                  error={!!errors.firstName}
                  helperText={errors.firstName}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label='Last Name'
                  name='lastName'
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                  error={!!errors.lastName}
                  helperText={errors.lastName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label='Email'
                  name='email'
                  value={formData.email}
                  onChange={handleChange}
                  required
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label='Phone Number'
                  name='phone'
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  error={!!errors.phone}
                  helperText={errors.phone}
                  inputProps={{ maxLength: 14 }} // Maximum length for formatted phone number
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label='What do you need help with?'
                  name='message'
                  value={formData.message}
                  onChange={handleChange}
                  required
                  error={!!errors.message}
                  helperText={errors.message}
                />
              </Grid>
              <input type='hidden' name='source' value='www.mystethi.com' />
              <input type='hidden' name='eventType' value='General Inquiry' />
            </Grid>
            <Box sx={{ textAlign: 'center', mt: 3 }}>
              <Button
                variant='contained'
                sx={{
                  backgroundColor: '#008080',
                  '&:hover': {
                    backgroundColor: '#005959',
                  },
                }}
                type='submit'
                size='large'
              >
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      ) : (
        <Box textAlign='center'>
          <Typography variant='h5' sx={{ mb: 2, color: '#008080' }}>
            Congratulations {capitalizeFirstLetter(formData.firstName)}, on taking the next step in
            your relocation journey! One of our relocation experts will be reaching out to you soon
            to discuss your potential move.
          </Typography>
          {!signedIn && (
            <>
              <Typography variant='body1' sx={{ mb: 4 }}>
                Would you like to create a MyStethi account to aid in your job search?
              </Typography>
              <Button
                variant='contained'
                color='primary'
                sx={{ mt: 2 }}
                onClick={handleCreateAccount}
              >
                Create MyStethi Account
              </Button>
            </>
          )}
        </Box>
      )}
    </Grid>
  )
}

export default HousingForm
