import React from "react"
import { Typography, Grid } from "@mui/material"

const TermsAndConditions = () => {
  return (
    <Grid container justifyContent="center">
      <Grid
        container
        style={{ backgroundColor: "white", width: "75%", padding: "20px" }}
        spacing={3}
      >
          <Grid item>
            <Typography variant="h5">All Pages Copyright © 2024, MyStethi, LLC, All Rights Reserved.
            </Typography>
          </Grid>
        <Grid container style={{paddingTop:"10px"}}>
        <Grid item>
          <Typography variant="h4">TERMS OF SERVICE</Typography>
        </Grid>
        </Grid>

        <Grid item>
          <Typography variant="body1">
            Please read these terms of service very carefully before registering for the MyStethi web site and Service.  These terms of service (“Agreement”) govern you accessing content and using www.mystethi.com and its  affiliate’s web sites and mobile applications. This Agreement between you (“you”) and MyStethi, is subject  to change by us as described below. By clicking the “I Accept” button, checkbox, or other mechanism to  signal assent, or by using the Site, you (i) accept this Agreement; (ii) agree to be bound by these terms and  conditions; and (iii) have entered into a binding agreement between you and MyStethi.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            1. This web site and any mobile application (collectively the “Site”) is owned and operated by MyStethi,  LLC (referred to as “MyStethi” herein). The Site and its content (“Content”) and the MyStethi job search  service (“Service”) may only be accessed in accordance with this Agreement. Any violation of the  copyright in the Content or these terms and conditions may be enforced by MyStethi or the copyright  owner to the fullest extent allowed by law.
            {" "}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            2. You acknowledge and agree that MyStethi may stop (permanently or temporarily) providing the Services  (or any features within the Services) to you or to users generally at MyStethi’s sole discretion, without  prior notice to you. You may stop using the Services at any time. You do not need to specifically inform  MyStethi when you stop using the Services. You acknowledge and agree that if MyStethi disables access  to your account, you may be prevented from accessing the Services, your account details or any files or  other materials which is contained in your account.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            3. You agree not to access (or attempt to access) any of the Services by any means other than through the  interface that is provided by MyStethi, unless you have been specifically allowed to do so in a separate  agreement with MyStethi. You agree that you will not engage in any activity that interferes with or  disrupts the Services (or the servers and networks which are connected to the Services). Unless you  have been specifically permitted to do so in a separate agreement with MyStethi, you agree that you will  not reproduce, duplicate, copy, sell, trade or resell the Services for any purpose. You agree that you are  solely responsible for (and that MyStethi has no responsibility to you or to any third party for) any breach  of your obligations under this Agreement and for the consequences (including any loss or damage which  MyStethi may suffer) of any such breach.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            4. MyStethi does not charge for access using its mobile application; however, your carrier rates will apply,  such as fees for text messaging and data charges. You hereby acknowledge, agree, and provide consent  in order for users to sync any device with your information.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            5. The Site and Services provide access to medical, real estate and other professionals (“Professionals”).  The Sites and Services also provide services to the Professionals, some of which are required by their  state’s jurisdictions to obtain a license to perform their particular professional services (“Licensed  Professionals”). For purposes of clarity, the term “Professionals” shall include “Licensed Professionals”  when used herein below. MyStethi does not screen or endorse any Professionals. Your interactions with  any Professionals is solely at your own risk.{" "}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            6. The Site and Services display Content that is not created or developed by MyStethi. This Content is the  sole responsibility of the person or entity that creates or develops it. MyStethi may review such third  party-Content to determine whether it is illegal or violates its policies, and MyStethi may remove or  refuse to display Content that it reasonably believes violates its policies or the law, but MyStethi is not  obligated to do so. MyStethi does not regularly screen third-party Content that is published via its  Services. This includes the information that Professionals post on the Site (“Professional Information”),  and MyStethi cannot guarantee the accuracy, adequacy or quality of any such Professional Information,  or the qualifications of those posting it.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            7. You understand that all information (such as data files, written text, computer software, music, audio  files or other sounds, photographs, videos or other images) which you may have access to as part of, or
            through your use of, the Services are the sole responsibility of the person from whom such materials  originated, which may be you.
            {" "}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            8. MyStethi is a platform where Professionals unaffiliated with MyStethi can offer information and interact  with other users. By using the Services, you may engage in direct discussions with a Professional. Any  professional relationship formed as a result of such discussions is between you and the Professional you  speak with and not between you and MyStethi. Furthermore, you understand that MyStethi cannot be  held responsible for the quality or accuracy of any Professional Information or professional services  provided by any Professionals you connect with via the Services.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            9. The Professional Information posted on the Site is intended for general informational purposes only. The  Services are not a substitute for an in-person or telephonic consultation with a Licensed Professional and  you should not rely on such Legal Information. You understand that questions and answers or other  postings to the Services are not confidential and are not subject to any professional-client privilege.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            10. You acknowledge that certain states and jurisdictions have special rules relating to the services of the  Licensed Professionals. You should contact your state or local jurisdiction’s licensing bodies for more  information. It is solely the responsibility of the Licensed Professionals to ensure that any information  or advertisements they post or place on the Site and any communications they may have with you or  other prospective clients through the Services, fully complies with all applicable laws and rules of  professional conduct, including those concerning the unauthorized practice of their profession and those  controlling the form, manner or content of communications with clients, advertising, or other matters.  {" "}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            11. MyStethi reserves all rights not specifically granted herein. You shall not modify any copyright notices,  proprietary legends, any trademark and service mark attributions, any patent markings, and other indicia  of ownership on the Content or other materials accessed through the Service. The delivery of, and license  to, the Content and/or access to third party materials does not transfer to you any commercial or  promotional use rights in the Content or any portion thereof. Any use of Content, or descriptions; any  derivative use of this Site or its materials; and any use of data mining, robots, or similar data gathering  and extraction tools is strictly prohibited. In no event shall the user frame any portion of the Site or any  materials contained therein.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            12. While MyStethi uses reasonable efforts to include accurate and up-to-date information on the Site,  MyStethi makes no warranties or representations as to its accuracy. MyStethi assumes no liability or  responsibility for any errors or representations in the Content or this Site.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            13. The Site may contain links to other sites on the Internet which are owned and operated by third party  vendors and other third parties (the “External Sites”). You acknowledge that MyStethi is not responsible  for the availability of, or the materials located on or through, any External Sites. You should contact the  site administrator or webmaster for those External Sites if you have any concerns regarding such links  or the materials located on such External Sites.{" "}
          </Typography>{" "}
        </Grid>
        <Grid item>
          <Typography variant="body1">
            14. You and other users of the Site may post comments, reviews, and other materials and submit suggestions,  ideas, or other information. You are solely responsible for your conduct and any content that you submit,  post, and display on the Site. You may not post materials or content that contains any unlawful, harmful,  threatening, abusive, harassing, defamatory, vulgar, obscene, or hateful content or content which is  racially, ethnically or otherwise objectionable, or which infringes upon the rights of any third party. You  acknowledge that by accessing the Site, you may come into contact with content that you find harmful,  offensive, threatening, indecent or objectionable and you acknowledge that MyStethi shall have no  liability to you for the content including, but not limited to explicit language and other potentially  offensive material. You agree to not impersonate any person or communicate under a false name or a  name the user is not entitled or authorized to use. MyStethi has the right (but not the obligation) to  remove, prohibit, edit or discontinue any content on the Site, including content that has been posted by  users.
          </Typography>{" "}
        </Grid>
        <Grid item>
          <Typography variant="body1">
            15. If you post content or submit material, unless such content or material submittal is subject to separate  terms and conditions, MyStethi shall own such content and material and you hereby assign any rights in  such content and material to MyStethi. In the event ownership in the content or material cannot be  granted to MyStethi, you grant MyStethi and its affiliates a perpetual, irrevocable, worldwide, royalty
            free, non-exclusive, sublicensable right and license to make, use, sell, sublicense, reproduce, distribute,  perform, display, prepare derivative works from and otherwise exploit all such content and material as  if it were the full owner thereof. Furthermore, you grant MyStethi, its affiliates, and sublicensees the  right to use your name and/or user name in connection with the content. You represent and warrant that  all content and materials you provide: (a) shall be your original work product and will not be based on,  or derived from, the proprietary information or items of a third party; and (b) shall comply with all  content restrictions set forth in this Agreement. Furthermore, you represent and warrant that your use of  the Service in connection with any Content or third party content complies with all laws including, but  not limited to, United States copyright law. You will defend, indemnify and hold MyStethi and its  affiliates harmless from and against any claims resulting from any content or materials you provide  hereunder or your use of the Service.
            {" "}
          </Typography>{" "}
        </Grid>
        <Grid item>
          <Typography variant="body1">
            16. At your discretion, you may provide feedback to MyStethi concerning the functionality and performance  of the Service from time to time, including, without limitation, identifying potential errors,  improvements, modifications, bug fixes, or enhancements (“Feedback”). If you, through your evaluation  or otherwise, suggests any Feedback, you hereby assign the ownership in all Feedback to MyStethi. In  the event ownership in the Feedback cannot be granted to MyStethi, you grant MyStethi at no charge a  perpetual, irrevocable, royalty-free, worldwide right and license to use, reproduce, disclose, sublicense,  distribute, modify, and otherwise exploit such Feedback without restriction. You agree that MyStethi  may disclose that Feedback to any third party in any manner and you agree that MyStethi has the ability  to sublicense all Feedback in any form to any third party without restriction.
          </Typography>{" "}
        </Grid>
        <Grid item>
          <Typography variant="body1">
            17. The Site may contain areas in which additional terms and conditions apply. For purposes of the use of  such areas, in the event of a conflict between the terms and conditions of such other areas and these  Terms and Conditions, the terms and conditions of the other area shall prevail. MyStethi may at any  time revise these Terms and Conditions by updating this posting. You are bound by any such revisions  and should therefore periodically visit this page to review the current Terms and Conditions to which  you are bound.{" "}
          </Typography>{" "}
        </Grid>
        <Grid item>
          <Typography variant="body1">
            18. You shall not transmit to MyStethi or upload to this Site any Harmful Code or use or misappropriate the  data on this Site for your own commercial gain. “Harmful Code” shall mean any software (sometimes  referred to as “viruses,” “worms,” “trojan horses,” “time bombs,” “time locks,” “drop dead devices,”  “traps,” “access codes,” “cancelbots” or “trap door devices”) that: (a) is intentionally designed to  damage, disrupt, disable, harm, impair, interfere with, intercept, expropriate or otherwise impede in any  manner, any data, storage media, program, system, equipment or communication, based on any event,  including for example but not limited to (i) exceeding a number of copies, (ii) exceeding a number of  users, (iii) passage of a period of time, (iv) advancement to a particular date or other numeral, or (v) use  of a certain feature; or (b) would enable an unauthorized person to cause such result; or (c) would enable  an unauthorized person to access another person’s information without such other person’s knowledge  and permission.{" "}
          </Typography>{" "}
        </Grid>
        <Grid item>
          <Typography variant="body1">
            19. You may not use your username and password for any unauthorized purpose. {" "}
          </Typography>{" "}
        </Grid>
        <Grid item>
          <Typography variant="body1">
            20. MyStethi may retain and use, information collected in your use of the Service, provided such information  does not individually identify you. MyStethi may collect, use and share your personal information in  accordance with its Privacy Policy.{" "}
          </Typography>{" "}
        </Grid>
        <Grid item>
          <Typography variant="body1">
            21. MyStethi does not knowingly collect any information from anyone who we know to be under the age of  13. If you are under the age of 18, you should use this website only with the involvement of a parent or  guardian and should not submit any personal information to MyStethi. If MyStethi discovers that a
            person under the age of 13 has provided MyStethi with any personal information, MyStethi will use  commercially reasonable efforts to delete such person's personal information from all MyStethi systems.
            {" "}
          </Typography>{" "}
        </Grid>
        <Grid item>
          <Typography variant="body1">
            22. MyStethi may terminate your access to our Site or the Service for any reason at any time. The provisions  that by their nature are designed to survive termination or expiration of this Agreement shall survive  termination or expiration of this Agreement or your access to our Site or Service.  {" "}
          </Typography>{" "}
        </Grid>
        <Grid item>
          <Typography variant="body1">
            23. By using this Site, you signify your assent to these terms of use and agree to comply with all applicable  laws and regulations, including U.S. export and re-export control laws and regulations, copyright laws  and other laws regarding intellectual property. If you do not agree to these terms of use, please do not  use the Site.
          </Typography>{" "}
        </Grid>
        <Grid item>
          <Typography variant="body1">
            24. The materials on this Site are provided with “Restricted Rights.” Use, duplication, or disclosure by the  Government is subject to restrictions as set forth in applicable laws and regulations. Use of the materials  by the Government constitutes acknowledgment of MyStethi’s proprietary rights in them.
          </Typography>{" "}
        </Grid>
        <Grid item>
          <Typography variant="body1">
            25. THE MATERIALS, CONTENT ON THIS SITE AND SERVICES ARE PROVIDED “AS IS”, “AS  AVAILABLE” WITHOUT WARRANTIES OF ANY KIND EITHER EXPRESS OR IMPLIED.  MYSTETHI SHALL HAVE NO RESPONSIBILITY OR LIABILITY FOR ANY CONTENT,  MATERIALS POSTED ON THE SITE OR SERVICES. MYSTETHI MAKES NO GUARANTEES  AS TO UPTIME OR AVAILABILITY OF THE SERVICE. TO THE FULLEST EXTENT POSSIBLE  PURSUANT TO THE APPLICABLE LAW, MYSTETHI DISCLAIMS ALL WARRANTIES,  EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF  MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NONINFRINGEMENT OR  OTHER VIOLATION OF RIGHTS. THE SERVICE MAY BE SUBJECT TO LIMITATIONS,  DELAYS, AND OTHER PROBLEMS INHERENT IN THE USE OF THE INTERNET AND  ELECTRONIC COMMUNICATIONS. MYSTETHI IS NOT RESPONSIBLE FOR ANY DELAYS,  DELIVERY FAILURES, OR OTHER DAMAGE RESULTING FROM SUCH PROBLEMS,  INCLUDING INTERNET CONGESTION, VIRUS ATTACKS, AND DENIAL OF SERVICE (DOS)  ATTACKS.
          </Typography>{" "}
        </Grid>
        <Grid item>
          <Typography variant="body1">
            26. UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, SHALL  MYSTETHI OR ITS THIRD PARTY LICENSORS BE LIABLE FOR ANY DIRECT, INDIRECT,  SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES, INCLUDING, BUT NOT LIMITED  TO, LOSS OF DATA OR PROFIT, ARISING OUT OF THE USE, OR THE INABILITY TO USE,  THE MATERIALS ON THIS SITE OR THE SERVICE, EVEN IF MYSTETHI HAS BEEN ADVISED  OF THE POSSIBILITY OF SUCH DAMAGES AND NOTWITHSTANDING THE FAILURE OF  ESSENTIAL PURPOSE OF ANY REMEDY. MYSTETHI’S AND ITS LICENSORS’ ENTIRE AND  AGGREGATE LIABILITY, WHETHER BASED IN CONTRACT, TORT (INCLUDING  NEGLIGENCE), STRICT LIABILITY OR OTHERWISE, SHALL NOT EXCEED $100, EVEN IF  MYSTETHI HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND  NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY REMEDY. IF YOUR  USE OF MATERIALS FROM THIS SITE OR THE SERVICE RESULTS IN THE NEED FOR  SERVICING, REPAIR OR CORRECTION OF EQUIPMENT OR DATA, YOU ASSUME ANY  COSTS THEREOF. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF  INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR  EXCLUSION MAY NOT APPLY TO YOU.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            27. You shall not assign or transfer this Agreement without the prior written consent of MyStethi. Any  attempt to assign or transfer this Agreement by you shall be void.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            28. The laws of the State of Texas, excluding its conflicts of law rules, govern this Agreement and your use  of the Site. Notwithstanding the foregoing, the Arbitration Agreement in Section 30 shall be governed  by the Federal Arbitration Act. For the avoidance of doubt, the choice of Texas governing law shall not  supersede any mandatory consumer protection legislation in such jurisdictions.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            29. MyStethi shall not be liable to you for any delay or failure of MyStethi to perform its obligations  hereunder if such delay or failure arises from any cause or causes beyond the reasonable control of  MyStethi. Such causes shall include, but are not limited to, acts of God, floods, fires, loss of electricity  or other utilities, labor strike, or delays by you in performing any requirements hereunder.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            30. This Agreement may not be modified, supplemented, qualified, or interpreted by any trade usage or prior  course of dealing not made a part of the Agreement by its express terms.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            31. Each provision of this Agreement is a separately enforceable provision. If any provision of this  Agreement is determined to be or becomes unenforceable or illegal, such provision shall be reformed to  the minimum extent necessary in order for this Agreement to remain in effect in accordance with its  terms as modified by such reformation. The invalidity or unenforceability of any provision of this  Agreement shall not affect the validity of this Agreement as a whole and any such provision should be  enforced by authorities, and reconstructed if need be, to apply to the maximum extent allowable under  applicable law.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            32. The failure by either party to enforce at any time any of the provisions of this Agreement, to exercise  any election or option provided herein, or to require at any time the performance of the other of any of  the provisions herein will not in any way be construed as a waiver of such provisions. No failure or delay  in enforcing any right or exercising any remedy will be deemed a waiver of any right or remedy.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            33.  Reasonable attorneys’ fees and costs will be awarded to the prevailing party in the event of litigation  involving the enforcement or interpretation of this Agreement.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            34. The section headings used herein are for convenience only and shall not be given any legal import.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            35. Any dispute, controversy or claim arising under, out of or relating to this Agreement and any subsequent  amendments of this Agreement, including, without limitation, its formation, validity, binding effect,  interpretation, performance, breach or termination, as well as non-contractual claims, shall be submitted  to arbitration before the American Arbitration Association (“AAA”) in accordance with the AAA  Consumer Arbitration Rules. The place of arbitration shall be Austin, Texas. The language to be used  in the arbitral proceedings shall be English. The parties may apply to any court of competent jurisdiction  for a temporary restraining order, preliminary injunction, or other interim or conservatory relief as  necessary, without breach of this Section and without abridgment of the powers of the arbitrator. The  arbitrator may award any form of individual or equitable relief, including injunctive relief. Any award  will be final and conclusive to the parties and may be entered in any court of competent jurisdiction. You  agree to the entry of injunctive relief to stop any lawsuit or to remove you as a participant in such a suit.  By using the Site in any manner, you agree to this arbitration agreement. In doing so, YOU GIVE UP  YOUR RIGHT TO GO TO COURT to assert or defend any claims between you and MyStethi. YOU  ALSO GIVE UP YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION OR OTHER CLASS  PROCEEDING. This provision preventing you from bringing, joining or participating in class action  lawsuits is an independent covenant. You may opt-out of this Section by providing written notice of your  decision within thirty (30) days of the date that you first use the Site.
            All claims arising out of or relating to this Agreement, to the Services, or to your relationship with  MyStethi that, for whatever reason, are not submitted to arbitration will be litigated exclusively in the  federal or state courts of Travis County, Austin, Texas, U.S.A. You and MyStethi consent to the exercise  of personal jurisdiction of such courts in the State of Texas and waive any claim that such courts  constitute an inconvenient forum.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            36. This Agreement shall constitute the entire agreement between the parties regarding the subject matter  hereof and supersede all proposals and prior discussions and writings between the parties with respect  thereto.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4">TERMS OF SERVICE</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default TermsAndConditions
