import React from 'react'
import { MenuItem, TextField } from '@mui/material'
import { Check } from '@mui/icons-material'

const JobMultiSelectTextField = ({
  label,
  name,
  value = [],
  showError,
  options = null,
  setState,
  setJobAlertData,
  required,
}) => {
  const handleChange = e => {
    const newValue = e.target.value

    // Update the specific field with the new multi-select values
    setState(name, newValue) // Pass `name` as the field key for setState

    // Update setJobAlertData only if necessary
    if (setJobAlertData && options) {
      const selectedOptions = options.filter(op => newValue.includes(op.id))
      setJobAlertData(prev => ({
        ...prev,
        [name]: { name: name, label: label, value: selectedOptions },
      }))
    }
  }

  return (
    <TextField
      id={name}
      select={!!options && options.length > 0}
      label={`${label}${required ? ' *' : ''}`}
      value={value}
      onChange={handleChange}
      fullWidth
      size='small'
      error={showError && value.length === 0}
      helperText={showError && value.length === 0 ? 'Required' : ''}
      SelectProps={{
        multiple: true,
        renderValue: selected =>
          options
            .filter(option => selected.includes(option.id))
            .map(option => option.name)
            .join(', '), // Show selected options as comma-separated values
      }}
    >
      {options?.map(o => (
        <MenuItem key={o.id} value={o.id}>
          {value.includes(o.id) && <Check sx={{ marginRight: '8px', color: 'green' }} />}
          {o.name}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default JobMultiSelectTextField
