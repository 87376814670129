import React, { useEffect } from 'react'
import { Link } from '@mui/material'
import Loader from 'components/Loader'
import { JobInterestTable } from 'components/Table/JobInterestTable'
import { useSelector, useDispatch } from 'react-redux'
import { fetchAllCandidateJobData } from '../../redux/candidateJob'

const JobInterest = () => {
  const dispatch = useDispatch()
  const { loading, candidateJobs = [] } = useSelector(state => state.candidateJobs)

  const partnerInterestData = candidateJobs.map(candidateJob => ({
    jobId: (
      <Link
        color='secondary'
        href={`/jobs/${candidateJob.jobId}`}
        underline='always'
        target='_blank'
      >
        {candidateJob.jobId}
      </Link>
    ),
    candidateId: candidateJob.candidateId,
    firstName: candidateJob.candidate.user.firstName,
    lastName: candidateJob.candidate.user.lastName,
    email: candidateJob.candidate.user.email,
    showEmail: candidateJob.candidate.user.showEmail.toString(),
    phone: candidateJob.candidate.user.phone,
    showPhone: candidateJob.candidate.user.showPhone.toString(),
    specialty: candidateJob.candidate.specialties[0].name,
    subspecialty:
      candidateJob.candidate.subspecialties && candidateJob.candidate.subspecialties.length > 0
        ? candidateJob.candidate.subspecialties[0].name
        : null,
    interestedInContactAt: new Date(candidateJob.interestedInContactAt).toDateString(),
  }))

  useEffect(() => {
    dispatch(fetchAllCandidateJobData({}))
  }, [])

  return <>{loading ? <Loader /> : <JobInterestTable data={partnerInterestData} />}</>
}

export default JobInterest
