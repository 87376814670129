import React from 'react'
import AddEmployerComponent from './AddEmployerComponent'

export const defaultProps = {
  name: 'Employers',
  columns: [
    { name: 'id', header: 'Id' },
    { name: 'name', header: 'Name' },
    { name: 'userCount', header: '# Users' },
    { name: 'jobCount', header: '# Jobs' },
    {
      name: 'actions',
      header: 'Actions',
    },
  ],
  addComponent: <AddEmployerComponent />, // Render the component directly
  searchableFields: ['name'],
}
