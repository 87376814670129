import React from 'react'
import { MenuItem, TextField } from '@mui/material'

const JobSingleSelectTextField = ({
  label,
  name,
  value = '',
  showError,
  options = null,
  setState,
  setJobAlertData,
  required,
}) => {
  const handleChange = e => {
    const newValue = e.target.value

    // Update the specific field with the new single-select value
    setState(name, newValue) // Pass `name` as the field key for setState

    // Update setJobAlertData only if necessary
    if (setJobAlertData && options) {
      const selectedOption = options.find(op => op.id === newValue)
      setJobAlertData(prev => ({
        ...prev,
        [name]: { name: name, label: label, value: selectedOption },
      }))
    }
  }

  return (
    <TextField
      id={name}
      select={!!options && options.length > 0}
      label={`${label}${required ? ' *' : ''}`}
      value={value}
      onChange={handleChange}
      fullWidth
      size='small'
      error={showError && !value}
      helperText={showError && !value ? 'Required' : ''}
    >
      {options?.map(o => (
        <MenuItem key={o.id} value={o.id}>
          {o.name}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default JobSingleSelectTextField
