import React from 'react'
import GenericDialog from 'components/Dialog/GenericDialog'
import { copyToLower } from 'data/helpers'
import { useDialogState } from 'data/dialog/actions'
import { useEmployer } from 'data/employer/actions'

export const dialogId = 'Edit Hospital Profile'

const handleValidation = (values, dialogState) => {
  var errors = copyToLower(dialogState.errors) // start with server generated errors, ensure all keys start with lowercase letterreturn errors
  return errors
}

export default function EditHospitalDialog({ id = dialogId }) {
  const [{ open, employerId }, setState] = useDialogState(id)
  const [employer, getEmployer, setEmployer] = useEmployer()

  React.useEffect(() => {
    open && getEmployer(employerId)
  }, [employerId, open, getEmployer])

  const handleSubmit = React.useCallback(
    values => {
      setEmployer(values, setState)
    },
    [setEmployer, setState],
  )

  return (
    <GenericDialog
      id={id}
      title={id}
      maxWidth='sm'
      submitLabel={'Save Profile'}
      onSubmit={handleSubmit}
      validate={handleValidation}
      initialValues={employer}
      fields={[
        {
          id: 'id',
          hidden: true,
        },
        {
          id: 'name',
          label: 'Hospital Name',
          required: true,
          forceErrorMargin: false,
          variant: 'outlined',
          margin: 'dense',
        },
        {
          id: 'about',
          label: 'About',
          type: 'textEditor',
          initialValue: employer.about || null,
          forceErrorMargin: false,
          variant: 'outlined',
          margin: 'dense',
          multiline: true,
          rows: 6,
          rowsMax: 32,
        },
        {
          id: 'aboutRaw',
          hidden: true
        },
      ]}
    />
  )
}
