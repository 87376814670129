import React from 'react'
import { TextField, useTheme } from '@mui/material'
import MaskedInput from 'react-text-mask'

const TextMaskCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { ...other } = props
  return (
    <MaskedInput
      {...other}
      ref={ref2 => {
        ref(ref2 ? ref2.inputElement : null)
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  )
})

export const PhoneNumberField = ({ value, disabled, handleChange, label, name, ...props }) => {
  const theme = useTheme()
  return (
    <TextField
      fullWidth
      disabled={disabled}
      value={value}
      onChange={event => handleChange(event)}
      name={name}
      key={name}
      size='small'
      label={label}
      sx={{
        '& .MuiInputBase-input.Mui-disabled': {
          WebkitTextFillColor: theme.palette.primary.dark,
        },
        '& fieldset': disabled && { border: 'none' },
      }}
      {...props}
      InputProps={{
        inputComponent: TextMaskCustom,
      }}
    />
  )
}

export default PhoneNumberField
