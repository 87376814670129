import * as React from 'react'
import { Grid, TextField, Chip, Autocomplete, Checkbox } from '@mui/material'
import { useApplicationDrawer } from './useApplicationDrawer'
import TagDialog from 'components/Dialog/TagDialog'
import AddIcon from '@mui/icons-material/Add'

export const TagInput = ({ selectedRow, availableTags, formState, setFormState }) => {
  const { openDialog, handleDialogOpen, handleDialogClose } = useApplicationDrawer({ selectedRow })
  const onDialogClose = newTagId => {
    handleDialogClose()
    if (newTagId) {
      setFormState({
        ...formState,
        tags: [...(formState.tags || []), newTagId],
      })
    }
  }

  const handleTagsChange = (event, value) => {
    const selectedTagIds = value.map(tag => tag.id)
    setFormState({ ...formState, tags: selectedTagIds })
  }

  return (
    <Grid item>
      <Autocomplete
        multiple
        id='tags-standard'
        options={[{ id: 'add-new-tag', tag: 'Add new tag...' }, ...availableTags]} // Ensure 'Add new tag' is always first
        value={availableTags.filter(tag => formState.tags && formState.tags.includes(tag.id))} // Filter tags based on selected IDs without conditional rendering
        getOptionLabel={option => option.tag}
        onChange={(event, value) => {
          const selectedOption = value[value.length - 1]
          if (selectedOption && selectedOption.id === 'add-new-tag') {
            handleDialogOpen()
            value.pop() // Remove 'Add new tag...' from the selected list
          } else {
            handleTagsChange(event, value)
          }
        }}
        filterOptions={(options, state) => {
          // Always include "Add new tag" as the first option
          return options.filter(
            option =>
              option.id === 'add-new-tag' ||
              option.tag.toLowerCase().includes(state.inputValue.toLowerCase()),
          )
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        disableCloseOnSelect
        renderOption={(props, option, { selected }) => (
          <li {...props} style={option.id === 'add-new-tag' ? { backgroundColor: '#f0f0f0' } : {}}>
            {option.id === 'add-new-tag' ? (
              <>
                <AddIcon style={{ marginRight: 8 }} color='primary' />
                <span style={{ fontWeight: 'bold' }}>{option.tag}</span>
              </>
            ) : (
              <>
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                {option.tag}
              </>
            )}
          </li>
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              key={option.id}
              label={option.tag}
              style={{ backgroundColor: option.color, color: '#fff' }}
              {...getTagProps({ index })}
              onClick={e => e.stopPropagation()} // Stop propagation on click
            />
          ))
        }
        renderInput={params => (
          <TextField
            {...params}
            variant='standard'
            label='Tags'
            placeholder='Add tag'
            fullWidth
            helperText='Click a tag to remove it. Type to add a new tag.'
          />
        )}
      />
      <TagDialog openDialog={openDialog} handleDialogClose={onDialogClose} />
    </Grid>
  )
}

export default TagInput
