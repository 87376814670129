import * as React from 'react'
import { Tabs, Tab, Grid, Typography, useTheme } from '@mui/material'
const FolderTabs = ({ tabSteps, setActiveTab, activeTab }) => {
  const theme = useTheme()

  const handleChange = (event, newValue) => {
    setActiveTab(newValue)
  }
  const activeStyling = {
    border: '1px solid',
    borderBottom: 'none',
    borderRadius: '20px 20px 0 0',
    backgroundColor: theme.palette.primary.light,
  }

  const inActiveStyling = {
    border: '1px solid',
    borderRadius: '20px 20px 0 0',
  }

  return (
    <Grid sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <Tabs
        variant='fullWidth'
        value={activeTab}
        onChange={handleChange}
        TabIndicatorProps={{
          style: {
            display: 'none',
          },
        }}
      >
        {tabSteps.map((step, index) => (
          <Tab
            key={index}
            label={<Typography variant='body2'>{step.label}</Typography>}
            sx={activeTab === index ? activeStyling : inActiveStyling}
          />
        ))}
      </Tabs>
    </Grid>
  )
}

export default FolderTabs
