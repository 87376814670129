import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { defaultDialogState } from 'data/dialog/reducers'
import { UPDATE_DIALOG } from 'data/constants'

const updateDialog = (id, payload) => ({ type: UPDATE_DIALOG, id, payload })

export const useDialogState = id => {
  const dispatch = useDispatch()
  const dialogState = useSelector(state => state.dialogs[id] || defaultDialogState)
  const setDialogState = React.useCallback(payload => dispatch(updateDialog(id, payload)), [
    id,
    dispatch,
  ])
  return [dialogState, setDialogState]
}
