import * as React from 'react'
import { snackbarTypes, UPDATE_EMPLOYER_USER } from 'data/constants'
import { fetchDispatch } from 'data/helpers'
import { useSelector, useDispatch } from 'react-redux'
import { useDispatchPayload } from 'data/hooks'
import { showSnackbar } from 'data/snackbar/actions'

export const GET_EMPLOYER_USER_LOADING = 'GET_EMPLOYER_USER_LOADING'

export const GET_EMPLOYER_USER_SUCCESS = 'GET_EMPLOYER_USER_SUCCESS'

export const GET_EMPLOYER_USER_ERROR = 'GET_EMPLOYER_USER_ERROR'

export function getEmployerUserLoading() {
  return {
    type: GET_EMPLOYER_USER_LOADING,
  }
}

export function getEmployerUserSuccess(payload) {
  return {
    type: GET_EMPLOYER_USER_SUCCESS,
    payload,
  }
}

export function getEmployerUserError() {
  return {
    type: GET_EMPLOYER_USER_ERROR,
  }
}

export function useEmployerUser(formatter = undefined) {
  const dispatch = useDispatch()
  const dispatchPayload = useDispatchPayload()
  const employerUser = useSelector(
    s => (formatter ? formatter(s.employerUser) : s.employerUser) || {},
  )
  const employerUserId = useSelector(s => s.identity.employerUserId)

  // Updates the redux store with new employer user information
  const onResponse = React.useCallback(
    ({ employerUser }) =>
      dispatchPayload(UPDATE_EMPLOYER_USER, { ...employerUser, loading: false }),
    [dispatchPayload],
  )

  const onResponseAdd = React.useCallback(
    ({ employerUser }) =>
      dispatchPayload(GET_EMPLOYER_USER_SUCCESS, { ...employerUser, loading: false }),
    [dispatchPayload],
  )

  // Gets the employer user information from the server and updates the redux store with the results.  If no id is specified, use the current employer user id
  const getEmployerUser = React.useCallback(
    (id = employerUserId) => {
      dispatchPayload(UPDATE_EMPLOYER_USER, prev => ({ ...prev, loading: true }))
      fetchDispatch({ path: `/api/employer_users/${id}`, method: "GET", onResponse })
    },
    [onResponse, employerUserId],
  )

  // Updates the database with new employer user information, closes dialog state or handles errors, and updates the redux store with the response
  const setEmployerUser = React.useCallback(
    (values, setState, OnResponse) => {
      dispatchPayload(UPDATE_EMPLOYER_USER, prev => ({ ...prev, loading: true }))
      fetchDispatch({
        path: `/api/employer_users/${values.id}`,
        method: "PUT",
        values,
        setState,
        onResponse: json => {
          showSnackbar(dispatch, 'Successfully updated your profile.', snackbarTypes.SUCCESS)
          onResponse(json) // Call internal onResponse to update the redux store
          OnResponse && OnResponse(json) // Call external OnResponse to perform any other actions necessary. I.E change route, signInSuccess, etc
        },
        onError: () => {
          showSnackbar(
            dispatch,
            'An error occurred while updating your profile.',
            snackbarTypes.ERROR,
          )
        },
        formData: true,
      })
    },
    [onResponse],
  )

  const createEmployerUser = React.useCallback(
    (values, setState, onResponseAdd) => {
      fetchDispatch({
        path: '/api/employer_users',
        values,
        setState,
        onResponse: json => {
          showSnackbar(dispatch, 'Successfully created user.', snackbarTypes.SUCCESS)
          onResponseAdd(json) // Call internal onResponse to update the redux store
          onResponseAdd && onResponseAdd(json) // Call external OnResponse to perform any other actions necessary. I.E change route, signInSuccess, etc
        },
        onError: () => {
          showSnackbar(dispatch, 'An error occurred while creating user.', snackbarTypes.ERROR)
        },
      })
    },
    [onResponseAdd],
  )

  const updateEmployerUser = React.useCallback((values, setState) => {
    dispatchPayload(UPDATE_EMPLOYER_USER, prev => ({ ...prev, loading: true }))
    fetchDispatch({
      path: `/api/employer_users/${values.id}`,
      method: "PUT",
      values,
      setState,
      onResponse: () => {
        showSnackbar(dispatch, 'Successfully updated user.', snackbarTypes.SUCCESS)
        dispatch(
          dispatchPayload(UPDATE_EMPLOYER_USER, prev => ({ ...prev, loading: false }))
        )
      },
      onError: () => {
        showSnackbar(dispatch, 'An error occurred while updating user.', snackbarTypes.ERROR)
      },
      formData: true,
    })
  }, [])

  return [employerUser, getEmployerUser, setEmployerUser, createEmployerUser, updateEmployerUser]
}

export function getEmployerUser(id) {
  return async dispatch => {
    dispatch(getEmployerUserLoading())
    const response = await fetchDispatch({ path: `/api/employer_users/${id}`, method: "GET" })
    if (response.employerUser) {
      return dispatch(getEmployerUserSuccess(response.employerUser))
    }

    showSnackbar(
      dispatch,
      'An error occurred attempting to get employer user information.',
      snackbarTypes.ERROR,
    )
    return dispatch(getEmployerUserError())
  }
}
