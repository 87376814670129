import React from 'react'
import { Paper, Card, CardContent, CardMedia, CardHeader, Grid, useTheme } from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import ListDataItem from './ListDataItem'
import { getLocation } from 'pages/Jobs/helpers'
import { getPhysicianProfileImage } from 'pages/Physicians/helpers'

export const ListItemRealtor = ({ data, isActive, onClick }) => {
  const theme = useTheme()
  const location = getLocation(data.city, data.state)
  const name = data.firstName + ' ' + data.lastName
  const image = getPhysicianProfileImage(data.profileImage)

  return (
    <Paper elevation={5} sx={{ cursor: 'pointer' }} onClick={() => onClick(data.id)}>
      <Card
        sx={{
          minWidth: 275,
          ...(isActive && {
            backgroundColor: theme.palette.secondary.light,
          }),
        }}
      >
        <Grid container direction={'row'} spacing={1} wrap={'nowrap'}>
          <Grid item>
            <CardMedia
              sx={{
                width: '100%',
                height: '70px',
              }}
              component='img'
              src={image}
              image={image}
              title='Realtor Photo'
            />
          </Grid>
          <Grid item xs={6}>
            <CardHeader
              style={{ overflowWrap: 'break-word' }}
              titleTypographyProps={{ variant: 'h6' }}
              title={name}
              sx={{
                display: 'flex',
                overflow: 'hidden',
                '& .MuiCardHeader-content': {
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '2',
                  WebkitBoxOrient: 'vertical',
                },
              }}
            />
          </Grid>
        </Grid>
        <CardContent>
          <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 12, sm: 12, md: 12 }}>
            <Grid item xs={12} sm={12} md={4}>
              {location && <ListDataItem icon={<LocationOnIcon />} text={location} />}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Paper>
  )
}
