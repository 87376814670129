import React from 'react'
import GenericDialog from 'components/Dialog/GenericDialog'
import { useDialogState } from 'data/dialog/actions'
import Content from './Content'

export const dialogId = 'Save Filter'

export default function SaveFilterDialog({
  selectedFilters,
  filterTypes,
  listType,
  openDialog,
  handleDialogClose,
  id = dialogId,
}) {
  const [, setState] = useDialogState(id)
  const component = () => (
    <Content
      dialogId={id}
      filterTypes={filterTypes}
      selectedFilters={selectedFilters}
      listType={listType}
      handleDialogClose={handleDialogClose}
    />
  )

  // Synchronize `openDialog` with the internal dialog state using the effect hook
  React.useEffect(() => {
    setState(prev => ({
      ...prev,
      open: openDialog, // Set dialog open state based on `openDialog` prop
      RenderComponent: component,
    }))
  }, [openDialog, setState]) // Add dependencies to avoid unnecessary re-renders
  return (
    <GenericDialog
      open={openDialog} // Ensure this is controlled by the `openDialog` prop
      selectedFilters={selectedFilters}
      headerTitle='Save New Filter'
      id={id}
    />
  )
}
