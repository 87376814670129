import React from 'react'
import { useDispatch } from 'react-redux'
import { Grid, Typography } from '@mui/material'
import GenericDialog from 'components/Dialog/GenericDialog'
import { useDialogState } from 'data/dialog/actions'

export const dialogId = 'Delete Dialog'

export default function DeleteDialog({ id = dialogId }) {
  const [{ data, onDelete, title, buttonText, isDeleted, Content = () => <></> }, setState] =
    useDialogState(id)
  const dispatch = useDispatch()
  const handleSubmit = () => {
    if (isDeleted) {
      dispatch(
        onDelete({
          ...data,
          alertCadence: 'Weekly',
        }),
      )
    } else {
      dispatch(onDelete(data))
    }
    setState()
  }
  return (
    <GenericDialog
      id={dialogId}
      title={title}
      maxWidth='sm'
      onSubmit={handleSubmit}
      submitLabel={buttonText ? buttonText : isDeleted ? 'Enable' : 'Disable'}
      cancelLabel='Cancel'
      disabled={false}
      alwaysEnabled={true}
      fields={[
        {
          type: 'genericComponent',
          id: 'content-delete',
          Component: () => (
            <Grid>
              <Typography variant='body1'>
                <Content />
              </Typography>
            </Grid>
          ),
        },
      ]}
    />
  )
}
