export const snackbarTypes = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  INFO: 'info',
}

export const SHOW_SNACKBAR = 'snackbar/SHOW_SNACKBAR'
export const CLEAR_SNACKBAR = 'snackbar/CLEAR_SNACKBAR'

export const showSnackbar = (message, snackbarType) => ({ type: SHOW_SNACKBAR, payload: { message, snackbarType } })
export const clearSnackbar = () => ({ type: CLEAR_SNACKBAR })

const initialState = {
  snackbarOpen: false,
  snackbarMessage: '',
  snackbarType: snackbarTypes.INFO,
}

export const snackbarReducer = (state = initialState, action) => {
  const { payload } = action
  switch (action.type) {
    case SHOW_SNACKBAR:
      return {
        ...state,
        snackbarOpen: true,
        snackbarMessage: payload.message,
        snackbarType: payload.snackbarType,
      }
    case CLEAR_SNACKBAR:
      return {
        ...state,
        snackbarOpen: false,
      }
    default:
      return state
  }
}

export default snackbarReducer
