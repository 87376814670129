import {
  GET_HOSPITAL_JOBS,
  GET_HOSPITAL_JOBS_SUCCESS,
  GET_HOSPITAL_JOBS_ERROR,
  GET_CANDIDATE_JOBS,
  GET_CANDIDATE_JOBS_SUCCESS,
  GET_CANDIDATE_JOBS_SUCCESS_PAGINATION,
  GET_CANDIDATE_JOBS_ERROR,
  ARCHIVE_JOB,
  ARCHIVE_JOB_SUCCESS,
  ARCHIVE_JOB_ERROR,
  SET_SELECTED_FILTERS,
  ADD_CANDIDATE_JOB_RATING_LOADING,
  ADD_CANDIDATE_JOB_RATING_SUCCESS,
  ADD_CANDIDATE_JOB_RATING_FAILURE,
  UPDATE_CANDIDATE_JOB_RATING_LOADING,
  UPDATE_CANDIDATE_JOB_RATING_SUCCESS,
  UPDATE_CANDIDATE_JOB_RATING_FAILURE,
  SET_JOBS_UPDATED,
} from 'data/jobs/actions'

export const mapJobData = jobs =>
  (jobs || []).map(job => ({
    ...job,
    candidateTypeId: job.candidateType ? job.candidateType.id : null,
    listingTitle: job.title,
    listingSubLabel: job.employer.name,
    employerUserId: job.employerUser.id,
    specialtyId: job.specialties && job.specialties.length === 1 ? job.specialties[0].id : null,
    specialty: job.specialties && job.specialties.length === 1 ? job.specialties[0].name : null,
    subspecialtyId:
      job.subspecialties && job.subspecialties.length === 1 ? job.subspecialties[0].id : null,
    subspecialty:
      job.subspecialties && job.subspecialties.length === 1 ? job.subspecialties[0].name : null,
    area: job.areaType,
    academic: job.academicType,
    image: job.employer.image,
    firstName: job.user.firstName,
    lastName: job.user.lastName,
    visas: job.visas || [],
    employerName: job.employer.name,
    phone: job.user.phone,
    email: job.user.email,
    profileImage: job.user.profileImage,
    showPhone: job.user.showPhone,
    showEmail: job.user.showEmail,
    aboutEmployer: job.employer.about,
    shiftType: job.shiftType,
    salary: job.salary,
    salaryType: job.salaryType,
    salaryMin: job.salaryMin,
    salaryMax: job.salaryMax,
  }))

const initialState = {
  jobsList: [],
  selectedFilters: [],
  isLoadingJobs: false,
  jobsUpdated: false,
}

export default function jobsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_HOSPITAL_JOBS:
      return {
        ...state,
        isLoadingJobs: true,
      }
    case GET_HOSPITAL_JOBS_SUCCESS:
      return {
        ...state,
        jobsList: mapJobData(action.payload.jobs),
        totalJobs: action.payload.totalCount,
        isLoadingJobs: false,
      }
    case GET_HOSPITAL_JOBS_ERROR:
      return {
        ...state,
        isLoadingJobs: false,
      }
    case ARCHIVE_JOB:
      return {
        ...state,
        isLoadingJobs: true,
      }
    case ARCHIVE_JOB_SUCCESS:
      return {
        ...state,
        isLoadingJobs: false,
      }
    case ARCHIVE_JOB_ERROR:
      return {
        ...state,
        isLoadingJobs: false,
      }
    case GET_CANDIDATE_JOBS:
      return {
        ...state,
        isLoadingJobs: true,
        jobsUpdated: false,
      }
    case GET_CANDIDATE_JOBS_SUCCESS:
      return {
        ...state,
        jobsList: mapJobData(action.payload.jobs),
        totalJobs: action.payload.totalCount,
        isLoadingJobs: false,
      }
    case GET_CANDIDATE_JOBS_SUCCESS_PAGINATION:
      return {
        ...state,
        jobsList: mapJobData(action.payload.jobs),
        totalJobs: action.payload.totalCount,
        isLoadingJobs: false,
      }
    case GET_CANDIDATE_JOBS_ERROR:
      return {
        ...state,
        isLoadingJobs: false,
      }
    case SET_SELECTED_FILTERS:
      return {
        ...state,
        selectedFilters: action.selectedFilters,
      }
    case ADD_CANDIDATE_JOB_RATING_LOADING:
      return {
        ...state,
        jobsUpdated: false,
      }
    case ADD_CANDIDATE_JOB_RATING_SUCCESS:
      return {
        ...state,
        jobsUpdated: true,
      }
    case ADD_CANDIDATE_JOB_RATING_FAILURE:
      return {
        ...state,
        jobsUpdated: false,
      }
    case UPDATE_CANDIDATE_JOB_RATING_LOADING:
      return {
        ...state,
        jobsUpdated: false,
      }
    case UPDATE_CANDIDATE_JOB_RATING_SUCCESS:
      return {
        ...state,
        jobsUpdated: true,
      }
    case UPDATE_CANDIDATE_JOB_RATING_FAILURE:
      return {
        ...state,
        jobsUpdated: false,
      }
    case SET_JOBS_UPDATED:
      return {
        ...state,
        jobsUpdated: true,
      }
    default:
      return {
        ...state,
      }
  }
}
