import React, { useState, useEffect } from 'react'
import { Box, Button, Grid, Checkbox, FormControlLabel, Alert } from '@mui/material'
import { isEqual } from 'lodash'
import { useGlobalNavigationBlock } from '../useGlobalNavigationBlock'
import GenericRadioGroup from 'components/UserFields/GenericRadioGroup'
import GenericChipField from 'components/UserFields/GenericChipField'
import GenericMultiSelectCheckboxField from 'components/UserFields/GenericMultiSelectCheckboxField'
import GenericMultipleSelect from 'components/UserFields/GenericMultipleSelect'
import {
  getStatesOptions,
  getAcademicOptions,
  getAreaOptions,
  getPracticeTypeOptions,
  AREA_ICONS,
  getRelocationPreferenceTypeOptions,
} from 'components/UserFields/helpers'
import { getChangedFields, clearFieldError } from '../helpers'

const PreferencesSection = ({ formData, onSave }) => {
  const [localFormData, setLocalFormData] = useState({
    ...formData,
  })
  const [initialData, setInitialData] = useState(formData)
  const [hasChanges, setHasChanges] = useState(false)
  const [errors, setErrors] = useState({})
  const [hasLocationPreference, setHasLocationPreference] = useState(
    localFormData.preferredStates?.length > 0,
  )
  useGlobalNavigationBlock(hasChanges)
  const stateOptions = getStatesOptions()

  useEffect(() => {
    setLocalFormData({
      ...formData,
    })
    setInitialData(formData)
    setHasChanges(false)
    setHasLocationPreference(formData.preferredStates?.length > 0)
  }, [formData])

  useEffect(() => {
    setHasChanges(!isEqual(localFormData, initialData))
  }, [localFormData, initialData])

  const handleInputChange = event => {
    const { name, value } = event.target
    clearFieldError(name, errors, setErrors)
    setLocalFormData(prev => ({ ...prev, [name]: value }))
  }

  const handleLocationPreferenceChange = event => {
    setHasLocationPreference(event.target.checked)
    if (!event.target.checked) {
      setLocalFormData(prev => ({ ...prev, preferredStates: [] }))
    }
  }

  const handleSave = () => {
    const changedFields = getChangedFields(localFormData, initialData)

    if (Object.keys(changedFields).length > 0) {
      onSave({
        ...changedFields,
        id: formData.id,
      })
      setInitialData(localFormData)
      setHasChanges(false)
    }
  }

  return (
    <Box>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {hasChanges && (
          <Grid item xs={12}>
            <Alert severity='info' sx={{ mb: 2 }}>
              You have unsaved changes in this section. Please save before navigating away.
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox checked={hasLocationPreference} onChange={handleLocationPreferenceChange} />
            }
            label='I have a location preference'
          />
          {hasLocationPreference && (
            <GenericMultipleSelect
              label='Preferred States'
              name='preferredStates'
              value={localFormData.preferredStates || []}
              handleChange={e =>
                handleInputChange({ target: { name: 'preferredStates', value: e.target.value } })
              }
              options={stateOptions}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <GenericRadioGroup
            value={localFormData.relocationPreference}
            label='Willing to Relocate'
            name='relocationPreference'
            handleChange={handleInputChange}
            options={getRelocationPreferenceTypeOptions()}
            error={errors.relocationPreference}
          />
        </Grid>

        <Grid item xs={12}>
          <GenericChipField
            value={localFormData.preferredAreaTypes}
            label='Area Type Preference'
            name='preferredAreaTypes'
            icons={AREA_ICONS}
            options={getAreaOptions()}
            handleChange={e =>
              handleInputChange({ target: { name: 'preferredAreaTypes', value: e.target.value } })
            }
          />
        </Grid>

        <Grid item xs={12}>
          <GenericMultiSelectCheckboxField
            label='Practice Type Preference'
            name='preferredPracticeTypes'
            value={localFormData.preferredPracticeTypes || []}
            options={getPracticeTypeOptions()}
            handleChange={e =>
              handleInputChange({
                target: { name: 'preferredPracticeTypes', value: e.target.value },
              })
            }
          />
        </Grid>

        <Grid item xs={12}>
          <GenericRadioGroup
            value={localFormData.academicPreference}
            label='Preferred Academic Setting'
            name='academicPreference'
            handleChange={handleInputChange}
            options={getAcademicOptions()}
          />
        </Grid>
      </Grid>

      <Button
        variant='contained'
        color='primary'
        onClick={handleSave}
        disabled={!hasChanges || Object.keys(errors).length > 0}
        sx={{ mt: 3 }}
      >
        Save
      </Button>
    </Box>
  )
}

export default PreferencesSection
