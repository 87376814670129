import { GET_SPECIALTIES, SET_SPECIALTIES, UPDATE_JOB_FILTERS_HEIGHT } from 'data/constants'
import theme from 'src/theme'

const initialState = {
  height: undefined,
  width: undefined,
  appBarHeight: theme.layout.toolbarHeight,
  loading: false,
  jobFiltersHeight: 0,
  specialties: [],
  subspecialties: [],
  referralSources: [],
}

export default function layoutReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SPECIALTIES:
      return {
        ...state,
        loading: true,
      }
    case SET_SPECIALTIES:
      return {
        ...state,
        specialties: action.payload.specialties || state.specialties,
        subspecialties: action.payload.subspecialties || state.subspecialties,
        referralSources: action.payload.referralSources || state.referralSources,
        candidateTypes: action.payload.candidateTypes || state.candidateTypes,
        loading: false,
      }
    case UPDATE_JOB_FILTERS_HEIGHT:
      return {
        ...state,
        jobFiltersHeight: action.payload,
      }
    default:
      return state
  }
}
