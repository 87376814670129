import React from 'react'
import MaskedInput from 'react-text-mask'
import { Input, InputLabel, FormControl, FormHelperText } from '@mui/material'
import { useStyles } from 'common/styles'

function TextMaskCustom({ inputRef, ...other }) {
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  )
}

export default function PhoneNumber({
  disabled,
  error,
  required,
  InputLabelProps,
  label,
  margin = 'normal',
  ...other
}) {
  return (
    <FormControl
      variant="standard"
      className={useStyles().formControl}
      margin={margin}
      error={error}
      required={required}
      disabled={disabled}>
      <InputLabel {...InputLabelProps}>{label}</InputLabel>
      <Input inputComponent={TextMaskCustom} {...other} />
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  )
}
