import React, { useEffect, useState } from 'react'
import { Box, Button, Grid } from '@mui/material'
import { omit, isEqual } from 'lodash'
import { TextEditor } from 'components/TextEditor/TextEditor'
import GenericMultiLineTextField from 'components/UserFields/GenericMultiLineTextField'
import AboutImage from 'static/images/about-you.png'

const YourStoryStep = ({ formData, setFormData, onNext, onBack, candidate, user, step }) => {
  const [initialFormData, setInitialFormData] = useState({}) // Track initial form data for change detection

  useEffect(() => {
    if (!candidate.loading) {
      const initialData = {
        about: candidate.about,
        aboutRaw: candidate.aboutRaw,
        aboutJob: candidate.aboutJob,
      }
      setFormData(initialData)
      setInitialFormData(initialData) // Store initial data
    }
  }, [candidate, setFormData])

  const handleInputChange = event => {
    const { name, value } = event.target
    setFormData(prev => ({ ...prev, [name]: value }))
  }

  const handleSubmit = () => {
    const dataToSubmit = {
      id: user.candidateId,
      about: formData.about,
      aboutRaw: formData.aboutRaw,
      aboutJob: formData.aboutJob,
    }
    // Exclude `aboutRaw` from the comparison
    const formDataWithoutAboutRaw = omit(formData, 'aboutRaw')
    const initialFormDataWithoutAboutRaw = omit(initialFormData, 'aboutRaw')

    const submitForm = !isEqual(formDataWithoutAboutRaw, initialFormDataWithoutAboutRaw)
    onNext({ dataToSubmit, step, submitForm: submitForm })
  }

  const handleEditorChange = content => {
    setFormData(prev => ({
      ...prev,
      about: content.about,
      aboutRaw: content.aboutRaw,
    }))
  }

  return (
    <Box padding={2} maxWidth={600} margin='auto'>
      <Box
        component='img'
        src={AboutImage}
        alt='About'
        sx={{
          width: '30%',
          height: 'auto',
          marginBottom: 1,
          display: 'block',
          mx: 'auto',
        }}
      />{' '}
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box border={1} borderColor='grey.300' borderRadius={1} padding={1.5} mt={1} mb={4}>
            <TextEditor
              initialValue={formData.about}
              label='About You'
              rows={8}
              placeholder='Share your journey, skills, and values that set you apart. Highlight accomplishments and experiences. Use paragraphs or bullet points to make it recruiter-friendly!"'
              onChange={handleEditorChange}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box border={1} borderColor='grey.300' borderRadius={1} padding={1.5} mt={1}>
            <Grid item xxs={12}>
              <GenericMultiLineTextField
                name='aboutJob'
                label='Describe your ideal job'
                placeholder={`Describe your ideal role, work environment, location, and career goals. Be specific to help recruiters find the best match for you.`}
                value={formData.aboutJob}
                handleChange={handleInputChange}
              />
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {/* Navigation Buttons */}
      <Box
        sx={{
          width: '100%',
          paddingTop: '16px',
          paddingBottom: '16px',
          mt: 3,
          display: 'flex',
          justifyContent: 'center',
          gap: 2, // Ensures the buttons have a gap between them
          pb: {
            xs: 'calc(env(safe-area-inset-bottom, 20px) + 100px)', // Add safe-area padding on mobile
            sm: '20px', // Standard padding for larger screens
          },
        }}
      >
        <Button
          onClick={onBack}
          variant='outlined'
          color='primary'
          sx={{
            width: 'auto',
            maxWidth: '200px',
          }}
        >
          Back
        </Button>
        <Button
          onClick={handleSubmit}
          variant='contained'
          color='primary'
          sx={{
            width: 'auto',
            maxWidth: '200px',
          }}
        >
          Finish
        </Button>
      </Box>
    </Box>
  )
}

export default YourStoryStep
