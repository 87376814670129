import React from 'react'
import Grid from '@mui/material/Grid'
import TopSection from 'pages/HomePage/TopSection'
import Info from './Info/Info'
import About from './About'
import Join from './Join'
import Footer from 'components/Layout/Footer'

const HomePage = () => {
  return (
    <Grid container direction='column' alignContent='center' justifyContent='center'>
      <TopSection />
      <About />
      <Info />
      <Join />
      <Footer />
    </Grid>
  )
}

export default HomePage
