import axios from 'axios'
import { showSnackbar, snackbarTypes } from 'src/redux/snackbar'

export const POST_SIGNUP_HOSPITAL_LOADING = 'POST_SIGNUP_HOSPITAL_LOADING'
export const POST_SIGNUP_HOSPITAL_SUCCESS = 'POST_SIGNUP_HOSPITAL_SUCCESS'
export const POST_SIGNUP_HOSPITAL_FAILURE = 'POST_SIGNUP_HOSPITAL_FAILURE'

export const postSignupHospitalLoading = () => {
  return {
    type: POST_SIGNUP_HOSPITAL_LOADING,
  }
}

export const postSignupHospitalSuccess = () => {
  return {
    type: POST_SIGNUP_HOSPITAL_SUCCESS,
  }
}

export const postSignupHospitalFailure = () => {
  return {
    type: POST_SIGNUP_HOSPITAL_FAILURE,
  }
}

export const postSignupHospital = (requestVariables = {}) => {
  return async dispatch => {
    dispatch(postSignupHospitalLoading())
    const successMsg = "Successfully submitted, we'll be in touch soon!"
    const failureMsg = 'Oh no, something went wrong! Try again later.'
    try {
      await axios({
        url: '/api/newsletter_subscriptions',
        method: 'POST',
        data: { ...requestVariables, type: 'HOSPITAL_SIGNUP' },
      })
      dispatch(showSnackbar(successMsg, snackbarTypes.SUCCESS))
      dispatch(postSignupHospitalSuccess())
    } catch (error) {
      dispatch(showSnackbar(failureMsg, snackbarTypes.ERROR))
      dispatch(postSignupHospitalFailure())
    }
  }
}

const initialState = {
  loading: false,
  errors: [],
  formSubmitted: false,
}

export const signupHospitalReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_SIGNUP_HOSPITAL_LOADING:
      return {
        ...state,
        formSubmitted: false,
        loading: true,
      }
    case POST_SIGNUP_HOSPITAL_SUCCESS:
      return {
        ...state,
        formSubmitted: true,
        loading: false,
      }
    case POST_SIGNUP_HOSPITAL_FAILURE:
      return {
        ...state,
        formSubmitted: true,
        loading: false,
      }
    default:
      return {
        ...state,
      }
  }
}
