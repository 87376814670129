import React from 'react'
import AddEmployerUserComponent from '../EmployerUsersTable/AddEmployerUserComponent'

export const defaultProps = {
  name: 'Users',
  columns: [
    {
      name: 'email',
      header: 'Email',
    },
    {
      name: 'firstName',
      header: 'First Name',
    },
    {
      name: 'lastName',
      header: 'Last Name',
    },
    {
      name: 'isEmployerAdmin',
      header: 'Is Employer Admin',
    },
    {
      name: 'employer',
      header: 'Account',
    },
    {
      name: 'actions',
      header: 'Actions',
    },
  ],
  addComponent: <AddEmployerUserComponent />,
  searchableFields: ['email', 'firstName', 'lastName'],
}
