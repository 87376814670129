import * as React from 'react'
import { TableCell, Box } from '@mui/material'

const ResizableTableCell = ({ children, align, isHeader = false, minWidth = 20, ...props }) => {
  const [width, setWidth] = React.useState(minWidth)

  const handleMouseDown = e => {
    const startX = e.clientX
    const startWidth = width

    const handleMouseMove = moveEvent => {
      const newWidth = startWidth + moveEvent.clientX - startX
      setWidth(Math.max(newWidth, minWidth))
    }

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }

    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)
  }

  return (
    <TableCell
      align={align}
      {...props}
      sx={{
        ...props.sx,
        width,
        minWidth: width,
        maxWidth: width,
        position: 'relative',
        userSelect: 'none',
        cursor: isHeader ? 'col-resize' : 'default', // Cursor for resizing only on header
        overflow: 'hidden', // Ensure content can shrink appropriately
        textOverflow: 'ellipsis', // Ensure content shrinks
        whiteSpace: 'nowrap', // Prevent line breaks for a compact look
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
        <Box sx={{ flex: 1, paddingRight: isHeader ? '10px' : '0px' }}>{children}</Box>
        {isHeader && (
          <Box
            onMouseDown={handleMouseDown}
            sx={{
              width: '4px', // Resize handle width
              height: '100%',
              cursor: 'col-resize',
              position: 'absolute',
              top: 0,
              right: 0,
              zIndex: 1,
              backgroundColor: 'rgba(0, 0, 0, 0.05)', // Subtle background color for the resize handle
              transition: 'background-color 0.2s ease',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.15)', // More visible on hover
              },
            }}
          />
        )}
      </Box>
    </TableCell>
  )
}

export default ResizableTableCell
