import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { fetchCreateCandidate } from 'src/redux/register'
import { convertValueToArray } from 'data/helpers'
import { useSelector } from 'react-redux'

const initialFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  city: null,
  state: null,
  specialtyId: null,
  subspecialtyId: null,
  password: '',
  confirmPassword: '',
  referralSourceId: null,
  referralSourceOther: null,
  registrationContact: false,
}

export const useFormControls = () => {
  const [values, setValues] = useState(initialFormValues)
  const [errors, setErrors] = useState({})
  const registrationErrorMessage = useSelector(state => state.register.errors)

  React.useEffect(() => {
    const tempErrors = {}
    if (registrationErrorMessage.errors) {
      Object.entries(registrationErrorMessage.errors).forEach(([key, value]) => {
        tempErrors[key] = value[0]
      })
    }
    setErrors(tempErrors)
  }, [registrationErrorMessage])

  const dispatch = useDispatch()

  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    if ('email' in fieldValues) {
      temp.email = !fieldValues.email && 'This field is required.'
      if (fieldValues.email)
        temp.email = !/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email) && 'Email is not valid.'
    }

    if ('password' in fieldValues) {
      const numericRegEx = /[0-9]/
      const alphaRegEx = /(?=.*[a-z])(?=.*[A-Z])/
      const nonAlpha = /[^a-zA-Z0-9]+/
      if (fieldValues.password.length < 8) {
        temp.password = 'Must be at least 8 characters'
      } else if (!numericRegEx.test(fieldValues.password)) {
        temp.password = 'Must contain at least 1 number'
      } else if (!alphaRegEx.test(fieldValues.password)) {
        temp.password = 'Must contain one upper and one lower case character'
      } else if (!nonAlpha.test(fieldValues.password)) {
        temp.password = 'Must contain one non-alphanumeric character'
      } else if (fieldValues.password !== values.confirmPassword) {
        temp.password = 'Passwords must match.'
        temp.confirmPassword = 'Passwords must match.'
      } else {
        temp.password = false
        temp.confirmPassword = false
      }
    }
    if ('confirmPassword' in fieldValues) {
      if (values.password !== fieldValues.confirmPassword) {
        temp.password = 'Passwords must match.'
        temp.confirmPassword = 'Passwords must match.'
      } else {
        temp.password = false
        temp.confirmPassword = false
      }
    }
    if ('specialtyId' in fieldValues) {
      if (fieldValues.specialtyId === '') {
        temp.specialtyId = 'Specialty is required'
      } else {
        temp.specialtyId = false
      }
    }

    setErrors({
      ...temp,
    })
  }

  const handleInputValue = e => {
    const { name, value, checked } = e.target
    if (checked) {
      setValues({
        ...values,
        [name]: checked,
      })
    } else {
      setValues({
        ...values,
        [name]: value,
      })
    }
    validate({ [name]: value })
  }

  const formIsValid = () => {
    const requiredFields = [
      'firstName',
      'lastName',
      'email',
      'password',
      'confirmPassword',
      'referralSourceId',
    ]
    const isValid =
      requiredFields.every(field => values[field]) && Object.values(errors).every(x => x === false)
    return isValid
  }

  const handleFormSubmit = async e => {
    e.preventDefault()
    const specialtyIds = convertValueToArray(values.specialtyId).map(Number)
    const subspecialtyIds = convertValueToArray(values.subspecialtyId).map(Number)
    if (formIsValid()) {
      dispatch(
        fetchCreateCandidate({
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          password: values.password,
          confirmPassword: values.confirmPassword,
          candidateTypeId: values.candidateTypeId,
          specialtyIds,
          subspecialtyIds,
          referralSourceId: values.referralSourceId,
          referralSourceOther: values.referralSourceOther,
          registrationContact: values.registrationContact,
          city: values.city,
          state: values.state,
        }),
      )
    }
  }

  const setSpecialtyId = specialtyId => {
    values.specialtyId = specialtyId
    setValues(values)
  }

  const setSubspecialtyId = subspecialtyId => {
    values.subspecialtyId = subspecialtyId
    setValues(values)
  }

  return {
    handleInputValue,
    handleFormSubmit,
    formIsValid,
    errors,
    values,
    setSpecialtyId,
    setSubspecialtyId,
  }
}
