import React, { useContext, useEffect, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Joyride, { STATUS, ACTIONS } from 'react-joyride'
import { useTheme, useMediaQuery } from '@mui/material'
import { JoyrideContext } from 'components/JoyRide/JoyrideContext'
import { fetchTrackEvent, ProductAnalyticsEventTypes } from 'src/redux/productAnalytics'

export const JoyRideStepper = ({ stepperSteps = [], tutorialName = '', onSuccess }) => {
  const dispatch = useDispatch()
  const { email } = useSelector(state => state.identity)
  const theme = useTheme()
  const below_lg = useMediaQuery(theme.breakpoints.down('md'))
  const [run, setRun] = React.useState(!below_lg)
  const [steps] = React.useState(stepperSteps)
  const { setCurrentStep } = useContext(JoyrideContext)

  // These refs are used to track the previous index and status of the Joyride tutorial
  const previousIndexRef = useRef(null)
  const previousStatusRef = useRef(null)

  // This ref tracks whether the component is mounted or unmounted
  const isMountedRef = useRef(true)

  useEffect(() => {
    isMountedRef.current = true // Component is mounted

    return () => {
      isMountedRef.current = false // Component will unmount
      setRun(false) // Stop Joyride from running on unmount
    }
  }, [])

  const isElementVisible = selector => {
    const element = document.querySelector(selector)
    if (!element) return false // Element does not exist
    const rect = element.getBoundingClientRect()
    return (
      rect.width > 0 &&
      rect.height > 0 &&
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
  }

  const checkElements = () => {
    let elementsExist = true
    steps.forEach(step => {
      elementsExist = elementsExist && isElementVisible(step.target)
    })
    if (elementsExist && !below_lg) {
      if (isMountedRef.current) {
        setRun(true)
      }
    } else {
      setTimeout(checkElements, 100) // Retry after 100ms if elements are not found
    }
  }

  useEffect(() => {
    checkElements()
  }, [])

  const handleJoyrideCallback = useCallback(
    data => {
      const { status, index, action, step } = data
      const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED]

      if (finishedStatuses.includes(status) || action === ACTIONS.CLOSE) {
        if (onSuccess) {
          onSuccess()
        }
        if (isMountedRef.current) {
          setRun(false) // Avoid setting state if the component is unmounted
        }
        if (action === ACTIONS.CLOSE) {
          dispatch(
            fetchTrackEvent({
              eventName: `tour_${tutorialName}_step_${index}_skipped`,
              eventType: ProductAnalyticsEventTypes.TOUR_EVENT,
              userId: email,
            }),
          )
        } else {
          if (isMountedRef.current) {
            setCurrentStep(null) // Avoid setting state if the component is unmounted
          }
          dispatch(
            fetchTrackEvent({
              eventName: `tour_${tutorialName}_finished`,
              eventType: ProductAnalyticsEventTypes.TOUR_EVENT,
              userId: email,
            }),
          )
        }
      } else if (index !== previousIndexRef.current || status !== previousStatusRef.current) {
        previousIndexRef.current = index
        previousStatusRef.current = status
        if (isMountedRef.current) {
          setCurrentStep(step.target) // Avoid setting state if the component is unmounted
        }
        dispatch(
          fetchTrackEvent({
            eventName: `tour_${tutorialName}_step_${index}`,
            eventType: ProductAnalyticsEventTypes.TOUR_EVENT,
            userId: email,
          }),
        )
      }
    },
    [setCurrentStep, dispatch, email, tutorialName, onSuccess],
  )

  return (
    run && (
      <Joyride
        styles={{
          buttonNext: {
            backgroundColor: theme.palette.secondary.main,
            color: '#fff',
            borderRadius: '4px',
            padding: '10px 20px',
          },
          options: {
            zIndex: 9999, // Ensure it's higher than any other element
          },
          buttonBack: {
            color: '#999',
            marginRight: '10px',
          },
          buttonClose: {
            color: theme.palette.primary.dark,
          },
        }}
        steps={steps}
        run={run} // Controls whether Joyride runs or not
        continuous
        callback={handleJoyrideCallback}
        locale={{
          last: 'Finish',
          skip: 'Skip tutorial',
        }}
      />
    )
  )
}

export default JoyRideStepper
