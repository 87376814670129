import 'core-js/stable'
import { createBrowserHistory } from 'history'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { JoyrideProvider } from 'components/JoyRide/JoyrideContext'

import ViewPort from 'components/Layout/ViewPort'
import Snackbar from 'components/SnackBar/SnackBar.js'

import getStore from 'src/store'
import getRouter from 'src/router'
import theme from 'src/theme'

// need this in the bundle to get an entry in the manifest for server email templates
import 'static/images/logo.png'

import StackTrace from 'stacktrace-js'

window.addEventListener('unhandledrejection', (event) => {
  const reason = event.reason
  if (reason instanceof Error) {
    StackTrace.fromError(reason).then((stackFrames) => {
      const stackTrace = stackFrames.map(stackFrame => stackFrame.toString()).join('\n')
      console.error("Resolved Stack Trace:", stackTrace)
      fetch('/api/client_errors', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          message: reason.message,
          stackTrace,
        }),
      })
    })
  }
  else {
    fetch('/api/client_errors', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        message: "unhandled promise rejection",
        reason: event.reason,
      }),
    })
  }
})

window.onerror = (message, source, lineno, colno, error) => {
  if (error) {
    StackTrace.fromError(error).then((stackFrames) => {
      const stackTrace = stackFrames.map((stackFrame) => stackFrame.toString()).join('\n')
      console.error("Resolved Stack Trace:", stackTrace)
      fetch('/api/client_errors', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ message, stackTrace }),
      })
    })
  }
  else {
    fetch('/api/client_errors', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        message,
        source,
        lineno,
        colno,
        error: error?.stack || error.toString(),
      }),
    })
  }
}

export const history = createBrowserHistory()
export const store = getStore(history)
export const router = getRouter(history, store)

history.listen(() => {
  const canonicalHref = `${window.location.origin}${window.location.pathname}`
  document.title = "MyStethi: Your free and easy platform for medical jobs."
  document.getElementById("canonicalHref").setAttribute("href", canonicalHref)
})

ReactDOM.render(
  <Provider store={store}>
    <JoyrideProvider>
      <Snackbar />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <ViewPort>{router}</ViewPort>
        </ThemeProvider>
      </StyledEngineProvider>
    </JoyrideProvider>
  </Provider>,
  document.getElementById('app'),
)
