import * as React from 'react'
import { Grid } from '@mui/material'
import FilterTable from './FilterTable'

export default function Filters() {
  return (
    <Grid container alignItems='center' alignContent='center' justifyContent='center' spacing={3}>
      <Grid container item xs={11} direction='column' spacing={2} alignItems='center'>
        <Grid
          container
          item
          xs={11}
          alignItems='center'
          alignContent='center'
          justifyContent='center'
          sx={{ marginTop: '20px' }}
        >
          <FilterTable />
        </Grid>
      </Grid>
    </Grid>
  )
}
