import React, { useState, useEffect } from 'react'
import { Collapse, Divider, Grid, IconButton, Typography, Box, useTheme } from '@mui/material'
import { ExpandMore, ExpandLess, Block } from '@mui/icons-material'

const GenericCollapsableSection = ({
  title,
  children,
  isOpenByDefault,
  disabled = false,
  disabledMessage = '',
}) => {
  const [isOpen, setIsOpen] = useState(isOpenByDefault && !disabled)
  const theme = useTheme()

  // Sync `isOpen` with `isOpenByDefault` and `disabled` whenever they change
  useEffect(() => {
    setIsOpen(isOpenByDefault && !disabled)
  }, [isOpenByDefault, disabled])

  const handleToggle = () => {
    if (!disabled) {
      setIsOpen(!isOpen)
    }
  }

  return (
    <Box
      sx={{
        border: '1px solid #e0e0e0', // Light border around section
        borderRadius: '8px', // Rounded corners
        marginBottom: '16px', // Space between sections
        boxShadow: isOpen ? '0px 4px 12px rgba(0, 0, 0, 0.1)' : 'none', // Shadow when expanded
        transition: 'box-shadow 0.3s', // Smooth transition of shadow
        opacity: disabled ? 0.5 : 1, // Reduced opacity if disabled
      }}
    >
      <Grid
        container
        alignItems='center'
        justifyContent='space-between'
        sx={{
          backgroundColor: isOpen ? '#f5f5f5' : '#fafafa', // Different background when expanded
          padding: '4px 8px',
          cursor: disabled ? 'not-allowed' : 'pointer', // Show not-allowed cursor when disabled
        }}
        onClick={handleToggle} // Click on the header to toggle
      >
        <Typography
          variant='subtitle1'
          sx={{
            fontWeight: 'bold',
            color: disabled
              ? theme.palette.text.disabled
              : isOpen
                ? theme.palette.secondary.main
                : '#333', // Change color when expanded or disabled
          }}
        >
          {title}
        </Typography>
        <IconButton disabled={disabled}>{isOpen ? <ExpandLess /> : <ExpandMore />}</IconButton>
      </Grid>
      <Collapse in={isOpen}>
        <Divider sx={{ margin: '0 8px', height: '1px' }} />
        <Box sx={{ padding: '16px' }}>{children}</Box>
      </Collapse>
      {disabled && disabledMessage && (
        <Box
          sx={{ padding: '16px', backgroundColor: '#f9f9f9', color: theme.palette.text.secondary }}
        >
          <Grid container alignItems='center'>
            <Block sx={{ marginRight: '8px', color: theme.palette.error.main }} />
            <Typography variant='body2'>{disabledMessage}</Typography>
          </Grid>
        </Box>
      )}
    </Box>
  )
}

export default GenericCollapsableSection
