import * as React from 'react'
export const useJobs = () => {
  const [drawerJobApplicationOpen, setJobApplicationDrawerOpen] = React.useState(false)
  const [drawerJobOpen, setDrawerJobOpen] = React.useState(false)

  const handleJobApplicationDrawerClose = () => {
    setJobApplicationDrawerOpen(false)
  }

  return {
    handleJobApplicationDrawerClose,
    drawerJobApplicationOpen,
    setJobApplicationDrawerOpen,
    drawerJobOpen,
    setDrawerJobOpen,
  }
}
