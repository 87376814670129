import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Title from 'components/Title'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'

const useStyles = makeStyles(theme =>
  createStyles({
    panel: {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.primary.dark,
      width: '70%',
      justifyContent: 'center',
      padding: '50px 50px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  }),
)
const About = () => {
  const classes = useStyles()
  return (
    <Grid container alignItems='center' alignContent='center' justifyContent='center'>
      <Grid container direction='column' rowSpacing={7} className={classes.panel}>
        <Grid item justifyContent='center'>
          <Title text='About Us' fullWidth={true} textAlign='center' justifyContent='center' />
          <Divider />
        </Grid>
        <Grid item justifyContent='center'>
          <Typography variant='h6'>
            Are you tired of the cumbersome process of finding the right healthcare professionals
            for your hospital? Do you struggle with the high costs and time-consuming efforts
            associated with recruitment and staffing? Look no further, because MyStethi is here to
            transform the way healthcare professionals and hospitals connect, streamlining the
            entire process and reducing both costs and headaches.
          </Typography>
        </Grid>
        <Grid item justifyContent='center'>
          <Typography variant='h6'>
            At MyStethi, we have developed an innovative platform that directly links healthcare
            professionals with hospitals in need of their expertise. By leveraging cutting-edge
            technology and a user-friendly interface, we have created a seamless and efficient
            solution that revolutionizes healthcare staffing.
          </Typography>
        </Grid>
        <Grid item justifyContent='center'>
          <Typography variant='h6' inverted='false'>
            For hospitals, MyStethi provides a comprehensive database of highly qualified healthcare
            professionals, including physicians, nurse practitioners, physician assistants and
            CRNAs. Our platform allows hospitals to easily search for professionals based on their
            specialty, experience, location, and availability. No more sifting through stacks of
            resumes or relying on outdated recruitment methods. With MyStethi, hospitals gain
            immediate access to a vast pool of qualified candidates, drastically reducing the time
            and effort spent on recruitment.
          </Typography>
        </Grid>
        <Grid item justifyContent='center'>
          <Typography variant='h6' inverted='false'>
            On the other side, healthcare professionals benefit from the convenience and efficiency
            of MyStethi. Our platform offers a centralized hub for professionals to showcase their
            skills, experience, and credentials. By creating a comprehensive profile, healthcare
            professionals can gain visibility to a wide range of job opportunities that match their
            preferences. They can browse through available positions, apply directly through the
            platform, and communicate with hospitals seamlessly. MyStethi empowers professionals to
            take control of their careers and find the perfect job that aligns with their expertise
            and personal goals.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default About
