import React from 'react'
import { Box, Button, Drawer, Typography, IconButton, useTheme } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

export const GenericDrawer = ({
  open,
  onClose,
  onSubmit,
  children,
  title = 'Drawer',
  submitLabel = 'Submit',
  cancelLabel = 'Cancel',
  width = '400px',
  disabled = false,
  showActionButtons = true,
  ...rest
}) => {
  const theme = useTheme()

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={onClose}
      sx={{ '& .MuiDrawer-paper': { width } }}
      {...rest}
    >
      <Box component='form' onSubmit={onSubmit} sx={{ padding: '0px 0px 16px 0px' }}>
        {/* Updated header design */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between', // Align title on the left, X on the right
            alignItems: 'center',
            backgroundColor: theme.palette.secondary.light,
            padding: '8px 16px', // Smaller padding for a tighter header
            borderBottom: `2px solid ${theme.palette.divider}`, // Adding a bottom border for distinction
            marginBottom: '4px', // Space between header and content
          }}
        >
          <Typography
            variant='subtitle1' // Smaller than 'h6', aligned with form headings
            component='h2'
            sx={{
              fontWeight: 'bold', // Bold to give emphasis
              color: theme.palette.text.primary, // Use the primary text color for contrast
            }}
          >
            {title}
          </Typography>
          <IconButton onClick={onClose} sx={{ color: theme.palette.text.primary }}>
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Drawer content */}
        <Box sx={{ padding: '16px' }}>{children}</Box>
        {showActionButtons && (
          <Box display='flex' justifyContent='flex-end' mt={2} sx={{ padding: '16px' }}>
            <Button onClick={onClose}>{cancelLabel}</Button>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              disabled={disabled}
              sx={{ marginLeft: '8px' }}
            >
              {submitLabel}
            </Button>
          </Box>
        )}
      </Box>
    </Drawer>
  )
}

export default GenericDrawer
