import React from 'react'
import { Typography, Divider, Grid } from '@mui/material'

const Success = () => {
  return (
    <Grid item>
      <Typography variant='h4' style={{ alignSelf: 'center', marginBottom: '10px' }}>
        Thanks for registering
      </Typography>
      <Divider />
      <Typography variant='body1' style={{ alignSelf: 'center', marginTop: '10px' }}>
        Please check your email to complete your registration process and get to finding your dream
        job!
      </Typography>
    </Grid>
  )
}

export default Success
