import { showSnackbar, snackbarTypes } from 'src/redux/snackbar'
import { fetchDispatch } from 'data/helpers'
import { fetchTrackEvent, ProductAnalyticsEventTypes } from 'src/redux/productAnalytics'
import { candidateTypeDefinitions } from 'components/Filters/candidateTypeDefinitions'

export const CREATE_USER_FILTER_LOADING = 'filters/CREATE_USER_FILTER_LOADING'
export const CREATE_USER_FILTER_SUCCESS = 'filters/CREATE_USER_FILTER_SUCCESS'
export const CREATE_USER_FILTER_FAILURE = 'filters/CREATE_USER_FILTER_FAILURE'

export const UPDATE_USER_FILTER_LOADING = 'filters/UPDATE_USER_FILTER_LOADING'
export const UPDATE_USER_FILTER_SUCCESS = 'filters/UPDATE_USER_FILTER_SUCCESS'
export const UPDATE_USER_FILTER_FAILURE = 'filters/UPDATE_USER_FILTER_FAILURE'

export const FETCH_FILTER_TYPES_LOADING = 'filters/FETCH_FILTER_TYPES_LOADING'
export const FETCH_FILTER_TYPES_SUCCESS = 'filters/FETCH_FILTER_TYPES_SUCCESS'
export const FETCH_FILTER_TYPES_FAILURE = 'filters/FETCH_FILTER_TYPES_FAILURE'

export const FETCH_USER_FILTER_LOADING = 'filters/FETCH_USER_FILTER_LOADING'
export const FETCH_USER_FILTER_SUCCESS = 'filters/FETCH_USER_FILTER_SUCCESS'
export const FETCH_USER_FILTER_FAILURE = 'filters/FETCH_USER_FILTER_FAILURE'

export const FETCH_USER_FILTER_ALERTS_LOADING = 'filters/FETCH_USER_FILTER_ALERTS_LOADING'
export const FETCH_USER_FILTER_ALERTS_SUCCESS = 'filters/FETCH_USER_FILTER_ALERTS_SUCCESS'
export const FETCH_USER_FILTER_ALERTS_FAILURE = 'filters/FETCH_USER_FILTER_ALERTS_FAILURE'

export const FETCH_PROVIDER_FILTER_VALUES_LOADING = 'filters/FETCH_PROVIDER_FILTER_VALUES_LOADING'
export const FETCH_PROVIDER_FILTER_VALUES_SUCCESS = 'filters/FETCH_PROVIDER_FILTER_VALUES_SUCCESS'
export const FETCH_PROVIDER_FILTER_VALUES_FAILURE = 'filters/FETCH_PROVIDER_FILTER_VALUES_FAILURE'

export const FETCH_JOB_FILTER_VALUES_LOADING = 'filters/FETCH_JOB_FILTER_VALUES_LOADING'
export const FETCH_JOB_FILTER_VALUES_SUCCESS = 'filters/FETCH_JOB_FILTER_VALUES_SUCCESS'
export const FETCH_JOB_FILTER_VALUES_FAILURE = 'filters/FETCH_JOB_FILTER_VALUES_FAILURE'

export const SET_USER_FILTER_ID_SUCCESS = 'filters/SET_USER_FILTER_ID_SUCCESS'

export const CLEAR_USER_FILTER_ID_SUCCESS = 'filters/CLEAR_USER_FILTER_ID_SUCCESS'

export const setAppliedUserFilterId = payload => ({ type: SET_USER_FILTER_ID_SUCCESS, payload })

export const clearAppliedUserFilterId = () => ({ type: CLEAR_USER_FILTER_ID_SUCCESS })

export const updateFilterLoading = () => ({ type: UPDATE_USER_FILTER_LOADING })
export const updateFilterSuccess = payload => ({ type: UPDATE_USER_FILTER_SUCCESS, payload })

export const updateFilterFailure = payload => ({ type: UPDATE_USER_FILTER_FAILURE, payload })

export const fetchProviderFilterValuesLoading = () => ({
  type: FETCH_PROVIDER_FILTER_VALUES_LOADING,
})
export const fetchProviderFilterValuesSuccess = payload => ({
  type: FETCH_PROVIDER_FILTER_VALUES_SUCCESS,
  payload,
})
export const fetchProviderFilterValuesFailure = payload => ({
  type: FETCH_PROVIDER_FILTER_VALUES_FAILURE,
  payload,
})

export const fetchJobFilterValuesLoading = () => ({ type: FETCH_JOB_FILTER_VALUES_LOADING })
export const fetchJobFilterValuesSuccess = payload => ({
  type: FETCH_JOB_FILTER_VALUES_SUCCESS,
  payload,
})
export const fetchJobFilterValuesFailure = payload => ({
  type: FETCH_JOB_FILTER_VALUES_FAILURE,
  payload,
})
export const fetchProviderFilterValues = ({ candidateTypeId, specialtyIds, employerId }) => {
  return async dispatch => {
    dispatch(fetchProviderFilterValuesLoading())
    try {
      // Build the query parameters manually from input values
      let queryParams = []

      if (employerId) {
        queryParams.push(`employerId=${encodeURIComponent(employerId)}`)
      }
      if (candidateTypeId) {
        queryParams.push(`candidateTypeId=${encodeURIComponent(candidateTypeId)}`)
      }
      if (specialtyIds && specialtyIds.length > 0) {
        specialtyIds.forEach(id => {
          queryParams.push(`specialtyIds=${encodeURIComponent(id)}`)
        })
      }

      const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : ''
      const path = `/api/candidates/filter_values${queryString}`

      const response = await fetchDispatch({
        path,
        method: 'GET',
      })
      const filteredCandidateTypes = response.candidateTypes.filter(candidateType =>
        candidateTypeDefinitions.some(
          def => def.id === Number(candidateType.value) && def.show === true,
        ),
      )
      // Update the response to only include the filtered candidateTypes
      const updatedResponse = {
        ...response,
        candidateTypes: filteredCandidateTypes,
      }
      dispatch(fetchProviderFilterValuesSuccess(updatedResponse))
    } catch (error) {
      dispatch(fetchProviderFilterValuesFailure(error.response))
    }
  }
}

export const createUserFilterLoading = () => ({ type: CREATE_USER_FILTER_LOADING })
export const createUserFilterSuccess = () => ({ type: CREATE_USER_FILTER_SUCCESS })
export const createUserFilterFailure = payload => ({ type: CREATE_USER_FILTER_FAILURE, payload })

export const fetchUserFilterLoading = () => ({ type: FETCH_USER_FILTER_LOADING })
export const fetchUserFilterSuccess = payload => ({ type: FETCH_USER_FILTER_SUCCESS, payload })
export const fetchUserFilterFailure = payload => ({ type: FETCH_USER_FILTER_FAILURE, payload })

export const fetchUserFilterAlertsLoading = () => ({ type: FETCH_USER_FILTER_ALERTS_LOADING })
export const fetchUserFilterAlertsSuccess = payload => ({
  type: FETCH_USER_FILTER_ALERTS_SUCCESS,
  payload,
})
export const fetchUserFilterAlertsFailure = payload => ({
  type: FETCH_USER_FILTER_ALERTS_FAILURE,
  payload,
})

export const fetchUserFilters = userId => {
  return async dispatch => {
    dispatch(fetchUserFilterLoading())
    try {
      const response = await fetchDispatch({
        path: `/api/user_filters/?userId=${userId}`,
        method: 'GET',
      })
      dispatch(fetchUserFilterSuccess(response))
    } catch (error) {
      dispatch(fetchUserFilterFailure(error.response))
    }
  }
}

export const fetchUserFilterAlerts = userId => {
  return async dispatch => {
    dispatch(fetchUserFilterAlertsLoading())
    try {
      const response = await fetchDispatch({
        path: `/api/user_filter_alerts/?userId=${userId}`,
        method: 'GET',
      })
      dispatch(fetchUserFilterAlertsSuccess(response))
    } catch (error) {
      dispatch(fetchUserFilterAlertsFailure(error.response))
    }
  }
}

export const fetchFilterTypesLoading = () => ({ type: FETCH_FILTER_TYPES_LOADING })
export const fetchFilterTypesSuccess = payload => ({
  type: FETCH_FILTER_TYPES_SUCCESS,
  payload,
})
export const fetchFilterTypesFailure = payload => ({
  type: FETCH_FILTER_TYPES_FAILURE,
  payload,
})

export const fetchFilterTypes = () => {
  return async dispatch => {
    dispatch(fetchFilterTypesLoading())
    try {
      const response = await fetchDispatch({
        path: '/api/user_filters/filter_types',
        method: 'GET',
      })
      dispatch(fetchFilterTypesSuccess(response))
    } catch (error) {
      dispatch(fetchFilterTypesFailure(error.response))
    }
  }
}

export const fetchCreateUserFilter = (values, onSuccess) => {
  return async dispatch => {
    dispatch(createUserFilterLoading())
    try {
      const response = await fetchDispatch({
        path: `/api/user_filters?${values.queryString ? values.queryString : ''}`,
        values: values,
        method: 'POST',
      })
      if (response.errors) {
        response.errors.name.map(error => {
          dispatch(showSnackbar(error, snackbarTypes.ERROR))
        })
        dispatch(createUserFilterFailure(response))
      } else {
        dispatch(createUserFilterSuccess(response))
        dispatch(fetchUserFilters(values.userId))
        if (onSuccess) {
          onSuccess()
        }
        dispatch(showSnackbar('Filter saved!', snackbarTypes.SUCCESS))
        dispatch(
          fetchTrackEvent({
            eventName: 'user_filter_create',
            eventType: ProductAnalyticsEventTypes.ACTION,
            userId: values.email,
          }),
        )
        if (values.alertCadence !== undefined || values.alertCadence !== 'None') {
          dispatch(
            fetchTrackEvent({
              eventName: 'user_filter_alert_create',
              eventType: ProductAnalyticsEventTypes.ACTION,
              userId: values.email,
            }),
          )
        }
      }
    } catch (error) {
      dispatch(
        showSnackbar(
          'Could not save filter, please try again later' + error.name,
          snackbarTypes.ERROR,
        ),
      )
      dispatch(createUserFilterFailure(error.response))
    }
  }
}

export const fetchJobFilterValues = ({ employerId, candidateTypeId, specialtyIds }) => {
  return async dispatch => {
    dispatch(fetchJobFilterValuesLoading())
    try {
      // Build the query parameters manually from input values
      let queryParams = []

      if (employerId) {
        queryParams.push(`employerId=${encodeURIComponent(employerId)}`)
      }
      if (candidateTypeId) {
        queryParams.push(`candidateTypeId=${encodeURIComponent(candidateTypeId)}`)
      }
      if (specialtyIds && specialtyIds.length > 0) {
        specialtyIds.forEach(id => {
          queryParams.push(`specialtyIds=${encodeURIComponent(id)}`)
        })
      }

      const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : ''
      const path = `/api/jobs/filter_values${queryString}`

      const response = await fetchDispatch({
        path,
        method: 'GET',
      })

      // Filter the candidateTypes based on the show property in candidateTypeDefinitions
      const filteredCandidateTypes = response.candidateTypes.filter(candidateType =>
        candidateTypeDefinitions.some(
          def => def.id === Number(candidateType.value) && def.show === true,
        ),
      )
      // Update the response to only include the filtered candidateTypes
      const updatedResponse = {
        ...response,
        candidateTypes: filteredCandidateTypes,
      }
      dispatch(fetchJobFilterValuesSuccess(updatedResponse))
    } catch (error) {
      dispatch(fetchJobFilterValuesFailure(error.response))
    }
  }
}

export const fetchUpdateUserFilter = ({ query, onSuccess }) => {
  return async dispatch => {
    dispatch(updateFilterLoading())
    try {
      const response = await fetchDispatch({
        path: `/api/user_filters/${query.id}`,
        values: query,
        method: 'PUT',
      })
      if (response.errors) {
        response.errors.name.map(error => {
          dispatch(showSnackbar(error, snackbarTypes.ERROR))
        })
        dispatch(updateFilterFailure(response))
      } else {
        dispatch(updateFilterSuccess(response))
        dispatch(fetchUserFilters(query.userId))
        if (onSuccess) {
          onSuccess()
        }
        dispatch(showSnackbar('Success!', snackbarTypes.SUCCESS))
      }
    } catch (error) {
      dispatch(
        showSnackbar(
          'There was an issue, please try again later' + error.name,
          snackbarTypes.ERROR,
        ),
      )
      dispatch(updateFilterFailure(error.response))
    }
  }
}

const initialState = {
  loading: false,
  filterTypes: [],
  jobFilterValues: {},
  providerFilterValues: {},
  userFilters: [],
  userFilterAlerts: [],
  appliedUserFilterId: null,
}

export const filtersReducer = (state = initialState, action) => {
  const { payload } = action

  switch (action.type) {
    case FETCH_FILTER_TYPES_LOADING:
      return {
        ...state,
        loading: true,
      }
    case FETCH_FILTER_TYPES_SUCCESS:
      return {
        ...state,
        filterTypes: payload.filterTypes,
        loading: false,
      }
    case FETCH_FILTER_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case FETCH_USER_FILTER_LOADING:
      return {
        ...state,
        loading: true,
      }
    case FETCH_USER_FILTER_SUCCESS:
      return {
        ...state,
        userFilters: payload.userFilters,
        loading: false,
      }
    case FETCH_USER_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case FETCH_PROVIDER_FILTER_VALUES_LOADING:
      return {
        ...state,
        loading: true,
      }
    case FETCH_PROVIDER_FILTER_VALUES_SUCCESS:
      return {
        ...state,
        providerFilterValues: payload,
        loading: false,
      }
    case FETCH_PROVIDER_FILTER_VALUES_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case FETCH_JOB_FILTER_VALUES_LOADING:
      return {
        ...state,
        loading: true,
      }
    case FETCH_JOB_FILTER_VALUES_SUCCESS:
      return {
        ...state,
        jobFilterValues: payload,
        loading: false,
      }
    case FETCH_JOB_FILTER_VALUES_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case FETCH_USER_FILTER_ALERTS_LOADING:
      return {
        ...state,
        loading: true,
      }
    case FETCH_USER_FILTER_ALERTS_SUCCESS:
      return {
        ...state,
        userFilterAlerts: payload.userFilterAlerts,
        loading: false,
      }
    case FETCH_USER_FILTER_ALERTS_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case SET_USER_FILTER_ID_SUCCESS:
      return {
        ...state,
        appliedUserFilterId: payload,
      }
    case CLEAR_USER_FILTER_ID_SUCCESS:
      return {
        ...state,
        appliedUserFilterId: null,
      }
    case CREATE_USER_FILTER_LOADING:
      return {
        ...state,
        loading: true,
      }
    case CREATE_USER_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case CREATE_USER_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
      }
    default:
      return {
        ...state,
      }
  }
}
