import * as React from 'react'
import { format } from 'date-fns'
import { Grid, Chip, Select, MenuItem, Typography, Link, Box, Tooltip } from '@mui/material'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined'
import { DeleteDialogButton } from 'components/Dialog/DialogButton'
import * as DeleteDialog from 'components/Dialog/DeleteDialog'
import { getLocation } from 'src/pages/Jobs/helpers'
import { fetchUpdateJobApplication } from 'src/redux/application'

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

export const descendingComparator = (a, b, orderBy) => {
  let aValue = a[orderBy]
  let bValue = b[orderBy]

  if (orderBy === 'title') {
    aValue = a.jobName.toLowerCase()
    bValue = b.jobName.toLowerCase()
  }

  if (orderBy === 'status') {
    aValue = a.jobApplicationStatus.name.toLowerCase()
    bValue = b.jobApplicationStatus.name.toLowerCase()
  }

  if (orderBy === 'updateDate') {
    aValue = new Date(a.updatedAt)
    bValue = new Date(b.updatedAt)
  }

  if (orderBy === 'location') {
    aValue = getLocation(a.city, a.state).toLowerCase()
    bValue = getLocation(b.city, b.state).toLowerCase()
  }

  if (bValue < aValue) {
    return -1
  }
  if (bValue > aValue) {
    return 1
  }
  return 0
}

export const formatSalary = value => {
  // Remove any non-numeric characters (except for commas) and insert commas for thousands
  return value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const transformRowsForTable = ({
  rows,
  onUpdateStatus,
  statuses,
  handleFavoriteChange,
  candidateId,
  userId,
}) => {
  return rows.map(row => ({
    id: row.id,
    title: (
      <Link href={row.title} target='_blank' rel='noopener noreferrer'>
        {row.jobId ? (
          row.favorite === true ? (
            <FavoriteIcon
              onClick={e => {
                e.stopPropagation()
                handleFavoriteChange({ id: row.jobId, favorite: false })
              }}
              color='error'
              sx={{ marginRight: '5px', cursor: 'pointer' }}
            />
          ) : (
            <FavoriteBorderIcon
              onClick={e => {
                e.stopPropagation()
                handleFavoriteChange({ id: row.jobId, favorite: true })
              }}
              color='error'
              sx={{ marginRight: '5px', cursor: 'pointer' }}
            />
          )
        ) : (
          <Tooltip title='External jobs are not eligible for favorite, please consider using a tag to specify priority'>
            <FavoriteOutlinedIcon
              sx={{
                marginRight: '5px',
                color: '#d3d3d3', // Lighter gray
                opacity: 0.5, // Semi-transparent
                cursor: 'not-allowed', // Non-clickable cursor
                pointerEvents: 'auto', // Disable pointer events to prevent any interaction
              }}
            />
          </Tooltip>
        )}
        {row.jobName}
      </Link>
    ),
    jobName: row.jobName,
    hospital: <Typography variant='body2'>{row.hospital}</Typography>,
    location: <Typography variant='body2'>{getLocation(row.city, row.state)}</Typography>,
    status: row.jobApplicationStatus.name, // Ensure this is used for sorting
    statusDropdown: (
      <Select
        value={
          statuses.find(status => status.id === row.jobApplicationStatus.id)
            ? row.jobApplicationStatus.id
            : '' // Fallback to an empty string if status is not found
        }
        onChange={e => onUpdateStatus(row.id, e.target.value)}
        onClick={e => e.stopPropagation()}
        variant='outlined'
        size='small'
        sx={{ width: '80%' }}
      >
        {statuses.map(item => (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
        {/* Fallback option in case the current value doesn't match any available statuses */}
        <MenuItem value='' disabled>
          Invalid Status
        </MenuItem>
      </Select>
    ),
    salaryOffered:
      row.salaryOffered !== null ? '$' + formatSalary(row.salaryOffered.toString()) : null,
    notes: row.notes,
    tags: (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
        {row.userTags.map(tag => (
          <Tooltip key={tag.id} title={tag.name}>
            <Chip
              label={tag.name}
              onClick={e => e.stopPropagation()}
              sx={{ backgroundColor: tag.color, color: '#fff' }}
            />
          </Tooltip>
        ))}
      </Box>
    ),
    updateDate: format(new Date(row.updatedAt), 'MM/dd/yyyy'),
    actions: (
      <div onClick={e => e.stopPropagation()}>
        <DeleteDialogButton
          onDelete={fetchUpdateJobApplication}
          id='Delete Dialog'
          title='Remove from Tracked Jobs'
          isDeleted={false}
          size='small'
          Module={DeleteDialog}
          data={{ delete: true, id: row.id, candidateId: candidateId, userId: userId }}
          buttonText='Remove'
          Content={() => (
            <Grid justifyContent='center'>
              <Typography variant='body1' sx={{ lineHeight: 2 }}>
                Are you sure you want to remove this job from your list? Favorited jobs will remain
                in your favorites.
              </Typography>
            </Grid>
          )}
        >
          Delete Application
        </DeleteDialogButton>
      </div>
    ),
  }))
}
