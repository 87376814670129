import * as React from 'react'
import { useDispatch } from 'react-redux'
import {
  Drawer,
  Box,
  IconButton,
  Typography,
  Switch,
  FormControlLabel,
  Grid,
  FormHelperText,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useApplicationDrawer } from './useApplicationDrawer'
import MyStethiApplicationForm from './MyStethiApplicationForm'
import ExternalApplicationForm from './ExternalApplicationForm'
import { fetchCreateJobApplication, fetchUpdateJobApplication } from 'src/redux/application'

const ApplicationDrawer = ({ open, onClose, selectedRow = {}, statuses }) => {
  const {
    formState,
    setFormState,
    handleInputChange,
    handleFavoriteChange,
    jobs,
    candidateId,
    userId,
    availableTags,
  } = useApplicationDrawer({ selectedRow, open })
  const isNew = !(selectedRow && selectedRow.id)
  const isExternalJob =
    (formState.externalJob === true && isNew) ||
    (selectedRow && selectedRow.jobId === null && !isNew)
  const title ='Track Job'
  const dispatch = useDispatch()
  const handleSave = () => {
    if (isNew) {
      dispatch(
        fetchCreateJobApplication({ ...formState, candidateId: candidateId, userId: userId }),
      )
    } else {
      dispatch(
        fetchUpdateJobApplication({ ...formState, candidateId: candidateId, userId: userId }),
      )
    }
    onClose()
  }

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDrawer-paper': {
          width: { xs: '100vw', sm: 400 }, // Drawer stays full viewport width on mobile
          maxWidth: '100vw', // Prevent overflow beyond the viewport
          overflowX: 'hidden', // Prevent horizontal scrolling
        },
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        <Box // Inner box for padding, ensuring no impact on the drawer's width
          sx={{
            paddingLeft: { xs: '24px', sm: '32px' },
            paddingRight: { xs: '24px', sm: '32px' },
            paddingTop: { xs: '16px', sm: '24px' },
            paddingBottom: { xs: '16px', sm: '24px' },
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          <Grid container justifyContent='space-between'>
            <Grid item>
              <Typography variant='h6'>{title}</Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={onClose} sx={{ width: 'fit-content' }}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>

          {formState && (
            <>
              {isNew && (
                <Grid item>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formState.externalJob || false}
                        onChange={handleInputChange}
                        name='externalJob'
                      />
                    }
                    label='External Job'
                  />
                  <FormHelperText>
                    You can add a job from outside of the MyStethi platform.
                  </FormHelperText>
                </Grid>
              )}
              {!isExternalJob ? (
                <MyStethiApplicationForm
                  handleInputChange={handleInputChange}
                  formState={formState}
                  handleFavoriteChange={handleFavoriteChange}
                  statuses={statuses}
                  availableTags={availableTags}
                  jobs={jobs}
                  isNew={isNew}
                  setFormState={setFormState}
                  handleSave={handleSave}
                  onClose={onClose}
                />
              ) : (
                <ExternalApplicationForm
                  handleInputChange={handleInputChange}
                  formState={formState}
                  handleFavoriteChange={handleFavoriteChange}
                  statuses={statuses}
                  availableTags={availableTags}
                  setFormState={setFormState}
                  handleSave={handleSave}
                  onClose={onClose}
                />
              )}
            </>
          )}
        </Box>
      </Box>
    </Drawer>
  )
}

export default ApplicationDrawer
