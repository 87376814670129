import React from 'react'
import { Grid, Typography, Button, useTheme } from '@mui/material'
import { useHandleChangeRoute } from 'data/hooks'
import './styles.css'

const About = () => {
  const theme = useTheme()
  const handleChangeRoute = useHandleChangeRoute()

  const handleClick = () => {
    handleChangeRoute('/register/physicians')()
  }

  return (
    <Grid
      container
      justifyContent='center'
      sx={{
        backgroundColor: 'black',
        padding: { xs: '40px', sm: '40px', md: '100px 200px' },
        textAlign: 'center',
      }}
    >
      <Typography variant='caption' sx={{ color: 'white', fontSize: '1.25rem' }}>
        At MyStethi, we're simplifying the job search for healthcare professionals. Launched in
        January 2024, our platform,{' '}
        <b style={{ color: 'white' }}>
          <mark>created by medical providers for medical providers,</mark>
        </b>{' '}
        ensures that job listings are always up to date. We{' '}
        <b
          style={{
            color: `${theme.palette.primary.main}`,
          }}
        >
          <mark>eliminate spam and irrelevant opportunities,</mark>
        </b>{' '}
        focusing on delivering credible,{' '}
        <b
          style={{
            color: `${theme.palette.primary.main}`,
          }}
        >
          <mark>tailored job listings</mark>
        </b>{' '}
        just for you.
      </Typography>
      <Button variant='contained' color='primary' sx={{ marginTop: '50px' }} onClick={handleClick}>
        Join Today
      </Button>
    </Grid>
  )
}

export default About
