import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getCandidateJobs } from '../../data/jobs/actions'

export const useApplicationDrawer = ({ selectedRow, open }) => {
  const dispatch = useDispatch()
  const { jobsList = [] } = useSelector(state => state.jobs)
  const { availableTags = [] } = useSelector(state => state.application)
  const { candidateId, userId } = useSelector(state => state.identity)
  const [formState, setFormState] = React.useState({ ...selectedRow })
  const [openDialog, setOpenDialog] = React.useState(false)
  const [newTagLabel, setNewTagLabel] = React.useState('')

  const jobs = jobsList.map(job => ({
    id: job.id,
    title: job.title,
    employerName: job.employerName,
  }))

  React.useEffect(() => {
    if (jobsList.length === 0 && open) {
      dispatch(getCandidateJobs())
    }
  }, [open])

  React.useEffect(() => {
    if (selectedRow && Object.keys(selectedRow).length > 0) {
      setFormState({
        id: selectedRow.id,
        jobId: selectedRow.jobId || '',
        jobName: selectedRow.jobName || '',
        hospital: selectedRow.hospital || '',
        jobUrl: selectedRow.jobUrl || '',
        jobApplicationStatusId: selectedRow.jobApplicationStatus
          ? selectedRow.jobApplicationStatus.id
          : '',
        notes: selectedRow.notes || '',
        city: selectedRow.city || '',
        state: selectedRow.state || '',
        tags: (selectedRow.userTags && selectedRow.userTags.map(tag => tag.id)) || [],
        favorite: selectedRow.favorite || false,
        jobApplicationId: selectedRow.jobApplicationId || null,
        externalJobApplicationId: selectedRow.externalJobApplicationId || null,
        salaryOffered: selectedRow.salaryOffered || null,
      })
    } else {
      setFormState({})
    }
  }, [selectedRow, jobsList])

  const handleInputChange = e => {
    const { name, value, type, checked } = e.target
    setFormState(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }))
  }

  const handleFavoriteChange = e => {
    setFormState(prevState => ({
      ...prevState,
      favorite: e.target.checked,
    }))
  }
  const handleDialogClose = () => {
    setOpenDialog(false)
  }

  const handleDialogOpen = () => {
    setOpenDialog(true)
  }

  const handleTagsChange = (event, value) => {
    // Remove duplicates by checking if the tag is already present
    const uniqueTags = value.filter(
      (tag, index, self) => index === self.findIndex(t => t.id === tag.id),
    )

    handleInputChange({
      target: {
        name: 'tags',
        value: uniqueTags,
      },
    })
  }

  return {
    formState,
    setFormState,
    handleInputChange,
    handleFavoriteChange,
    openDialog,
    setOpenDialog,
    newTagLabel,
    setNewTagLabel,
    handleDialogClose,
    handleDialogOpen,
    handleTagsChange,
    jobs,
    candidateId,
    availableTags,
    userId,
  }
}
