import * as React from 'react'
import {
  Box,
  Button,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid,
  InputAdornment,
} from '@mui/material'
import TagInput from './TagInput'
import StateField from 'components/UserFields/StateField'
import { formatSalary } from './helpers'

export const ExternalApplicationForm = ({
  handleInputChange,
  formState,
  statuses,
  selectedRow,
  availableTags,
  setFormState,
  onClose,
  handleSave,
}) => {
  const isFormValid = () => {
    return formState.jobName && formState.hospital && formState.jobApplicationStatusId
  }

  const handleSalaryChange = event => {
    const formattedSalary = formatSalary(event.target.value)
    setFormState({
      ...formState,
      salaryOffered: formattedSalary,
    })
  }

  return (
    <Grid container spacing={1} direction='column'>
      <Grid item>
        <TextField
          label='Job Title'
          name='jobName'
          required
          value={formState.jobName}
          onChange={handleInputChange}
          fullWidth
        />
      </Grid>
      <Grid item>
        <TextField
          label='Hospital'
          name='hospital'
          required
          value={formState.hospital}
          onChange={handleInputChange}
          fullWidth
        />
      </Grid>
      <Grid item>
        <TextField
          label='Job Link'
          name='jobUrl'
          value={formState.jobUrl}
          onChange={handleInputChange}
          fullWidth
        />
      </Grid>
      <Grid item>
        <TextField
          label='City'
          name='city'
          value={formState.city}
          onChange={handleInputChange}
          fullWidth
        />
      </Grid>
      <Grid item>
        <StateField
          name='state'
          label='State'
          value={formState.state}
          handleChange={handleInputChange}
        />
      </Grid>
      <Grid item>
        <FormControl fullWidth required>
          <InputLabel>Status</InputLabel>
          <Select
            name='jobApplicationStatusId'
            value={formState.jobApplicationStatusId || ''}
            onChange={handleInputChange}
          >
            {statuses.map(item => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <TextField
          label='Salary Offered'
          name='salaryOffered'
          value={formState.salaryOffered || ''}
          onChange={handleSalaryChange} // Handle salary formatting
          fullWidth
          InputProps={{
            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item>
        <TextField
          label='Notes'
          name='notes'
          value={formState.notes}
          onChange={handleInputChange}
          multiline
          rows={4}
          fullWidth
        />
      </Grid>
      <Grid item>
        <TagInput
          setFormState={setFormState}
          formState={formState}
          selectedRow={selectedRow}
          availableTags={availableTags}
        />
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
        <Button onClick={onClose} variant='outlined'>
          Cancel
        </Button>
        <Button onClick={handleSave} disabled={!isFormValid()} variant='contained' color='primary'>
          Save
        </Button>
      </Box>
    </Grid>
  )
}

export default ExternalApplicationForm
