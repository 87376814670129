import React from 'react'
import { Grid, Typography, Box } from '@mui/material'

const Connections = () => (
  <Box m={2}>
    <Grid container justifyContent='center'>
      <Grid item>
        <Typography variant='h5'>Connections Placeholder</Typography>
      </Grid>
    </Grid>
  </Box>
)

export default Connections
