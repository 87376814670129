import * as React from 'react'
import { TextField } from '@mui/material'
import { useStyles } from 'common/styles'
import { isError } from 'data/helpers'

const Text = ({ value, error, forceErrorMargin = false, ...other }) => {
  return (
    <TextField
      variant="standard"
      value={value}
      margin='normal'
      className={useStyles().formControl}
      error={isError(error)}
      // Forces a constant helper text margin
      helperText={forceErrorMargin ? error || ' ' : error && error}
      {...other} />
  )
}

export default Text
