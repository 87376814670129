import React from 'react'
import { Switch, FormGroup, FormControlLabel, useTheme } from '@mui/material'
const GenericSwitchField = ({ name, handleChange, disabled, value, label }) => {
  const theme = useTheme()
  const [checked, setChecked] = React.useState(value)

  React.useEffect(() => {
    setChecked(value)
  }, [value])

  const handleClick = event => {
    setChecked(event.target.checked)
    handleChange({ target: { name: name, value: event.target.checked } })
  }

  return (
    <FormGroup>
      <FormControlLabel
        sx={{ alignItems: 'flex-start', color: theme.palette.primary.dark }}
        labelPlacement='top'
        control={
          <Switch
            name={name}
            id={name}
            onChange={handleClick}
            disabled={disabled}
            checked={checked || false}
          />
        }
        label={label}
      />
    </FormGroup>
  )
}

export default GenericSwitchField
