import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadingButton from '@mui/lab/LoadingButton'
import { Grid, Typography, useTheme, Button, Avatar, useMediaQuery } from '@mui/material'
import { sanitize } from 'dompurify'
import GenericTextField from 'components/UserFields/GenericTextField'
import StateField from 'components/UserFields/StateField'
import { UploadPhotoButton } from 'pages/Users/Profile/Buttons'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import { fetchUpdateEmployerUser } from 'src/redux/employerUser'
import PhoneNumberField from 'components/UserFields/PhoneNumberField'
import { TextEditor } from 'components/TextEditor/TextEditor'
import GenericSwitchField from 'components/UserFields/GenericSwitchField'
import { getProfileImage } from '../../Jobs/helpers'

const AboutYou = ({ employerUser }) => {
  const theme = useTheme()
  const user = useSelector(state => state.user)
  user.type = 'employerUser'
  const { loading, id } = employerUser
  user.id = id
  const [readOnly, setReadOnly] = React.useState(true)
  const [values, setValues] = React.useState({})
  const dispatch = useDispatch()
  const imageURL = getProfileImage(employerUser.profileImage)
  const below_lg = useMediaQuery(theme.breakpoints.down('md'))
  const setDefaultValues = () => {
    setValues({
      firstName: employerUser.firstName,
      lastName: employerUser.lastName,
      email: employerUser.email,
      phone: employerUser.phone,
      city: employerUser.city,
      state: employerUser.state,
      about: employerUser.about,
      showPhone: employerUser.showPhone,
      showEmail: employerUser.showEmail,
    })
  }
  React.useEffect(() => {
    if (!employerUser.loading) {
      setDefaultValues()
    }
  }, [employerUser])

  const handleEditClick = () => {
    setReadOnly(false)
  }
  const handleCancelClick = () => {
    setDefaultValues()
    setReadOnly(true)
  }

  const handleSubmit = () => {
    const data = {
      ...values,
      id: employerUser.id,
    }
    dispatch(
      fetchUpdateEmployerUser(data, 'Updated Basic Info', null, {
        function: setReadOnly,
        params: true,
      }),
    )
  }

  const handleChange = event => {
    setValues({ ...values, [event.target.name]: event.target.value })
  }

  const handleEditorChange = event => {
    const { about, aboutRaw } = event
    setValues({ ...values, about: about, aboutRaw: aboutRaw })
  }

  return (
    <Grid
      container
      direction='column'
      justifyContent='flex-start'
      sx={[
        {
          border: '1px solid',
          borderTop: 'none',
          borderRadius: '0 0 20 20',
          marginBottom: '50px',
          backgroundColor: theme.palette.primary.light,
        },
        below_lg ? { padding: '20px' } : { padding: '50px' },
      ]}
    >
      <Grid container direction='row' justifyContent='space-between' item xs={12}>
        <Grid container item justifyContent='flex-end'>
          {readOnly ? (
            <Button
              variant='contained'
              size='small'
              onClick={handleEditClick}
              startIcon={<EditIcon />}
            >
              Edit Info
            </Button>
          ) : (
            <Grid item>
              <Button size='small' sx={{ marginRight: '20px' }} onClick={handleCancelClick}>
                Cancel
              </Button>
              <LoadingButton
                variant='contained'
                size='small'
                onClick={handleSubmit}
                startIcon={<SaveIcon />}
                loading={loading}
              >
                Save Changes
              </LoadingButton>
            </Grid>
          )}
        </Grid>
        <Grid
          container
          item
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          spacing={2}
          sx={{ marginBottom: '20px', padding: '20px' }}
        >
          <Grid item>
            <Avatar src={imageURL} sx={{ width: 100, height: 100, marginBottom: '10px' }} />
          </Grid>
          <Grid item>
            {readOnly ? (
              <Typography variant='h6'>
                {employerUser.firstName} {employerUser.lastName}
              </Typography>
            ) : (
              <Grid container spacing={2} item>
                <Grid item xs={12} md={6}>
                  <GenericTextField
                    name='firstName'
                    label='First Name'
                    value={values.firstName}
                    disabled={readOnly}
                    handleChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <GenericTextField
                    name='lastName'
                    label='Last Name'
                    value={values.lastName}
                    disabled={readOnly}
                    handleChange={handleChange}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item sx={{ marginBottom: '20px' }}>
          <UploadPhotoButton user={user} />
        </Grid>
        <Grid
          container
          rowSpacing={2}
          sx={{
            backgroundColor: 'white',
            marginTop: '20px',
            borderRadius: '10px',
            padding: '20px',
          }}
        >
          <Grid item xs={12} md={6}>
            <GenericTextField value={values.email} disabled={true} label='Email' name='email' />
          </Grid>
          <Grid item xs={6}>
            <PhoneNumberField
              value={values.phone}
              disabled={readOnly}
              handleChange={handleChange}
              label='Phone Number'
              name='phone'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <GenericSwitchField
              name='showEmail'
              label='Display Email'
              value={values.showEmail}
              disabled={readOnly}
              handleChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <GenericSwitchField
              name='showPhone'
              label='Display Phone Number'
              value={values.showPhone}
              disabled={readOnly}
              handleChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <GenericTextField
              name='city'
              label='City'
              value={values.city}
              disabled={readOnly}
              handleChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <StateField
              name='state'
              label='State'
              value={values.state}
              disabled={readOnly}
              handleChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            marginTop: '20px',
            borderRadius: '10px',
          }}
        >
          {readOnly ? (
            <Grid
              item
              sx={{
                backgroundColor: 'white',
                padding: '20px',
              }}
            >
              <Typography variant='h6'>About You</Typography>
              <Typography
                variant='body1'
                dangerouslySetInnerHTML={{ __html: sanitize(values.about) }}
              ></Typography>
            </Grid>
          ) : (
            <TextEditor initialValue={values.about} onChange={handleEditorChange} />
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
export default AboutYou
