import styled from 'styled-components'

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.justifyContent || 'center'};
  text-align: ${(props) => props.textAlign || 'center'};
  max-width: ${(props) => (props.fullWidth ? 'auto' : '500px')};
  margin-bottom: ${({ marginBottom = 50 }) => marginBottom}px;
  align-self: ${(props) => props.justifyContent || 'center'};
`
