import React, { useEffect } from 'react'
import Loader from 'components/Loader'
import { PartnerInterestTable } from 'components/Table/PartnerInterestTable'
import { fetchAllNewsletterSubscriptions } from 'src/redux/newsletterSubscription'
import { useSelector, useDispatch } from 'react-redux'

const PartnerInterest = () => {
  const dispatch = useDispatch()
  const { loading, newsletterSubscriptions = [] } = useSelector(
    state => state.newsletterSubscription,
  )

  const partnerInterestData = newsletterSubscriptions.map(newsletter => ({
    id: newsletter.id,
    email: newsletter.email,
    firstName: newsletter.firstName,
    lastName: newsletter.lastName,
    phone: newsletter.phone,
    hospital: newsletter.hospital,
    message: newsletter.message,
    createdAt: new Date(newsletter.createdAt).toDateString(),
  }))

  useEffect(() => {
    dispatch(fetchAllNewsletterSubscriptions({}))
  }, [])

  return <>{loading ? <Loader /> : <PartnerInterestTable data={partnerInterestData} />}</>
}

export default PartnerInterest
