import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box, TextField, MenuItem, Select, InputLabel, FormControl } from '@mui/material'
import GenericDrawer from './GenericComponents/GenericDrawer'
import { copyToLower } from 'data/helpers'
import { fetchUpdateEmployerUserAdmin, fetchCreateEmployerUser } from 'src/redux/employerUser'
import { fetchGetEmployers } from 'src/redux/employer'
import { useIdentity } from 'pages/Identity/Login'
import { isEdit } from 'components/Dialog/helpers'

const EmployerUserDrawer = ({ open, onClose, type = 'add', initialValues = {} }) => {
  const users = useSelector(state => state.users.users || [])
  const { isSuper } = useIdentity()
  const { employers = [] } = useSelector(state => state.employer)
  const { employerName, employerId } = useSelector(state => state.employerUser)
  const dispatch = useDispatch()

  // Form state
  const [formValues, setFormValues] = useState({
    email: '',
    firstName: '',
    lastName: '',
    employerId: isSuper ? '' : employerId,
    isEmployerAdmin: false,
  })
  const [errors, setErrors] = useState({})

  const formEmployers = isSuper ? employers : [{ id: employerId, name: employerName }]
  // Update form values when initialValues change
  useEffect(() => {
    if (initialValues && Object.keys(initialValues).length !== 0) {
      if (initialValues) {
        setFormValues({
          email: initialValues.email || '',
          firstName: initialValues.firstName || '',
          lastName: initialValues.lastName || '',
          employerId: initialValues.employerId || '',
          isEmployerAdmin: initialValues.isEmployerAdmin || false,
        })
      }
    }
  }, [initialValues])

  useEffect(() => {
    dispatch(fetchGetEmployers())
  }, [dispatch])

  const handleChange = event => {
    const { name, value } = event.target
    setFormValues({
      ...formValues,
      [name]: value,
    })
  }

  const handleSubmit = event => {
    event.preventDefault()
    const { email, firstName, lastName, employerId, isEmployerAdmin } = formValues

    const values = {
      email,
      firstName,
      lastName,
      employerId,
      isEmployerAdmin,
    }

    const validationErrors = handleValidation(values)
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors)
      return
    }

    if (isEdit(type)) {
      dispatch(
        fetchUpdateEmployerUserAdmin({
          data: { ...values, id: initialValues.id },
          isSuper,
          onSuccess: onClose,
        }),
      )
    } else {
      dispatch(
        fetchCreateEmployerUser(
          isEmployerAdmin
            ? { data: { ...values, employerId }, employerId, onResponse: onClose }
            : { data: values, updateDialog: onClose },
        ),
      )
    }
  }

  const handleValidation = values => {
    const userEmails = users
      .filter(u => (initialValues.id ? u.id !== initialValues.id : true))
      .map(u => u.email)

    let errors = copyToLower(errors) // Start with server-generated errors, ensure all keys are lowercase
    const { email } = values

    if (userEmails.includes(email)) {
      errors['email'] = 'The email is already in use by another user.'
    }

    return errors
  }

  return (
    <GenericDrawer
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      title={isEdit(type) ? 'Edit User' : 'Add User'}
      type={type}
      submitLabel={isEdit(type) ? 'Update User' : 'Add User'}
      cancelLabel='Cancel'
    >
      <Box display='flex' flexDirection='column' gap={2}>
        <TextField
          id='email'
          name='email'
          label='Email'
          required
          fullWidth
          value={formValues.email}
          onChange={handleChange}
          error={Boolean(errors.email)}
          helperText={errors.email}
        />
        <TextField
          id='firstName'
          name='firstName'
          label='First Name'
          required
          fullWidth
          value={formValues.firstName}
          onChange={handleChange}
          error={Boolean(errors.firstName)}
          helperText={errors.firstName}
        />
        <TextField
          id='lastName'
          name='lastName'
          label='Last Name'
          required
          fullWidth
          value={formValues.lastName}
          onChange={handleChange}
          error={Boolean(errors.lastName)}
          helperText={errors.lastName}
        />
        <FormControl fullWidth disabled={!isSuper}>
          <InputLabel id='employerId-label'>Account</InputLabel>
          <Select
            id='employerId'
            name='employerId'
            labelId='employerId-label'
            value={formValues.employerId || employerId}
            onChange={handleChange}
            label='Account'
            required
          >
            {formEmployers.map(employer => (
              <MenuItem key={employer.id} value={employer.id}>
                {employer.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id='isEmployerAdmin-label'>Is Employer Admin</InputLabel>
          <Select
            id='isEmployerAdmin'
            name='isEmployerAdmin'
            labelId='isEmployerAdmin-label'
            value={formValues.isEmployerAdmin}
            onChange={handleChange}
            label='Is Employer Admin'
          >
            <MenuItem value={false}>No</MenuItem>
            <MenuItem value={true}>Yes</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </GenericDrawer>
  )
}

export default EmployerUserDrawer
