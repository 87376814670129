import { getValue } from 'components/DialogField'
import { evalFunc } from 'data/helpers'

export const setMappedValue = ({ field, value, prev }) => {
  const { id, object, container } = field
  if (container && !prev[container]) {
    prev[container] = {}
  }
  if (container && object && !prev[container][object]) {
    prev[container][object] = {}
  }
  if (!container && object && !prev[object]) {
    prev[object] = {}
  }

  if (container && object) {
    prev[container][object][id] = value
  } else if (container) {
    prev[container][id] = value
  } else if (object) {
    prev[object][id] = value
  } else {
    prev[id] = value
  }
  return prev
}

export const isDisabled = (f, values) =>
  typeof f.disabled === 'function' ? f.disabled(values, f) : f.disabled //Disabled fields are shown but disabled
export const isActive = ({ active }, values) =>
  typeof active === 'function' ? active(values) : active //Active fields are hidden, but can be shown via other options changing in the dialog
export const isHidden = ({ hidden }, values) =>
  typeof hidden === 'function' ? hidden(values) : hidden //Hidden fields are hidden for the current dialog state, and can't be shown via other options
export const isActiveBool = (f, values) => !(isActive(f, values) === false) // Converts the above result to a boolean instead of false/undefined/true, where undefined above means it is active
export const isExcluded = ({ exclude }, values) => evalFunc(exclude, values) //Exclude values when submitting final values

// Only count errors for active fields that are not disabled or hidden
export const isError = (field, values, errors) => {
  return (
    !(isActive(field, values) === false) && // Active
    !(isDisabled(field, values) === true) && // Not disabled
    !(isHidden(field, values) === true) && // Not hidden
    getValue(field, errors) !== undefined // Not undefined
  )
}

// Returns a new object with nay active = false field values removed
const inActiveFilter = (values, fields) => {
  const filtered = {}
  fields.forEach(
    f =>
      isActiveBool(f, values) &&
      setMappedValue({ field: f, value: getValue(f, values, true), prev: filtered }),
  )
  return filtered
}

// Returns a new object, with any exclude = true field values removed
const excludeFilter = (values, fields) => {
  const filtered = {}
  fields.forEach(
    f =>
      !isExcluded(f, values) &&
      setMappedValue({ field: f, value: getValue(f, values, true), prev: filtered }),
  )
  return filtered
}

const inActiveOrExcluded = (values, fields) => {
  const filtered = {}
  fields.forEach(
    f =>
      !isExcluded(f, values) &&
      isActiveBool(f, values) &&
      setMappedValue({ field: f, value: getValue(f, values, true), prev: filtered }),
  )
  return filtered
}

export const filterOnSubmit = (values, fields, filterInActive, filterExclude) => {
  if (filterInActive && filterExclude) {
    return inActiveOrExcluded(values, fields)
  } else if (filterInActive) {
    return inActiveFilter(values, fields)
  } else if (filterExclude) {
    return excludeFilter(values, fields)
  } else {
    return values
  }
}

export const isEdit = type => type === 'Edit' || type === 'edit'
export const isView = type => type === 'View' || type === 'view'
