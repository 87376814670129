import React, { useState } from 'react'
import { Grid, Typography, IconButton, Collapse, Box, useTheme } from '@mui/material'
import { ExpandMore, ExpandLess } from '@mui/icons-material'

const GenericSectionHeader = ({ title, children, collapse = true }) => {
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(true)

  const handleToggle = () => {
    if (collapse) {
      setIsOpen(prev => !prev)
    }
  }

  return (
    <>
      {/* Full-width gray header with toggle icon on the left */}
      <Grid
        container
        alignItems='center'
        onClick={handleToggle}
        sx={{
          width: '100%',
          padding: '6px',
          backgroundColor: theme.palette.grey[100],
          cursor: collapse ? 'pointer' : 'default',
          '&:hover': collapse ? { backgroundColor: theme.palette.grey[200] } : {},
        }}
      >
        {collapse && (
          <IconButton
            size='small'
            sx={{ color: theme.palette.primary.dark, marginRight: 1, padding: 0 }}
          >
            {isOpen ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        )}
        <Typography
          variant='subtitle1'
          sx={{
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            flexGrow: 1,
          }}
        >
          {title}
        </Typography>
      </Grid>

      {/* Collapsible Content */}
      {collapse ? (
        <Collapse in={isOpen}>
          <Box sx={{ paddingY: 1 }}>{children}</Box>
        </Collapse>
      ) : (
        <Box sx={{ paddingY: 1 }}>{children}</Box> // Always shown if collapse is false
      )}
    </>
  )
}

export default GenericSectionHeader
