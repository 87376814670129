export const columns = [
  { id: 'title', label: 'Title', align: 'left', minWidth: 100, sortable: true },
  { id: 'hospital', label: 'Hospital', align: 'left', minWidth: 100, sortable: true },
  {
    id: 'status',
    label: 'Status',
    align: 'center',
    minWidth: 25,
    fitheader: 'true',
    sortable: true,
  },
  {
    id: 'location',
    label: 'Location',
    align: 'center',
    minWidth: 25,
    fitheader: 'true',
    sortable: true,
  },
  { id: 'salaryOffered', label: 'Salary', align: 'center', minWidth: 100, sortable: true },
  { id: 'notes', label: 'Notes', align: 'left', minWidth: 200, sortable: false },
  { id: 'tags', label: 'Tags', align: 'left', minWidth: 150, sortable: false },
  {
    id: 'updateDate',
    label: 'Last Updated',
    align: 'right',
    fitheader: 'true',
    minWidth: 50,
    sortable: true,
  },
  { id: 'actions', label: 'Actions', align: 'right', minWidth: 20, sortable: false },
]
