import ProfileImagePlaceholder from 'static/images/profile-image-placeholder.jpg'
import { checkEmpty, imageAddress } from 'data/helpers'

export const getRealtorProfileImage = profileImage => {
  const { key, fileName } = checkEmpty(profileImage) ? {} : profileImage
  const image = !checkEmpty(key) ? imageAddress(key, fileName) : ProfileImagePlaceholder
  return image
}

export const getLocation = (city, state) => {
  let location
  if (city) {
    if (state) {
      location = city + ', ' + state
    } else {
      location = city
    }
  } else if (state) {
    location = state
  }
  return location
}
