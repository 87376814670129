import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
  useTheme,
  useMediaQuery,
  Alert,
} from '@mui/material'
import useComponentSize from '@rehooks/component-size'

import { useStyles } from 'common/styles'
import { useContentHeight, useLandscape, useWidth } from 'src/redux/layout'
import { fetchLogin } from 'src/redux/identity'
import ValidationErrorMessages from 'components/ValidationErrorMessages'

export default () => {
  const width = useWidth()
  const landscape = useLandscape()
  const componentRef = React.useRef(null)
  const componentSize = useComponentSize(componentRef)
  const marginTop = (useContentHeight() - (componentSize.height + 8)) / 2
  const classes = useStyles({ landscape, marginTop, width })
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const [state, setState] = React.useState({ email: '', password: '' })
  const dispatch = useDispatch()
  const { loading, loginFailure, lockedOut, errors = {} } = useSelector(state => state.identity)

  const handleChange = e => {
    e.preventDefault()
    const { id, value } = e.target
    setState(prevState => ({
      ...prevState,
      [id]: value,
    }))
  }

  const handleSubmit = () => {
    dispatch(fetchLogin(state))
  }

  const handleEnterKey = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleSubmit()
    }
  }

  const handleSubmitClick = e => {
    e.preventDefault()
    handleSubmit()
  }

  return (
    <Grid
      container
      justifyContent='center'
      alignItems='center'
      sx={{ height: '100vh', backgroundColor: `${theme.palette.primary.dark}` }}
    >
      <Paper
        ref={componentRef}
        className={classes.identityContainer}
        onKeyUp={handleEnterKey}
        sx={{ padding: isSmallScreen ? '16px' : '32px', width: isSmallScreen ? '90%' : '400px' }}
      >
        <Grid container alignItems='center' spacing={1}>
          <Grid item xs={12}>
            <Typography align='left' variant='h5'>
              Login
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ height: '1px' }} />
          </Grid>
        </Grid>
        <Grid container alignItems='center' spacing={2} style={{ paddingLeft: 8, paddingRight: 8 }}>
          {loading && <CircularProgress size={24} className={classes.submitProgress} />}
          <Grid item xs={12}>
            {loginFailure && (
              <Box component='div' mb={2}>
                <Alert severity='error' className={classes.alert}>
                  We could not log you in with the credentials provided.
                </Alert>
                <Alert severity='warning' className={classes.alert}>
                  If you did not receive your account confirmation email,{' '}
                  <Link underline='always' href='/resend_confirmation_link'>
                    you can resend it.
                  </Link>
                  Otherwise try again or{' '}
                  <Link underline='always' href='/send_reset_password_link'>
                    reset your password.
                  </Link>
                </Alert>
              </Box>
            )}
            {lockedOut && (
              <Box component='div' mb={2}>
                <Alert severity='error' className={classes.alert}>
                  Your account has been locked out.
                </Alert>
                <Alert severity='warning' className={classes.alert}>
                  Please{' '}
                  <Link underline='always' href='/send_reset_password_link'>
                    reset your password.
                  </Link>
                </Alert>
              </Box>
            )}
            <TextField
              variant='standard'
              id='email'
              label='Email'
              placeholder='Enter your email address'
              value={state.email}
              margin='normal'
              fullWidth
              className={classes.input}
              InputProps={{
                className: classes.input,
              }}
              onChange={handleChange}
              disabled={loading}
              error={!!errors['Email']}
              helperText={<ValidationErrorMessages errors={errors['Email']} />}
            />
            <TextField
              variant='standard'
              id='password'
              label='Password'
              placeholder='Enter your password'
              margin='normal'
              type='password'
              value={state.password}
              fullWidth
              className={classes.input}
              InputProps={{
                className: classes.input,
              }}
              onChange={handleChange}
              disabled={loading}
              error={!!errors['Password']}
              helperText={<ValidationErrorMessages errors={errors['Password']} />}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent='center' spacing={2}>
              <Grid item>
                <Button
                  variant='contained'
                  size='large'
                  onClick={handleSubmitClick}
                  disabled={loading}
                  fullWidth
                >
                  Login
                </Button>
              </Grid>
            </Grid>
            <Grid container justifyContent='flex-start' mt={2}>
              <Typography variant='body1'>
                <Link color='#1B1B1B' underline='hover' href='/send_reset_password_link'>
                  Forgot Password
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}
