import axios from 'axios'
import { sortReferralSources } from './helpers'
import { candidateTypeDefinitions } from 'components/Filters/candidateTypeDefinitions'

export const FETCH_REGISTRATION_DATA_LOADING = 'supportingData/FETCH_REGISTRATION_DATA_LOADING'
export const FETCH_REGISTRATION_DATA_SUCCESS = 'supportingData/FETCH_REGISTRATION_DATA_SUCCESS'
export const FETCH_REGISTRATION_DATA_FAILURE = 'specialties/FETCH_REGISTRATION_DATA_FAILURE'

export const fetchRegistrationDataLoading = () => {
  return {
    type: FETCH_REGISTRATION_DATA_LOADING,
  }
}
export const fetchRegistrationDataSuccess = payload => ({
  type: FETCH_REGISTRATION_DATA_SUCCESS,
  payload,
})
export const fetchRegistrationDataFailure = () => ({ type: FETCH_REGISTRATION_DATA_FAILURE })

export const fetchRegistrationData = () => {
  return async dispatch => {
    dispatch(fetchRegistrationDataLoading())
    try {
      const response = await axios.get('/api/candidates/registration_form')
      const filteredCandidateTypes = response.data.candidateTypes.filter(candidateType =>
        candidateTypeDefinitions.some(
          def => def.id === Number(candidateType.id) && def.show === true,
        ),
      )
      // Update the response to only include the filtered candidateTypes
      const updatedResponse = {
        ...response.data,
        candidateTypes: filteredCandidateTypes,
      }
      dispatch(fetchRegistrationDataSuccess(updatedResponse))
    } catch (error) {
      dispatch(fetchRegistrationDataFailure())
    }
  }
}

const initialState = {
  loading: false,
  specialties: [],
  subspecialties: [],
  referralSources: [],
  candidateTypes: [],
}

export const supportingDataReducer = (state = initialState, action) => {
  const { payload } = action

  switch (action.type) {
    case FETCH_REGISTRATION_DATA_LOADING:
      return {
        ...state,
        loading: true,
      }
    case FETCH_REGISTRATION_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        specialties: payload.specialties,
        subspecialties: payload.subspecialties,
        referralSources: sortReferralSources(payload.referralSources),
        candidateTypes: payload.candidateTypes,
      }
    case FETCH_REGISTRATION_DATA_FAILURE:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}
