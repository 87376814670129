import React, { useRef } from 'react'
import { Button, Typography, Grid, Box, CardMedia, Container, useTheme } from '@mui/material'
import HeaderImage from 'static/images/keys.jpg'
import Footer from 'components/Layout/Footer'
import './styles.css'
import { useHousing } from './useHousing'
import { useFormControls } from './formActions'
import Testimonials from './Testimonials'
import { slideInFromRight } from './styles'
import HousingForm from './HousingForm'

const Housing = () => {
  const { signedIn } = useHousing()
  const theme = useTheme()
  const formRef = useRef(null) // Ref to scroll to the form
  const underlineRef = useRef(null)
  const { formData, formSubmitted, errors, handleSubmit, handleCreateAccount, handleChange } =
    useFormControls()

  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <>
      <Box sx={{ width: '100%', overflowX: 'hidden', position: 'relative' }}>
        {/* Header Section */}
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '800px',
            color: '#fff',
          }}
        >
          <CardMedia
            component='img'
            height='800'
            image={HeaderImage}
            alt='Medical professionals finding homes'
            sx={{
              width: '100%',
              objectFit: 'cover',
              objectPosition: 'center',
              zIndex: 0,
            }}
          />

          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              right: { xs: '0%', md: '10%' }, // Adjust for mobile to center
              left: { xs: '0%', md: 'auto' }, // Center horizontally for mobile
              transform: 'translate(0, -50%)',
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
              padding: 3,
              borderRadius: 2,
              zIndex: 2,
              width: { xs: '90%', sm: '60%', md: '40%' },
              margin: { xs: '0 auto' }, // Center box on mobile
              animation: `${slideInFromRight} 1s ease-in-out forwards`,
            }}
          >
            <Typography variant='h2' gutterBottom color='white' textAlign='center'>
              Relocate with Confidence
            </Typography>
            <Typography variant='h6' color='white' sx={{ mb: 3 }} textAlign='center'>
              Whether you're buying, selling, or renting, relocating is the next big step after
              landing your job. Let MyStethi guide you through the process with trusted partnerships
              designed specifically for medical professionals. Enjoy exclusive benefits tailored
              just for you.
            </Typography>
            <Button
              variant='contained'
              sx={{
                mt: 3,
                backgroundColor: theme.palette.primary.main,
                '&:hover': {
                  backgroundColor: '#005959',
                },
                display: 'block',
                mx: 'auto', // Center button
              }}
              onClick={scrollToForm} // Scroll to form on click
            >
              Learn More
            </Button>
          </Box>
        </Box>

        {/* New Section */}
        <Box
          sx={{
            backgroundColor: '#000',
            color: '#fff',
            textAlign: 'center',
            paddingTop: '80px',
            paddingBottom: '80px',
            paddingLeft: { xs: '16px', sm: '150px' }, // Adjust padding for mobile
            paddingRight: { xs: '16px', sm: '150px' }, // Adjust padding for mobile
          }}
        >
          <Typography variant='h5' color='white'>
            MyStethi has partnered with{' '}
            <a
              href='https://www.spyglassrealty.com/'
              target='_blank'
              rel='noopener noreferrer'
              ref={underlineRef}
            >
              Spyglass Relocation Services
            </a>{' '}
            to utilize the{' '}
            <a
              href='https://www.leadingre.com'
              target='_blank'
              rel='noopener noreferrer'
              ref={underlineRef}
            >
              Leading Real Estate Companies of the World® (LeadingRE)
            </a>{' '}
            network, connecting medical providers to the best realtors and services across the
            world! This connection not only makes your home search easier but also makes you
            eligible for savings of up to $2,000 on closing costs as part of the MyStethi community!
          </Typography>
        </Box>

        {/* Form and Bullet Points Section */}
        <Container sx={{ py: 5 }} ref={formRef}>
          <Grid container spacing={4} alignItems='center'>
            {/* Left Panel: Bullet Points */}
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  backgroundColor: '#f0f0f0',
                  p: 4,
                  borderRadius: 2,
                }}
              >
                <Typography variant='h5' sx={{ color: theme.palette.primary.main, mb: 2 }}>
                  Pre-Decision Services
                </Typography>
                <Typography variant='body1' sx={{ mb: 2 }}>
                  * Personalized needs assessment <br />
                  * Tours of prospective move locations <br />
                  * Community comparison and cost of living insights <br />* Relocation resources
                </Typography>
                <Typography variant='h5' sx={{ color: theme.palette.primary.main, mb: 2 }}>
                  Destination Services
                </Typography>
                <Typography variant='body1' sx={{ mb: 2 }}>
                  * Area orientation tours <br />
                  * Home search assistance for purchase or lease <br />
                  * Mortgage service recommendations <br />* Support for settling in
                </Typography>

                <Typography variant='h5' sx={{ color: theme.palette.primary.main, mb: 2 }}>
                  Departure Services
                </Typography>
                <Typography variant='body1' sx={{ mb: 2 }}>
                  * Home sale assistance at your current location <br />* Move-out support
                </Typography>

                <Typography variant='h5' sx={{ color: theme.palette.primary.main, mb: 2 }}>
                  Additional Services
                </Typography>
                <Typography variant='body1'>
                  * Moving and transportation recommendations <br />* Spouse and family support
                </Typography>
                <Typography
                  variant='body1'
                  sx={{ fontSize: '10px', marginTop: '10px', fontStyle: 'italic' }}
                >
                  MyStethi may receive a fee from referrals for relocation assistance
                </Typography>
              </Box>
            </Grid>
            <HousingForm
              formSubmitted={formSubmitted}
              handleSubmit={handleSubmit}
              formData={formData}
              handleChange={handleChange}
              errors={errors}
              handleCreateAccount={handleCreateAccount}
              signedIn={signedIn}
            />
          </Grid>
        </Container>
        <Testimonials />
      </Box>
      <Footer />
    </>
  )
}

export default Housing
