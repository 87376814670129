import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'

export const useContentHeight = () => {
  const theme = useTheme()
  return useSelector(state => state.layout.height - (theme.layout.toolbarHeight + theme.layout.footerHeight))
}

export const useHeaderHeight = (min = 350) => {
  const height = useHeight()
  return Math.min(min, height * 0.45)
}

export const useHeight = () => {
  return useSelector(state => state.layout.height)
}

export const useLandscape = () => {
  return useSelector(state => state.layout.height < 400 && state.layout.width > state.layout.height)
}

export const useWidth = () => {
  return useSelector(state => state.layout.width)
}
