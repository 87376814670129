import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  Paper,
  Checkbox,
  FormControlLabel,
} from '@mui/material'
import { isEqual } from 'lodash'
import CloseIcon from '@mui/icons-material/Close'
import ResumeUpload from 'components/UserFields/ResumeUpload'
import GenericTextField from 'components/UserFields/GenericTextField'
import GenericDatePicker from 'components/UserFields/GenericDatePicker'
import GenericSelectField from 'components/UserFields/GenericSelectField'
import GenericMultiSelectCheckboxField from 'components/UserFields/GenericMultiSelectCheckboxField'
import {
  getVisaOptions,
  getProficiencyOptions,
  getLanguageOptions,
} from 'components/UserFields/helpers'
import { professionFields } from 'components/UserFields/professionDefinitions'
import { fetchUpdateCandidate } from 'src/redux/candidate'
import { hostAddress } from 'data/helpers'

const BackgroundInformationStep = ({
  formData,
  setFormData,
  onNext,
  onBack,
  candidate,
  user,
  step,
}) => {
  const [errors, setErrors] = useState({})
  const [candidateType, setCandidateType] = useState({})
  const [requiresVisa, setRequiresVisa] = useState(candidate.visas.length > 0)
  const [initialFormData, setInitialFormData] = useState({}) // Track initial form data for change detection
  const { candidateTypes } = useSelector(state => state.supportingData)
  const dispatch = useDispatch()

  useEffect(() => {
    if (candidate.candidateType) {
      const candidateTypeData = candidateTypes.find(x => x.id === candidate.candidateType.id)
      setCandidateType(professionFields.find(x => x.name === candidateTypeData.name))
    }
  }, [candidate, candidateTypes])

  useEffect(() => {
    if (!candidate.loading) {
      const initialData = {
        resume: candidate.resume,
        residency: candidate.residency,
        residencyEnd: candidate.residencyEnd,
        visaIds: candidate.visas?.map(visa => visa.id) || [],
        candidateLanguages:
          candidate.candidateLanguages?.map(lang => ({
            language: lang.language,
            proficiency: lang.proficiency,
          })) || [],
      }
      setFormData(initialData)
      setInitialFormData(initialData) // Store initial data
    }
  }, [candidate, candidateTypes, setFormData])

  const handleInputChange = event => {
    const { name, value } = event.target
    setFormData(prev => ({ ...prev, [name]: value }))
  }

  const handleVisaCheckboxChange = event => {
    setRequiresVisa(event.target.checked)
    if (!event.target.checked) {
      setFormData(prev => ({ ...prev, visaIds: null }))
    }
  }

  const handleValidation = () => {
    const newErrors = {}
    if (requiresVisa && !formData.visaIds) {
      newErrors.visaIds = 'Visa type is required'
    }
    if (candidateType?.residency?.required && !formData.residency) {
      newErrors.residency = 'Residency program is required'
    }
    if (candidateType?.residencyEnd?.required && !formData.residencyEnd) {
      newErrors.residencyEnd = 'Residency end date is required'
    }
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = () => {
    const filteredLanguages = formData.candidateLanguages?.filter(
      lang => lang.language && lang.proficiency,
    )

    if (handleValidation()) {
      const dataToSubmit = {
        id: user.candidateId,
        visaIds: formData.visaIds || [],
        candidateLanguages: filteredLanguages,
        residency: formData.residency,
        residencyEnd: formData.residencyEnd,
      }

      const submitForm = !isEqual(formData, initialFormData)
      onNext({ dataToSubmit, step, submitForm: submitForm })
    }
  }

  const handleResumeClick = () => {
    fetch(
      hostAddress(
        `/api/storage/resume?key=${formData.resume.key}&fileName=${formData.resume.fileName}`,
      ),
      {
        method: 'GET',
      },
    ).then(response => response.blob().then(blob => window.open(URL.createObjectURL(blob))))
  }

  const handleRemoveResume = () => {
    dispatch(fetchUpdateCandidate({ id: candidate.id, resumeFile: null }, 'Removed Resume'))
  }

  const handleResumeSave = event => {
    const file = event.target.files?.[0]
    if (file) {
      dispatch(fetchUpdateCandidate({ id: candidate.id, resumeFile: file }, 'Uploaded Resume'))
    }
  }

  const handleLanguageChange = (index, field, value) => {
    setFormData(prev => ({
      ...prev,
      candidateLanguages: prev.candidateLanguages.map((lang, i) =>
        i === index ? { ...lang, [field]: value } : lang,
      ),
    }))
  }

  const handleAddLanguage = () => {
    setFormData(prev => ({
      ...prev,
      candidateLanguages: [...prev.candidateLanguages, { language: '', proficiency: '' }],
    }))
  }

  const handleRemoveLanguage = index => {
    setFormData(prev => ({
      ...prev,
      candidateLanguages: prev.candidateLanguages.filter((_, i) => i !== index),
    }))
  }
  const handleVisaIdsChange = selectedVisaIds => {
    const numericVisaIds = selectedVisaIds.target.value.map(id => Number(id)) // Convert to numbers
    setFormData(prev => ({ ...prev, visaIds: numericVisaIds }))
  }

  return (
    <Box maxWidth={600} margin='auto'>
      {/* Resume Section */}
      <Paper
        maxWidth={600}
        width={'100%'}
        elevation={3}
        sx={{ padding: 4, borderRadius: 2, mb: 1 }}
      >
        <Typography variant='h6' color='primary' gutterBottom textAlign='center'>
          Resume
        </Typography>
        <Box display='flex' flexDirection='column' alignItems='center' mt={2}>
          {formData.resume ? (
            <Box display='flex' alignItems='center'>
              <Typography
                component='a'
                onClick={handleResumeClick}
                sx={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  color: 'primary.main.dark',
                  mr: 2,
                  mb: 1,
                }}
              >
                {formData.resume.fileName}
              </Typography>
              <Typography
                component='button'
                onClick={handleRemoveResume}
                sx={{
                  background: 'none',
                  border: 'none',
                  color: 'error.main',
                  cursor: 'pointer',
                  fontSize: '0.875rem',
                  fontWeight: 'bold',
                  padding: 0,
                  margin: 0,
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                  '&:focus': {
                    outline: 'none',
                  },
                }}
              >
                Remove
              </Typography>
            </Box>
          ) : (
            <Typography variant='body2' color='textSecondary' textAlign='center'>
              No resume uploaded.
            </Typography>
          )}
          <ResumeUpload
            value={formData.resume}
            handleChange={handleResumeSave}
            label={formData.resume ? 'Update Resume' : 'Upload Resume'}
            name='resume'
            id='resume-upload'
            loading={candidate?.resumeLoading}
            sx={{ mt: 2, mb: 6 }}
          />
          <Grid container spacing={3}>
            {candidateType?.residency?.show && (
              <Grid item xs={6} sx={{ mt: 2 }}>
                <GenericTextField
                  name='residency'
                  label='Residency Program'
                  value={formData.residency}
                  handleChange={handleInputChange}
                  required={candidateType?.residency?.required}
                  error={errors.residency}
                  helperText={errors.residency}
                />
              </Grid>
            )}
            {candidateType?.residencyEnd?.show && (
              <Grid item xs={6} sx={{ mt: 2 }}>
                <GenericDatePicker
                  name='residencyEnd'
                  label='Residency End Date'
                  value={formData.residencyEnd}
                  handleChange={handleInputChange}
                  required={candidateType?.residencyEnd?.required}
                  error={errors.residencyEnd}
                  helperText={errors.residencyEnd}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </Paper>
      <Grid item xs={12}>
        <Box border={1} borderColor='grey.300' borderRadius={1} padding={1.5} mt={1}>
          <Typography variant='subtitle2' fontWeight='medium' color='textSecondary' mb={2}>
            Visa Information
          </Typography>
          <Grid item xs={12} sm={6} sx={{ mb: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={requiresVisa}
                  onChange={handleVisaCheckboxChange}
                  color='primary'
                />
              }
              label='I will require a Visa'
            />
          </Grid>
          {requiresVisa && (
            <Grid item xs={12} sm={6}>
              <GenericMultiSelectCheckboxField
                value={formData.visaIds || []}
                label='Visa Type'
                name='visaIds'
                handleChange={handleVisaIdsChange}
                options={getVisaOptions()}
                required={requiresVisa}
                error={errors.visaIds}
                helperText={errors.visaIds && 'Visa type is required if Visa is needed'}
              />
            </Grid>
          )}
          <Typography variant='subtitle2' fontWeight='medium' color='textSecondary' mr={1} mb={2}>
            Additional Language Information
          </Typography>
          {formData.candidateLanguages?.map((language, index) => (
            <Grid
              container
              spacing={2}
              alignItems='center'
              alignContent='center'
              key={index}
              sx={{ mb: 2 }}
            >
              <Grid item xs={5}>
                <GenericSelectField
                  options={getLanguageOptions()}
                  value={language.language}
                  label='Language'
                  name={`language-${index}`}
                  handleChange={e => handleLanguageChange(index, 'language', e.target.value)}
                />
              </Grid>
              <Grid item xs={5}>
                <GenericSelectField
                  options={getProficiencyOptions()}
                  value={language.proficiency}
                  label='Proficiency'
                  name={`proficiency-${index}`}
                  handleChange={e => handleLanguageChange(index, 'proficiency', e.target.value)}
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton onClick={() => handleRemoveLanguage(index)} color='error'>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Button onClick={handleAddLanguage} variant='outlined' sx={{ mb: 2 }}>
            Add Another Language
          </Button>
        </Box>
      </Grid>
      {/* Navigation Buttons */}
      <Box
        sx={{
          width: '100%',
          paddingTop: '16px',
          paddingBottom: '16px',
          mt: 3,
          display: 'flex',
          justifyContent: 'center',
          gap: 2, // Ensures the buttons have a gap between them
          pb: {
            xs: 'calc(env(safe-area-inset-bottom, 20px) + 100px)', // Add safe-area padding on mobile
            sm: '20px', // Standard padding for larger screens
          },
        }}
      >
        <Button
          onClick={onBack}
          variant='outlined'
          color='primary'
          sx={{
            width: 'auto',
            maxWidth: '200px',
          }}
        >
          Back
        </Button>
        <Button
          onClick={handleSubmit}
          variant='contained'
          color='primary'
          sx={{
            width: 'auto',
            maxWidth: '200px',
          }}
        >
          Next
        </Button>
      </Box>
    </Box>
  )
}

export default BackgroundInformationStep
