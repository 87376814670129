import axios from 'axios'
import { showSnackbar, snackbarTypes } from 'src/redux/snackbar'
import { fetchDispatch } from 'data/helpers'
import { fetchTrackEvent, ProductAnalyticsEventTypes } from 'src/redux/productAnalytics'

export const GET_APPLICATIONS_LOADING = 'application/GET_APPLICATIONS_LOADING'
export const GET_APPLICATIONS_SUCCESS = 'application/GET_APPLICATIONS_SUCCESS'
export const GET_APPLICATIONS_FAILURE = 'application/GET_APPLICATIONS_FAILURE'
export const GET_ALL_STATUSES_LOADING = 'application/GET_ALL_STATUSES_LOADING'
export const GET_ALL_STATUSES_SUCCESS = 'application/GET_ALL_STATUSES_SUCCESS'
export const GET_ALL_STATUSES_FAILURE = 'application/GET_ALL_STATUSES_FAILURE'
export const GET_ALL_USER_TAGS_LOADING = 'application/GET_ALL_USER_TAGS_LOADING'
export const GET_ALL_USER_TAGS_SUCCESS = 'application/GET_ALL_USER_TAGS_SUCCESS'
export const GET_ALL_USER_TAGS_FAILURE = 'application/GET_ALL_USER_TAGS_FAILURE'
export const CREATE_USER_TAGS_LOADING = 'application/CREATE_USER_TAGS_LOADING'
export const CREATE_USER_TAGS_SUCCESS = 'application/CREATE_USER_TAGS_SUCCESS'
export const CREATE_USER_TAGS_FAILURE = 'application/CREATE_USER_TAGS_FAILURE'
export const CREATE_JOB_APPLICATION_LOADING = 'application/CREATE_JOB_APPLICATION_LOADING'
export const CREATE_JOB_APPLICATION_SUCCESS = 'application/CREATE_JOB_APPLICATION_SUCCESS'
export const CREATE_JOB_APPLICATION_FAILURE = 'application/CREATE_JOB_APPLICATION_FAILURE'
export const UPDATE_JOB_APPLICATION_LOADING = 'application/UPDATE_JOB_APPLICATION_LOADING'
export const UPDATE_JOB_APPLICATION_SUCCESS = 'application/UPDATE_JOB_APPLICATION_SUCCESS'
export const UPDATE_JOB_APPLICATION_FAILURE = 'application/UPDATE_JOB_APPLICATION_FAILURE'

export const getApplicationsLoading = () => ({ type: GET_APPLICATIONS_LOADING })
export const getApplicationsSuccess = payload => ({ type: GET_APPLICATIONS_SUCCESS, payload })
export const getApplicationsFailure = payload => ({ type: GET_APPLICATIONS_FAILURE, payload })

export const getAllStatusesLoading = () => ({ type: GET_ALL_STATUSES_LOADING })
export const getAllStatusesSuccess = payload => ({ type: GET_ALL_STATUSES_SUCCESS, payload })
export const getAllStatusesFailure = payload => ({ type: GET_ALL_STATUSES_FAILURE, payload })

export const getAllUserTagsLoading = () => ({ type: GET_ALL_USER_TAGS_LOADING })
export const getAllUserTagsSuccess = payload => ({ type: GET_ALL_USER_TAGS_SUCCESS, payload })
export const getAllUserTagsFailure = payload => ({ type: GET_ALL_USER_TAGS_FAILURE, payload })

export const createUserTagsLoading = () => ({ type: CREATE_USER_TAGS_LOADING })
export const createUserTagsSuccess = payload => ({ type: CREATE_USER_TAGS_SUCCESS, payload })
export const createUserTagsFailure = payload => ({ type: CREATE_USER_TAGS_FAILURE, payload })

export const createJobApplicationLoading = () => ({ type: CREATE_JOB_APPLICATION_LOADING })
export const updateJobApplicationLoading = () => ({ type: UPDATE_JOB_APPLICATION_LOADING })
export const updateJobApplicationSuccess = payload => ({
  type: UPDATE_JOB_APPLICATION_SUCCESS,
  payload,
})
export const updateJobApplicationFailure = payload => ({
  type: UPDATE_JOB_APPLICATION_FAILURE,
  payload,
})

export const createJobApplicationSuccess = payload => ({
  type: CREATE_JOB_APPLICATION_SUCCESS,
  payload,
})
export const createJobApplicationFailure = payload => ({
  type: CREATE_JOB_APPLICATION_FAILURE,
  payload,
})

export const fetchAllApplications = ({ candidateId, jobId }) => {
  return async dispatch => {
    dispatch(getApplicationsLoading())
    try {
      const response = await fetchDispatch({
        path: jobId
          ? `/api/candidate_job_applications?candidateId=${candidateId}&jobId=${jobId}`
          : `/api/candidate_job_applications?candidateId=${candidateId}`,
        method: 'GET',
      })
      dispatch(getApplicationsSuccess(response))
    } catch (error) {
      dispatch(getApplicationsFailure(error.response))
    }
  }
}

export const fetchAllStatuses = () => {
  return async dispatch => {
    dispatch(getAllStatusesLoading())
    try {
      const response = await fetchDispatch({
        path: '/api/job_application_statuses',
        method: 'GET',
      })
      dispatch(getAllStatusesSuccess(response))
    } catch (error) {
      dispatch(getAllStatusesFailure(error.response))
    }
  }
}

export const fetchAllUserTags = userId => {
  return async dispatch => {
    dispatch(getAllUserTagsLoading())
    try {
      const response = await fetchDispatch({
        path: `/api/user_tags?userId=${userId}`,
        method: 'GET',
      })
      dispatch(getAllUserTagsSuccess(response))
    } catch (error) {
      dispatch(getAllUserTagsFailure(error.response))
    }
  }
}

export const fetchCreateUserTag = ({ userId, name, color }) => {
  return async dispatch => {
    dispatch(createUserTagsLoading())
    try {
      const response = await axios({
        url: '/api/user_tags',
        method: 'POST',
        data: { userId, name, color },
      })
      dispatch(createUserTagsSuccess(response))
      dispatch(showSnackbar(`New ${name} tag created`, snackbarTypes.SUCCESS))
      return response.data.userTag.id
    } catch (error) {
      if (error.response) {
        dispatch(showSnackbar(error.response.data.errors.message[0], snackbarTypes.ERROR))
      } else {
        dispatch(showSnackbar('Error creating new tag', snackbarTypes.ERROR))
      }
      dispatch(createUserTagsFailure(error.response.data))
    }
  }
}
export const fetchCreateJobApplication = data => {
  return async dispatch => {
    dispatch(createJobApplicationLoading())
    try {
      const response = await axios({
        url: '/api/candidate_job_applications',
        method: 'POST',
        data: cleanJobApplicationData(data),
      })
      dispatch(createJobApplicationSuccess(response))
      dispatch(showSnackbar('Job information added', snackbarTypes.SUCCESS))
      dispatch(fetchAllApplications({ candidateId: data.candidateId }))
      dispatch(
        fetchTrackEvent({
          eventName: 'add_application_success',
          eventType: ProductAnalyticsEventTypes.ACTION,
          userId: data.userId.toString(),
        }),
      )
    } catch (error) {
      dispatch(showSnackbar('Error adding job application', snackbarTypes.ERROR))
      dispatch(createJobApplicationFailure(error.response))
    }
  }
}

export const fetchUpdateJobApplication = data => {
  return async dispatch => {
    dispatch(updateJobApplicationLoading())
    try {
      const response = await axios({
        url: `/api/candidate_job_applications/${data.id}`,
        method: 'PUT',
        data: cleanJobApplicationData(data),
      })
      dispatch(updateJobApplicationSuccess(response))
      dispatch(showSnackbar('Job information updated', snackbarTypes.SUCCESS))
      dispatch(fetchAllApplications({ candidateId: data.candidateId }))
      dispatch(
        fetchTrackEvent({
          eventName: 'update_application_success',
          eventType: ProductAnalyticsEventTypes.ACTION,
          userId: data.userId.toString(),
        }),
      )
    } catch (error) {
      dispatch(showSnackbar('Error updating job application', snackbarTypes.ERROR))
      dispatch(updateJobApplicationFailure(error.response))
    }
  }
}

const mapUserTags = userTags => {
  return userTags.map(tag => ({ id: tag.id, tag: tag.name, color: tag.color }))
}

const addUserTag = (state, userTag) => {
  state.availableTags.push({ id: userTag.id, tag: userTag.name, color: userTag.color })
  return state.availableTags
}
const cleanJobApplicationData = data => {
  return {
    candidateId: data.candidateId,
    jobId: data.jobId ? data.jobId : null,
    notes: data.notes,
    jobApplicationStatusId: data.jobApplicationStatusId,
    userTagIds: data.tags,
    jobName: data.jobName,
    city: data.city,
    state: data.state,
    hospital: data.hospital,
    jobUrl: data.jobUrl,
    id: data.id ? data.id : null,
    delete: data.delete ? data.delete : null,
    salaryOffered: data.salaryOffered
      ? Number(data.salaryOffered.toString().replace(/,/g, ''))
      : null,
  }
}

const initialState = {
  applications: [],
  availableTags: [],
  statuses: [],
  loading: false,
}

export const applicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_APPLICATIONS_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_APPLICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        applications: action.payload.candidateJobApplications,
      }
    case GET_APPLICATIONS_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case GET_ALL_STATUSES_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_ALL_STATUSES_SUCCESS:
      return {
        ...state,
        loading: false,
        statuses: action.payload.jobApplicationStatuses,
      }
    case GET_ALL_STATUSES_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case GET_ALL_USER_TAGS_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_ALL_USER_TAGS_SUCCESS:
      return {
        ...state,
        loading: false,
        availableTags: mapUserTags(action.payload.userTags),
      }
    case GET_ALL_USER_TAGS_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case CREATE_USER_TAGS_LOADING:
      return {
        ...state,
      }
    case CREATE_USER_TAGS_SUCCESS:
      return {
        ...state,
        loading: false,
        availableTags: addUserTag(state, action.payload.data.userTag),
      }
    case CREATE_USER_TAGS_FAILURE:
      return {
        ...state,
        loading: false,
      }
    default:
      return {
        ...state,
      }
  }
}
