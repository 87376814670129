import * as React from 'react'
import {
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid,
  Autocomplete,
  Button,
  Box,
  InputAdornment,
} from '@mui/material'
import TagInput from './TagInput'
import { formatSalary } from './helpers'

export const MyStethiApplicationForm = ({
  handleInputChange,
  formState,
  statuses,
  availableTags,
  selectedRow,
  jobs,
  isNew,
  setFormState,
  handleSave,
  onClose,
}) => {
  const handleTitleChange = (event, value) => {
    setFormState({
      ...formState,
      jobId: value ? value.id : '', // Store job.id
      jobName: value ? value.title : '', // Store job.title to display in Autocomplete
      hospital: value ? value.employerName : '', // Update hospital based on selected job
    })
  }

  const isFormValid = () => {
    return formState.jobId && formState.jobApplicationStatusId
  }

  const handleSalaryChange = event => {
    const formattedSalary = formatSalary(event.target.value)
    setFormState({
      ...formState,
      salaryOffered: formattedSalary,
    })
  }

  return (
    <Grid container spacing={1} direction='column'>
      <Grid item>
        <Autocomplete
          options={jobs.map(job => ({
            id: job.id,
            title: job.title,
            employerName: job.employerName,
          }))}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {`${option.title} (${option.employerName})`}
            </li>
          )}
          getOptionLabel={option => `${option.title} (${option.employerName})`}
          value={jobs.find(job => job.id === formState.jobId) || null}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          disabled={!isNew}
          onChange={handleTitleChange} // Use handleTitleChange to update formState
          renderInput={params => (
            <TextField
              {...params}
              label='Title' // Manually add asterisk
              placeholder='Start typing to search for a job'
              fullWidth
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          freeSolo
        />
      </Grid>
      <Grid item>
        <TextField
          name='hospital'
          disabled={true}
          placeholder={'Select a job to see the hospital'}
          value={formState.hospital}
          onChange={handleInputChange}
          fullWidth
        />
      </Grid>
      <Grid item>
        <FormControl fullWidth required>
          <InputLabel>Status</InputLabel>
          <Select
            name='jobApplicationStatusId'
            value={formState.jobApplicationStatusId || ''}
            onChange={handleInputChange}
          >
            {statuses.map(item => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <TextField
          label='Salary Offered'
          name='salaryOffered'
          value={formState.salaryOffered || ''}
          onChange={handleSalaryChange} // Handle salary formatting
          fullWidth
          InputProps={{
            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item>
        <TextField
          label='Notes'
          name='notes'
          value={formState.notes}
          onChange={handleInputChange}
          multiline
          rows={4}
          fullWidth
        />
      </Grid>
      <TagInput
        setFormState={setFormState}
        formState={formState}
        selectedRow={selectedRow}
        availableTags={availableTags}
      />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
        <Button onClick={onClose} variant='outlined'>
          Cancel
        </Button>
        <Button onClick={handleSave} disabled={!isFormValid()} variant='contained' color='primary'>
          Save
        </Button>
      </Box>
    </Grid>
  )
}

export default MyStethiApplicationForm
