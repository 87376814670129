import * as React from 'react'
import {
  Grid,
  Typography,
} from '@mui/material'
import Rating from '@mui/material/Rating'

export default function RatingField({ value, defaultValue, onChange }) {
  const caption = defaultValue ?
    'Please update your previous rating'
    : 'Please select an accuracy rating for this job posting'
  const handleChange = React.useCallback(
    event => {
      const newValue = parseInt(event.target.value)
      onChange && onChange({ target: { value: newValue } })
    },
    [onChange],
  )

  return (
    <Grid item xs={12}>
      <Typography variant='body1'>
        {caption}
      </Typography>
      <Rating
        id='rating'
        required
        name="rating"
        value={value || defaultValue}
        onChange={handleChange}
        label='Rate the accuracy of the job posting'
        precision={1}
      />
    </Grid>
  )
}


