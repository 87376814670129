export const defaultProps = {
  name: 'Candidates',
  columns: [
    {
      name: 'id',
      header: 'Id',
    },
    {
      name: 'email',
      header: 'Email',
    },
    {
      name: 'firstName',
      header: 'First Name',
    },
    {
      name: 'lastName',
      header: 'Last Name',
    },
    {
      name: 'specialty',
      header: 'Specialty',
    },
    {
      name: 'subspecialty',
      header: 'Subspecialty',
    },
    {
      name: 'registrationContact',
      header: 'Contact?',
    },
    {
      name: 'createdAt',
      header: 'Created',
    },
    {
      name: 'emailVerifiedAt',
      header: 'Email Verified',
    },
    {
      name: 'lastLoginAt',
      header: 'Last Login',
    },
  ],
  searchableFields: ['email', 'firstName', 'lastName'],
  externalLink: {
    link: 'https://docs.google.com/spreadsheets/d/1bOZAhT5kxFl2WeEa5ejONLlJfmyXLw0GdNt6EZdKT4g/edit#gid=0',
    title: 'Candidate Outreach Logging Sheet',
  },
}
