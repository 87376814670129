import React from 'react'
import {
  Grid,
  Typography,
  Paper,
  Divider,
  List,
  ListItemText,
  ListItem,
  ListItemIcon,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import DoneOutlineIcon from '@mui/icons-material/DoneOutline'
import { useStyles } from 'common/styles'

const About = () => {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <Paper className={classes.registrationPaper}>
      <Grid container direction='column' justifyContent='center' alignItems='center' spacing={2}>
        <Grid item>
          <Typography variant='h6'>Partner with Us</Typography>
          <Divider />
        </Grid>
        <Grid item>
          <Typography variant='body1'>
            Join MyStethi as we build a comprehensive platform featuring all healthcare provider
            jobs. Our solution is simple, effective, and remarkably cost-efficient, allowing you to
            focus on connecting with new hires rather than marketing jobs.
          </Typography>
        </Grid>
        <Grid item justifyContent='flex-start'>
          <List>
            <ListItem>
              <ListItemIcon>
                <DoneOutlineIcon style={{ color: `${theme.palette.secondary.main}` }} />
              </ListItemIcon>
              <ListItemText primary='Unlimited Job Postings' />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <DoneOutlineIcon style={{ color: `${theme.palette.secondary.main}` }} />
              </ListItemIcon>
              <ListItemText primary='Unlimited Institutional Users' />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <DoneOutlineIcon style={{ color: `${theme.palette.secondary.main}` }} />
              </ListItemIcon>
              <ListItemText primary='Unlimited Provider Views and Contacts' />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <DoneOutlineIcon style={{ color: `${theme.palette.secondary.main}` }} />
              </ListItemIcon>
              <ListItemText primary='Bulk Job Upload Options' />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <DoneOutlineIcon style={{ color: `${theme.palette.secondary.main}` }} />
              </ListItemIcon>
              <ListItemText primary='Affordable and Transparent Pricing' />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default About
