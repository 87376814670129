import React from 'react'
import {
  Chip,
  FormControl,
  Grid,
  FormLabel,
  FormHelperText,
  useTheme,
  Tooltip,
  IconButton,
} from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import ClearIcon from '@mui/icons-material/Clear'

const GenericChipField = ({
  value = [], // Initialize as an array for multi-selection
  label,
  name,
  options,
  icons = {},
  required = false,
  handleChange,
  error = false,
  tooltipText,
}) => {
  const theme = useTheme()

  const handleChipClick = selectedId => {
    let newValue

    if (selectedId === 'noPreference') {
      // If "No Preference" is selected, clear all other selections
      newValue = value.includes('noPreference') ? [] : ['noPreference']
    } else {
      // If another option is selected and "No Preference" is already selected, remove "No Preference"
      newValue = value.includes('noPreference')
        ? [selectedId] // Replace "noPreference" with selected option
        : value.includes(selectedId)
          ? value.filter(id => id !== selectedId) // Deselect if already selected
          : [...value, selectedId] // Otherwise, add to selections
    }

    handleChange({
      target: { name, value: newValue },
    })
  }

  const handleReset = () => {
    handleChange({
      target: { name, value: [] }, // Clear all selected values
    })
  }

  return (
    <FormControl variant='outlined' required={required} error={error} fullWidth sx={{ mb: 2 }}>
      <Grid container alignItems='center' spacing={1} sx={{ mb: 1 }}>
        <Grid item>
          <FormLabel
            id={`${name}-label`}
            sx={{ color: theme.palette.primary.main, fontWeight: 500 }}
          >
            {label}
          </FormLabel>
        </Grid>
        {tooltipText && (
          <Grid item>
            <Tooltip title={tooltipText} arrow>
              <IconButton size='small' sx={{ padding: '4px' }}>
                <InfoOutlinedIcon fontSize='small' color='action' />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
        {value.length > 0 && (
          <Grid item>
            <IconButton onClick={handleReset} color='secondary' size='small'>
              <ClearIcon fontSize='small' />
            </IconButton>
          </Grid>
        )}
      </Grid>

      <Grid container spacing={1} sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {options.map(option => (
          <Grid item xs={6} sm={4} md={3} key={option.id}>
            <Chip
              icon={
                icons[option.id]
                  ? React.cloneElement(icons[option.id], { fontSize: 'small' })
                  : null
              }
              label={option.name}
              clickable
              color={value.includes(option.id) ? 'primary' : 'default'}
              onClick={() => handleChipClick(option.id)}
              variant={value.includes(option.id) ? 'filled' : 'outlined'}
              sx={{
                justifyContent: 'center',
                width: '100%',
                fontSize: '0.85rem',
                height: '32px',
                padding: '4px 8px',
              }}
            />
          </Grid>
        ))}
      </Grid>
      {error && required && <FormHelperText>Please select at least one option</FormHelperText>}
    </FormControl>
  )
}

export default GenericChipField
