import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import { Header } from 'components/Title/styled'

const Title = ({
  text,
  fullWidth,
  justifyContent,
  textAlign,
  marginBottom = 24,
  variant = 'h2',
  className = '',
  color,
}) => {
  return (
    <Header
      fullWidth={fullWidth}
      justifyContent={justifyContent}
      textAlign={textAlign}
      marginBottom={marginBottom}
    >
      <Typography
        color={color}
        className={className}
        variant={variant}
        gutterBottom={true}
      >
        {text}
      </Typography>
    </Header>
  )
}

Title.propTypes = {
  text: PropTypes.string,
  omitDivider: PropTypes.bool,
  fullWidth: PropTypes.bool,
  justifyContent: PropTypes.string,
  textAlign: PropTypes.string,
}

Title.defaultProps = {
  inverted: false,
}

export default Title
