import axios from 'axios'

import { showSnackbar, snackbarTypes } from 'src/redux/snackbar'

export const GET_PROFILE = 'profile/GET_USER_PROFILE'
export const GET_PROFILE_SUCCESS = 'profile/GET_USER_PROFILE_SUCCESS'
export const GET_PROFILE_FAILURE = 'profile/GET_USER_PROFILE_FAILURE'

export const getProfile = () => ({ type: GET_PROFILE })
export const getProfileSuccess = payload => ({ type: GET_PROFILE_SUCCESS, payload })
export const getProfileFailure = payload => ({ type: GET_PROFILE_FAILURE, payload })

export const fetchUserProfile = userId => {
  return async dispatch => {
    dispatch(getProfile())
    try {
      const response = await axios({
        url: `/api/users/${userId}`,
        method: 'GET',
      })
      dispatch(getProfileSuccess(response.data))
    } catch (error) {
      dispatch(showSnackbar('An Error occurred while retrieving user info', snackbarTypes.ERROR))
      dispatch(getProfileFailure(error.response.data))
    }
  }
}

const initialState = {
  loading: false,
  profile: {},
}

export const profileReducer = (state = initialState, action) => {
  const { payload } = action

  switch (action.type) {
    case GET_PROFILE:
      return {
        ...state,
        loading: true,
      }
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: {
          ...payload.user,
          ...payload.candidate,
          ...payload.employer_user,
          type: payload.user.employerUserId ? 'employee' : 'candidate',
        },
      }

    case GET_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        errors: payload.errors,
        profile: {},
      }
    default:
      return {
        ...state,
      }
  }
}
