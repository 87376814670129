import React, { useState, useEffect } from 'react'
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Box,
  Button,
  Typography,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  TextField,
  FormHelperText,
} from '@mui/material'
import GenericCollapsableSection from '../GenericComponents/GenericCollapsableSection'
import GenericSectionHeader from '../GenericComponents/GenericSectionHeader'
import { getAlertCadenceOptions } from 'src/data/constants'
import { filterDefinitions } from 'src/components/Filters/filterDefinitions'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCreateUserFilter } from 'src/redux/filters'
import UserFilterSelector from './UserFilterSelector'

const CreateFilterAlert = ({
  disabled,
  openFilterAlert,
  disabledMessage,
  onClose,
  jobAlertData,
  handleSuccessfulCompletion,
  isEdit,
  editJobId,
  userJobFilters,
}) => {
  const dispatch = useDispatch()
  const { filterTypes, userFilters } = useSelector(state => state.filters)
  const { id: jobId } = useSelector(state => state.job.job)
  const { userId } = useSelector(state => state.identity)

  const [selectedFilters, setSelectedFilters] = useState({})
  const [alertCadence, setAlertCadence] = useState('')
  const [showCadenceError, setShowCadenceError] = useState(false)

  const defaultFilterName = jobAlertData.title?.value
    ? `${jobAlertData.title.value.name} Filter`
    : ''
  const [filterName, setFilterName] = useState('')
  const [mode, setMode] = useState('new') // 'existing' or 'new'
  const providerFilterType = filterTypes.find(type => type.name === 'Provider')

  useEffect(() => {
    const initialFilters = Object.entries(jobAlertData).reduce((acc, [key, { value }]) => {
      if (key === 'title') return acc
      if (Array.isArray(value)) {
        value.forEach(item => (acc[`${key}-${item.id}`] = true))
      } else {
        acc[key] = true
      }
      return acc
    }, {})
    setSelectedFilters(initialFilters)
    setFilterName(defaultFilterName)
  }, [jobAlertData])

  const handleFilterChange = filterKey => {
    setSelectedFilters(prev => ({
      ...prev,
      [filterKey]: !prev[filterKey],
    }))
  }

  const handleCreateAlert = () => {
    if (!alertCadence) {
      setShowCadenceError(true) // Show error if alertCadence is not set
      return
    }
    setShowCadenceError(false)

    const params = new URLSearchParams()
    const queryKeyValues = {}

    Object.entries(selectedFilters).forEach(([filterKey, isSelected]) => {
      if (!isSelected) return

      const [baseKey, id] = filterKey.split('-')
      const filterDefinition = filterDefinitions.find(def => def.queryStringKey === baseKey)
      if (filterDefinition) {
        const queryKey = filterDefinition.queryStringKey
        queryKeyValues[queryKey] = queryKeyValues[queryKey] || []
        if (Array.isArray(jobAlertData[baseKey]?.value)) {
          jobAlertData[baseKey].value.forEach(item => {
            if (item.id.toString() === id) {
              queryKeyValues[queryKey].push(item.id)
            }
          })
        } else {
          queryKeyValues[queryKey].push(jobAlertData[baseKey]?.value?.id || id)
        }
      }
    })

    Object.entries(queryKeyValues).forEach(([key, values]) => {
      params.set(key, values.join('|'))
    })
    const queryString = params.toString()
    const filterTypeId = providerFilterType.id
    const payload = {
      userId,
      name: filterName,
      queryString,
      filterTypeId,
      jobId,
      ...(alertCadence && { alertCadence }),
    }

    dispatch(fetchCreateUserFilter(payload, handleSuccessfulCompletion))
  }

  const handleCadenceChange = event => {
    setAlertCadence(event.target.value)
    if (showCadenceError) setShowCadenceError(false) // Clear error on selection
  }

  return (
    <GenericCollapsableSection
      title='Create Alert and Saved Filter'
      isOpenByDefault={openFilterAlert}
      disabled={disabled}
      disabledMessage={disabledMessage}
    >
      <Grid container spacing={2} sx={{ padding: '16px' }}>
        {isEdit ? (
          <UserFilterSelector
            userFilters={userFilters}
            alertCadenceOptions={getAlertCadenceOptions()}
            onClose={onClose}
            jobId={editJobId ? editJobId : jobId}
            userJobFilters={userJobFilters}
            handleSuccessfulCompletion={handleSuccessfulCompletion}
          />
        ) : (
          <>
            {/* Mode Selection */}
            <Grid item xs={12}>
              <FormControl component='fieldset'>
                <FormLabel component='legend'>Choose Filter Mode</FormLabel>
                <RadioGroup row value={mode} onChange={e => setMode(e.target.value)}>
                  <FormControlLabel
                    value='existing'
                    control={<Radio />}
                    label='Use Existing Filter'
                  />
                  <FormControlLabel value='new' control={<Radio />} label='Create New Filter' />
                </RadioGroup>
              </FormControl>
            </Grid>
            {mode === 'existing' ? (
              <UserFilterSelector
                userFilters={userFilters}
                alertCadenceOptions={getAlertCadenceOptions()}
                onClose={onClose}
                userJobFilters={userJobFilters}
                handleSuccessfulCompletion={handleSuccessfulCompletion}
                jobId={jobId}
              />
            ) : (
              <>
                {/* Filter Name Input */}
                <Grid item xs={12}>
                  <TextField
                    label='Filter Name'
                    value={filterName}
                    onChange={e => setFilterName(e.target.value)}
                    fullWidth
                    size='small'
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant='body1'>
                    Would you like to create an alert and saved filter for this job? Select the
                    criteria you want to include.
                  </Typography>
                </Grid>

                {/* Dynamically render checkboxes based on jobAlertData, excluding 'title' */}
                {Object.entries(jobAlertData).map(([key, { label, value }]) =>
                  key === 'title' || key === 'candidateTypeId' ? null : (
                    <Grid item xs={12} key={`${key}-header`}>
                      <GenericSectionHeader title={label} collapse={false} />
                      <Grid container spacing={2}>
                        {Array.isArray(value) ? (
                          value.map((item, index) => (
                            <Grid item xs={6} key={`${key}-${item.id}-${index}`}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={selectedFilters[`${key}-${item.id}`] || false}
                                    onChange={() => handleFilterChange(`${key}-${item.id}`)}
                                  />
                                }
                                label={item.name}
                              />
                            </Grid>
                          ))
                        ) : (
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={selectedFilters[key] || false}
                                  onChange={() => handleFilterChange(key)}
                                />
                              }
                              label={value?.name || label}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  ),
                )}
                <Grid item xs={12}>
                  <GenericSectionHeader title='Set Email Notification' collapse={false} />
                  <FormControl component='fieldset' error={showCadenceError}>
                    <FormLabel component='legend'>
                      Send notifications when new candidates fit the criteria:
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-label='alert cadence'
                      name='alertCadence'
                      value={alertCadence}
                      onChange={handleCadenceChange}
                    >
                      {getAlertCadenceOptions().map((option, index) => (
                        <FormControlLabel
                          key={`${option.id}-${index}`}
                          value={option.name}
                          control={<Radio />}
                          label={option.name}
                        />
                      ))}
                    </RadioGroup>
                    {showCadenceError && (
                      <FormHelperText>Please select an alert cadence</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                {/* Action Buttons */}
                <Grid item xs={12}>
                  <Box display='flex' justifyContent='flex-end'>
                    <Button
                      variant='text'
                      color='primary'
                      onClick={handleSuccessfulCompletion}
                      sx={{ marginRight: '5px' }}
                    >
                      Setup Later
                    </Button>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={handleCreateAlert}
                      disabled={
                        mode === 'new' && !Object.values(selectedFilters).some(filter => filter)
                      }
                    >
                      {mode === 'existing'
                        ? 'Use Selected Filter'
                        : 'Create Alert and Saved Filter'}
                    </Button>
                  </Box>
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </GenericCollapsableSection>
  )
}
export default CreateFilterAlert
