import { showSnackbar, snackbarTypes } from 'src/redux/snackbar'
import { fetchDispatch } from 'data/helpers'
import { mapJobData } from '../data/jobs/reducer'
import { fetchTrackEvent, ProductAnalyticsEventTypes } from 'src/redux/productAnalytics'
import { capitalizeWords } from 'common/helpers'

export const cleanJobData = job => {
  let cleanedJob = { ...job }
  if (cleanedJob.city) {
    cleanedJob.city = capitalizeWords(cleanedJob.city.trim())
  }
  if (cleanedJob.title) {
    cleanedJob.title = cleanedJob.title.trim()
  }
  return cleanedJob
}

export const GET_JOB_LOADING = 'job/GET_JOB_LOADING'
export const GET_JOB_SUCCESS = 'job/GET_JOB_SUCCESS'
export const GET_JOB_FAILURE = 'job/GET_JOB_FAILURE'

export const CREATE_JOB_LOADING = 'job/CREATE_JOB_LOADING'
export const CREATE_JOB_SUCCESS = 'job/CREATE_JOB_SUCCESS'
export const CREATE_JOB_FAILURE = 'job/CREATE_JOB_FAILURE'
export const UPDATE_JOB_LOADING = 'job/UPDATE_JOB_LOADING'
export const UPDATE_JOB_SUCCESS = 'job/UPDATE_JOB_SUCCESS'
export const UPDATE_JOB_FAILURE = 'job/UPDATE_JOB_FAILURE'

export const getJobLoading = () => ({ type: GET_JOB_LOADING })
export const getJobSuccess = payload => ({ type: GET_JOB_SUCCESS, payload })
export const getJobFailure = payload => ({ type: GET_JOB_FAILURE, payload })

export const createJobLoading = () => ({ type: CREATE_JOB_LOADING })
export const createJobSuccess = payload => ({ type: CREATE_JOB_SUCCESS, payload })
export const createJobFailure = payload => ({ type: CREATE_JOB_FAILURE, payload })
export const updateJobLoading = () => ({ type: UPDATE_JOB_LOADING })
export const updateJobSuccess = payload => ({ type: UPDATE_JOB_SUCCESS, payload })
export const updateJobFailure = payload => ({ type: UPDATE_JOB_FAILURE, payload })

export const fetchGetJob = jobId => {
  return async dispatch => {
    dispatch(getJobLoading())
    try {
      const response = await fetchDispatch({
        path: `/api/jobs/${jobId}`,
        method: 'GET',
      })
      dispatch(getJobSuccess(response))
    } catch (error) {
      dispatch(
        showSnackbar(
          'Sorry! We encountered an error finding you job. Check back again!',
          snackbarTypes.ERROR,
        ),
      )
      dispatch(getJobFailure(error.response))
    }
  }
}

export const fetchCreateJob = ({ values }) => {
  return async dispatch => {
    dispatch(createJobLoading())
    try {
      const response = await fetchDispatch({
        path: '/api/jobs',
        values: cleanJobData(values),
        method: 'POST',
      })
      dispatch(
        fetchTrackEvent({
          eventName: 'job_add_success',
          eventType: ProductAnalyticsEventTypes.ACTION,
          userId: response.job.user.email,
          employerId: response.job.employer.id,
          eventData: { jobData: response.job },
        }),
      )
      dispatch(createJobSuccess(response))
    } catch (error) {
      dispatch(
        showSnackbar('Encountered Error creating Job ' + error.response, snackbarTypes.ERROR),
      )
      dispatch(createJobFailure(error.response))
    }
  }
}

export const fetchUpdateJob = ({ values, onSuccess }) => {
  return async dispatch => {
    dispatch(updateJobLoading())
    try {
      const response = await fetchDispatch({
        path: `/api/jobs/${values.id}`,
        values: cleanJobData(values),
        method: 'PUT',
      })
      dispatch(showSnackbar('Successfully updated job.', snackbarTypes.SUCCESS))
      if (onSuccess) onSuccess(response)
    } catch (error) {
      dispatch(
        showSnackbar('Encountered Error updating job ' + error.response, snackbarTypes.ERROR),
      )
      dispatch(updateJobFailure(error.response))
    }
  }
}

const initialState = { job: {}, loading: false }

export function jobReducer(state = initialState, action) {
  switch (action.type) {
    case GET_JOB_LOADING:
      return { ...state, loading: true }
    case GET_JOB_SUCCESS:
      return { job: mapJobData([action.payload.job])[0], loading: false }
    case GET_JOB_FAILURE:
      return { ...state, loading: false }
    case CREATE_JOB_LOADING:
      return { ...state, loading: true }
    case CREATE_JOB_SUCCESS:
      return { job: mapJobData([action.payload.job])[0], loading: false }
    case CREATE_JOB_FAILURE:
      return { ...state, loading: false }
    default:
      return state
  }
}
