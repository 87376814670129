import * as React from 'react'
import { Switch, Typography } from '@mui/material'

const SwitchField = ({ id, helperText, defaultValue, onChange }) => {
  const [checked, setChecked] = React.useState(defaultValue)
  const handleChange = React.useCallback(
    event => {
      setChecked(event.target.checked)
      onChange && onChange({ target: { value: event.target.checked } }) // Ensure a number is sent instead of a string
    },
    [onChange],
  )

  return (
    <>
      <Typography variant='body1'>{helperText}</Typography>
      <Switch id={id} onChange={handleChange} checked={checked} />
    </>
  )
}

export default SwitchField
