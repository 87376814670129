import React from 'react'
import { Typography, Grid } from '@mui/material'
import { useHeaderHeight } from 'data/hooks'
import useStyles from '../styles'

export const FieldData = ({ user, inverted, isMobile = false }) => {
  const classes = useStyles({ headerHeight: useHeaderHeight(175), mobile: isMobile })
  return (
    <div className={classes.fieldContainer} style={{ display: 'flex', flexDirection: 'row' }} >
      <Grid item style={{ width: 150, minHeight: 42 }}>
        {Object.keys(user).map(k => (
          <Typography
            key={k}
            variant='body1'
            inverted={inverted}
            color='textSecondary'
            gutterBottom
            className={classes.leftField}
          >
            {user[k].name || '\xA0'}
          </Typography>
        ))}
      </Grid>
      <Grid item zeroMinWidth xs>
        {Object.keys(user).map(k => (
          <Typography
            key={`${k}-value`}
            noWrap
            variant='body1'
            inverted={inverted}
            color='textSecondary'
            gutterBottom
            className={classes.rightField}
            style={{ height: 24, width: isMobile ? 'calc(90vw - 200px)' : 300 }}
          >
            {user[k].value}
          </Typography>
        ))}
      </Grid>
    </div>
  )
}
