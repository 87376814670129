import React from 'react'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import GenericFieldHeader from '../GenericComponents/GenericFieldHeader'

const JobRadioGroup = ({ label, value, required = false, options, name, setState }) => {
  const handleReset = () => {
    setState(name, null) // Reset the specific field to null
  }
  const handleChange = e => {
    let newValue = e.target.value

    // Convert string representations of booleans to actual booleans if necessary
    if (newValue === 'true') newValue = true
    else if (newValue === 'false') newValue = false

    setState(name, newValue)
  }

  return (
    <FormControl component='fieldset' fullWidth>
      <GenericFieldHeader
        name={label}
        field={value}
        optional={!required}
        handleReset={handleReset}
        showClear={Boolean(value)}
      />
      <RadioGroup
        row
        value={String(value) || ''} // Ensure `value` is a string for consistency
        onChange={handleChange}
        sx={{
          justifyContent: 'space-between', // Evenly space radio buttons
          width: '100%',
        }}
      >
        {options.map((option, index) => (
          <FormControlLabel
            key={`${option.id}-${index}`} // Append index to ensure uniqueness if needed
            value={String(option.id)} // Convert all option ids to strings
            control={<Radio size='small' />}
            label={option.name}
            sx={{
              flex: 1, // Distribute each option evenly
              minWidth: 0, // Prevent minimum width restrictions
              mx: 1, // Add horizontal spacing between items
            }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

export default JobRadioGroup
