import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Grid, Button, Typography } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import * as EditEmployerUserProfileDialog from 'components/Dialog/EditEmployerUserProfileDialog'
import * as EditProfileDialog from 'components/Dialog/EditProfileDialog'
import DialogButton from 'components/Dialog/DialogButton'
import { showSnackbar } from 'data/snackbar/actions'
import { snackbarTypes } from 'data/constants'
import { checkEmpty, isEmpty } from 'data/helpers'
import { fetchUpdateEmployerUser } from 'src/redux/employerUser'
import { fetchUpdateCandidate } from 'src/redux/candidate'
import useStyles from './styles'

const variant = 'contained'

export default function Buttons({
  spacing = 1,
  alignItems = 'center',
  alignContent = 'center',
  user,
}) {
  return (
    <Grid
      container
      spacing={spacing}
      alignItems={alignItems}
      alignContent={alignContent}
      direction='row'
      justifyContent='center'
    >
      <Grid item>
        <UploadPhotoButton user={user} />
      </Grid>

      <Grid item>{user.type === 'candidate' && <UploadResumeButton type={user.type} />}</Grid>
      <Grid item>
        <EditProfileButton type={user.type} />
      </Grid>
    </Grid>
  )
}

export const EditProfileButton = ({ type }) => {
  const classes = useStyles()

  return (
    <DialogButton
      Module={type === 'employee' ? EditEmployerUserProfileDialog : EditProfileDialog}
      className={classes.button}
      size='small'
      tooltip=''
      variant={variant}
    >
      Update Info
    </DialogButton>
  )
}

export const UploadPhotoButton = ({ user }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [error, setError] = React.useState(null)
  const candidate = useSelector(state => state.candidate)
  const employerUser = useSelector(state => state.employerUser)

  const updateUser = user.type === 'candidate' ? fetchUpdateCandidate : fetchUpdateEmployerUser
  const loading = user.type === 'candidate' ? candidate.photoLoading : employerUser.loading
  const onInput = e => {
    setError(null)
    const uploadFiles = e.target.files
    if (uploadFiles) {
      const arrFiles = Array.from(uploadFiles)
      var file = arrFiles.length > 0 ? arrFiles[0] : undefined
      if (file) {
        if (file.size > 5 * 1024 * 1024) {
          showSnackbar(dispatch, 'File exceeds 5 MB upload limit.', snackbarTypes.ERROR)
        } else {
          dispatch(
            updateUser({
              id: user.id,
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
              employerId: user.employerId,
              profileImageFile: file,
              photoLoading: true,
            }),
          )
        }
      }
    }
  }

  const buttonText = candidate.profileImage ? 'Update Photo' : 'Upload Photo'
  return (
    <div className={classes.uploadPhotoButtonWrapper}>
      {error && <Typography color='error'>{error}</Typography>}
      <input
        className={classes.hidden}
        name='fileselect'
        type='file'
        accept='*'
        id='fileselect'
        onChange={onInput}
      />
      <label htmlFor='fileselect'>
        <LoadingButton
          className={classes.button}
          onClick={onInput}
          size='medium'
          component='span'
          variant={variant}
          loading={loading}
        >
          {buttonText}
        </LoadingButton>
      </label>
    </div>
  )
}

export const UploadResumeButton = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const candidate = useSelector(state => state.candidate)

  const { id } = candidate
  const resume = checkEmpty(candidate.resume) ? {} : candidate.resume
  const { key, fileName } = resume

  const [error, setError] = React.useState(null)

  const onInput = e => {
    setError(null)
    const uploadFiles = e.target.files
    const arrFiles = Array.from(uploadFiles)
    const file = arrFiles.length > 0 ? arrFiles[0] : undefined
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        setError('File exceeds 5 MB upload limit.')
      } else {
        dispatch(fetchUpdateCandidate({ id, resumeFile: file, isResumeUpload: true })) // Proceed to upload file
      }
    } else {
      // Error: No file specified or user hit cancel
    }
  }

  return (
    <>
      {error && <Typography color='error'>{error}</Typography>}
      <input
        className={classes.hidden}
        name='resumeselect'
        type='file'
        accept='*.pdf'
        id='resumeselect'
        onChange={onInput}
      />
      <label htmlFor='resumeselect'>
        <Button
          className={classes.button}
          onClick={onInput}
          size='small'
          component='span'
          variant={variant}
        >
          {candidate.resumeLoading ? (
            <CircularProgress className={classes.submitProgress} />
          ) : isEmpty(key) || isEmpty(fileName) ? (
            'Upload Resume'
          ) : (
            'Update Resume'
          )}
        </Button>
      </label>
    </>
  )
}
