import React, { useState, useEffect } from 'react'
import { Box, Button, Grid, Alert } from '@mui/material'
import { isEqual } from 'lodash'
import { useGlobalNavigationBlock } from '../useGlobalNavigationBlock'
import GenericTextField from 'components/UserFields/GenericTextField'
import PhoneNumberField from 'components/UserFields/PhoneNumberField'
import StateField from 'components/UserFields/StateField'
import GenericCheckboxField from 'components/UserFields/GenericCheckboxField'
import { getChangedFields, handleInputChangeHelper } from '../helpers'

const BasicInformationSection = ({ formData, onSave }) => {
  const [localFormData, setLocalFormData] = useState(formData)
  const [initialData, setInitialData] = useState(formData)
  const [hasChanges, setHasChanges] = useState(false)
  useGlobalNavigationBlock(hasChanges)
  useEffect(() => {
    setLocalFormData(formData)
    setInitialData(formData)
    setHasChanges(false)
  }, [formData])

  const handleInputChange = event => {
    handleInputChangeHelper(event, setLocalFormData)
    setHasChanges(!isEqual(localFormData, initialData))
  }

  const handleSave = () => {
    if (hasChanges) {
      const changedFields = getChangedFields(localFormData, initialData)
      if (Object.keys(changedFields).length > 0) {
        onSave({ ...changedFields, id: formData.id })
        setInitialData(localFormData)
        setHasChanges(false)
      }
    }
  }

  return (
    <Box>
      <Grid container spacing={2}>
        {hasChanges && (
          <Grid item xs={12}>
            <Alert severity='info' sx={{ mb: 2 }}>
              You have unsaved changes in this section. Please save before navigating away.
            </Alert>
          </Grid>
        )}
        <Grid item xs={6}>
          <GenericTextField
            value={localFormData.email}
            disabled
            label='Email'
            name='email'
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <PhoneNumberField
            value={localFormData.phone}
            handleChange={handleInputChange}
            label='Phone Number'
            name='phone'
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <GenericTextField
            value={localFormData.city || ''}
            handleChange={handleInputChange}
            label='Current City'
            name='city'
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <StateField
            value={localFormData.state || ''}
            handleChange={handleInputChange}
            label='Current State'
            name='state'
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <GenericCheckboxField
            name='showEmail'
            label='Display Email'
            value={localFormData.showEmail}
            handleChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={6}>
          {localFormData.phone && (
            <GenericCheckboxField
              name='showPhone'
              label='Display Phone Number'
              value={localFormData.showPhone}
              handleChange={handleInputChange}
            />
          )}
        </Grid>
      </Grid>
      <Button
        variant='contained'
        color='primary'
        onClick={handleSave}
        disabled={!hasChanges}
        sx={{ mt: 3 }}
      >
        Save
      </Button>
    </Box>
  )
}

export default BasicInformationSection
