import React from 'react'
import { Chip, FormControl, Grid, Box, IconButton, Tooltip } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import DOMPurify from 'dompurify'
import GenericFieldHeader from '../GenericComponents/GenericFieldHeader'

const JobChipField = ({
  label,
  name,
  options,
  icons,
  required = false,
  value,
  setState,
  tooltipText = '', // Optional tooltip text
}) => {
  const handleReset = () => {
    setState(name, null)
  }

  const handleChipClick = selectedId => {
    setState(name, selectedId)
  }

  const TooltipContent = () => (
    <Box
      sx={{ whiteSpace: 'pre-line' }}
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(tooltipText.replace(/\n/g, '<br/>')),
      }}
    />
  )

  return (
    <FormControl component='fieldset' fullWidth>
      <Box display='flex' alignItems='center' sx={{ width: 'fit-content' }}>
        <GenericFieldHeader
          name={label}
          field={value}
          optional={!required}
          handleReset={handleReset}
          showClear={Boolean(value)}
        />
        {tooltipText && (
          <Tooltip title={<TooltipContent />} placement='top' arrow>
            <IconButton size='small' sx={{ ml: 0.5, color: 'primary.main' }}>
              <InfoIcon fontSize='small' />
            </IconButton>
          </Tooltip>
        )}
      </Box>

      <Grid container spacing={2} sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {options.map(option => (
          <Grid item xs={6} sm={4} md={4} key={option.id}>
            <Chip
              icon={icons[option.id]}
              label={option.name}
              clickable
              color={value === option.id ? 'primary' : 'default'}
              onClick={() => handleChipClick(option.id)}
              variant={value === option.id ? 'filled' : 'outlined'}
              sx={{
                justifyContent: 'center',
                width: '100%',
              }}
            />
          </Grid>
        ))}
      </Grid>
    </FormControl>
  )
}

export default JobChipField
