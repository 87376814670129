import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Route, Routes, useLocation } from 'react-router'
import HomePage from 'pages/HomePage'
import HospitalProfile from 'pages/Hospitals/Profile/index'
import UserProfile from 'pages/Users/Profile'
import CandidatesV1 from 'pages/Hospitals/CandidatesV1/index'
import JobsList from 'pages/Jobs/index'
import JobDetails from 'pages/Jobs/JobDetails'
import PhysiciansList from 'pages/Physicians/index'
import Connections from 'pages/Hospitals/Connections/index'
import About from 'pages/About/index'
import Employers from './pages/Super/Employers'
import EmployerUsers from './pages/Super/EmployerUsers'
import Login from 'pages/Identity/Login'
import CreatePassword from 'pages/Identity/CreatePassword'
import ResendConfirmationLink from 'pages/Identity/ResendConfirmationLink'
import SendResetPasswordLink from 'pages/Identity/SendResetPasswordLink'
import ResetPassword from 'pages/Identity/ResetPassword'
import EmailConfirmation from 'pages/Register/EmailConfirmation'
import Support from 'pages/Contact/Support.js'
import ContactUs from 'pages/Contact/ContactUs.js'
import { ReduxRouter } from '@lagunovsky/redux-react-router'
import Filters from 'pages/Users/Filters'
import Alerts from 'pages/Users/Alerts'
import HospitalRegister from 'pages/HospitalSignup'
import PhysicianRegister from 'pages/Register/Physicians'
import Layout from 'components/Layout'
import TermsAndConditions from 'pages/Legal/TermsAndConditions'
import PrivacyPolicy from 'pages/Legal/PrivacyPolicy'
import CandidatesSuper from 'pages/Super/Candidates'
import PartnerInterest from 'pages/Super/PartnerInterest'
import JobInterest from './pages/Super/JobInterest'
import Users from './pages/Admin/Users'
import Onboarding from './pages/Onboarding'
import CandidateProfile from './pages/Users/CandidateProfile'
import EmployerUserProfile from './pages/Users/EmployerUserProfile'
import Housing from './pages/Housing'
import Applications from './pages/Applications'
import SuperJobs from './pages/Super/Jobs'

const PrivateRoute = ({ children }) => {
  const location = useLocation()
  const signedIn = useSelector(state => state.identity.signedIn)
  if (!signedIn) {
    return <Navigate to="/login" state={{ from: `${location.pathname}${location.search}` }} replace />
  }

  return children
}

const PublicPrivateRoute = ({ children }) => {
  const location = useLocation()
  const signedIn = useSelector(state => state.identity.signedIn)
  if (location.search) {
    const queryParams = new URLSearchParams(location.search)
    const shouldLogin = queryParams.get('login') === 'true' || queryParams.get('clickFrom') === 'email'
    if (!signedIn && shouldLogin) {
      queryParams.delete('login')
      return <Navigate to="/login" state={{ from: `${location.pathname}?${queryParams.toString()}` }} replace />
    }
  }

  return children
}

// TODO:BROCK nested routes and outlets could cut down on cruft here: https://blog.logrocket.com/complete-guide-authentication-with-react-router-v6/

export default history => {
  return (
    <ReduxRouter history={history}>
      <Layout>
        <Routes>
          <Route path='/' element={<HomePage />} />

          <Route path='/login' element={<Login />} />
          <Route path='/housing' element={<Housing />} />

          <Route path='/candidate/email_confirmation_prompt' element={<EmailConfirmation />} />
          <Route path='/create_password' element={<CreatePassword />} />
          <Route path='/resend_confirmation_link' element={<ResendConfirmationLink />} />
          <Route path='/send_reset_password_link' element={<SendResetPasswordLink />} />
          <Route path='/reset_password' element={<ResetPassword />} />

          <Route path='/support' element={<Support />} />
          <Route path='/contact' element={<ContactUs />} />
          <Route path='/about' element={<About />} />
          <Route path='/register/hospitals' element={<HospitalRegister />} />
          <Route path='/register/physicians' element={<PhysicianRegister />} />
          <Route
            path='/super/jobs'
            element={
              <PrivateRoute>
                <SuperJobs />
              </PrivateRoute>
            }
          />
          <Route
            path='/super/employers'
            element={
              <PrivateRoute>
                <Employers />
              </PrivateRoute>
            }
          />
          <Route
            path='/super/employer_users'
            element={
              <PrivateRoute>
                <EmployerUsers />
              </PrivateRoute>
            }
          />
          <Route
            path='/admin/users'
            element={
              <PrivateRoute>
                <Users />
              </PrivateRoute>
            }
          />
          <Route
            path='/super/candidates'
            element={
              <PrivateRoute>
                <CandidatesSuper />
              </PrivateRoute>
            }
          />
          <Route
            path='/super/partner_interest'
            element={
              <PrivateRoute>
                <PartnerInterest />
              </PrivateRoute>
            }
          />
          <Route
            path='/super/job_interest'
            element={
              <PrivateRoute>
                <JobInterest />
              </PrivateRoute>
            }
          />

          <Route
            path='/hospitals/profile'
            element={
              <PrivateRoute>
                <HospitalProfile />
              </PrivateRoute>
            }
          />
          <Route
            path='/hospitals/candidates'
            element={
              <PrivateRoute>
                <CandidatesV1 />
              </PrivateRoute>
            }
          />
          <Route
            path='/hospitals/jobs'
            element={
              <PrivateRoute>
                <JobsList />
              </PrivateRoute>
            }
          />
          <Route
            path='/onboarding'
            element={
              <PrivateRoute>
                <Onboarding />
              </PrivateRoute>
            }
          />
          <Route
            path='/hospitals/connections'
            element={
              <PrivateRoute>
                <Connections />
              </PrivateRoute>
            }
          />

          <Route
            path='/jobs'
            element={
              <PublicPrivateRoute>
                <JobsList />
              </PublicPrivateRoute>
            }
          />
          <Route path='/jobs/:id' element={<JobDetails />} />
          <Route
            path='/applications'
            element={
              <PrivateRoute>
                <Applications />
              </PrivateRoute>
            }
          />
          <Route
            path='/physicians'
            element={
              <PrivateRoute>
                <PhysiciansList />
              </PrivateRoute>
            }
          />

          <Route
            path='/users/candidate/profile/'
            element={
              <PrivateRoute>
                <CandidateProfile />
              </PrivateRoute>
            }
          />
          <Route
            path='/users/employer_user/profile/'
            element={
              <PrivateRoute>
                <EmployerUserProfile />
              </PrivateRoute>
            }
          />
          <Route
            path='/users/profile/:id'
            element={
              <PrivateRoute>
                <UserProfile />
              </PrivateRoute>
            }
          />
          <Route
            path='/users/filters'
            element={
              <PrivateRoute>
                <Filters />
              </PrivateRoute>
            }
          />

          <Route
            path='/users/alerts'
            element={
              <PrivateRoute>
                <Alerts />
              </PrivateRoute>
            }
          />
          <Route
            path='users/alerts/alert-records'
            element={
              <PrivateRoute>
                <Alerts />
              </PrivateRoute>
            }
          />
          <Route path='/recruiters/profile/:id' element={<UserProfile />} />
          <Route path='/tac' element={<TermsAndConditions />} />
          <Route path='/privacy' element={<PrivacyPolicy />} />
        </Routes>
      </Layout>
    </ReduxRouter>
  )
}
