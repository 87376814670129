import axios from 'axios'
import { showSnackbar, snackbarTypes } from 'src/redux/snackbar'
import { fetchDispatch } from 'data/helpers'
import { buildFormData } from 'src/redux/helpers'

export const GET_REALTORS_LOADING = 'realtor/GET_REALTORS_LOADING'
export const GET_REALTORS_SUCCESS = 'realtor/GET_REALTORS_SUCCESS'
export const GET_REALTORS_FAILURE = 'realtor/GET_REALTORS_FAILURE'

export const CREATE_REALTOR_LOADING = 'realtor/CREATE_REALTOR_LOADING'
export const CREATE_REALTOR_SUCCESS = 'realtor/CREATE_REALTOR_SUCCESS'
export const CREATE_REALTOR_FAILURE = 'realtor/CREATE_REALTOR_FAILURE'

export const UPDATE_REALTOR_LOADING = 'realtor/UPDATE_REALTOR_LOADING'
export const UPDATE_REALTOR_SUCCESS = 'realtor/UPDATE_REALTOR_SUCCESS'
export const UPDATE_REALTOR_FAILURE = 'realtor/UPDATE_REALTOR_FAILURE'

const mapRealtorData = realtors =>
  realtors.map(realtor => ({
    ...realtor,
    listingTitle: `${realtor.firstName} ${realtor.lastName}`,
    listingSubLabel: null,
    image: realtor.profileImage,
  }))

export const getRealtorsLoading = () => ({ type: GET_REALTORS_LOADING })
export const getRealtorsSuccess = payload => ({ type: GET_REALTORS_SUCCESS, payload })
export const getRealtorsFailure = payload => ({ type: GET_REALTORS_FAILURE, payload })

export const createRealtorLoading = () => ({ type: CREATE_REALTOR_LOADING })
export const createRealtorSuccess = () => ({ type: CREATE_REALTOR_SUCCESS })
export const createRealtorFailure = payload => ({ type: CREATE_REALTOR_FAILURE, payload })

export const updateRealtorLoading = () => ({ type: UPDATE_REALTOR_LOADING })
export const updateRealtorSuccess = () => ({ type: UPDATE_REALTOR_SUCCESS })
export const updateRealtorFailure = payload => ({ type: UPDATE_REALTOR_FAILURE, payload })

export const fetchAllRealtors = () => {
  return async dispatch => {
    dispatch(getRealtorsLoading())
    try {
      const response = await fetchDispatch({
        path: '/api/realtors',
        method: 'GET',
      })

      dispatch(getRealtorsSuccess(response))
    } catch (error) {
      dispatch(
        showSnackbar(
          'Sorry! We encountered an error finding you realtors. Check back again!',
          snackbarTypes.ERROR,
        ),
      )
      dispatch(getRealtorsFailure(error.response))
    }
  }
}

export const fetchAddRealtor = query => {
  return async dispatch => {
    dispatch(createRealtorLoading())
    try {
      const response = await fetchDispatch({
        path: '/api/realtors',
        values: query,
        method: 'POST',
      })
      dispatch(fetchAllRealtors())
      dispatch(createRealtorSuccess(response))
    } catch (error) {
      dispatch(
        showSnackbar('Encountered Error creating Realtor: ' + error.response, snackbarTypes.ERROR),
      )
      dispatch(createRealtorFailure(error.response))
    }
  }
}

export const fetchUpdateRealtor = query => {
  return async dispatch => {
    dispatch(updateRealtorLoading())
    try {
      const response = await axios({
        url: `/api/realtors/${query.id}`,
        data: buildFormData(query),
        method: 'PUT',
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      dispatch(fetchAllRealtors())
      dispatch(updateRealtorSuccess(response))
    } catch (error) {
      dispatch(
        showSnackbar('Encountered error updating Realtor: ' + error.response, snackbarTypes.ERROR),
      )
      dispatch(updateRealtorFailure(error.response))
    }
  }
}

const initialState = {
  loading: false,
  errors: [],
  realtors: [],
}

export const realtorReducer = (state = initialState, action) => {
  const { payload } = action

  switch (action.type) {
    case GET_REALTORS_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_REALTORS_SUCCESS:
      return {
        ...state,
        errors: [],
        loading: false,
        realtors: mapRealtorData(payload.realtors),
      }
    case GET_REALTORS_FAILURE:
      return {
        ...state,
        loading: false,
      }
    default:
      return {
        ...state,
      }
  }
}
