import React from 'react'
import { useEffect, createContext, useContext, useState } from 'react'
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom'

const NavigationBlockContext = createContext()

let globalSetHasChanges

/**
 * Provider to manage global navigation blocking across sections.
 */
export const NavigationBlockProvider = ({ children }) => {
  const [hasChanges, setHasChanges] = useState(false)

  globalSetHasChanges = setHasChanges // Share setter globally

  useEffect(() => {
    const handleBeforeUnload = event => {
      if (hasChanges) {
        event.preventDefault()
        event.returnValue = '' // Trigger native browser dialog
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [hasChanges])

  const { navigator } = useContext(NavigationContext)

  useEffect(() => {
    const unblock = navigator.block(tx => {
      if (hasChanges) {
        const confirmLeave = window.confirm(
          'You have unsaved changes. Are you sure you want to leave?',
        )
        if (confirmLeave) {
          unblock()
          tx.retry()
        }
      } else {
        unblock()
        tx.retry()
      }
    })

    return () => {
      unblock()
    }
  }, [hasChanges, navigator])

  return (
    <NavigationBlockContext.Provider value={{ setHasChanges }}>
      {children}
    </NavigationBlockContext.Provider>
  )
}

/**
 * Hook for individual sections to contribute to the global navigation block state.
 * @param {boolean} sectionHasChanges - Whether the current section has unsaved changes.
 */
export const useGlobalNavigationBlock = sectionHasChanges => {
  useEffect(() => {
    if (!globalSetHasChanges) return

    globalSetHasChanges(prev => prev || sectionHasChanges)

    return () => {
      globalSetHasChanges(prev => prev && !sectionHasChanges)
    }
  }, [sectionHasChanges])
}
