import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchAllApplications } from 'src/redux/application'
import { stableSort, getComparator } from './helpers'
import { fetchAllStatuses, fetchAllUserTags } from 'src/redux/application'
import { fetchUpdateCandidateJob } from 'src/redux/candidateJob'
import { fetchUpdateJobApplication } from 'src/redux/application'

export const useApplications = () => {
  const dispatch = useDispatch()
  const {
    applications = [],
    statuses,
    loading,
    availableTags,
  } = useSelector(state => state.application)
  const { userId, candidateId } = useSelector(state => state.identity)
  const [rows, setRows] = React.useState([])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('title') // Default to a valid column
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  const [selectedRow, setSelectedRow] = React.useState(null)

  React.useEffect(() => {
    dispatch(fetchAllApplications({ candidateId }))
    dispatch(fetchAllStatuses())
    dispatch(fetchAllUserTags(userId))
  }, [])

  React.useEffect(() => {
    const sortedApplications = stableSort(applications, getComparator(order, orderBy))
    setRows(sortedApplications)
  }, [applications, order, orderBy])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleRowClick = row => {
    setSelectedRow(row)
    setDrawerOpen(true)
  }

  const handleDrawerClose = () => {
    setDrawerOpen(false)
    setSelectedRow(null)
  }

  const handleStatusChange = (rowId, newStatusId) => {
    setRows(prevRows =>
      prevRows.map(row =>
        row.id === rowId
          ? { ...row, jobApplicationStatus: { ...row.jobApplicationStatus, id: newStatusId } }
          : row,
      ),
    )
    dispatch(
      fetchUpdateJobApplication({
        candidateId,
        id: rowId,
        jobApplicationStatusId: newStatusId,
      }),
    )
  }

  const handleFavoriteChange = ({ id, favorite }) => {
    setRows(prevRows => prevRows.map(row => (row.jobId === id ? { ...row, favorite } : row)))
    dispatch(fetchUpdateCandidateJob({ candidateId, jobId: id, favorite }))
  }

  const handleDelete = id => {
    dispatch(fetchUpdateJobApplication({ delete: true, id: id }))
  }

  return {
    loading,
    rows,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    order,
    orderBy,
    handleRequestSort,
    drawerOpen,
    selectedRow,
    handleDrawerClose,
    handleStatusChange,
    handleRowClick,
    statuses,
    availableTags,
    handleFavoriteChange,
    handleDelete,
    candidateId,
    setDrawerOpen,
    setSelectedRow,
    userId,
  }
}
