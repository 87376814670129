import React, { useEffect, useState } from 'react'
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  Box,
  Checkbox,
  ListItemText,
  IconButton,
  InputAdornment,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { useSearchParams } from 'react-router-dom'
import { filterDefinitions } from './filterDefinitions'
import { capitalizeFirstLetter } from 'common/helpers'

export const FilterHeader = ({ filterValues, handleFilterChange }) => {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const [searchParams, setSearchParams] = useSearchParams()
  const { specialties = [], candidateTypes = [] } = filterValues
  const [candidateTypeId, setCandidateTypeId] = useState('')
  const [selectedSpecialties, setSelectedSpecialties] = useState([])

  useEffect(() => {
    const candidateTypeIdFromParams = searchParams.get('candidateTypeId')
    if (candidateTypeIdFromParams) {
      if (candidateTypeIdFromParams !== candidateTypeId) {
        const selectedProfession = candidateTypes.find(
          option => String(option.value) === candidateTypeIdFromParams,
        )
        if (selectedProfession) {
          setCandidateTypeId(selectedProfession.value)
        } else {
          setCandidateTypeId('')
        }
      }
    } else {
      if (candidateTypeId !== '') {
        setCandidateTypeId('')
      }
    }
  }, [searchParams, candidateTypes])

  useEffect(() => {
    const searchParamSpecialties = searchParams.get('specialtyIds')?.split('|') || []
    const specialtyObjects = searchParamSpecialties.map(id => {
      const specialty = specialties.find(s => s.value === id)
      return specialty ? { name: specialty.name, value: specialty.value } : { name: '', value: id }
    })
    if (JSON.stringify(specialtyObjects) !== JSON.stringify(selectedSpecialties)) {
      setSelectedSpecialties(specialtyObjects.length > 0 ? specialtyObjects : [])
    }
  }, [searchParams, specialties])

  const handleProfessionChange = event => {
    const newSelectedProfession = event.target.value
    const filterDefinition = filterDefinitions.find(filter => filter.name === 'candidateTypes')
    handleFilterChange({
      filterDefinition,
      selectedValues: newSelectedProfession ? [{ value: newSelectedProfession }] : [],
    })

    setCandidateTypeId(newSelectedProfession)
    const newSearchParams = new URLSearchParams(searchParams)
    if (newSelectedProfession) {
      newSearchParams.set('candidateTypeId', String(newSelectedProfession))
    } else {
      newSearchParams.delete('candidateTypeId')
    }
    newSearchParams.delete('specialtyIds')
    setSearchParams(newSearchParams)
    setSelectedSpecialties([])
  }

  const handleSpecialtyChange = event => {
    const value = event.target.value

    if (value.includes('all')) {
      if (selectedSpecialties.length === specialties.length) {
        setSelectedSpecialties([])
        const filterDefinition = filterDefinitions.find(filter => filter.name === 'specialty')
        handleFilterChange({
          filterDefinition,
          selectedValues: [],
        })
        const newSearchParams = new URLSearchParams(searchParams)
        newSearchParams.delete('specialtyIds')
        setSearchParams(newSearchParams)
      } else {
        const allSpecialties = specialties.map(s => ({ name: s.name, value: s.value }))
        setSelectedSpecialties(allSpecialties)
        const filterDefinition = filterDefinitions.find(filter => filter.name === 'specialty')
        handleFilterChange({
          filterDefinition,
          selectedValues: allSpecialties,
        })
        const newSearchParams = new URLSearchParams(searchParams)
        newSearchParams.set('specialtyIds', specialties.map(s => s.value).join('|'))
        setSearchParams(newSearchParams)
      }
    } else {
      const selectedValues = value.filter(val => val !== 'all')
      const newSelectedSpecialties = selectedValues
        .map(val => {
          const specialty = specialties.find(s => s.value === val)
          return specialty ? { name: specialty.name, value: specialty.value } : null
        })
        .filter(s => s !== null)

      setSelectedSpecialties(newSelectedSpecialties)

      const filterDefinition = filterDefinitions.find(filter => filter.name === 'specialty')
      handleFilterChange({
        filterDefinition,
        selectedValues: newSelectedSpecialties,
      })

      const newSearchParams = new URLSearchParams(searchParams)
      if (newSelectedSpecialties.length > 0) {
        newSearchParams.set('specialtyIds', newSelectedSpecialties.map(s => s.value).join('|'))
      } else {
        newSearchParams.delete('specialtyIds')
      }
      setSearchParams(newSearchParams)
    }
  }

  const clearProfession = () => {
    setCandidateTypeId('')
    handleProfessionChange({ target: { value: '' } })
  }

  const clearSpecialties = () => {
    setSelectedSpecialties([])
    handleSpecialtyChange({ target: { value: [] } })
  }

  const specialtyLabel =
    specialties.length === 0
      ? ''
      : selectedSpecialties.length > 1 || selectedSpecialties.length === 0
        ? 'Specialties:'
        : 'Specialty:'
  const type = 'Professions: '
  const jobsText = `${capitalizeFirstLetter(type)}`

  return (
    <>
      <Grid
        container
        direction='row'
        xs={12}
        justifyContent={mobile ? 'center' : 'flex-start'}
        alignItems='center'
        spacing={mobile ? 2 : 0}
        sx={{
          margin: '0px',
          backgroundColor: theme.palette.secondary.dark,
          padding: '10px 0px',
          borderRadius: '5px',
          overflow: 'hidden',
          textAlign: 'center',
          flexWrap: mobile ? 'wrap' : 'nowrap',
        }}
      >
        <Grid item xs={12} container alignItems='center' justifyContent='flex-start'>
          <Box
            display='flex'
            alignItems='center'
            color='white'
            flexDirection={mobile ? 'column' : 'row'}
            justifyContent='flex-start'
            width='100%'
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: '10px',
              }}
            ></Box>
            <Grid
              container
              flexDirection='row'
              xs={11}
              justifyContent='flex-start'
              alignItems='center'
              sx={{ textAlign: 'left' }}
            >
              <Grid item xs={4} md={2}>
                <Typography
                  variant={mobile ? 'body2' : 'h6'}
                  color='inherit'
                  noWrap
                  sx={{
                    fontWeight: 'bold',
                    marginTop: mobile ? '0px' : '0',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {jobsText}
                </Typography>
              </Grid>
              <Grid item xs={8} md={3}>
                <FormControl
                  variant='standard'
                  sx={{
                    margin: '0',
                    minWidth: 100,
                    backgroundColor: 'white',
                    borderRadius: '4px',
                    width: mobile ? '90%' : '100%', // Adjusted to take up less space on larger screens
                    marginBottom: mobile ? '10px' : 0,
                  }}
                >
                  <Select
                    displayEmpty
                    value={candidateTypeId || ''}
                    onChange={handleProfessionChange}
                    disableUnderline
                    variant='standard'
                    endAdornment={
                      candidateTypeId && (
                        <InputAdornment position='end'>
                          <IconButton
                            onClick={clearProfession}
                            sx={{ padding: 0, fontSize: '16px', color: theme.palette.primary.main }}
                          >
                            <ClearIcon fontSize='small' />
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                    renderValue={selected => {
                      if (!selected || selected.length === 0) {
                        return <>All</>
                      }
                      return (
                        candidateTypes.find(option => String(option.value) === String(selected))
                          ?.name || ''
                      )
                    }}
                    sx={{
                      color: theme.palette.primary.dark,
                      '& .MuiSelect-select': { padding: '0 10px' },
                      backgroundColor: 'transparent',
                      '& .MuiSelect-icon': { right: '30px' },
                      padding: '5px',
                    }}
                  >
                    <MenuItem value=''>All</MenuItem>
                    {candidateTypes.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {specialties.length > 0 && (
                <>
                  <Grid item xs={4} md={2}>
                    <Typography
                      variant={mobile ? 'body2' : 'h6'}
                      color='inherit'
                      noWrap
                      sx={{
                        fontWeight: 'bold',
                        marginLeft: mobile ? '0' : '10px',
                        marginTop: mobile ? '5px' : '0',
                        whiteSpace: 'nowrap', // Prevents text from wrapping
                      }}
                    >
                      {specialtyLabel}
                    </Typography>
                  </Grid>
                  <Grid item xs={8} md={3}>
                    <FormControl
                      variant='standard'
                      sx={{
                        margin: '0',
                        minWidth: 100,
                        backgroundColor: 'white',
                        borderRadius: '4px',
                        width: mobile ? '90%' : '100%', // Adjusted to take up less space on larger screens
                        marginBottom: mobile ? '10px' : 0,
                      }}
                    >
                      <Select
                        multiple
                        displayEmpty
                        value={
                          selectedSpecialties.length === 0
                            ? []
                            : selectedSpecialties.length === specialties.length
                              ? ['all']
                              : selectedSpecialties.map(s => s.value)
                        }
                        onChange={handleSpecialtyChange}
                        disableUnderline
                        variant='standard'
                        endAdornment={
                          selectedSpecialties.length > 0 && (
                            <InputAdornment position='end'>
                              <IconButton
                                onClick={clearSpecialties}
                                sx={{
                                  padding: 0,
                                  fontSize: '16px',
                                  color: theme.palette.primary.main,
                                }}
                              >
                                <ClearIcon fontSize='small' />
                              </IconButton>
                            </InputAdornment>
                          )
                        }
                        renderValue={selected => {
                          if (selected.length === 0 || selected.includes('all')) {
                            return 'All'
                          }
                          return selected
                            .filter(value => value && value !== 'all')
                            .map(value => specialties.find(s => s.value === value)?.name || '')
                            .join(', ')
                        }}
                        sx={{
                          color: theme.palette.primary.dark,
                          '& .MuiSelect-select': { padding: '0 10px' },
                          backgroundColor: 'transparent',
                          '& .MuiSelect-icon': { right: '30px' },
                          padding: '5px',
                        }}
                      >
                        <MenuItem key='all' value='all'>
                          <Checkbox
                            checked={selectedSpecialties.length === specialties.length}
                            indeterminate={
                              selectedSpecialties.length > 0 &&
                              selectedSpecialties.length < specialties.length
                            }
                          />
                          <ListItemText primary='All' />
                        </MenuItem>
                        {specialties.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            <Checkbox
                              checked={selectedSpecialties.some(s => s.value === option.value)}
                            />
                            <ListItemText primary={option.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default FilterHeader
