import React from 'react'
import {
  Grid,
  Typography,
  Paper,
  Divider,
  List,
  ListItemText,
  ListItem,
  ListItemIcon,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import DoneOutlineIcon from '@mui/icons-material/DoneOutline'
import { useStyles } from 'common/styles'

const About = () => {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <Paper className={classes.registrationPaper}>
      <Grid container direction='column' justifyContent='center' alignItems='center' spacing={2}>
        <Grid item>
          <Typography variant='h6'>Welcome Medical Professionals</Typography>
          <Divider />
        </Grid>
        <Grid item>
          <Typography variant='body1'>
            Your dedicated hub, crafted by and for healthcare professionals. Our mission is to
            provide a comprehensive, spam-free list of medical jobs.
          </Typography>
        </Grid>
        <Grid item>
          <List>
            <ListItem>
              <ListItemIcon>
                <DoneOutlineIcon style={{ color: `${theme.palette.secondary.main}` }} />
              </ListItemIcon>
              <ListItemText primary='Free to Use' />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <DoneOutlineIcon style={{ color: `${theme.palette.secondary.main}` }} />
              </ListItemIcon>
              <ListItemText primary='Simple, Intuitive Interface' />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <DoneOutlineIcon style={{ color: `${theme.palette.secondary.main}` }} />
              </ListItemIcon>
              <ListItemText primary='No Third-Party Recruiters' />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <DoneOutlineIcon style={{ color: `${theme.palette.secondary.main}` }} />
              </ListItemIcon>
              <ListItemText primary='Control Over Contact Preferences' />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <DoneOutlineIcon style={{ color: `${theme.palette.secondary.main}` }} />
              </ListItemIcon>
              <ListItemText primary='Developed by Medical Providers like Yourself' />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default About
