import { filterDefinitions } from './filterDefinitions'

export const generateFilters = (filterType, userType, filterValues) => {
  const filters = filterDefinitions.filter(filter => filter.isVisible(filterType, userType))
  return filters.map(filter => {
    let values = filterValues[filter.valuesResponseKey] || []

    if (filter.includeUnspecified) {
      const unspecifiedName = filter.unspecifiedText || 'Unspecified'
      const unspecifiedOption = { name: unspecifiedName, value: '0' }
      if (!values.some(option => option.value === '0')) {
        values = [unspecifiedOption, ...values]
      }
    }

    return {
      filterDefinition: filter,
      filterValues: values,
    }
  })
}

export const getSelectedFiltersFromQueryString = (searchParams, filterValues) => {
  if (filterValues == null || Object.keys(filterValues).length === 0) {
    return {}
  }
  const selectedFilters = {}
  searchParams.forEach((value, key) => {
    const filterDefinition = filterDefinitions.find(x => x.queryStringKey === key)
    if (!filterDefinition) {
      return
    }

    if (filterDefinition.isSavableFilter) {
      let queryStringValues = null
      if (filterDefinition.isMultiple) {
        queryStringValues = value.split('|')
      } else if (filterDefinition.isYesNo) {
        queryStringValues = [value === 'true' ? '1' : '0']
      } else {
        queryStringValues = [value]
      }

      // Add "Unspecified" dynamically if not in `filterValues`
      const dynamicOptions = [...filterValues[filterDefinition.valuesResponseKey]]
      if (filterDefinition.includeUnspecified) {
        const unspecifiedName = filterDefinition.unspecifiedText || 'Unspecified'
        const unspecifiedOption = { name: unspecifiedName, value: '0' }
        if (!dynamicOptions.some(option => option.value === '0')) {
          dynamicOptions.unshift(unspecifiedOption)
        }
      }

      const selectedValues = dynamicOptions.filter(x => queryStringValues.includes(x.value))
      selectedFilters[filterDefinition.name] = {
        filterDefinition,
        selectedValues,
      }
    }
  })
  return selectedFilters
}

export const getTotalFiltersSelected = selectedFilters => {
  return Object.values(selectedFilters).reduce((acc, selectedFilter) => {
    return acc + selectedFilter.selectedValues.length
  }, 0)
}

export const updateSelectedFilters = (selectedFilters, selectedFilter) => {
  let updatedFilters = { ...selectedFilters }
  updatedFilters[selectedFilter.filterDefinition.name] = selectedFilter
  const specialtyFilterDefintion = filterDefinitions.find(filter => filter.name === 'specialty')
  const subspecialtyFilterDefintion = filterDefinitions.find(
    filter => filter.name === 'subspecialty',
  )

  if (selectedFilter.filterDefinition.name === specialtyFilterDefintion.name) {
    const selectedSpecialtyFilter = selectedFilters[specialtyFilterDefintion.name] || {}
    const selectedSpecialtyIds = (selectedSpecialtyFilter.selectedValues || []).map(x => x.value)
    const selectedSubspecialtyFilter = updatedFilters[subspecialtyFilterDefintion.name]
    if (selectedSubspecialtyFilter) {
      selectedSubspecialtyFilter.selectedValues = selectedSubspecialtyFilter.selectedValues.filter(
        x => {
          selectedSpecialtyIds.includes(x.specialtyId)
        },
      )
    }
  }

  return updatedFilters
}

export const applyFilters = ({
  candidateId,
  employerId,
  employerUserId,
  searchParams,
  dispatch,
  getData,
  page,
  pageSize,
  isEmployerAdmin,
  userId,
}) => {
  const hiddenSearchParams = {}
  if (candidateId) {
    hiddenSearchParams.activeOnly = true
    hiddenSearchParams.candidateId = candidateId
  }
  if (employerId) {
    hiddenSearchParams.employerId = employerId
    hiddenSearchParams.activeOnly = true
  }
  if (employerUserId !== userId && !isEmployerAdmin && employerUserId) {
    hiddenSearchParams.employerUserIds = [employerUserId]
  }

  searchParams.set('page', page)
  searchParams.set('pageSize', pageSize)
  dispatch(getData({ ...hiddenSearchParams, ...Object.fromEntries(searchParams.entries()) }))
}

export const generateAlertName = (name, date) => {
  const formattedDate = new Date(date).toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })
  return `${name} - ${formattedDate}`
}
