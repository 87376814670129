export const buildFormData = obj => {
  var formData = new FormData()
  Object.keys(obj).forEach(key => {
    if (Array.isArray(obj[key])) {
      obj[key].map(item => {
        formData.append(`${key}`, item)
      })
    } else {
      const value = obj[key]
      // https://stackoverflow.com/questions/62303002/angular-9-formdata-appendkey-null-actually-appends-null-string
      if (value === undefined) {
        return
      }
      if (value === null) {
        formData.append(key, '')
      } else {
        formData.append(key, value)
      }
    }
  })
  return formData
}

export const sortReferralSources = referralSources => {
  const otherReferralIndex = referralSources.findIndex(r => r.name === 'Other')
  let otherReferral = { id: '', name: 'Other' }
  if (otherReferralIndex !== -1) {
    otherReferral = referralSources.splice(otherReferralIndex, 1)[0]
  }
  // Add 'Other' referral source to the end of the list
  referralSources.push(otherReferral)
  return referralSources
}
