import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import GenericDialog from 'components/Dialog/GenericDialog'
import { useDialogState } from 'data/dialog/actions'
import { fetchAddCandidateJobRating, fetchUpdateCandidateJobRating } from 'src/data/jobs/actions'
import { fetchTrackEvent, ProductAnalyticsEventTypes } from 'src/redux/productAnalytics'

export const dialogId = 'Rating'

export default function RatingDialog({ id = dialogId }) {
  const dispatch = useDispatch()

  const [{ jobId, candidateRating = {} }, setState] = useDialogState(id)
  const candidate = useSelector(state => state.candidate) || {}
  const { email } = useSelector(state => state.identity)
  const defaultValue = candidateRating.rating || 0
  const defaultComment = candidateRating.comment

  const handleAdd = values => {
    const data = {
      ...values,
      jobId,
      candidateId: candidate.id,
    }
    dispatch(fetchAddCandidateJobRating(data))
    dispatch(
      fetchTrackEvent({
        eventName: 'job_rating_add_click',
        eventType: ProductAnalyticsEventTypes.BUTTON_CLICK,
        userId: email,
        eventData: { values: values },
      }),
    )
    setState()
  }

  const handleEdit = values => {
    const data = {
      ...values,
      jobId,
      candidateId: candidate.id,
      id: candidateRating.id,
    }
    dispatch(fetchUpdateCandidateJobRating(data))
    setState()
  }
  return (
    <GenericDialog
      id={id}
      submitLabel='Submit'
      title='Submit Accuracy Rating'
      initialValues={{ rating: defaultValue, comment: defaultComment }}
      onSubmit={defaultValue ? handleEdit : handleAdd}
      fields={[
        {
          id: 'id',
          hidden: true,
        },
        {
          id: 'rating',
          type: 'rating',
          variant: 'outlined',
          required: true,
          defaultValue: defaultValue,
          value: defaultValue || 0,
          xs: 12,
        },
        {
          id: 'comment',
          type: 'text',
          defaultValue: defaultComment,
          value: defaultComment,
          label: 'Additional comments, not shown publicly',
          variant: 'standard',
          margin: 'dense',
          multiline: true,
          required: false,
          rows: 4,
        },
      ]}
    />
  )
}
