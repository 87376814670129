import React from 'react'
import {
  Grid,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
} from '@mui/material'
import DoneOutlineIcon from '@mui/icons-material/DoneOutline'

const HospitalInfo = () => {
  const theme = useTheme()
  return (
    <Grid
      container
      direction='column'
      justifyContent='flex-start'
      sx={{
        border: '1px solid',
        borderTop: 'none',
        borderRadius: '0 0 20 20',
        backgroundColor: theme.palette.primary.light,
        padding: { xs: '20px', sm: '50px' },
      }}
    >
      <Grid item>
        <Typography variant='h6'>Partner with Us</Typography>
        <Divider sx={{ height: '1px', width: '50%' }} />
      </Grid>
      <Grid item>
        <Typography variant='body1'>
          Join MyStethi as we build a comprehensive platform featuring all healthcare provider jobs.
          Our solution is simple, effective, and remarkably cost-efficient, allowing you to focus on
          connecting with new hires rather than marketing jobs.
        </Typography>
      </Grid>
      <Grid item justifyContent='flex-start'>
        <List>
          <ListItem>
            <ListItemIcon>
              <DoneOutlineIcon style={{ color: `${theme.palette.secondary.main}` }} />
            </ListItemIcon>
            <ListItemText primary='Unlimited Job Postings' />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <DoneOutlineIcon style={{ color: `${theme.palette.secondary.main}` }} />
            </ListItemIcon>
            <ListItemText primary='Unlimited Institutional Users' />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <DoneOutlineIcon style={{ color: `${theme.palette.secondary.main}` }} />
            </ListItemIcon>
            <ListItemText primary='Unlimited Provider Views and Contacts' />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <DoneOutlineIcon style={{ color: `${theme.palette.secondary.main}` }} />
            </ListItemIcon>
            <ListItemText primary='Bulk Job Upload Options' />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <DoneOutlineIcon style={{ color: `${theme.palette.secondary.main}` }} />
            </ListItemIcon>
            <ListItemText primary='Affordable and Transparent Pricing' />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  )
}

export default HospitalInfo
