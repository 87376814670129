import React from 'react'
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  ListItemText,
  Typography,
  Box,
  Chip,
  useTheme,
} from '@mui/material'

const DesktopCompositeFilterDropdown = ({ groupedFilter, onChange, selectedFilters }) => {
  const theme = useTheme()

  // Filter the grouped filters based on selectedFilters
  const selectedGroupedFilters = groupedFilter.filters.filter(
    filter => filter.filterDefinition.name && selectedFilters[filter.filterDefinition.name],
  )

  // Handler to remove a selected filter
  const handleRemoveFilter = (e, filter) => {
    e.stopPropagation() // Prevent the dropdown from opening when the chip is clicked
    const groupFilters = selectedFilters[filter.filterDefinition.name] || { selectedValues: [] }
    const selectedValues = groupFilters.selectedValues.filter(
      existingFilter =>
        !(
          existingFilter.name === filter.filterDefinition.name &&
          JSON.stringify(existingFilter.value) === JSON.stringify(filter.filterValues[0])
        ),
    )

    onChange({
      filterDefinition: filter.filterDefinition,
      filterGroupName: groupedFilter.displayName,
      selectedValues,
    })
  }

  // Handler to toggle filter selection
  const handleFilterChange = (filterGroupName, filter) => event => {
    const groupFilters = selectedFilters[filter.filterDefinition.name] || { selectedValues: [] }
    const selectedValues = groupFilters.selectedValues

    const updatedValues = event.target.checked
      ? [...selectedValues, { name: filter.filterDefinition.name, value: filter.filterValues[0] }]
      : selectedValues.filter(
          existingFilter =>
            !(
              existingFilter.name === filter.filterDefinition.name &&
              JSON.stringify(existingFilter.value) === JSON.stringify(filter.filterValues[0])
            ),
        )

    onChange({
      filterDefinition: filter.filterDefinition,
      filterGroupName,
      selectedValues: updatedValues,
    })
  }

  return (
    <FormControl
      sx={{
        m: 1,
        width: 150,
        '& .MuiInputLabel-root': {
          fontSize: '.7rem',
          color: theme.palette.primary.dark,
          '&.MuiInputLabel-shrink': {
            color: 'white',
          },
        },
      }}
      size='small'
    >
      <InputLabel
        id={`label-${groupedFilter.filterGroup}`}
        sx={{
          fontSize: '.7rem',
          color: theme.palette.primary.dark,
        }}
      >
        {groupedFilter.displayName}
      </InputLabel>
      <Select
        labelId={`label-${groupedFilter.filterGroup}`}
        value={selectedGroupedFilters.map(filter => filter.filterDefinition.name)}
        multiple
        sx={{
          height: '32px', // Set a default minimum height
          display: 'flex',
          alignItems: 'flex-start', // Allow content to grow vertically
          flexWrap: 'wrap', // Wrap chips when there are multiple
          gap: 0.5,
          '& .MuiSelect-select': {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            padding: '4px 8px',
            minHeight: '32px', // Ensure default compact height
          },
        }}
        renderValue={() => (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 0.5,
              maxHeight: '72px', // Limit height for multiple chips
              overflow: 'hidden',
            }}
          >
            {selectedGroupedFilters.map(filter => (
              <Chip
                key={filter.filterDefinition.name}
                label={filter.filterDefinition.displayName}
                onDelete={e => handleRemoveFilter(e, filter)}
                onMouseDown={e => e.stopPropagation()} // Prevent dropdown from opening
                sx={{
                  fontSize: '.7rem',
                  color: theme.palette.primary.dark,
                  backgroundColor: theme.palette.grey[200],
                  height: '24px',
                }}
              />
            ))}
          </Box>
        )}
        sx={{
          minHeight: '32px', // Base height
          padding: 0,
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 0.5,
          '& .MuiSelect-select': {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            gap: 0.5,
            padding: '4px 8px',
            maxHeight: '72px', // Limit content height
            overflowY: 'auto',
          },
        }}
        displayEmpty
      >
        <Box sx={{ px: 2, py: 1 }}>
          {groupedFilter.filters.map(filter => {
            const isChecked = (
              selectedFilters[filter.filterDefinition.name]?.selectedValues || []
            ).some(f => f.value === '1')

            return (
              <MenuItem key={filter.filterDefinition.name} value={filter.filterDefinition.name}>
                <Checkbox
                  checked={isChecked} // Dynamically control checkbox based on selection
                  onChange={handleFilterChange(groupedFilter.displayName, filter)}
                />
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        fontSize: '.8rem',
                        color: theme.palette.primary.dark,
                      }}
                    >
                      {filter.filterDefinition.displayName}
                    </Typography>
                  }
                />
              </MenuItem>
            )
          })}
        </Box>
      </Select>
    </FormControl>
  )
}

export default DesktopCompositeFilterDropdown
