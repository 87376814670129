import * as React from 'react'
import {
  useTheme,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Link,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserFilterAlerts, fetchUserFilters } from 'src/redux/filters'
import { transformAlertsData } from './helpers'
import { useChangeRoute } from 'data/hooks'
import { fetchTrackEvent, ProductAnalyticsEventTypes } from 'src/redux/productAnalytics'

export default function AlertTable() {
  const theme = useTheme()
  const dispatch = useDispatch()
  const changeRoute = useChangeRoute()
  const [filtersWithAlerts, setFiltersWithAlerts] = React.useState([])
  const [rows, setRows] = React.useState([])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const { userFilterAlerts = [], userFilters = [] } = useSelector(state => state.filters)
  const { userId, email } = useSelector(state => state.identity)

  const handleAlertClick = () => {
    dispatch(
      fetchTrackEvent({
        eventName: 'user_filter_alert_click',
        eventType: ProductAnalyticsEventTypes.BUTTON_CLICK,
        userId: email,
      }),
    )
  }

  const handleAlertHistoryClick = () => {
    dispatch(
      fetchTrackEvent({
        eventName: 'user_filter_alert_history_click',
        eventType: ProductAnalyticsEventTypes.BUTTON_CLICK,
        userId: email,
      }),
    )
  }

  React.useEffect(() => {
    setFiltersWithAlerts(
      userFilters.filter(filter => filter.alertCadence !== 'None' && filter.alertCadence !== null),
    )
  }, [userFilters])

  React.useEffect(() => {
    setRows(
      transformAlertsData({
        filtersWithAlerts: filtersWithAlerts,
        userFilterAlerts: userFilterAlerts,
        handleAlertClick,
        handleAlertHistoryClick,
        changeRoute,
        theme,
      }) || [],
    )
  }, [filtersWithAlerts])

  React.useEffect(() => {
    dispatch(fetchUserFilters(userId))
    dispatch(fetchUserFilterAlerts(userId))
  }, [dispatch, userId])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handlePreviousResultsClick = alertId => {
    changeRoute(`/users/alerts?alertId=${alertId}`)
  }
  const columns = [
    { id: 'name', label: 'Alert Name' },
    { id: 'type', label: 'Type' },
    { id: 'cadence', label: 'Email Notifications' },
    { id: 'results', label: 'Latest Results' },
    { id: 'previousRuns', label: 'Previous Runs' },
    { id: 'created', label: 'Latest Run Date' },
    { id: 'actions', label: 'Actions' },
  ]

  return (
    <Paper
      sx={{
        width: '100%',
        display: 'table',
        tableLayout: 'fixed',
        overflow: 'scroll',
        marginBottom: '50px',
      }}
    >
      <TableContainer>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead sx={{ '&.MuiTableCell-stickyHeader': { backgroundColor: 'black' } }}>
            <TableRow key='alert-header'>
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sx={{
                    '&.MuiTableCell-stickyHeader': {
                      backgroundColor: theme.palette.secondary.light,
                    },
                  }}
                >
                  <Typography variant='body1'>{column.label}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
              <TableRow hover role='checkbox' tabIndex={-1} key={row.id}>
                {columns.map(column => {
                  const value = row[column.id]
                  return (
                    <TableCell key={column.id} align={column.align}>
                      {column.id === 'previousRun' ? (
                        <Link
                          onClick={() => handlePreviousResultsClick(row.id)}
                          sx={{ cursor: 'pointer' }}
                        >
                          {row.previousResults}
                        </Link>
                      ) : (
                        value
                      )}
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component='div'
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}
