const cadenceItems = [
  { id: 2, name: 'Weekly' },
  { id: 3, name: 'Monthly' },
]
export const getSummaryText = ({ selectedUserFilter, email, cadenceId }) => {
  const type = selectedUserFilter.filterType.name == 'Job' ? 'jobs' : 'candidates'
  const cadence = cadenceItems.find(item => item.id === cadenceId).name
  return `You will receive an email to ${email} ${cadence} about ${type} that fit the following criteria:`
}

export const getCriteria = ({ queryString }) => {
  return queryString.split('&')
}
