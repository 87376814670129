import axios from 'axios'

import { showSnackbar, snackbarTypes } from 'src/redux/snackbar'

export const POST_APPLY = 'email/POST_APPLY'
export const POST_APPLY_SUCCESS = 'email/POST_SUCCESS'
export const POST_APPLY_FAILURE = 'email/POST_FAILURE'

export const postApply = () => ({ type: POST_APPLY })
export const postApplySuccess = payload => ({ type: POST_APPLY_SUCCESS, payload })
export const postApplyFailure = payload => ({ type: POST_APPLY_FAILURE, payload })

export const postApplyJob = (job, candidate, userId) => {
  return async dispatch => {
    dispatch(postApply())
    const profileLink = userId ? `/users/profile/${userId}` : null
    try {
      const response = await axios({
        url: '/api/emails/apply',
        method: 'POST',
        data: {
          recruiterEmail: job.email,
          recruiterName: job.firstName,
          jobTitle: job.title,
          jobLink: job.link,
          candidateId: candidate.id,
          candidateFirstName: candidate.firstName,
          candidateLastName: candidate.lastName,
          candidateEmail: candidate.email,
          candidateNPINumber: candidate.npiNumber,
          candidateAbout: candidate.aboutRaw,
          candidatePhone: candidate.phone,
          candidateResume: candidate.resume,
          employerName: job.employerName,
          candidateProfileLink: profileLink,
        },
      })
      dispatch(postApplySuccess(response.data.user))
      dispatch(
        showSnackbar(
          'Application sent! Check your email for confirmation and happy applying!',
          snackbarTypes.SUCCESS,
        ),
      )
    } catch (error) {
      dispatch(showSnackbar('An Error occurred applying for job', snackbarTypes.ERROR))
      dispatch(postApplyFailure(error.response.data))
    }
  }
}

const initialState = {
  loading: false,
  errors: null,
}

export const emailReducer = (state = initialState, action) => {
  const { payload } = action

  switch (action.type) {
    case POST_APPLY:
      return {
        ...state,
        loading: true,
      }
    case POST_APPLY_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case POST_APPLY_FAILURE:
      return {
        ...state,
        loading: false,
        errors: payload.errors,
      }
    default:
      return {
        ...state,
      }
  }
}
