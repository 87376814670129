import axios from 'axios'

export const GET_JOB_COUNT_LOADING = 'home/GET_JOB_COUNT'
export const GET_JOB_COUNT_SUCCESS = 'employerUser/GET_JOB_COUNT_SUCCESS'
export const GET_JOB_COUNT_FAILURE = 'employerUser/GET_JOB_COUNT_FAILURE'

export const getJobCountLoading = () => ({ type: GET_JOB_COUNT_LOADING })
export const getJobCountSuccess = payload => ({ type: GET_JOB_COUNT_SUCCESS, payload })
export const getJobCountFailure = payload => ({ type: GET_JOB_COUNT_FAILURE, payload })

export const fetchGetJobCount = () => {
  return async dispatch => {
    dispatch(getJobCountLoading())
    try {
      const response = await axios({
        url: '/api/jobs/count',
        method: 'GET',
      })
      dispatch(getJobCountSuccess(response.data))
    } catch (error) {
      dispatch(getJobCountFailure(error.response.data))
    }
  }
}

const initialState = {
  jobCount: 0,
}

export const homeReducer = (state = initialState, action) => {
  const { payload } = action

  switch (action.type) {
    case GET_JOB_COUNT_LOADING:
      return {
        ...initialState,
      }
    case GET_JOB_COUNT_SUCCESS:
      return {
        ...state,
        jobCount: payload.jobCount.count,
      }
    default:
      return {
        ...state,
      }
  }
}
