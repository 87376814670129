import React from 'react'
import { TextField, useTheme } from '@mui/material'

const NPINumberField = ({
  value,
  disabled,
  handleChange,
  label,
  setErrors,
  errors,
  name,
  required,
  ...props
}) => {
  const theme = useTheme()

  const validate = value => {
    const trimmedValue = value.trim()

    if (trimmedValue.length === 0) {
      if (required) {
        return 'NPI number is required'
      }
      return null // No error if not required
    }

    if (trimmedValue.length !== 10) {
      return 'NPI number must be exactly 10 digits'
    }

    return null // Valid input
  }

  const handleInputChange = event => {
    const { value } = event.target
    handleChange(event) // Update parent state

    // Run validation on each input change
    const error = validate(value)
    if (error) {
      setErrors(prevErrors => ({
        ...prevErrors,
        npiNumber: error,
      }))
    } else {
      setErrors(prevErrors => {
        const { ...remainingErrors } = prevErrors
        return remainingErrors
      })
    }
  }

  return (
    <TextField
      id={name}
      type='number'
      name={name}
      label={label}
      error={Boolean(errors.npiNumber)}
      disabled={disabled}
      required={required}
      helperText={errors.npiNumber}
      size='small'
      onChange={handleInputChange}
      value={value || ''}
      sx={{
        '& .MuiInputBase-input.Mui-disabled': {
          WebkitTextFillColor: theme.palette.primary.dark,
        },
        '& fieldset': disabled && { border: 'none' },
        // Remove spin buttons in Chrome, Safari, Edge
        '& input[type=number]': {
          MozAppearance: 'textfield', // Firefox
          WebkitAppearance: 'none', // Chrome, Safari, Edge
          appearance: 'textfield',
          '&::-webkit-outer-spin-button': {
            display: 'none',
          },
          '&::-webkit-inner-spin-button': {
            display: 'none',
          },
        },
      }}
      {...props}
    />
  )
}

export default NPINumberField
