import axios from 'axios'
import { snackbarTypes } from 'data/constants'
import { fetchDispatch } from 'data/helpers'
import { showSnackbar } from 'data/snackbar/actions'

export const GET_HOSPITAL_JOBS = 'GET_HOSPITAL_JOBS'
export const GET_HOSPITAL_JOBS_SUCCESS = 'GET_HOSPITAL_JOBS_SUCCESS'
export const GET_HOSPITAL_JOBS_ERROR = 'GET_HOSPITAL_JOBS_ERROR'

export const GET_CANDIDATE_JOBS = 'GET_CANDIDATE_JOBS'
export const GET_CANDIDATE_JOBS_SUCCESS = 'GET_CANDIDATE_JOBS_SUCCESS'
export const GET_CANDIDATE_JOBS_SUCCESS_PAGINATION = 'GET_CANDIDATE_JOBS_SUCCESS_PAGINATION'
export const GET_CANDIDATE_JOBS_ERROR = 'GET_CANDIDATE_JOBS_ERROR'

export const ARCHIVE_JOB = 'ARCHIVE_JOB'
export const ARCHIVE_JOB_SUCCESS = 'ARCHIVE_JOB_SUCCESS'
export const ARCHIVE_JOB_ERROR = 'ARCHIVE_JOB_ERROR'

export const ADD_CANDIDATE_JOB_RATING_LOADING = 'ADD_CANDIDATE_JOB_RATING_LOADING'
export const ADD_CANDIDATE_JOB_RATING_SUCCESS = 'ADD_CANDIDATE_JOB_RATING_SUCCESS'
export const ADD_CANDIDATE_JOB_RATING_FAILURE = 'ADD_CANDIDATE_JOB_RATING_FAILURE'

export const UPDATE_CANDIDATE_JOB_RATING_LOADING = 'UPDATE_CANDIDATE_JOB_RATING_LOADING'
export const UPDATE_CANDIDATE_JOB_RATING_SUCCESS = 'UPDATE_CANDIDATE_JOB_RATING_SUCCESS'
export const UPDATE_CANDIDATE_JOB_RATING_FAILURE = 'UPDATE_CANDIDATE_JOB_RATING_FAILURE'

export const SET_SELECTED_FILTERS = 'SET_SELECTED_FILTERS'

export const SET_JOBS_UPDATED = 'SET_JOBS_UPDATED'

export const getHospitalJobsAction = () => {
  return {
    type: GET_HOSPITAL_JOBS,
  }
}

export const getHospitalJobsSuccess = (payload = {}) => {
  return {
    type: GET_HOSPITAL_JOBS_SUCCESS,
    payload,
  }
}

export const getHospitalJobsError = () => {
  return {
    type: GET_HOSPITAL_JOBS_ERROR,
  }
}

export const getCandidateJobsAction = () => {
  return {
    type: GET_CANDIDATE_JOBS,
  }
}

export const getCandidateJobsSuccess = (payload = {}) => {
  return {
    type: GET_CANDIDATE_JOBS_SUCCESS,
    payload,
  }
}

export const getCandidateJobsPagination = (payload = {}) => {
  return {
    type: GET_CANDIDATE_JOBS_SUCCESS_PAGINATION,
    payload,
  }
}

export const getCandidateJobsError = () => {
  return {
    type: GET_CANDIDATE_JOBS_ERROR,
  }
}

export const archiveJobAction = () => {
  return {
    type: ARCHIVE_JOB,
  }
}

export const archiveJobSuccess = (jobs = []) => {
  return {
    type: ARCHIVE_JOB_SUCCESS,
    jobs,
  }
}

export const archiveJobError = () => {
  return {
    type: ARCHIVE_JOB_ERROR,
  }
}

export const setSelectedFilters = (selectedFilters = []) => {
  return {
    type: SET_SELECTED_FILTERS,
    selectedFilters,
  }
}

export const setJobsUpdated = () => {
  return {
    type: SET_JOBS_UPDATED,
  }
}

export const addCandidateJobRating = () => {
  return {
    type: ADD_CANDIDATE_JOB_RATING_LOADING,
  }
}

export const addCandidateJobRatingSuccess = (payload = {}) => {
  return {
    type: ADD_CANDIDATE_JOB_RATING_SUCCESS,
    payload,
  }
}

export const addCandidateJobRatingFailure = () => {
  return {
    type: ADD_CANDIDATE_JOB_RATING_FAILURE,
  }
}

export const updateCandidateJobRating = () => {
  return {
    type: UPDATE_CANDIDATE_JOB_RATING_LOADING,
  }
}

export const updateCandidateJobRatingSuccess = (payload = {}) => {
  return {
    type: UPDATE_CANDIDATE_JOB_RATING_SUCCESS,
    payload,
  }
}

export const updateCandidateJobRatingFailure = () => {
  return {
    type: UPDATE_CANDIDATE_JOB_RATING_FAILURE,
  }
}

export const getHospitalJobs = searchParams => {
  searchParams = new URLSearchParams(searchParams)
  return async function getHospitalJobsThunk(dispatch) {
    // show loader
    dispatch(getHospitalJobsAction())
    const response = await fetchDispatch({
      path: `/api/jobs?${searchParams.toString()}`,
      method: 'GET',
    })
    return dispatch(getHospitalJobsSuccess(response))
  }
}

export const getCandidateJobs = searchParams => {
  return async function getCandidateJobsThunk(dispatch) {
    // show loader
    searchParams = new URLSearchParams(searchParams)
    dispatch(getCandidateJobsAction())
    const response = await fetchDispatch({
      path: `/api/jobs?${searchParams.toString()}`,
      method: 'GET',
    })
    return dispatch(getCandidateJobsPagination(response))
  }
}

export const archiveJob = (requestVariables = {}) => {
  return async function archiveJobThunk(dispatch) {
    // show loader
    dispatch(archiveJobAction())
    try {
      await fetchDispatch({
        path: `/api/jobs/${requestVariables.id}`,
        method: 'PUT',
        values: requestVariables,
      })
      dispatch(archiveJobSuccess())
      if (requestVariables.archive) {
        showSnackbar(dispatch, 'Successfully archived job.', snackbarTypes.SUCCESS)
      } else {
        showSnackbar(dispatch, 'Successfully re-opened job.', snackbarTypes.SUCCESS)
      }
      dispatch(getHospitalJobs({ employerId: requestVariables.employerId }))
    } catch (error) {
      dispatch(archiveJobError())
      showSnackbar(
        dispatch,
        'Error Archiving this job, please try again later',
        snackbarTypes.ERROR,
      )
    }
  }
}

export const fetchAddCandidateJobRating = values => {
  return async dispatch => {
    dispatch(addCandidateJobRating())
    try {
      await axios({
        url: 'api/jobs/rating',
        method: 'POST',
        data: { ...values },
      })
      dispatch(addCandidateJobRatingSuccess())
      showSnackbar(
        dispatch,
        'Thank you for rating! This will help other Stethi users!',
        snackbarTypes.SUCCESS,
      )
    } catch (error) {
      dispatch(addCandidateJobRatingFailure(error.response.data))
      showSnackbar(dispatch, 'Error rating this job, please try again later.', snackbarTypes.ERROR)
    }
  }
}

export const fetchUpdateCandidateJobRating = values => {
  return async dispatch => {
    dispatch(updateCandidateJobRating())
    try {
      await axios({
        url: `api/jobs/rating/${values.id}`,
        method: 'PUT',
        data: {
          candidateId: values.candidateId,
          jobId: values.jobId,
          rating: values.rating,
          comment: values.comment,
        },
      })
      dispatch(updateCandidateJobRatingSuccess())
      showSnackbar(
        dispatch,
        'Thank you for updating this job rating! This will help other Stethi users!',
        snackbarTypes.SUCCESS,
      )
    } catch (error) {
      dispatch(updateCandidateJobRatingFailure())
      showSnackbar(dispatch, 'Error rating this job, please try again later.', snackbarTypes.ERROR)
    }
  }
}
