import React from 'react'
import { isEmpty } from 'lodash'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppBar, Typography, Grid, Toolbar, Button, Badge, useTheme } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useStyles } from 'common/styles'
import { evalFunc } from 'data/helpers'
import ButtonMenuList from 'components/Utilities/ButtonMenuList'
import { useWidth } from 'data/layout/actions'
import { useIsUser } from 'pages/Identity/Login'
import { useHandleChangeRoute, useChangeRoute } from 'data/hooks'
import { useNavigate, useLocation } from 'react-router'
import { useHandleRegister, isStatic, useHandleLogout } from 'components/Layout/Header/helpers'
import {
  candidateButtons,
  hospitalButtons,
  adminButtons,
  publicButtons,
} from 'components/Layout/Header/buttons'
import { getSuperUser } from 'data/superUser/actions'
import { fetchGetEmployerUser } from 'src/redux/employerUser'
import { fetchGetCandidate } from 'src/redux/candidate'
import HeaderLogo from 'components/Layout/Header/HeaderLogo'

const buttonSpacing = 1

export default function Header() {
  const classes = useStyles()
  const theme = useTheme()
  const { pathname } = useLocation()
  const signedIn = useSelector(state => state.identity.signedIn)
  let abRef = React.useRef(null) // Button container ref
  const { userId, candidateId, isCandidate, employerUserId, isEmployerUser, isSuper } = useIsUser()

  const width = useWidth()
  // if user is on mobile device, show hamburger menu instead
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const handleChangeRoute = useHandleChangeRoute()
  const changeRoute = useChangeRoute()
  const handleRegister = useHandleRegister()

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const handleLogout = useHandleLogout(dispatch, navigate)

  const candidate = useSelector(state => state.candidate)
  const employerUser = useSelector(state => state.employerUser)
  const superUser = useSelector(state => state.superUser)

  useEffect(() => {
    if (isCandidate && isEmpty(candidate)) {
      dispatch(fetchGetCandidate(candidateId))
    } else if (isEmployerUser && isEmpty(employerUser)) {
      dispatch(fetchGetEmployerUser(employerUserId))
    } else if (isSuper && isEmpty(superUser)) {
      dispatch(getSuperUser(userId))
    }
  })

  let sections
  let toolbarButtons = !signedIn
    ? publicButtons(handleRegister)
    : isSuper
      ? adminButtons.concat({ label: 'Logout', width: 100, onClick: handleLogout })
      : isCandidate
        ? candidateButtons()
        : hospitalButtons

  toolbarButtons = toolbarButtons.map(b => ({
    ...b,
    active: b.link === pathname || b.active,
    onClick: b.link
      ? e => {
          b.onClick && b.onClick(e)
          changeRoute(b.link)
        }
      : b.onClick, // Connect change route onClick handler if a link is present
  }))

  const staticButtons = toolbarButtons
    .filter(b => b.type === 'static' && evalFunc(b.hidden, width) !== true)
    .map(({ active, Component, width, variant, color }, i) => {
      return (
        <Grid key={i} item xs style={{ minWidth: width }}>
          <Grid container justifyContent='flex-end'>
            <Component
              variant={variant ? variant : active ? 'contained' : 'text'}
              stylevariant={!variant && 'header'}
              color={color}
            />
          </Grid>
        </Grid>
      )
    })

  const rightStaticButtons = toolbarButtons
    .filter(b => b.type === 'rightstatic' && evalFunc(b.hidden, width) !== true)
    .map(({ active, Component, width, variant, color }, i) => {
      return (
        <Grid key={i} item style={{ minWidth: width }}>
          <Grid container justifyContent='flex-end'>
            <Component
              variant={variant ? variant : active ? 'contained' : 'text'}
              stylevariant={!variant && 'header'}
              color={color}
            />
          </Grid>
        </Grid>
      )
    })

  const dynamicButtons = toolbarButtons
    .filter(b => !isStatic(b) && evalFunc(b.hidden, width) !== true)
    .map(
      ({
        active,
        label,
        link,
        onClick,
        Component = Button,
        isBeta,
        variant,
        className,
        badgeContent,
      }) => {
        const buttonElement = (
          <Grid item key={label}>
            <Typography variant='h4'>
              <Badge
                badgeContent={badgeContent}
                overlap='rectangular'
                invisible={!isBeta}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                className={classes.betaBadge}
              >
                <Component
                  variant={variant ? variant : active ? 'contained' : 'text'}
                  stylevariant={!variant && 'header'}
                  onClick={onClick ? onClick : handleChangeRoute(link)}
                  sx={
                    active
                      ? {
                          backgroundColor: 'secondary.dark',
                          color: theme =>
                            theme.palette.getContrastText(theme.palette.secondary.dark),
                        }
                      : {}
                  }
                >
                  {label}
                </Component>
              </Badge>
            </Typography>
          </Grid>
        )
        return (
          <Grid item key={label}>
            {className ? <div className={className}>{buttonElement}</div> : buttonElement}
          </Grid>
        )
      },
    )

  const collapsedButtons = (
    <Grid item key='collapseButtons'>
      <ButtonMenuList
        menuItems={toolbarButtons.filter(b => !isStatic(b) && evalFunc(b.hidden, width) !== true)}
      />
    </Grid>
  )

  sections = isMobile
    ? [staticButtons, rightStaticButtons, collapsedButtons]
    : [staticButtons, dynamicButtons, rightStaticButtons]

  return (
    <AppBar ref={abRef} position='sticky' style={{ maxWidth: width }}>
      <Toolbar>
        <Grid container justifyContent='space-between' alignItems='center' spacing={buttonSpacing}>
          <Grid item style={{ cursor: 'pointer' }} onClick={handleChangeRoute('/')}>
            <HeaderLogo />
          </Grid>
          <Grid item xs>
            <Grid container justifyContent='flex-end' alignItems='center' spacing={buttonSpacing}>
              {sections.map(s => s)}
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}
