import { GET_SUPER_USER_LOADING, GET_SUPER_USER_SUCCESS, GET_SUPER_USER_ERROR } from 'data/superUser/actions'

export default function employerUserReducer(state = {}, action) {
  const { payload } = action
  switch (action.type) {
    case GET_SUPER_USER_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_SUPER_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        id: payload.id,
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
      }
    case GET_SUPER_USER_ERROR:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}
