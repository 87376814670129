import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Card } from '@mui/material'
import CardMedia from '@mui/material/CardMedia'
import PortraitPhoto from 'static/images/profile-image-placeholder.jpg'
import { checkEmpty, imageAddress } from 'data/helpers'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import CardContent from '@mui/material/CardContent'

const useStyles = makeStyles(() =>
  createStyles({
    card: ({ height }) => ({
      height,
      width: height,
      borderRadius: 8,
    }),
    media: ({ height }) => ({
      height,
      width: height,
    }),
    content: {
      padding: 0,
    },
  }),
)

const useProfileImage = () => {
  const candidate = useSelector(state => state.candidate)
  const employerUser = useSelector(state => state.employerUser)
  return candidate.id
    ? candidate.profileImage
    : employerUser.id || employerUser.isEmployerAdmin
      ? employerUser.profileImage
      : ''
}

export default function HeaderPhotoCard({ image = PortraitPhoto, title = 'Portrait' }) {
  const appBarHeight = 64
  const classes = useStyles({ height: Math.min(appBarHeight - 8, 56) })

  const profileImage = useProfileImage()

  const parsedProfileImage = checkEmpty(profileImage) ? {} : profileImage
  const { key, fileName } = parsedProfileImage

  const img = key => (!checkEmpty(key) ? imageAddress(key, fileName) : image)

  return (
    <Card raised={true} className={classes.card}>
      <CardContent className={classes.content}>
        <CardMedia className={classes.media} image={img(key)} title={title} />
      </CardContent>
    </Card>
  )
}

HeaderPhotoCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
}
