import React from 'react'
import {
  Select,
  MenuItem,
  Radio,
  Checkbox,
  ListItemText,
  FormControl,
  InputLabel,
  Box,
  Chip,
  Typography,
  useTheme,
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { onFilterDropDownChange } from './helpers'

export const DesktopFilterDropdown = ({ filterDefinition, options, onChange, checked, styles }) => {
  checked = checked || []
  let disabledProps = {}
  if (options.length === 0) {
    disabledProps = {
      disabled: true,
    }
  }

  const theme = useTheme()
  const isMultiple = filterDefinition.isMultiple

  const handleDelete = valueToRemove => event => {
    event.stopPropagation() // Ensure the event doesn't bubble up and open the dropdown
    const newChecked = checked.filter(item => item.name !== valueToRemove)
    onChange({
      filterDefinition,
      selectedValues: newChecked,
    })
  }

  const selectedValues = checked.map(item => item.name)
  const selectValue = isMultiple ? selectedValues : selectedValues[0] || ''

  // Conditional rendering for `isYesNo` filters
  if (filterDefinition.isYesNo) {
    const isChecked = checked.some(item => item.value === '1')

    return (
      <FormControl
        sx={{ m: 1, width: 150, display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <Checkbox
          checked={isChecked}
          onChange={event => {
            const selectedValues = event.target.checked ? [{ value: '1', name: 'Yes' }] : [] // Clear filter when unchecked
            onChange({
              filterDefinition,
              selectedValues,
            })
          }}
          color='default'
          sx={{
            color: 'white',
            '&.Mui-checked': {
              color: 'white', // White checkmark
            },
            marginRight: 1,
          }}
        />
        <Typography
          sx={{
            color: 'white',
            fontSize: '0.8rem',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {filterDefinition.displayName}
        </Typography>
      </FormControl>
    )
  }

  // Default dropdown rendering
  return (
    <FormControl sx={{ m: 1, width: 150 }} size='small' {...disabledProps}>
      <InputLabel
        sx={{
          fontSize: '.7rem', // Match CompositeFilterDropdown label font size
          color: theme.palette.primary.dark,
          '&.MuiInputLabel-shrink': {
            color: 'white', // Ensure white label when shrunk
          },
        }}
        id={`label=${filterDefinition.name}`}
      >
        {filterDefinition.displayName}
      </InputLabel>
      <Select
        labelId={`label-${filterDefinition.name}`}
        id={filterDefinition.name}
        value={selectValue}
        multiple={isMultiple}
        sx={{
          minHeight: '32px', // Base height to match CompositeFilterDropdown
          display: 'flex',
          alignItems: 'center', // Align content at center initially
          flexWrap: 'wrap', // Wrap chips when there are multiple
          gap: 0.5,
          padding: 0,
          '& .MuiSelect-select': {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            gap: 0.5,
            padding: '4px 8px',
            minHeight: '32px', // Ensure default compact height
          },
        }}
        displayEmpty
        renderValue={selected =>
          isMultiple ? (
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 0.5,
              }}
            >
              {selected.map(value => (
                <Chip
                  sx={{
                    fontSize: '.7rem', // Match CompositeFilterDropdown chip font size
                    height: '24px',
                    '& .MuiChip-label': {
                      fontSize: '.7rem', // Match CompositeFilterDropdown chip label font size
                    },
                  }}
                  key={value}
                  label={value}
                  onDelete={handleDelete(value)}
                  deleteIcon={<ClearIcon sx={{ fontSize: '.8rem' }} />}
                  onMouseDown={e => e.stopPropagation()} // Prevent the dropdown from opening on delete icon click
                />
              ))}
            </Box>
          ) : (
            <Typography sx={{ color: theme.palette.primary.dark, fontSize: '.7rem' }}>
              {selected}
            </Typography>
          )
        }
        onChange={event => onFilterDropDownChange(event, options, filterDefinition, onChange)}
      >
        {options.map(({ name, value }) => (
          <MenuItem
            classes={{
              root: styles?.menuItem,
            }}
            name={name}
            key={`${value}-${name}`}
            value={name}
            id={value}
          >
            {!isMultiple ? (
              <Radio checked={checked.some(item => item.name === name)} />
            ) : (
              <Checkbox checked={checked.some(item => item.name === name)} />
            )}
            <ListItemText
              primary={
                <Typography
                  sx={{
                    fontSize: '.8rem',
                    color: theme.palette.primary.dark,
                  }}
                >
                  {name}
                </Typography>
              }
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
