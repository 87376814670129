import * as React from 'react'
import { snackbarTypes } from 'data/constants'
import { fetchDispatch } from 'data/helpers'
import { showSnackbar } from 'data/snackbar/actions'

export const GET_CANDIDATE_LOADING = 'GET_CANDIDATE_LOADING'

export const GET_CANDIDATE_SUCCESS = 'GET_CANDIDATE_SUCCESS'

export const GET_CANDIDATE_ERROR = 'GET_CANDIDATE_ERROR'

export function getCandidateLoading() {
  return {
    type: GET_CANDIDATE_LOADING,
  }
}

export function getCandidateSuccess(payload) {
  return {
    type: GET_CANDIDATE_SUCCESS,
    payload,
  }
}

export function getCandidateError() {
  return {
    type: GET_CANDIDATE_ERROR,
  }
}

export function useGetCandidates() {
  const [{ candidates, fetchingCandidates }, setState] = React.useState({
    candidates: [],
    fetchingCandidates: false,
  })

  // Update local state with candidates and request status
  const onResponse = React.useCallback(
    ({ candidateListing }) =>
      setState(prev => ({ ...prev, candidates: candidateListing, fetchingCandidates: false })),
    [setState],
  )

  const onError = () => {
    setState(prev => ({ ...prev, fetchingCandidates: false }))
  }

  // Gets list of candidates
  const getCandidates = React.useCallback(() => {
    setState(prev => ({ ...prev, fetchingCandidates: true }))
    fetchDispatch({
      path: '/api/candidates/list',
      onResponse,
      onError,
      setState,
      responseOnError: false,
    })
  }, [setState, onResponse])

  return [candidates, getCandidates, fetchingCandidates]
}

export function getCandidate(id) {
  return async dispatch => {
    dispatch(getCandidateLoading())
    const response = await fetchDispatch({ path: `/api/candidates/${id}`, method: "GET" })
    if (response.candidate) {
      return dispatch(getCandidateSuccess(response.candidate))
    }

    showSnackbar(
      dispatch,
      'An error occurred attempting to get candidate information.',
      snackbarTypes.ERROR,
    )
    return dispatch(getCandidateError())
  }
}
