import * as React from 'react'
import { Stack, Typography } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'

const ListDataItem = ({ icon, text, boolValue, isLink, link }) => {
  const Icon = icon ? (
    icon
  ) : boolValue ? (
    <CheckCircleIcon color='success' />
  ) : (
    <CancelIcon color='error' />
  )
  return (
    <Stack direction='row' spacing={1}>
      {Icon}
      {isLink ? (
        <a href={link} underline='always' color='#1B1B1B' target='_blank'>
          {text}
        </a>
      ) : (
        <Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
          {text}
        </Typography>
      )}
    </Stack>
  )
}

export default ListDataItem
