import React from 'react'
import { useDispatch } from 'react-redux'
import Grid from '@mui/material/Grid'
import Profile from 'pages/Users/Profile/Profile'
import { fetchUserProfile } from 'src/redux/profile'
import { useParams } from 'react-router-dom'

export default () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const handleGetProfile = userId => {
    dispatch(fetchUserProfile(parseInt(userId)))
  }
  if (id) {
    handleGetProfile(id)
  }
  return (
    <Grid container style={{ backgroundColor: 'white', height: '100%' }}>
      <Profile userId={id} />
    </Grid>
  )
}
