import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Drawer, Box, IconButton, Typography, Grid } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import MyStethiApplicationForm from 'pages/Applications/MyStethiApplicationForm'
import { fetchCreateJobApplication, fetchUpdateJobApplication } from 'src/redux/application'
import { useApplicationDrawer } from '../Applications/useApplicationDrawer'
import { useApplications } from '../Applications/useApplications'
import { fetchAllApplications } from 'src/redux/application'

const ApplicationDrawer = ({ open, onClose, job, jobs }) => {
  const { applications = [] } = useSelector(state => state.application)
  const [applicationData, setApplicationData] = React.useState({})
  const { formState, handleInputChange, setFormState, userId, candidateId } = useApplicationDrawer({
    selectedRow: applicationData,
  })

  const { statuses, availableTags } = useApplications()
  const isNew = job.candidateJobApplicationId === null
  const title = 'Track Job'
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (open === true) {
      dispatch(fetchAllApplications({ candidateId, jobId: job.id }))
    }
  }, [open])

  React.useEffect(() => {
    if (open) {
      if (applications.length === 1) {
        setApplicationData({
          ...applications[0],
        })
      } else {
        setApplicationData({
          jobId: job.id,
          jobName: job.title,
          hospital: job.employerName,
        })
      }
    }
  }, [applications, job, open])
  const handleSave = () => {
    if (isNew) {
      dispatch(
        fetchCreateJobApplication({ ...formState, candidateId: candidateId, userId: userId }),
      )
    } else {
      dispatch(
        fetchUpdateJobApplication({ ...formState, candidateId: candidateId, userId: userId }),
      )
    }
    onClose()
  }

  return (
    <Drawer anchor='right' open={open} onClose={onClose} sx={{ width: 400 }}>
      <Box
        sx={{
          width: 400,
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        <Grid container justifyContent='space-between'>
          <Grid item>
            <Typography variant='h6'>{title}</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={onClose} sx={{ width: 'fit-content' }}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        {formState && (
          <>
            <MyStethiApplicationForm
              handleInputChange={handleInputChange}
              formState={formState}
              statuses={statuses}
              availableTags={availableTags}
              isNew={isNew}
              setFormState={setFormState}
              handleSave={handleSave}
              onClose={onClose}
              jobs={jobs}
            />
          </>
        )}
      </Box>
    </Drawer>
  )
}

export default ApplicationDrawer
