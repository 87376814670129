import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import {
  TextField,
  MenuItem,
  InputAdornment,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot'
import {
  setAppliedUserFilterId,
  clearAppliedUserFilterId,
  fetchUserFilters,
} from 'src/redux/filters'

export const SavedFiltersButton = ({ listType = 'job', filterTypes = [] }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const { userFilters = [] } = useSelector(state => state.filters)
  const { userId } = useSelector(state => state.identity)

  const pageUserFilters =
    userFilters.length > 0 && filterTypes.length > 0
      ? userFilters.filter(
          item =>
            item.filterType.id ===
            filterTypes.find(filterType => filterType.name.toLowerCase() === listType.toLowerCase())
              .id,
        )
      : []

  React.useEffect(() => {
    dispatch(fetchUserFilters(userId))
  }, [dispatch, userId])

  const appliedFilter =
    pageUserFilters.find(item => item.queryString === searchParams.toString()) || {}

  React.useEffect(() => {
    if (Object.keys(appliedFilter).length !== 0) {
      dispatch(setAppliedUserFilterId(appliedFilter.id))
    }
  }, [appliedFilter, dispatch])

  const handleChange = event => {
    setSearchParams(event.target.value.queryString)
    dispatch(setAppliedUserFilterId(event.target.value.id))
  }

  const handleClear = () => {
    setSearchParams({})
    dispatch(clearAppliedUserFilterId())
  }

  const isDisabled = pageUserFilters.length === 0
  const hasSelection = Boolean(appliedFilter.name)

  return (
    <TextField
      onChange={handleChange}
      id='select-filters'
      select
      fullWidth
      disabled={isDisabled}
      label={!hasSelection ? '' : 'Saved Filter'}
      value={hasSelection ? appliedFilter : ''}
      size='small'
      InputLabelProps={{
        shrink: hasSelection,
        style: {
          color: hasSelection ? 'black' : 'white', // Black label when there is a selection
          fontSize: '0.7rem',
        },
      }}
      InputProps={{
        style: {
          color: hasSelection ? 'black' : 'white',
          backgroundColor: hasSelection ? 'white' : 'transparent',
        },
        endAdornment: hasSelection ? (
          <InputAdornment position='end'>
            <IconButton onClick={handleClear} size='small'>
              <ClearIcon
                fontSize='small'
                sx={{
                  color: 'black', // Black "x" when selected
                }}
              />
            </IconButton>
          </InputAdornment>
        ) : (
          <InputAdornment position='end'>
            <TurnedInNotIcon
              fontSize='small'
              sx={{
                color: isDisabled ? theme.palette.action.disabled : 'white', // Disabled color when component is disabled
              }}
            />
          </InputAdornment>
        ),
      }}
      SelectProps={{
        displayEmpty: true,
        renderValue: selected => {
          if (!selected) {
            return (
              <Typography style={{ color: 'white', fontSize: '0.8rem' }}>Saved Filters</Typography>
            )
          }
          return (
            <Typography style={{ color: 'black', fontSize: '0.8rem' }}>
              {appliedFilter.name}
            </Typography>
          )
        },
        IconComponent: () => null, // Completely remove the dropdown arrow
      }}
      sx={{
        width: '200px',
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'white',
          },
          '&:hover fieldset': {
            borderColor: 'white',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'white',
          },
          backgroundColor: hasSelection ? 'white' : 'transparent',
        },
        '& .MuiInputLabel-root': {
          transform: 'translate(10px, 0px) scale(0.6)', // Adjusted label position
        },
      }}
    >
      {pageUserFilters.map((item, index) => (
        <MenuItem id={`saved-filter-${index}`} key={index} value={item}>
          {item.name}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default SavedFiltersButton
