import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router'
import { CircularProgress } from '@mui/material'
import LoginForm from 'pages/Identity/LoginForm'
import { useStyles } from 'common/styles'

export const useIdentity = () => useSelector(state => state.identity)

export const useIsUser = () => {
  const { userId, candidateId, employerUserId, isEmployerAdmin, isSuper, signedIn } = useIdentity()
  return {
    userId,
    candidateId,
    isCandidate: !!candidateId,
    employerUserId,
    isEmployerUser: !!employerUserId,
    isEmployerAdmin,
    isSuper,
    signedIn,
  }
}

export default () => {
  const classes = useStyles({})
  const location = useLocation()
  const candidate = useSelector(state => state.candidate)
  const employerUser = useSelector(state => state.employerUser)
  const [onboardingSeen, setOnboardingSeen] = useState(undefined) // Initially undefined
  const loading =
    useSelector(state => state.identity.loading) || candidate.loading || employerUser.loading
  const { isSuper, isEmployerAdmin, isEmployerUser, isCandidate, signedIn } = useIsUser()
  const redirectTo = location.state?.from

  useEffect(() => {
    if (isCandidate) {
      const hasSeenOnboarding = candidate.tutorialViews?.includes('CandidateOnboardingNov2024')
      setOnboardingSeen(hasSeenOnboarding)
    }
  }, [candidate, isCandidate])

  if (loading || (isCandidate && onboardingSeen === undefined)) {
    // Wait for loading and for onboardingSeen to be determined for candidates
    return <CircularProgress className={classes.submitProgress} />
  }

  if (signedIn && !loading) {
    if (redirectTo) {
      return <Navigate to={redirectTo} replace />
    } else if (isSuper) {
      return <Navigate to='/super/employer_users' replace />
    } else if (isCandidate) {
      return onboardingSeen ? (
        <Navigate to='/jobs' replace />
      ) : (
        <Navigate to='/onboarding' replace />
      )
    } else if (isEmployerAdmin || isEmployerUser) {
      return <Navigate to='/physicians' replace />
    } else {
      return <Navigate to='/jobs' replace />
    }
  } else {
    return <LoginForm />
  }
}
