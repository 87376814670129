export const candidateTypeDefinitions = [
  {
    id: 1,
    name: 'Physician',
    useCandidateSpecialty: true,
    show: true,
  },
  {
    id: 2,
    name: 'Nurse',
    useCandidateSpecialty: false,
    show: false,
  },
  {
    id: 3,
    name: 'Advanced Practice Provider (NP/PA)',
    useCandidateSpecialty: true,
    show: true,
  },
  {
    id: 4,
    name: 'CRNA',
    useCandidateSpecialty: false,
    show: true,
  },
  {
    id: 5,
    name: 'Nurse Midwife',
    useCandidateSpecialty: false,
    show: true,
  },
]
