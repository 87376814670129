import * as React from 'react'
import { useTheme, CircularProgress, Button, CardMedia, Grid } from '@mui/material'
import { useDispatch } from 'react-redux'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import FileEmptyPhoto from 'static/images/file_empty.png'
import FileUploadedPhoto from 'static/images/file_uploaded.png'
import { fetchUpdateCandidate } from 'src/redux/candidate'
import { checkEmpty, hostAddress } from 'data/helpers'
import { useDialogState } from 'data/dialog/actions'

const useStyles = makeStyles(() =>
  createStyles({
    media: ({ pictureSize }) => ({
      height: pictureSize + 'px',
      width: pictureSize + 'px',
    }),
    resumeButton: {
      textDecoration: 'underline',
      color: 'blue',
      cursor: 'pointer',
    },
  }),
)
const FileUpload = ({ candidateId, dialogId, currentFile, loading }) => {
  const [, setState] = useDialogState(dialogId)
  const size = 100
  const classes = useStyles({ pictureSize: size })
  const { layout } = useTheme()
  const dispatch = useDispatch()
  const buttonText = checkEmpty(currentFile) ? `Upload Resume` : `Update Resume`

  const resume = checkEmpty(currentFile) ? {} : currentFile
  const { key, fileName } = resume
  const handleResumeClick = React.useCallback(() => {
    fetch(hostAddress(`/resume?key=${key}&fileName=${fileName}`), {
      method: 'GET',
      headers: new Headers({ 'content-type': 'application/json' }),
    }).then(response => response.blob().then(blob => window.open(URL.createObjectURL(blob))))
  }, [key, fileName])

  // Can update this if we need this file for different type of uploads later
  const handleChange = React.useCallback(event => {
    const uploadFiles = event.target.files
    if (uploadFiles) {
      const arrFiles = Array.from(uploadFiles)
      const file = arrFiles.length > 0 ? arrFiles[0] : undefined
      dispatch(
        fetchUpdateCandidate({
          id: candidateId,
          resumeFile: file,
        }),
      )
      setState(prev => ({ ...prev, resumeFile: file }))
    }
  })
  const img = currentFile ? FileUploadedPhoto : FileEmptyPhoto
  return (
    <Grid container alignItems='center' direction='column'>
      <CardMedia image={img} title='Resume' className={classes.media} />
      {resume.key ? (
        <a className={classes.resumeButton} onClick={() => handleResumeClick()}>
          View Resumé
        </a>
      ) : (
        <br />
      )}
      <input
        name='fileselect'
        type='file'
        accept='*'
        id='fileselect'
        style={{ display: 'none' }}
        onChange={handleChange}
      />
      <label htmlFor='fileselect'>
        <Button variant='outlined' size='small' component='span' disabled={loading}>
          {loading ? (
            <CircularProgress size={layout.progressSize} className={classes.submitProgress} />
          ) : (
            buttonText
          )}
        </Button>
      </label>
    </Grid>
  )
}

export default FileUpload
