import React from 'react'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { useStyles } from 'common/styles'
import Title from 'components/Title'

const EmailConfirmation = () => {
  const classes = useStyles()
  return (
    <Grid container className={classes.panel}>
      <Grid item className={classes.panelContent}>
        <Title text='Welcome to MyStethi' />
        <Typography variant='subtitle1'>Thanks for registering with MyStethi. A confirmation link has been sent to your email address. Please follow that link to login.</Typography>
      </Grid>
    </Grid>
  )
}

export default EmailConfirmation
