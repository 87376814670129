import { UPDATE_TABLE } from 'data/constants'
import { spread } from 'data/helpers'
import { LOGOUT_LOADING } from 'src/redux/identity'

const initialState = [
  {
    id: 'Users',
    searchText: '',
    orderBy: 'id',
  },
  {
    id: 'Accounts',
    searchText: '',
    orderBy: 'id',
  },
  {
    id: 'Realtors',
    searchText: '',
    orderBy: 'id',
  },
]

function setDefaults(state, tables) {
  //If default table doesnt exist in state, then populate the values, then combine state with upated state
  return tables
    .filter(t => !state[t.id])
    .map(t => updateState(state, t))
    .reduce(spread, { ...state })
}

function updateState(state, table) {
  var newState = { ...state }
  newState[table.id] = table
  if (state && state[table.id]) {
    //If table already exists, keep any other existing props
    newState[table.id] = {
      ...state[table.id],
      ...table,
    }
  }
  return newState
}

export default function tableReducer(state = setDefaults([], initialState), action) {
  switch (action.type) {
    case UPDATE_TABLE:
      return updateState(state, action.table)
    case LOGOUT_LOADING:
      return setDefaults([], initialState)
    default:
      return setDefaults(state ? { ...state } : [], initialState)
  }
}
