import React from 'react'
import { Grid, Divider } from '@mui/material'
import { useSelector } from 'react-redux'
import { useHeaderHeight } from 'data/hooks'
import Title from 'components/Title'
import { TextEditor } from 'components/TextEditor/TextEditor'
import useStyles from '../styles'
import { UserCard } from './index'
import { ContactInfo } from './ContactInfo'

export const UserProfileDesktop = ({ loggedInUser, fieldData = {}, shouldShowEditButtons }) => {
  const profile = useSelector(state => state.profile.profile)
  const user = loggedInUser || profile
  const classes = useStyles({
    headerHeight: useHeaderHeight(175),
    flexCol: { display: 'flex', flexDirection: 'column' },
    flexRow: { display: 'flex', flexDirection: 'row' },
  })

  return (
    <Grid
      container
      className={classes.profileWrapper}
      style={{ background: 'white', width: '100%' }}
    >
      <div className={classes.profileContainer} style={{ padding: '0px', width: '100%' }}>
        <div className={classes.colContainer}>
          <UserCard
            user={user}
            fieldData={fieldData}
            shouldShowEditButtons={shouldShowEditButtons}
          />
          <div className={classes.contactInfoContainer}>
            {(user.about !== null || shouldShowEditButtons) && (
              <div className={classes.aboutContainer} style={{ width: '50%', paddingTop: 20 }}>
                <Title text='About Me' textAlign='left' inverted='false' />
                <Divider variant='fullWidth' className={classes.divider} />
              </div>
            )}
            <ContactInfo user={user} isEditMode={shouldShowEditButtons} />
          </div>
          <div style={{ paddingLeft: 30, maxWidth: '950px' }}>
            <TextEditor inverted='false' initialValue={user.about} isReadOnly={true} />
          </div>
        </div>
      </div>
    </Grid>
  )
}
