import React from 'react'
import { FormControl, FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import GenericFieldHeader from '../GenericComponents/GenericFieldHeader'

const JobCheckboxField = ({ label, name, value = [], options, setState, required = false }) => {
  const handleReset = () => {
    setState(name, []) // Reset the field to an empty array
  }

  const handleCheckboxChange = (event, id) => {
    const newValue = event.target.checked
      ? [...value, id] // Add the checked item to the array
      : value.filter(val => val !== id) // Remove the unchecked item from the array

    setState(name, newValue)
  }

  return (
    <FormControl component='fieldset' fullWidth>
      <GenericFieldHeader
        name={label}
        field={value}
        optional={!required}
        handleReset={handleReset}
        showClear={value.length > 0}
      />
      <FormGroup row>
        {options.map(option => (
          <FormControlLabel
            key={option.id}
            control={
              <Checkbox
                checked={value.includes(option.id)} // Check if the ID is in the array
                onChange={event => handleCheckboxChange(event, option.id)}
              />
            }
            label={option.name}
          />
        ))}
      </FormGroup>
    </FormControl>
  )
}

export default JobCheckboxField
