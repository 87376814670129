export const defaultProps = {
  name: 'Candidate Job Interest',
  columns: [
    {
      name: 'jobId',
      header: 'Job',
    },
    { name: 'candidateId', header: 'Candidate Id' },
    {
      name: 'firstName',
      header: 'First Name',
    },
    {
      name: 'lastName',
      header: 'Last Name',
    },
    {
      name: 'email',
      header: 'Email',
    },
    {
      name: 'showEmail',
      header: 'Show Email',
    },
    {
      name: 'phone',
      header: 'Phone',
    },
    {
      name: 'showPhone',
      header: 'Show Phone',
    },

    {
      name: 'specialty',
      header: 'Specialty',
    },
    {
      name: 'interestedInContactAt',
      header: 'Interested Date',
    },
  ],
  searchableFields: ['firstName', 'lastName', 'email'],
  externalLink: {
    link: 'https://docs.google.com/spreadsheets/d/1bOZAhT5kxFl2WeEa5ejONLlJfmyXLw0GdNt6EZdKT4g/edit',
    title: 'Candidate Job Interest Logging Sheet',
  },
}
