import { keyframes } from '@mui/material'
export const slideInFromRight = keyframes`
  0% {
    transform: translate(100%, -50%);
    opacity: 0;
  }
  100% {
    transform: translate(0, -50%);
    opacity: 1;
  }
`
