import React from 'react'
import { Typography, Grid } from '@mui/material'
import { Phone as PhoneIcon, Email as EmailIcon } from '@mui/icons-material/index'
import useStyles from '../styles'

export const ContactInfo = ({ user, isEditMode }) => {
  const classes = useStyles({})
  return (
    <Grid container xs={12} alignContent='center' alignItems='center'>
      <Grid item xs={12}>
        <Typography
          variant='subtitle2'
          style={{ paddingBottom: 5, textAlign: 'center' }}
          inverted='true'
        >
          Provided Contact Information
        </Typography>
      </Grid>
      {((user.showPhone && user.phone !== null) || isEditMode) && (
        <Grid
          container
          direction='row'
          xs={12}
          justifyContent='center'
          alignContent='center'
          alignItems='center'
          aligntext='center'
        >
          <Grid item alignItems='flex-end' justifyContent='flex-end'>
            <PhoneIcon className={classes.icon} />
          </Grid>
          <Grid item alignItems='flex-end' justifyContent='flex-end'>
            <Typography className={classes.iconText}>{user.phone}</Typography>
          </Grid>
        </Grid>
      )}
      {((user.showEmail && user.email !== null) || isEditMode) && (
        <Grid
          container
          direction='row'
          xs={12}
          justifyContent='center'
          alignContent='center'
          alignItems='center'
          aligntext='center'
          style={{ paddingBottom: '10px' }}
        >
          <Grid item alignItems='flex-end' justifyContent='flex-end'>
            <EmailIcon className={classes.icon} />
          </Grid>
          <Grid item alignContent='left'>
            <Typography className={classes.iconText}>
              <a href={`mailto:${user.email}`}>{user.email}</a>
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}
