import React, { useState } from 'react'
import Loader from 'components/Loader'
import { useSelector, useDispatch } from 'react-redux'
import { EmployersTable } from 'components/Table/EmployersTable'
import EmployerDrawer from 'components/Drawers/EmployerDrawer'
import { fetchGetEmployers } from 'src/redux/employer'
import { Button } from '@mui/material'

const Employers = () => {
  const dispatch = useDispatch()
  const { loading = false, employers = [] } = useSelector(state => state.employer)
  const [isEditDrawerOpen, setEditDrawerOpen] = useState(false)
  const [selectedEmployer, setSelectedEmployer] = useState(null)
  // Functions for handling the Edit Employer Drawer
  const handleOpenEditDrawer = employer => {
    setSelectedEmployer(employer)
    setEditDrawerOpen(true)
  }

  const handleCloseEditDrawer = () => {
    setSelectedEmployer(null)
    setEditDrawerOpen(false)
  }

  React.useEffect(() => {
    dispatch(fetchGetEmployers())
  }, [dispatch])

  const employerData = employers.map(employer => ({
    ...employer,
    actions: (
      <Button variant='outlined' onClick={() => handleOpenEditDrawer(employer)}>
        Edit
      </Button>
    ),
  }))

  return (
    <>
      {loading ? <Loader /> : <EmployersTable data={employerData} />}
      <EmployerDrawer
        open={isEditDrawerOpen}
        onClose={handleCloseEditDrawer}
        employer={selectedEmployer}
      />
    </>
  )
}

export default Employers
