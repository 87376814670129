export const getItems = obj => Object.keys(obj).map(k => ({ value: Number(k), label: obj[k] }))
export const getRawItems = obj => Object.keys(obj).map(k => ({ value: k, label: obj[k] }))

// TODO:BROCK these enums should probably come from endpoints... don't need the front code duplicating them
// we already ran into one bug where the visa name didn't match

export const RELOCATION_PREFERENCE_TYPE_ENUM = {
  WillingToRelocate: 'Yes',
  NotWillingToRelocate: 'No',
}

export const PRACTICE_TYPE_ENUM = {
  FullTime: 'Full-Time',
  Locum: 'Locum',
  Telemedicine: 'Telemedicine',
  PartTime: 'Part-Time',
  PRN: 'PRN',
  Travel: 'Travel',
}

export const SHIFT_TYPE_ENUM = {
  EightDay: '8 Hours - Day',
  EightAfternoon: '8 Hours - Afternoon',
  EightOvernight: '8 Hours - Overnight',
  TwelveDay: '12 Hours - Day',
  TwelveNight: '12 Hours - Night',
  Mixed: 'Day/Night - Mixed',
}

export const SALARY_TYPE_ENUM = {
  Hourly: 'Hourly',
  Annual: 'Annual',
}

export const AREA_ENUM = {
  Urban: 'Urban',
  Rural: 'Rural',
  Suburban: 'Suburban',
}

export const ACADEMIC_ENUM = {
  Yes: 'Yes',
  Hybrid: 'Hybrid',
  No: 'No',
}

// TODO: Make this dynamic from the db
export const VISA_ENUM = {
  1: 'H-1B',
  2: 'J1',
  3: 'O-1',
}

export const CANDIDATE_TYPE_ENUM = {
  1: 'Physician',
  2: 'Nurse',
  3: 'Advanced Practice Provider (NP/PA)',
  4: 'CRNA (Nurse Anesthetist)',
  5: 'Nurse Midwife',
}

export const SEARCH_STATUS_ENUM = {
  1: 'ActivelyLooking',
  2: 'PassivelyLooking',
  3: 'NotLooking',
}

export const BOARD_ELIGIBILITY_ENUM = {
  1: 'BoardCertified',
  2: 'BoardEligible',
  3: 'Neither',
}

export const USER_TUTORIAL_VIEW_ENUM = {
  CandidateJobIntro: 1,
  RecruiterJobIntro: 2,
  RecruiterCandidateIntro: 3,
  CandidateOnboardingNov2024: 4,
}

export const YEARS_EXPERIENCE_ENUM = {
  ZeroToOne: '0-1',
  TwoToFive: '2-5',
  SixToTen: '6-10',
  ElevenPlus: '11+',
}

export const LANGUAGE_ENUM = {
  Spanish: 'Spanish',
  French: 'French',
  German: 'German',
  Chinese: 'Chinese',
  Japanese: 'Japanese',
  Korean: 'Korean',
  Arabic: 'Arabic',
  Portuguese: 'Portuguese',
  Russian: 'Russian',
  Italian: 'Italian',
  Dutch: 'Dutch',
  Hindi: 'Hindi',
  Bengali: 'Bengali',
  Punjabi: 'Punjabi',
  Turkish: 'Turkish',
  Vietnamese: 'Vietnamese',
  Tamil: 'Tamil',
  Urdu: 'Urdu',
  Persian: 'Persian',
  Swahili: 'Swahili',
  Thai: 'Thai',
  Greek: 'Greek',
  Polish: 'Polish',
  Czech: 'Czech',
  Hungarian: 'Hungarian',
  Swedish: 'Swedish',
  Danish: 'Danish',
  Finnish: 'Finnish',
  Norwegian: 'Norwegian',
  Hebrew: 'Hebrew',
  Malay: 'Malay',
  Filipino: 'Filipino',
  Romanian: 'Romanian',
  Burmese: 'Burmese',
  Serbian: 'Serbian',
  Ukrainian: 'Ukrainian',
  Croatian: 'Croatian',
  Slovak: 'Slovak',
  Bulgarian: 'Bulgarian',
  Georgian: 'Georgian',
  Armenian: 'Armenian',
  Azerbaijani: 'Azerbaijani',
  Lithuanian: 'Lithuanian',
  Latvian: 'Latvian',
  Estonian: 'Estonian',
  Bosnian: 'Bosnian',
  Slovenian: 'Slovenian',
  Kazakh: 'Kazakh',
  Uzbek: 'Uzbek',
  Icelandic: 'Icelandic',
  Maltese: 'Maltese',
  Albanian: 'Albanian',
  Macedonian: 'Macedonian',
  Pashto: 'Pashto',
  Kurdish: 'Kurdish',
  Somali: 'Somali',
  Nepali: 'Nepali',
  Lao: 'Lao',
  Khmer: 'Khmer',
  Sinhala: 'Sinhala',
  Tajik: 'Tajik',
  Tatar: 'Tatar',
  Bashkir: 'Bashkir',
  Chechen: 'Chechen',
  Chuvash: 'Chuvash',
  Yiddish: 'Yiddish',
  Maori: 'Maori',
  Samoan: 'Samoan',
  Hawaiian: 'Hawaiian',
  Tahitian: 'Tahitian',
  Malagasy: 'Malagasy',
  Wolof: 'Wolof',
  Zulu: 'Zulu',
  Xhosa: 'Xhosa',
  Sesotho: 'Sesotho',
  Tswana: 'Tswana',
  Venda: 'Venda',
  Ndebele: 'Ndebele',
  Shona: 'Shona',
  Yoruba: 'Yoruba',
  Igbo: 'Igbo',
  Amharic: 'Amharic',
  Tigrinya: 'Tigrinya',
  Oromo: 'Oromo',
  Hausa: 'Hausa',
  Fulani: 'Fulani',
  Berber: 'Berber',
  Kanuri: 'Kanuri',
  Fang: 'Fang',
  Kikuyu: 'Kikuyu',
  Luo: 'Luo',
  Tsonga: 'Tsonga',
  Swati: 'Swati',
  Chichewa: 'Chichewa',
  Tonga: 'Tonga',
  Bemba: 'Bemba',
  Nyanja: 'Nyanja',
  MalagasySignLanguage: 'MalagasySignLanguage',
  BurmeseSignLanguage: 'BurmeseSignLanguage',
  Other: 'Other',
}

export const PROFICIENCY_ENUM = {
  Beginner: 'Beginner',
  Intermediate: 'Intermediate',
  Proficient: 'Proficient',
  Native: 'Native',
}
