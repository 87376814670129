import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Loader from 'components/Loader'
import { fetchAllCandidates } from 'src/redux/candidate'
import { CandidatesTable } from 'components/Table/CandidatesTable'

const Candidates = () => {
  const dispatch = useDispatch()
  const { loading, list = [] } = useSelector(state => state.candidate)

  const candidateData = list.map(candidate => ({
    id: candidate.id,
    email: candidate.email,
    firstName: candidate.firstName,
    lastName: candidate.lastName,
    specialty: candidate.specialties.length > 0 ? candidate.specialties[0].name : null,
    subspecialty:
      candidate.subspecialties && candidate.subspecialties.length > 0
        ? candidate.subspecialties[0].name
        : null,
    registrationContact: candidate.registrationContact ? 'Yes' : 'No',
    createdAt: new Date(candidate.createdAt).toDateString(),
    emailVerifiedAt: candidate.emailVerifiedAt
      ? new Date(candidate.emailVerifiedAt).toDateString()
      : null,
    lastLoginAt: candidate.lastLoginAt ? new Date(candidate.lastLoginAt).toDateString() : null,
  }))
  useEffect(() => {
    dispatch(fetchAllCandidates({}))
  }, [])

  return <>{loading ? <Loader /> : <CandidatesTable data={candidateData} />}</>
}

export default Candidates
