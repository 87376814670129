import React from 'react'
import PropTypes from 'prop-types'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { FormControl, InputLabel, Select, FormHelperText } from '@mui/material'
import { setIfEmpty } from 'data/helpers'

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      marginTop: '16px',
    },
    helperText: {
      marginLeft: '0px',
    },
  }),
)

const OutlinedSelect = ({
  label,
  variant = 'outlined',
  value,
  error,
  forceErrorMargin = true,
  children,
  ...other
}) => {
  const classes = useStyles()

  const inputLabel = React.useRef(null)
  const [, setLabelWidth] = React.useState(0)
  const current = inputLabel && inputLabel.current
  const offsetWidth = current && current.offsetWidth

  React.useEffect(() => {
    setLabelWidth(offsetWidth || undefined)
  }, [offsetWidth])

  const isOutlined = variant === 'outlined' ? true : undefined

  return (
    <FormControl
      variant={variant}
      className={classes.formControl}
      error={error ? true : false}
      fullWidth
      {...other}
    >
      <InputLabel ref={inputLabel} shrink={isOutlined}>
        {label}
      </InputLabel>
      <Select variant='standard' notched={isOutlined} value={setIfEmpty(value)} {...other}>
        {children}
      </Select>
      {(forceErrorMargin || error) && (
        <FormHelperText className={classes.helperText}>{error}</FormHelperText>
      )}
    </FormControl>
  )
}

OutlinedSelect.propTypes = {
  label: PropTypes.string,
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  forceErrorMargin: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  classes: PropTypes.object,
}

export default OutlinedSelect
