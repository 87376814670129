import React from 'react'
import { useSelector } from 'react-redux'
import { Container, Grid } from '@mui/material'
import NavigationTabs from '../NavigationTabs'
import AboutYou from './AboutYou'
import Security from './Security'

const EmployerUserProfile = () => {
  const employerUser = useSelector(state => state.employerUser)
  const [activeTab, setActiveTab] = React.useState(0)
  const tabSteps = [
    { tab: 0, label: employerUser.firstName, Component: <AboutYou employerUser={employerUser} /> },
    { tab: 1, label: 'Security', Component: <Security employerUser={employerUser} /> },
  ]


  return (
    <Container sx={{ maxWidth: '70em', marginTop: '50px' }}>
      <Grid container direction='column'>
        <NavigationTabs activeTab={activeTab} setActiveTab={setActiveTab} tabSteps={tabSteps} />
        {tabSteps[activeTab].Component}
      </Grid>
    </Container>
  )
}

export default EmployerUserProfile
