import {
  SEND_SUPPORT_EMAIL,
  SEND_SUPPORT_EMAIL_SUCCESS,
  SEND_CONTACT_EMAIL,
  SEND_CONTACT_EMAIL_SUCCESS,
} from '../constants'

const initialState = {
  contactFormSubmitted: false,
  supportFormSubmitted: false,
}

export default function contactReducer(state = initialState, action) {
  switch (action.type) {
    case SEND_SUPPORT_EMAIL:
      return {
        ...state,
        supportFormSubmitted: false,
      }
    case SEND_SUPPORT_EMAIL_SUCCESS:
      return {
        ...state,
        supportFormSubmitted: true,
      }
    case SEND_CONTACT_EMAIL:
      return {
        ...state,
        contactFormSubmitted: false,
      }
    case SEND_CONTACT_EMAIL_SUCCESS:
      return {
        ...state,
        contactFormSubmitted: true,
      }
    default:
      return {
        ...state,
      }
  }
}
