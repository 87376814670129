import React from 'react'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import GenericDialog from 'components/Dialog/GenericDialog'
import { fetchUpdateEmployerUser } from 'src/redux/employerUser'
import { copyToLower } from 'data/helpers'
import { useDialogState } from 'data/dialog/actions'
import { States } from 'data/constants'

export const dialogId = 'Edit Recruiter Profile'

const handleValidation = (values, dialogState) => {
  var errors = copyToLower(dialogState.errors) // start with server generated errors, ensure all keys start with lowercase letterreturn errors
  return errors
}

export default function EditEmployerUserDialog({ id = dialogId }) {
  const dispatch = useDispatch()
  const [, setState] = useDialogState(id)
  const user = useSelector(state => state.employerUser)

  const handleSubmit = values => {
    const nonNullValues = _.pickBy(values, _.identity)
    dispatch(fetchUpdateEmployerUser(nonNullValues))
    // TODO: Move this into redux
    setState()
  }

  return (
    <GenericDialog
      id={id}
      title={id}
      maxWidth='sm'
      submitLabel={'Save Profile'}
      onSubmit={handleSubmit}
      validate={handleValidation}
      initialValues={user}
      fields={[
        {
          id: 'id',
          hidden: true,
        },
        {
          id: 'employerId',
          hidden: true,
        },
        {
          id: 'firstName',
          label: 'First Name',
          required: true,
          forceErrorMargin: false,
          variant: 'outlined',
          margin: 'dense',
          xs: 6,
        },
        {
          id: 'lastName',
          label: 'Last Name',
          forceErrorMargin: false,
          required: true,
          variant: 'outlined',
          margin: 'dense',
          xs: 6,
        },
        {
          id: 'jobTitle',
          label: 'Job Title',
          forceErrorMargin: false,
          variant: 'outlined',
          margin: 'dense',
          xs: 12,
        },
        {
          id: 'city',
          label: 'City',
          required: true,
          forceErrorMargin: false,
          variant: 'outlined',
          margin: 'dense',
          xs: 6,
        },
        {
          id: 'state',
          label: 'State',
          type: 'autocomplete',
          required: true,
          forceErrorMargin: false,
          variant: 'outlined',
          items: Object.keys(States).map(k => ({ value: k, label: `${States[k]}` })),
          margin: 'dense',
          xs: 6,
        },
        {
          id: 'phone',
          label: 'Phone',
          type: 'phoneNumber',
          forceErrorMargin: false,
          variant: 'outlined',
          margin: 'dense',
          xs: 6,
        },
        {
          id: 'email',
          label: 'Email',
          forceErrorMargin: false,
          variant: 'outlined',
          margin: 'dense',
          xs: 6,
        },
        {
          id: 'div1',
          type: 'div',
          xs: 6,
        },
        {
          id: 'about',
          label: 'About',
          type: 'textEditor',
          initialValue: user.about,
          forceErrorMargin: false,
          variant: 'outlined',
          margin: 'dense',
          multiline: true,
        },
        {
          id: 'aboutRaw',
          hidden: true,
        },
      ]}
    />
  )
}
