import React from 'react'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { useIdentity } from 'pages/Identity/Login'
import { EmployeeProfile, PhysicianProfile } from './ProfileCard'

// if a userId param is passed in, it is a user viewing another users profile. otherwise, it is the currently logged in user viewing their own profile.
export default function Profile({ userId }) {
  // I'm sure there's a better way of doing this
  const { employerUserId } = useIdentity()
  const isEmployerUser = !_.isNil(employerUserId)
  const employee = useSelector(state => state.employerUser)
  const candidate = useSelector(state => state.candidate)
  const profile = useSelector(state => state.profile)
  let user
  if (userId) {
    user = { ...(profile || {}).profile, type: (isEmployerUser ? 'employee' : 'candidate') }
  } else {
    user = employee.id ? { ...employee, type: 'employee' } : { ...candidate, type: 'candidate' }
  }

  return user.type === 'employee' ? (
    <EmployeeProfile user={user} showEditButtons={!userId} />
  ) : (
    <PhysicianProfile user={user} showEditButtons={!userId} />
  )
}
