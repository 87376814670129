import { OPEN_POPOVER, CLOSE_POPOVER } from 'data/constants'
import { LOGOUT_LOADING } from 'src/redux/identity'

const initialState = {}

export default function userReducer(state, action) {
  let newState = !state ? initialState : state
  switch (action.type) {
    case OPEN_POPOVER:
      newState[action.id] = { anchorEl: action.anchorEl, ...action.props }
      return newState
    case CLOSE_POPOVER:
      newState[action.id] = { anchorEl: null }
      return newState
    case LOGOUT_LOADING:
      return initialState
    default:
  }
  return newState || initialState
}
