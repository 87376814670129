import axios from 'axios'
import { showSnackbar, snackbarTypes } from 'src/redux/snackbar'
import { fetchTrackEvent, ProductAnalyticsEventTypes } from 'src/redux/productAnalytics'

export const CREATE_CANDIDATE_LOADING = 'candidate/CREATE_CANDIDATE_LOADING'
export const CREATE_CANDIDATE_SUCCESS = 'candidate/CREATE_CANDIDATE_SUCCESS'
export const CREATE_CANDIDATE_FAILURE = 'candidate/CREATE_CANDIDATE_FAILURE'

export const SET_CURRENT_USER_TYPE = 'register/SET_CURRENT_USER_TYPE'
export const SET_CURRENT_USER_EMAIL = 'register/SET_CURRENT_USER_EMAIL'

export const createCandidateLoading = () => ({ type: CREATE_CANDIDATE_LOADING })
export const createCandidateSuccess = payload => ({
  type: CREATE_CANDIDATE_SUCCESS,
  payload,
})
export const createCandidateFailure = payload => ({
  type: CREATE_CANDIDATE_FAILURE,
  payload,
})

export const setCurrentUserType = payload => ({ type: SET_CURRENT_USER_TYPE, payload })
export const setCurrentUserEmail = payload => ({ type: SET_CURRENT_USER_EMAIL, payload })

export const fetchCreateCandidate = values => {
  return async dispatch => {
    dispatch(createCandidateLoading())
    try {
      const response = await axios({
        url: '/api/candidates',
        method: 'POST',
        data: values,
      })
      dispatch(createCandidateSuccess(response.data.candidate))
      dispatch(
        fetchTrackEvent({
          eventName: 'physician_registration_success',
          eventType: ProductAnalyticsEventTypes.ACTION,
          userId: response.data.candidate.user.email,
        }),
      )
      dispatch(showSnackbar('Success. Please verify your email to log in.', snackbarTypes.SUCCESS))
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch(createCandidateFailure(error.response.data))
        dispatch(showSnackbar('Error creating your profile.', snackbarTypes.ERROR))
      }
    }
  }
}

const initialState = {
  loading: false,
  errors: [],
  formSubmitted: false,
  userType: null,
  email: null,
}

export const registerReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CANDIDATE_LOADING:
      return {
        ...state,
        loading: true,
        formSubmitted: false,
      }
    case CREATE_CANDIDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        formSubmitted: true,
      }
    case CREATE_CANDIDATE_FAILURE:
      return {
        ...state,
        loading: false,
        formSubmitted: false,
        errors: action.payload,
      }
    case SET_CURRENT_USER_TYPE:
      return {
        ...state,
        userType: action.payload,
      }
    case SET_CURRENT_USER_EMAIL:
      return {
        ...state,
        email: action.payload,
      }
    default:
      return {
        ...state,
      }
  }
}
