import React from 'react'
import CSVReader from 'react-csv-reader'
import { convertToRaw } from 'draft-js'
import { convertFromHTML } from 'draft-convert'
import { PRACTICE_TYPE_ENUM, AREA_ENUM, SHIFT_TYPE_ENUM, SALARY_TYPE_ENUM } from 'data/enums'
import { CANDIDATE_TYPE_ENUM } from '../../data/enums'

const parseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
}

const TRUE_VALUES = ['yes', 'Yes', 'YES', 'Y', 'y', 't', 'T', 'True', 'true', 'TRUE']

const getEmumKey = (object, value) => {
  return Object.keys(object).find(key => object[key] === value)
}

const getValue = (row, key) => {
  if (row[key]) {
    if (row[key].trim) {
      return row[key].trim()
    }

    return row[key]
  }

  return null
}

// TODO: format this button
// TODO: eventually remove hospital_name and have this be dynamic based on row or if a hospital uses this button based on the employer logged in
export default function CSVButton({ uploadCSV }) {
  const onFileLoad = data => {
    const transformedData = data.map(row => {
      const candidateType = getValue(row, 'CandidateType')
      const practiceType = getValue(row, 'PracticeType')
      const areaType = getValue(row, 'Area')
      const employerName = getValue(row, 'EmployerName')
      const employerUserEmail = getValue(row, 'EmployerUserEmail')
      const recruiterName = getValue(row, 'RecruiterName')
      const title = getValue(row, 'Title')
      const city = getValue(row, 'City')
      const state = getValue(row, 'State')
      const salary = getValue(row, 'Salary')

      // Split specialties and subspecialties into arrays of strings
      const specialties = getValue(row, 'SpecialtyNames')
        ? getValue(row, 'SpecialtyNames')
            .split(',')
            .map(s => s.trim())
        : []
      const subspecialties = getValue(row, 'SubspecialtyNames')
        ? getValue(row, 'SubspecialtyNames')
            .split(',')
            .map(s => s.trim())
        : []

      const link = getValue(row, 'Link')
      const pslfCompatible = getValue(row, 'PslfCompatible')
      const loanForgiveness = getValue(row, 'LoanForgiveness')
      const academicType = getValue(row, 'Academic')
      const visas = getValue(row, 'Visas')
      const aboutHtml = getValue(row, 'AboutHTML')
      const aboutRaw = getValue(row, 'AboutRaw')
      const salaryMin = getValue(row, 'SalaryMin')
      const salaryMax = getValue(row, 'SalaryMax')
      const salaryType = getValue(row, 'SalaryType')
      const shiftType = getValue(row, 'ShiftType')

      return {
        employerName: employerName,
        employerUserEmail: employerUserEmail,
        recruiterName: recruiterName,
        candidateTypeId: Number(getEmumKey(CANDIDATE_TYPE_ENUM, candidateType)),
        title: title,
        city: city,
        state: state,
        areaType: getEmumKey(AREA_ENUM, areaType),
        practiceType: getEmumKey(PRACTICE_TYPE_ENUM, practiceType),
        shiftType: getEmumKey(SHIFT_TYPE_ENUM, shiftType),
        salary: salary ? parseFloat(salary) : null,
        salaryMin: salaryMin ? parseFloat(salaryMin) : null,
        salaryMax: salaryMin ? parseFloat(salaryMax) : null,
        salaryType: getEmumKey(SALARY_TYPE_ENUM, salaryType),
        specialtyNames: specialties, // Now an array of strings
        subspecialtyNames: subspecialties, // Now an array of strings
        link: link,
        pslfCompatible: TRUE_VALUES.includes(pslfCompatible),
        loanForgiveness: TRUE_VALUES.includes(loanForgiveness),
        academicType: academicType,
        visas: visas, // TODO: make this multi
        about: aboutHtml ? JSON.stringify(convertToRaw(convertFromHTML(aboutHtml))) : null,
        aboutRaw: aboutRaw ? aboutRaw : aboutHtml ? aboutHtml.replace(/<[^>]*>/g, '') : null,
        aboutHtml: aboutHtml,
      }
    })
    uploadCSV({ jobs: transformedData })
  }

  return (
    <CSVReader
      cssClass='csv-reader-input'
      label='Upload CSV for jobs'
      onFileLoaded={onFileLoad}
      onError={() => console.log('Error Encountered')}
      parserOptions={parseOptions}
      inputId='jop-csv'
      inputName='jop-csv'
    />
  )
}
