import React, { useState } from 'react'
import Loader from 'components/Loader'
import { Link } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { JobsTable } from 'components/Table/JobsTable'
import JobCSVUploadDrawer from 'components/Drawers/JobCSVUploadDrawer'
import { getCandidateJobs } from 'src/data/jobs/actions'
import { Button, Grid } from '@mui/material'

const Jobs = () => {
  const dispatch = useDispatch()
  const { loading = false, jobsList = [] } = useSelector(state => state.jobs)
  const [isCSVDrawerOpen, setCSVDrawerOpen] = useState(false)

  const handleOpenCSVDrawer = () => setCSVDrawerOpen(true)
  const handleCloseCSVDrawer = () => setCSVDrawerOpen(false)

  React.useEffect(() => {
    dispatch(getCandidateJobs())
  }, [dispatch])

  const jobData = jobsList.map(job => ({
    id: (
      <Link color='secondary' href={`/jobs/${job.id}`} underline='always' target='_blank'>
        {job.id}
      </Link>
    ),
    title: job.title,
    employerName: job.employerName,
    link: (
      <Link color='secondary' href={job.link} underline='always' target='_blank'>
        {job.link}
      </Link>
    ),
    email: job.employerUser.user.email,
    createdAt: new Date(job.createdAt).toDateString(),
  }))

  return (
    <>
      <Grid container direction='row' justifyContent='space-between' sx={{ padding: '10px 20px' }}>
        <Button variant='contained' onClick={handleOpenCSVDrawer}>
          Upload CSV
        </Button>
      </Grid>
      {loading ? <Loader /> : <JobsTable data={jobData} />}
      <JobCSVUploadDrawer open={isCSVDrawerOpen} onClose={handleCloseCSVDrawer} />
    </>
  )
}

export default Jobs
