import React from 'react'
import { TextField, Grid } from '@mui/material'

const PasswordField = ({ handleChange, values, errors, setErrors, disabled }) => {
  const validate = event => {
    if (event.target.name === 'password') {
      const numericRegEx = /[0-9]/
      const alphaRegEx = /(?=.*[a-z])(?=.*[A-Z])/
      const nonAlpha = /[^a-zA-Z0-9]+/
      if (event.target.value.length < 8) {
        setErrors({ password: 'Must be at least 8 characters' })
      } else if (!numericRegEx.test(event.target.value)) {
        setErrors({ password: 'Must contain at least 1 number' })
      } else if (!alphaRegEx.test(event.target.value)) {
        setErrors({ password: 'Must contain one upper and one lower case char' })
      } else if (!nonAlpha.test(event.target.value)) {
        setErrors({ password: 'Must contain one non-alphanumeric char' })
      } else if (event.target.name !== values.confirmPassword) {
        setErrors({ password: 'Passwords must match.' })
        setErrors({ confirmPassword: 'Passwords must match.' })
      } else {
        delete errors.password
      }
    }
    if (event.target.name === 'confirmPassword') {
      if (event.target.value !== values.password) {
        setErrors({ ...errors, confirmPassword: 'Passwords must match.' })
      } else {
        delete errors.confirmPassword
      }
    }
    if (values.currentPassword === null && event.target.name !== 'currentPassword') {
      setErrors({ currentPassword: 'Current password is required' })
    } else {
      delete errors.currentPassword
    }
    handleChange(event)
  }
  return (
    <Grid container item direction='column' xs={6} sx={{ marginTop: '20px' }}>
      <TextField
        variant='outlined'
        id='currentPassword'
        name='currentPassword'
        label='Current Password'
        disabled={disabled}
        required={true}
        placeholder='Enter your current password'
        margin='normal'
        type='password'
        onChange={validate}
        {...(errors['currentPassword'] && {
          error: true,
          helperText: errors['currentPassword'],
        })}
      />
      <TextField
        variant='outlined'
        id='password'
        name='password'
        label='New Password'
        placeholder='Enter your new password'
        margin='normal'
        type='password'
        disabled={disabled}
        onChange={validate}
        {...(errors['password'] && {
          error: true,
          helperText: errors['password'],
        })}
      />
      <TextField
        variant='outlined'
        id='confirmPassword'
        label='Confirm Password'
        name='confirmPassword'
        placeholder='Confirm your password'
        margin='normal'
        type='password'
        disabled={disabled}
        onChange={validate}
        {...(errors['confirmPassword'] && {
          error: true,
          helperText: errors['confirmPassword'],
        })}
      />
    </Grid>
  )
}

export default PasswordField
