import React from 'react'
import { TextField, FormControl, FormLabel, FormHelperText, useTheme } from '@mui/material'

const GenericMultiLineTextField = ({
  name,
  value,
  label,
  disabled,
  placeholder,
  handleChange,
  required = false,
  error = false,
  helperText = '',
}) => {
  const theme = useTheme()

  return (
    <FormControl
      variant='outlined'
      fullWidth={true}
      required={required}
      error={error}
      sx={{ width: '100%' }}
    >
      <FormLabel
        id={`${name}-form`}
        sx={{
          color: theme.palette.primary.main,
          display: 'block',
          mb: 1,
          fontSize: '0.875rem', // Consistent font size
          fontWeight: 500, // Match styling
        }}
      >
        {label}
      </FormLabel>

      <TextField
        id={name}
        name={name}
        placeholder={placeholder}
        multiline
        disabled={disabled}
        rows={4}
        variant='outlined'
        value={value || ''}
        onChange={event => handleChange(event)}
        required={required}
        error={error}
        sx={{
          width: '100%',
          backgroundColor: theme.palette.background.paper, // Matches TextEditor background
          fontFamily: theme.typography.fontFamily, // Matches TextEditor font family
          '& .MuiInputBase-root': {
            fontSize: '0.875rem', // Consistent font size with TextEditor
            fontWeight: 400, // Matches normal text weight
            color: theme.palette.text.primary, // Matches TextEditor text color
          },
          '& .MuiInputBase-input': {
            fontFamily: theme.typography.fontFamily, // Matches TextEditor font family
            '&::placeholder': {
              color: theme.palette.text.secondary, // Matches TextEditor placeholder color
              opacity: 1, // Ensures full visibility of placeholder
              fontSize: '0.875rem', // Matches TextEditor placeholder font size
              fontStyle: 'normal', // Ensure it's not italicized
            },
          },
          '& .MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor: theme.palette.text.disabled, // Disabled text styling
          },
          '& fieldset': {
            borderColor: error ? theme.palette.error.main : theme.palette.grey[400], // Border color
          },
          '&:hover fieldset': {
            borderColor: error ? theme.palette.error.main : theme.palette.text.primary, // Hover border
          },
          '&.Mui-focused fieldset': {
            borderColor: error ? theme.palette.error.main : theme.palette.primary.main, // Focus border
          },
        }}
      />

      {/* Conditionally show helper text or error message */}
      {helperText && (
        <FormHelperText error={error} sx={{ mt: 1 }}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default GenericMultiLineTextField
