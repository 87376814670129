import React from 'react'
import {
  Paper,
  Card,
  CardContent,
  CardMedia,
  CardHeader,
  Typography,
  Divider,
  Grid,
  useTheme,
} from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import ListDataItem from './ListDataItem'
import { getLocation } from 'pages/Jobs/helpers'
import { getSpecialty } from 'pages/Physicians/helpers'
import { getPhysicianProfileImage } from 'pages/Physicians/helpers'
import { formatName, capitalizeFirstLetter } from '../../common/helpers'

export const ListItemPhysician = ({ data, isActive, onClick }) => {
  const theme = useTheme()
  const location = getLocation(capitalizeFirstLetter(data.city), data.state)
  if (data.subspecialties && data.subspecialties.length > 0) {
    data.subspecialty = data.subspecialties[0].name
  }

  const specialty =
    data.specialties.length > 0 ? getSpecialty(data.specialties[0].name, data.subspecialty) : ''
  const name = formatName(data.user.firstName) + ' ' + formatName(data.user.lastName)
  const image = getPhysicianProfileImage(data.user.profileImage)
  const lastSeenDate =
    data.lastLoginAt !== null
      ? new Date(data.lastLoginAt).toDateString()
      : new Date(data.emailVerifiedAt).toDateString()
  const lastLoginAt = 'Last seen: ' + lastSeenDate
  return (
    <Paper elevation={5} sx={{ cursor: 'pointer' }} onClick={() => onClick(data.id)}>
      <Card
        sx={{
          minWidth: 275,
          ...(isActive && {
            backgroundColor: theme.palette.secondary.light,
          }),
        }}
      >
        <Grid container direction={'row'} spacing={1} wrap={'nowrap'}>
          <Grid item>
            <CardMedia
              sx={{
                width: '100%',
                height: '70px',
                objectFit: 'cover',
              }}
              component='img'
              src={image}
              image={image}
              title='Physician Photo'
            />
          </Grid>
          <Grid item xs={6}>
            <CardHeader
              style={{ overflowWrap: 'break-word' }}
              title={name}
              titleTypographyProps={{ variant: 'h6' }}
              subheader={specialty}
              sx={{
                display: 'flex',
                overflow: 'hidden',
                '& .MuiCardHeader-content': {
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '2',
                  WebkitBoxOrient: 'vertical',
                },
              }}
            />
          </Grid>
          <Grid item style={{ marginLeft: 'auto' }}>
            <Typography sx={{ fontSize: 12, padding: '5px' }} color='text.secondary' gutter='true'>
              {lastLoginAt}
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={{ height: '1px' }} />
        <CardContent>
          <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 12, sm: 12, md: 12 }}>
            <Grid item xs={12} sm={12} md={4}>
              {location && <ListDataItem icon={<LocationOnIcon />} text={location} />}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Paper>
  )
}
