import React, { useEffect, useState } from 'react'
import { Button, Box, Typography, Avatar, Grid } from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { isEqual } from 'lodash'
import PhoneNumberField from 'components/UserFields/PhoneNumberField'
import GenericCheckboxField from 'components/UserFields/GenericCheckboxField'
import GenericTextField from 'components/UserFields/GenericTextField'
import { getPhysicianProfileImage } from 'src/pages/Physicians/helpers'
import { UploadPhotoButton } from 'pages/Users/Profile/Buttons'

const BasicInformationStep = ({ formData, setFormData, onNext, user, candidate, step }) => {
  const imageURL = getPhysicianProfileImage(candidate.profileImage)
  const [errors, setErrors] = useState({})
  const [initialFormData, setInitialFormData] = useState({}) // Track initial form data for change detection

  useEffect(() => {
    if (!candidate.loading) {
      const initialData = {
        phone: candidate.phone,
        showPhone: candidate.showPhone,
        showEmail: candidate.showEmail,
        email: candidate.email,
        id: user.candidateId,
      }
      setFormData(initialData)
      setInitialFormData(initialData) // Store initial data
    }
  }, [user, candidate, setFormData])

  const handleInputChange = event => {
    const { name, value } = event.target
    setFormData({ ...formData, [name]: value })
    if (errors[name]) {
      setErrors({ ...errors, [name]: false })
    }
  }

  const handleSubmit = () => {
    const dataToSubmit = Object.fromEntries(
      Object.entries({
        id: candidate.id,
        setupSeen: true,
        phone: formData.phone,
        showPhone: formData.showPhone,
        showEmail: formData.showEmail,
      }).filter(([, value]) => value !== null && value !== undefined && value !== ''),
    )
    const submitForm = !isEqual(formData, initialFormData)
    onNext({ dataToSubmit: dataToSubmit, step: step, submitForm: submitForm })
  }

  return (
    <Box maxWidth={600} margin='auto'>
      <Box display='flex' alignItems='center' flexDirection='column' mb={2}>
        {imageURL ? (
          <Avatar src={imageURL} sx={{ width: 100, height: 100 }} />
        ) : (
          <AccountCircleIcon color='primary' sx={{ fontSize: 100 }} />
        )}
        <Typography variant='h6' mt={1}>
          Welcome, {candidate.firstName}!
        </Typography>
        <UploadPhotoButton user={{ ...candidate, type: 'candidate' }} />
      </Box>
      <Typography variant='body2' color='textSecondary' mb={2} textAlign='center'>
        MyStethi helps connect top candidates with the best job opportunities. Complete your profile
        to get matched with the right roles! 🌟
      </Typography>
      <Typography variant='body2' color='textSecondary' mb={2} textAlign='center'>
        Your details are secure and shared only with hospital recruiters on MyStethi. 🔒
      </Typography>
      <Typography variant='body2' color='textSecondary' mb={2} textAlign='center'>
        Recruiters will see the info you provide, but you can update it anytime! 📝
      </Typography>

      <Grid container spacing={1}>
        {/* Contact Information Section */}
        <Grid item xs={12}>
          <Box border={1} borderColor='grey.300' borderRadius={1} padding={1.5} mt={1}>
            <Typography variant='subtitle2' fontWeight='medium' color='textSecondary' mb={2}>
              Contact Information
            </Typography>

            <Grid container spacing={1} alignItems='center'>
              <Grid item xs={12} md={6}>
                <GenericTextField
                  value={formData.email}
                  disabled
                  label='Email'
                  name='email'
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <PhoneNumberField
                  value={formData.phone}
                  handleChange={handleInputChange}
                  label='Phone Number'
                  name='phone'
                  fullWidth
                  error={errors.phone}
                  helperText={errors.phone && 'Phone number is required'}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <GenericCheckboxField
                  name='showEmail'
                  label='Display Email'
                  value={formData.showEmail}
                  handleChange={handleInputChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6}>
                {formData.phone && (
                  <GenericCheckboxField
                    name='showPhone'
                    label='Display Phone Number'
                    value={formData.showPhone}
                    handleChange={handleInputChange}
                    fullWidth
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {/* Next Button */}
      <Box
        textAlign='center'
        mt={3}
        mb={7}
        sx={{
          pb: {
            xs: 'calc(env(safe-area-inset-bottom, 20px) + 100px)', // Add safe-area padding on mobile
            sm: '20px', // Standard padding for larger screens
          },
        }}
      >
        <Button
          variant='contained'
          color='primary'
          onClick={handleSubmit}
          sx={{
            width: {
              xs: '90%', // Button width for mobile devices
              sm: 'auto', // Default width for larger screens
            },
            maxWidth: '400px', // Limit the button width
          }}
        >
          Setup Your Profile!
        </Button>
      </Box>
    </Box>
  )
}

export default BasicInformationStep
