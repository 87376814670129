import React from 'react'
import { Select, MenuItem, FormControl, FormHelperText, InputLabel, useTheme } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

const GenericSelectField = ({
  options,
  value,
  label,
  name,
  disabled = false,
  handleChange,
  helperText,
  required = false,
  error = false,
  errorText = '',
  ...props
}) => {
  const theme = useTheme()

  return (
    <FormControl
      variant='outlined'
      required={required}
      error={error}
      size='small'
      sx={{
        width: '100%',
        '& .MuiInputLabel-root': {
          backgroundColor: theme.palette.background.paper, // Label background
          padding: '0 4px', // Space around the label text
        },
      }}
    >
      <InputLabel
        id={`${name}-label`}
        shrink={Boolean(value)} // Shrink when there is a value
        sx={{
          color: error
            ? theme.palette.error.main
            : disabled
              ? theme.palette.text.disabled
              : theme.palette.primary.main,
          backgroundColor: theme.palette.background.paper, // Background behind the label
          padding: '0 4px', // Space for better visibility
          transform: 'translate(14px, 12px) scale(1)', // Initial label position
          '&.Mui-focused': {
            color: error ? theme.palette.error.main : theme.palette.primary.main,
          },
          '&.MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(0.75)', // Shrink position
          },
        }}
      >
        {label}
      </InputLabel>

      <Select
        labelId={`${name}-label`}
        id={name}
        value={value || ''}
        onChange={e => {
          const { value } = e.target
          handleChange({
            target: { name, value }, // Pass name and value in the same format expected by the parent
          })
        }}
        disabled={disabled}
        IconComponent={ArrowDropDownIcon}
        displayEmpty
        sx={{
          '& .MuiSelect-select': {
            paddingRight: '40px', // Space for dropdown arrow
            color: disabled ? theme.palette.text.disabled : theme.palette.text.primary, // Text color for active/inactive states
          },
          '& .MuiSelect-icon': {
            color: theme.palette.text.primary, // Dropdown arrow color
            fontSize: '1.5rem', // Dropdown arrow size
            marginRight: '8px', // Arrow alignment
          },
          '& fieldset': {
            borderColor: error ? theme.palette.error.main : theme.palette.grey[400], // Default border
          },
          '&:hover fieldset': {
            borderColor: error ? theme.palette.error.main : theme.palette.text.primary, // Hover border
          },
          '&.Mui-focused fieldset': {
            borderColor: error ? theme.palette.error.main : theme.palette.primary.main, // Focus border
          },
        }}
        {...props}
      >
        {options.map(option => (
          <MenuItem key={option.id} value={option.id}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{error ? errorText : helperText}</FormHelperText>
    </FormControl>
  )
}

export default GenericSelectField
