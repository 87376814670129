import * as React from 'react'
import { TableCell, TableHead, TableRow, TableSortLabel, Typography, useTheme } from '@mui/material'

export default function EnhancedTableHead(props) {
  const theme = useTheme()
  const { order, orderBy, onRequestSort, columns } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {columns.map(column => (
          <TableCell
            key={'header-' + column.name}
            align={column.numeric ? 'right' : 'left'}
            sortDirection={orderBy === column.name ? order : false}
            sx={{
              '&.MuiTableCell-stickyHeader': {
                backgroundColor: theme.palette.secondary.light,
              },
            }}
          >
            {column.name !== 'actions' ? (
              <TableSortLabel
                active={orderBy === column.name}
                direction={orderBy === column.name ? order : 'asc'}
                onClick={createSortHandler(column.name)}
              >
                <Typography variant='body2'>{column.header}</Typography>
              </TableSortLabel>
            ) : (
              <Typography variant='body2'>{column.header}</Typography>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
