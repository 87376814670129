import React from 'react'
import MaskedInput from 'react-text-mask'
import Text from 'components/DialogField/Text'

const TextMaskCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { ...other } = props
  return (
    <MaskedInput
      {...other}
      ref={ref2 => {
        ref(ref2 ? ref2.inputElement : null)
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      placeholder={'(888) 888-8888'}
    />
  )
})

export default function PhoneNumber({ InputProps, ...other }) {
  return (
    <Text
      InputProps={{
        ...InputProps,
        inputComponent: TextMaskCustom,
      }}
      {...other}
    />
  )
}
