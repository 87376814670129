// AddEmployerComponent.js
import React, { useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import Button from '@mui/material/Button'
import EmployerDrawer from 'components/Drawers/EmployerDrawer'

const AddEmployerComponent = () => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Button variant='outlined' onClick={handleOpen} startIcon={<AddIcon />}>
        Add Employer
      </Button>
      <EmployerDrawer open={open} onClose={handleClose} />
    </>
  )
}

export default AddEmployerComponent
