/* eslint-disable */

// TODO:BROCK server should do this, and don't strip https
export function addHttp(url) {
  if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
    url = 'http://' + url
  }
  return url
}

export function capitalizeFirstLetter(str) {
  if (str !== null) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
  }
  return str
}

export function capitalizeWords(sentence) {
  return sentence
    .split(' ')
    .map(word => capitalizeFirstLetter(word))
    .join(' ')
}

export const formatName = str => {
  if (str !== null) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
  }
  return str
}
