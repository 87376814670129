import React from 'react'
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'

const GenericCheckboxField = ({ name, handleChange, disabled, value, label }) => {
  const handleClick = event => {
    handleChange({ target: { name: name, value: event.target.checked } })
  }

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            name={name}
            id={name}
            onChange={handleClick}
            disabled={disabled}
            checked={value || false}
          />
        }
        label={label}
      />
    </FormGroup>
  )
}

export default GenericCheckboxField
