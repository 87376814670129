import React, { useState } from 'react'
import Loader from 'components/Loader'
import { Button } from '@mui/material'
import { fetchGetEmployerUsers } from 'src/redux/users'
import { useSelector, useDispatch } from 'react-redux'
import { EmployerUsersTable } from 'components/Table/EmployerUsersTable'
import EmployerUserDrawer from 'components/Drawers/EmployerUserDrawer'
const EmployerUsers = () => {
  const dispatch = useDispatch()
  const [isEditDrawerOpen, setEditDrawerOpen] = useState(false)
  const [selectedEmployerUser, setSelectedEmployerUser] = useState(null)

  const { loading, users } = useSelector(state => state.users)
  const usersData = users.map(user => ({
    id: user.user.id,
    isEmployerAdmin: user.isEmployerAdmin === true ? 'Yes' : 'No',
    isActive: user.user.emailVerifiedAt !== null ? 'Yes' : 'No',
    verifiedAt: user.user.emailVerifiedAt
      ? new Date(user.user.emailVerifiedAt).toDateString()
      : null,
    email: user.user.email,
    firstName: user.user.firstName,
    lastName: user.user.lastName,
    employer: user.employer.name,
    actions: (
      <Button variant='outlined' onClick={() => handleOpenEditDrawer(user)}>
        Edit
      </Button>
    ),
  }))

  const handleOpenEditDrawer = employerUser => {
    setSelectedEmployerUser(employerUser)
    setEditDrawerOpen(true)
  }

  const handleCloseEditDrawer = () => {
    setSelectedEmployerUser(null)
    setEditDrawerOpen(false)
  }
  React.useEffect(() => {
    dispatch(fetchGetEmployerUsers())
  }, [dispatch])

  return (
    <>
      {loading ? <Loader /> : <EmployerUsersTable showCSV={false} data={usersData} />}
      <EmployerUserDrawer
        open={isEditDrawerOpen}
        onClose={handleCloseEditDrawer}
        initialValues={selectedEmployerUser}
        type='edit'
      />
    </>
  )
}
export default EmployerUsers
