import React, { useState, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import JoyRideStepper from 'components/JoyRide'
import FeatureIntroDialog from 'components/Dialog/FeatureIntroDialog'
import { useTheme, useMediaQuery, Typography } from '@mui/material'
import { JoyrideContext } from 'components/JoyRide/JoyrideContext'
import { fetchTrackEvent, ProductAnalyticsEventTypes } from 'src/redux/productAnalytics'
import { fetchCreateUserTutorialView } from 'src/redux/users'
import { USER_TUTORIAL_VIEW_ENUM } from 'src/data/enums'

const CandidatesPageTutorial = () => {
  const [showModal, setShowModal] = useState(true)
  const [runTour, setRunTour] = useState(false)
  const { setCurrentStep } = useContext(JoyrideContext)
  const dispatch = useDispatch()
  const tutorialName = 'RecruiterCandidateIntro'
  const theme = useTheme()
  const below_lg = useMediaQuery(theme.breakpoints.down('md'))
  const { firstName } = useSelector(state => state.employerUser)
  const { email, userId, employerUserId } = useSelector(state => state.identity)

  const tutorialId = USER_TUTORIAL_VIEW_ENUM[tutorialName]

  const startTour = () => {
    setShowModal(false)
    setRunTour(true)
    dispatch(
      fetchTrackEvent({
        eventName: `tour_${tutorialName}_start`,
        eventType: ProductAnalyticsEventTypes.TOUR_EVENT,
        userId: email,
      }),
    )
  }

  const skipTour = () => {
    setShowModal(false)
    setRunTour(false)
    dispatch(
      fetchCreateUserTutorialView({
        userId: userId,
        tutorial: tutorialId,
        employerUserId: employerUserId,
        tutorialName: tutorialName,
      }),
    )
    dispatch(
      fetchTrackEvent({
        eventName: `tour_${tutorialName}_skip`,
        eventType: ProductAnalyticsEventTypes.TOUR_EVENT,
        userId: email,
      }),
    )
  }

  const header = `👋 Welcome, ${firstName}!`
  const content = `Let's help you get started with viewing potential job candidates!`
  const buttonText = 'Show me around'
  const steps = [
    {
      target: '.joyride_candidate_list',
      content: (
        <Typography variant='body1'>
          Here you can view all the candidates on MyStethi. Click on a candidate to see more details
          about them and their ideal job.
        </Typography>
      ),
      disableBeacon: true,
    },
    {
      target: '.joyride_candidate_content',
      content: (
        <Typography variant='body1'>
          This section shows the profile of the selected candidate. Review their details and see if
          they are a good fit for your job openings.
        </Typography>
      ),
      disableBeacon: true,
    },
    {
      target: '.joyride_contact_candidate',
      content: (
        <Typography variant='body1'>
          Click here to contact the candidate directly. Communicate with them to discuss the role
          and schedule interviews.
        </Typography>
      ),
      disableBeacon: true,
    },
    {
      target: '.joyride_filters',
      content: (
        <Typography variant='body1'>
          Use these filters to narrow down the list of candidates to those who best match your
          criteria.
        </Typography>
      ),
      disableBeacon: true,
    },
    {
      target: '.joyride_more_filters',
      content: (
        <Typography variant='body1'>
          Click here to access additional filter options to further refine your candidate search.
        </Typography>
      ),
      disableBeacon: true,
    },
    {
      target: '.joyride_save_filters',
      content: (
        <Typography variant='body1'>
          Save your filters for quick access later. You can also set up email notifications for new
          candidates who match your criteria.
        </Typography>
      ),
      disableBeacon: true,
    },
    {
      target: '.joyride_saved_filters',
      content: (
        <Typography variant='body1'>
          Access your saved filters here for faster searches in the future.
        </Typography>
      ),
      disableBeacon: true,
    },
    {
      target: '.joyride_profile_header',
      content: (
        <Typography variant='body1'>
          Manage your profile and settings here. Ensure your profile is up-to-date to make the most
          of your recruiting efforts.
        </Typography>
      ),
      disableBeacon: true,
      placement: 'left',
    },
    {
      target: '.joyride_header_alerts',
      content: (
        <Typography variant='body1'>
          Manage and view your email alerts here. Stay updated with the latest candidate
          applications and updates. Good luck hiring! 🎉
        </Typography>
      ),
      disableBeacon: true,
      placement: 'left',
    },
  ]

  const handleJoyrideCallback = ({ step }) => {
    setCurrentStep(step.index)
  }

  return (
    !below_lg && (
      <>
        <FeatureIntroDialog
          open={showModal}
          onClose={startTour}
          header={header}
          content={content}
          buttonText={buttonText}
          onSkip={skipTour}
        />
        {runTour && (
          <JoyRideStepper
            stepperSteps={steps}
            tutorialName={tutorialName}
            callback={handleJoyrideCallback}
            onSuccess={() => {
              dispatch(
                fetchCreateUserTutorialView({
                  userId: userId,
                  tutorial: tutorialId,
                  employerUserId: employerUserId,
                  tutorialName: tutorialName,
                }),
              )
            }}
          />
        )}
      </>
    )
  )
}

export default CandidatesPageTutorial
