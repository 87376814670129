import React from 'react'
import { useSelector } from 'react-redux'
import GenericDialog from 'components/Dialog/GenericDialog'
import { useDialogState } from 'data/dialog/actions'
import Content from './Content'
export const dialogId = 'Job Interest'

export default function JobInterestDialog({ id = dialogId }) {
  const [, setState] = useDialogState(id)
  const candidate = useSelector(state => state.candidate) || {}
  const { email } = useSelector(state => state.identity)

  const component = () => <Content candidateId={candidate.id} email={email} />

  React.useEffect(() => {
    setState(prev => ({
      ...prev,
      RenderComponent: component,
    }))
  }, [])

  return <GenericDialog id={id} />
}
