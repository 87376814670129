import * as React from 'react'
import { Grid, Typography, Link } from '@mui/material'
import { NotificationEnableDialogButton } from 'components/Dialog/DialogButton'
import { formatDate } from 'data/helpers'
import * as DeleteDialog from 'components/Dialog/DeleteDialog'
import { fetchUpdateUserFilter } from 'src/redux/filters'

export const transformAlertsData = ({
  filtersWithAlerts,
  userFilterAlerts,
  handleAlertClick,
  handleAlertHistoryClick,
  changeRoute,
  theme,
}) => {
  return filtersWithAlerts.map(userFilter => {
    const initialValues = {
      filterId: userFilter.id,
      cadenceId: userFilter.alertCadence,
    }

    const alertRecordsForFilter =
      userFilterAlerts.filter(
        item => item.userFilterId === userFilter.id && Number(item.recordCount) > 0,
      ) || []
    const urlPath = userFilter.filterType.name === 'Job' ? 'jobs' : 'physicians'
    const date =
      alertRecordsForFilter.length > 0 ? formatDate(alertRecordsForFilter[0].createdAt) : '-'

    const count =
      alertRecordsForFilter.length > 0 ? (
        <Link
          onClick={e => {
            e.preventDefault() // Prevent default anchor behavior
            handleAlertClick() // Call your custom function
            window.open(
              `/${urlPath}?userFilterAlertId=${alertRecordsForFilter[0].id}`,
              '_blank',
              'noopener,noreferrer',
            ) // Open link in new tab
          }}
          sx={{ cursor: 'pointer' }}
        >
          {alertRecordsForFilter[0].recordCount} {userFilter.filterType.name}
          {alertRecordsForFilter[0].recordCount > 1 ? 's' : ''}
        </Link>
      ) : (
        'No Results'
      )

    const previousResultsCount = alertRecordsForFilter.length
    const previousResultCountDisplay =
      previousResultsCount > 0 ? previousResultsCount + ' Previous run(s)' : '-'
    const deleteData = {
      id: userFilter.id,
      userId: userFilter.userId,
      alertCadence: 'None',
    }

    const onDelete = () => {
      return fetchUpdateUserFilter({
        query: deleteData,
      })
    }

    return {
      id: userFilter.id,
      name: userFilter.name,
      type: userFilter.filterType.name,
      cadence:
        userFilter.alertCadence === 'None' ? (
          <span style={{ color: theme.palette.warning.main }}>Not running</span>
        ) : (
          userFilter.alertCadence
        ),
      results: count,
      previousRuns: previousResultsCount ? (
        <Link
          onClick={e => {
            e.preventDefault() // Prevent default anchor behavior
            handleAlertHistoryClick()
            changeRoute(`/users/alerts/alert-records?alertId=${userFilter.id}`)
          }}
          sx={{ cursor: 'pointer' }}
        >
          {previousResultCountDisplay}
        </Link>
      ) : (
        previousResultCountDisplay
      ),
      created: date,
      actions: (
        <Grid container>
          <NotificationEnableDialogButton
            Module={DeleteDialog}
            size='small'
            id='Delete Dialog'
            data={deleteData}
            onDelete={onDelete}
            isDeleted={userFilter.alertCadence === 'None'}
            initialValues={initialValues}
            title={userFilter.alertCadence === 'None' ? ' ⚠️ Turn on Alert' : ' ⚠️ Turn Off Alert'}
            Content={() => (
              <Grid justifyContent='center'>
                {userFilter.alertCadence === 'None' ? (
                  <Typography variant='body1' sx={{ lineHeight: 2 }}>
                    Are you sure you want to enable notifications for this alert?
                  </Typography>
                ) : (
                  <Typography variant='body1' sx={{ lineHeight: 2 }}>
                    Are you sure you want to delete notification for this alert?
                    <br /> This action will <b>not</b> remove all historic records associated with
                    this alert, but you will <b>stop</b> receiving notifications.
                    <br /> Remember, you can always re-enable this alert later!
                  </Typography>
                )}
              </Grid>
            )}
          />
        </Grid>
      ),
    }
  })
}

export const transformAlertsRecordData = ({ alertRecords, handleAlertRecordClick }) => {
  return alertRecords.map(alert => {
    const hrefSubstring = alert.filterType === 'Job' ? 'jobs' : 'physicians'
    const handleClick = e => {
      e.preventDefault() // Prevent default anchor behavior
      handleAlertRecordClick() // Call your custom function
      window.open(
        `/${hrefSubstring}?userFilterAlertId=${alert.id}`,
        '_blank',
        'noopener,noreferrer',
      ) // Open link in a new window
    }
    const count = (
      <Link
        href={`/${hrefSubstring}?userFilterAlertId=${alert.id}`}
        onClick={handleClick}
        sx={{ cursor: 'pointer' }}
      >
        {alert.recordCount} {alert.filterType}
        {alert.recordCount > 1 ? 's' : ''}
      </Link>
    )
    return {
      runDate: formatDate(alert.createdAt),
      results: count,
    }
  })
}
