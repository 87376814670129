import React from 'react'
import { Grid, Typography, Box } from '@mui/material'

const CandidatesV1 = () => (
  <Box m={2}>
    <Grid container justifyContent='center'>
      <Grid item>
        <Typography variant='h5'>Candidates Placeholder</Typography>
      </Grid>
    </Grid>
  </Box>
)

export default CandidatesV1
