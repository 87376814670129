import React from 'react'
import { useSelector } from 'react-redux'
import Grid from '@mui/material/Grid'
import { useStyles } from 'common/styles'
import RegistrationForm from './RegistrationForm'
import Success from './Success'
import About from './About'

const PhysicianRegister = () => {
  const classes = useStyles()
  const { formSubmitted } = useSelector(state => state.register)
  return (
    <>
      <Grid container className={classes.registrationPhysicianContainer}>
        <Grid
          container
          justifyContent='center'
          alignItems='center'
          md={6}
          className={classes.registerBackground}
        >
          <Grid
            item
            sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}
            style={{ margin: '50px' }}
          >
            <About />
          </Grid>
        </Grid>
        {formSubmitted === false ? (
          <Grid item xs={12} md={6} className={classes.registerContent}>
            <RegistrationForm />
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            md={6}
            alignItems='center'
            justifyContent='center'
            className={classes.registerSuccess}
          >
            <Success />
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default PhysicianRegister
