import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Box } from '@mui/material'
import { useChangeRoute } from 'data/hooks'
import Loader from 'components/Loader'
import OnboardingStepper from './OnboardingStepper'
import BasicInformationStep from './Steps/BasicInformationStep'
import ProfessionalDetailsStep from './Steps/ProfessionalDetailsStep'
import BackgroundInformationStep from './Steps/BackgroundInformationStep'
import PreferencesStep from './Steps/PreferencesStep'
import YourStoryStep from './Steps/YourStoryStep'
import { fetchCreateUserTutorialView } from 'src/redux/users'
import { fetchTrackEvent, ProductAnalyticsEventTypes } from 'src/redux/productAnalytics'
import { fetchUpdateCandidate } from 'src/redux/candidate'
import { fetchRegistrationData } from 'src/redux/supportingData'
import { USER_TUTORIAL_VIEW_ENUM } from 'src/data/enums'

const steps = [
  'Basic Information',
  'Professional Details',
  'Background Information',
  'Preferences',
  'Your Story',
]

const Onboarding = () => {
  const user = useSelector(state => state.identity)
  const candidate = useSelector(state => state.candidate)
  const [activeStep, setActiveStep] = useState(0)
  const [formData, setFormData] = useState({})
  const [tutorialViewSubmitted, setTutorialViewSubmitted] = useState(false) // Tracks submission status
  const dispatch = useDispatch()
  const changeRoute = useChangeRoute()
  const onboardingTutorialName = 'CandidateOnboardingNov2024'
  const tutorialId = USER_TUTORIAL_VIEW_ENUM[onboardingTutorialName]
  user.type = 'candidate'

  useEffect(() => {
    dispatch(fetchRegistrationData())
  }, [dispatch])

  useEffect(() => {
    if (
      !tutorialViewSubmitted && // Prevent re-dispatch
      !candidate.tutorialViews?.includes(onboardingTutorialName) &&
      user.userId
    ) {
      dispatch(
        fetchCreateUserTutorialView({
          userId: user.userId,
          tutorial: tutorialId,
          tutorialName: onboardingTutorialName,
        }),
      )
      setTutorialViewSubmitted(true) // Mark as submitted
    }
  }, [candidate.tutorialViews, tutorialViewSubmitted])

  const handleNext = ({ dataToSubmit, step, submitForm }) => {
    if (submitForm) {
      if (activeStep === 4) {
        dispatch(
          fetchUpdateCandidate(dataToSubmit, 'Success!', null, {
            function: changeRoute,
            params: '/users/candidate/profile',
          }),
        )
      } else {
        dispatch(
          fetchUpdateCandidate(dataToSubmit, 'Updated!', null, {
            function: setActiveStep,
            params: activeStep + 1,
          }),
        )
      }
      dispatch(
        fetchTrackEvent({
          eventName: `onboarding_${step}_submit_click`,
          eventType: ProductAnalyticsEventTypes.BUTTON_CLICK,
          userId: dataToSubmit.email,
        }),
      )
    } else {
      if (activeStep !== 4) {
        setActiveStep(activeStep + 1)
      } else {
        changeRoute('/jobs')
      }
    }
  }

  const handleBack = () => setActiveStep(prev => prev - 1)

  // New handler to allow navigation to completed steps
  const handleStepClick = stepIndex => {
    if (stepIndex <= activeStep) {
      setActiveStep(stepIndex)
    }
  }

  const getStepComponent = step => {
    switch (step) {
      case 0:
        return (
          <BasicInformationStep
            formData={formData}
            setFormData={setFormData}
            onNext={handleNext}
            user={user}
            candidate={candidate}
            step='basicInformation'
          />
        )
      case 1:
        return (
          <ProfessionalDetailsStep
            formData={formData}
            setFormData={setFormData}
            onNext={handleNext}
            user={user}
            candidate={candidate}
            onBack={handleBack}
            step='professionalDetails'
          />
        )
      case 2:
        return (
          <BackgroundInformationStep
            formData={formData}
            setFormData={setFormData}
            onNext={handleNext}
            onBack={handleBack}
            candidate={candidate}
            user={user}
            step='backgroundInformation'
          />
        )
      case 3:
        return (
          <PreferencesStep
            formData={formData}
            setFormData={setFormData}
            onNext={handleNext}
            onBack={handleBack}
            candidate={candidate}
            user={user}
            step='preferences'
          />
        )
      case 4:
        return (
          <YourStoryStep
            formData={formData}
            setFormData={setFormData}
            onNext={handleNext}
            onBack={handleBack}
            candidate={candidate}
            user={user}
            step='yourStory'
          />
        )
      default:
        return null
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start', // Aligns content at the top
        alignItems: 'center',
        minHeight: '100vh', // Ensures background covers full viewport height
        width: '100%',
        background: `linear-gradient(to right,
        #D7EAE9 1%,
        #F5F5F5 5%,
        #FFFFFF 85%,
        #F5F5F5 95%,
        #D7EAE9 99%)`,
        paddingTop: '20px', // Optional padding to add some space at the top
      }}
    >
      <Grid container direction='column' alignItems='center'>
        {/* Stepper Component */}
        <Grid item xs={12} md={8} lg={6}>
          <OnboardingStepper
            steps={steps}
            activeStep={activeStep}
            onStepClick={handleStepClick} // Pass handler for step navigation
          />
        </Grid>

        {/* Step Content */}
        <Grid item xs={12} md={8} lg={6}>
          <Box mt={1}>
            {candidate.loading ? (
              <Box display='flex' justifyContent='center' alignItems='center' height='100px'>
                <Loader />
              </Box>
            ) : (
              getStepComponent(activeStep)
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Onboarding
