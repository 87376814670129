import React from 'react'
import { Grid, Divider } from '@mui/material'
import { useSelector } from 'react-redux'
import { useHeaderHeight } from 'data/hooks'
import { checkEmpty, imageAddress } from 'data/helpers'
import Title from 'components/Title'
import ContactPhotoCard from 'components/ContactPhotoCard'
import Buttons from 'pages/Users/Profile/Buttons'
import { TextEditor } from 'components/TextEditor/TextEditor'
import useStyles from '../styles'
import { FieldData } from './index'
import { ContactInfo } from './ContactInfo'

export const UserProfileMobile = ({ loggedInUser, fieldData = {}, shouldShowEditButtons }) => {
  const user = loggedInUser || useSelector(state => state.profile.profile)
  const { key, fileName } = checkEmpty(user.employerImage) ? {} : user.employerImage
  const BackgroundImage = !checkEmpty(key) ? imageAddress(key, fileName) : undefined
  const classes = useStyles({
    headerHeight: useHeaderHeight(175),
    BackgroundImage,
    flexCol: { display: 'flex', flexDirection: 'column' },
    flexRow: { display: 'flex', flexDirection: 'row' },
  })
  {
    user.about
  }
  return (
    <Grid container justifyContent='center' spacing={4}>
      <Grid item xs={12}>
        <Grid container className={classes.header} />
      </Grid>
      <div className={classes.mobileContent}>
        <div className={classes.mobilePhoto}>
          <ContactPhotoCard profileImage={user.profileImage} size={150} />
        </div>

        <div className={classes.mobileFieldWrapper}>
          <Title text={[user.firstName, user.lastName].join(' ')} textAlign='center' />
          <FieldData user={fieldData} isMobile={true} />

          {shouldShowEditButtons && <Buttons user={user} />}
        </div>

        <div className={classes.mobileAboutContainer}>
          <ContactInfo user={user} isEditMode={shouldShowEditButtons} />
          <Title text='About' inverted='false' textAlign='center' />
          <Divider variant='fullWidth' className={classes.dividerMobile} />
          <TextEditor
            inverted='false'
            style={{ width: '100%' }}
            initialValue={user.about}
            isReadOnly={true}
          />
        </div>
      </div>
    </Grid>
  )
}
