export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => ascendingComparator(a, b, orderBy)
}

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

export const ascendingComparator = (a, b, orderBy) => {
  if (b[orderBy] > a[orderBy]) {
    return -1
  }
  if (b[orderBy] < a[orderBy]) {
    return 1
  }
  return 0
}

export const filterData = (query, data, columns) => {
  if (!query) {
    return data
  } else {
    const filteredData = columns.map(column => {
      return data.filter(d => {
        if (d[column].toLowerCase().includes(query)) {
          return d
        }
      })
    })
    const result = [...new Set(...filteredData)]
    return result
  }
}
