import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  CircularProgress,
  Button,
  TextField,
  Divider,
  Grid,
  Paper,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import useComponentSize from '@rehooks/component-size'
import { useContentHeight } from 'data/hooks'
import { useWidth, useLandscape } from 'data/layout/actions'
import { useStyles } from 'common/styles'
import { fetchSendResetPasswordLink } from 'src/redux/identity'
import ValidationErrorMessages from 'components/ValidationErrorMessages'

export default () => {
  const width = useWidth()
  const landscape = useLandscape()
  const componentRef = React.useRef(null)
  const componentSize = useComponentSize(componentRef)
  const marginTop = (useContentHeight() - (componentSize.height + 8)) / 2
  const classes = useStyles({ landscape, marginTop, width })
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const [state, setState] = React.useState({ email: '' })

  const dispatch = useDispatch()
  const { loading, errors = {} } = useSelector(state => state.identity)

  const handleChange = e => {
    const { id, value } = e.target
    setState(prevState => ({
      ...prevState,
      [id]: value,
    }))
  }

  const handleSubmit = () => {
    if (!state.email) {
      return
    }

    setState({ email: '' })
    dispatch(fetchSendResetPasswordLink(state))
  }

  const handleEnterKey = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleSubmit()
    }
  }

  const handleSubmitClick = e => {
    e.preventDefault()
    handleSubmit()
  }

  return (
    <div style={{ height: '100vh', backgroundColor: `${theme.palette.primary.dark}` }}>
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        sx={{ height: '100vh', backgroundColor: `${theme.palette.primary.dark}` }}
      >
        <Paper
          ref={componentRef}
          className={classes.identityContainer}
          onKeyUp={handleEnterKey}
          sx={{ padding: isSmallScreen ? '16px' : '32px', width: isSmallScreen ? '90%' : '400px' }}
        >
          <Grid container alignItems='center' spacing={1}>
            <Grid item xs={12}>
              <Typography align='left' variant='h5'>
                Password Reset Request
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ height: '1px' }} />
            </Grid>
          </Grid>
          <Grid
            container
            alignItems='center'
            spacing={2}
            style={{ paddingLeft: 8, paddingRight: 8 }}
          >
            {loading && <CircularProgress size={24} className={classes.submitProgress} />}
            <Grid item xs={12}>
              <TextField
                variant='standard'
                id='email'
                label='Email'
                placeholder='Enter your email address'
                value={state.email}
                margin='normal'
                fullWidth
                className={classes.input}
                InputProps={{
                  className: classes.input,
                }}
                onChange={handleChange}
                disabled={loading}
                error={!!errors['Email']}
                helperText={<ValidationErrorMessages errors={errors['Email']} />}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent='center' spacing={2}>
                <Grid item>
                  <Button
                    variant='contained'
                    size='large'
                    onClick={handleSubmitClick}
                    disabled={loading}
                    fullWidth
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </div>
  )
}
