import React from 'react'
import { Grid, Button, Typography, Divider } from '@mui/material'
import { TextField } from '@mui/material'
import PhoneNumber from 'components/DialogField/PhoneNumber'
import { useStyles } from 'common/styles'
import { useFormControls } from './formActions'

const InterestForm = () => {
  const classes = useStyles()
  const { handleInputValue, values, errors, formIsValid, handleFormSubmit } = useFormControls()

  return (
    <Grid container className={classes.formBox} direction='column' justifyContent='center'>
      <Grid
        container
        alignItems='center'
        direction='row'
        style={{ paddingTop: '30px', paddingLeft: '50px' }}
      >
        <Grid item style={{ marginLeft: '20px' }}>
          <Typography variant='body2' style={{ fontWeight: 'bold', letterSpacing: '0' }}>
            Let's Connect
          </Typography>
          <Divider light={true} className={classes.registrationDivider} />
        </Grid>
      </Grid>
      <Grid item className={classes.registrationFieldsContainer}>
        <form onSubmit={handleFormSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                key='first'
                onBlur={handleInputValue}
                onChange={handleInputValue}
                name='firstName'
                label='First Name'
                fullWidth
                variant='outlined'
                autoComplete='none'
                {...(errors['firstName'] && {
                  error: true,
                  helperText: errors['firstName'],
                })}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                key='last'
                onBlur={handleInputValue}
                onChange={handleInputValue}
                name='lastName'
                label='Last Name'
                fullWidth
                variant='outlined'
                autoComplete='none'
                {...(errors['lastName'] && {
                  error: true,
                  helperText: errors['lastName'],
                })}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                key='email'
                onBlur={handleInputValue}
                onChange={handleInputValue}
                name='email'
                label='Email'
                fullWidth
                variant='outlined'
                autoComplete='none'
                {...(errors['email'] && {
                  error: true,
                  helperText: errors['email'],
                })}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <PhoneNumber
                key='phone'
                id='phone'
                name='phone'
                fullWidth
                value={values.phone}
                onChange={handleInputValue}
                onBlur={handleInputValue}
                label='Phone (Optional)'
                forceErrorMargin={false}
                required={false}
                variant='outlined'
                margin='dense'
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                key='hospital'
                onBlur={handleInputValue}
                onChange={handleInputValue}
                name='hospital'
                label='Hospital'
                variant='outlined'
                fullWidth
                autoComplete='none'
                {...(errors['hospital'] && {
                  error: true,
                  helperText: errors['hospital'],
                })}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                key='message'
                onBlur={handleInputValue}
                onChange={handleInputValue}
                name='message'
                label='Message'
                variant='outlined'
                multiline={true}
                rows={5}
                autoComplete='none'
                fullWidth
                {...(errors['message'] && {
                  error: true,
                  helperText: errors['message'],
                })}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Button
              type='submit'
              disabled={!formIsValid()}
              autoFocus
              variant='contained'
              className={classes.largeButton}
              style={{ width: '100%', marginTop: '20px' }}
            >
              Schedule a Demo
            </Button>
          </Grid>
        </form>
      </Grid>
    </Grid>
  )
}

export default InterestForm
