import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchTrackEvent, ProductAnalyticsEventTypes } from 'src/redux/productAnalytics'
import { mapUrlForAnalytics, mapEmailEvent } from './helpers'

const ViewPort = ({ children }) => {
  let ref = React.useRef(null)
  const { employerId, email } = useSelector(state => state.identity)
  const dispatch = useDispatch()
  const route = window.location.pathname
  const searchParams = new URLSearchParams(window.location.search)

  useEffect(() => {
    const urlData = mapUrlForAnalytics(route)
    const event = {
      eventName: urlData.url,
      eventType: ProductAnalyticsEventTypes.PAGE_VIEW,
      eventData: { ...urlData.properties },
    }

    const emailEvent = mapEmailEvent({ searchParams, email })
    if (email) {
      event.userId = email
    }
    if (employerId) {
      event.employerId = employerId
    }

    if (emailEvent) {
      dispatch(fetchTrackEvent(emailEvent))
    }
    dispatch(fetchTrackEvent(event))
  }, [route, email, employerId, dispatch])

  return (
    <>
      <div ref={ref} />
      <div id='backdrop'>
        <div id='viewport'>{children}</div>
      </div>
    </>
  )
}

export default ViewPort
