import * as React from 'react'
import Text from 'components/DialogField/Text'
import Autocomplete from '@mui/material/Autocomplete'
import { useDialogState } from 'data/dialog/actions'

export default function AutoComplete({
  items: options,
  value,
  onChange = undefined,
  defaultValue,
  freeSolo = false,
  multiple = false, // Enable multiple selection
  getOptionLabel = option => (option ? option.label : '') || '',
  disableClearable = undefined,
  dialogId,
  dialogVar,
  hidden,
  ...other
}) {
  const [, setState] = useDialogState(dialogId)

  const handleChange = React.useCallback(
    (e, option) => {
      const newValue = multiple
        ? option.map(opt => opt.value) // Extract values for multiple options
        : option
          ? option.value
          : option

      onChange && onChange({ target: { value: newValue } })

      if (dialogId) {
        const values = {}
        values[dialogVar] = multiple
          ? option.map(opt => opt.label) // Store labels for multiple selections
          : option.label
        setState(prev => ({ ...prev, ...values }))
      }
    },
    [onChange, multiple],
  )

  // Ensure value is handled as an array for multiple selections
  const val = multiple
    ? options.filter(
        option => Array.isArray(value) && value.some(val => String(val) === String(option.value)),
      )
    : options.find(
        option =>
          option.value === value ||
          option.value === `${value}` ||
          option.label === value ||
          String(option.value) === String(value),
      )

  if (hidden) {
    return <></>
  } else {
    return (
      <Autocomplete
        freeSolo={freeSolo}
        multiple={multiple} // Enable multiple selection in Autocomplete
        onChange={handleChange}
        value={val || (multiple ? [] : null)} // Ensure value is properly handled
        defaultValue={defaultValue}
        options={options}
        getOptionLabel={getOptionLabel}
        disableClearable={disableClearable}
        renderInput={params => (
          <Text
            {...params}
            variant='outlined'
            autoComplete='off'
            inputProps={{
              ...params.inputProps,
              autoComplete: 'off',
            }}
            {...other}
          />
        )}
      />
    )
  }
}
