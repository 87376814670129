import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
} from '@mui/material'
import { HexColorPicker } from 'react-colorful'
import { fetchCreateUserTag } from 'src/redux/application'

export const TagDialog = ({ openDialog, handleDialogClose }) => {
  const dispatch = useDispatch()
  const { userId } = useSelector(state => state.identity)

  const [newTagLabel, setNewTagLabel] = React.useState('')
  const [newTagColor, setNewTagColor] = React.useState('#000000')

  const handleColorChange = color => {
    setNewTagColor(color)
  }

  const handleTextInputChange = event => {
    const color = event.target.value
    setNewTagColor(color)
  }

  const handleSubmit = async () => {
    const newTagId = await dispatch(
      fetchCreateUserTag({ userId: userId, name: newTagLabel, color: newTagColor }),
    )
    handleDialogClose(newTagId) // Close the dialog after submitting
    setNewTagColor('#000000')
    setNewTagLabel('')
  }

  return (
    <Dialog open={openDialog} onClose={handleDialogClose}>
      <DialogTitle>Add a New Tag</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter the name of the new tag and select a color.
        </DialogContentText>
        <TextField
          autoFocus
          margin='dense'
          label='Tag Name'
          type='text'
          fullWidth
          value={newTagLabel}
          onChange={e => setNewTagLabel(e.target.value)}
        />
        <Box mt={2}>
          <HexColorPicker
            color={newTagColor}
            onChange={handleColorChange} // Real-time color updates from picker
          />
          <TextField
            margin='dense'
            label='Hex Color'
            type='text'
            fullWidth
            value={newTagColor}
            onChange={handleTextInputChange} // Real-time color updates from text input
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose} color='primary'>
          Cancel
        </Button>
        <Button onClick={handleSubmit} color='primary' disabled={!newTagLabel.trim()}>
          Add Tag
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TagDialog
