import React from 'react'
import Container from '@mui/material/Container'
import CircularProgress from '@mui/material/CircularProgress'
import { useStyles } from 'common/styles'

export default function Loader() {
  const classes = useStyles()

  return (
    <Container className={classes.loader}>
      <CircularProgress color='secondary' />
    </Container>
  )
}
