import makeStyles from '@mui/styles/makeStyles'
import backgroundImage from 'static/images/register-physician-background.jpg'
// TODO: move padding to container logic
export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    backgroundColor: 'white',
  },
  menuItemSelected: {
    color: `${theme.palette.primary.contrastText} !important`,
    background: `${theme.palette.primary.dark} !important`,
  },
  registrationHeader: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    textAlign: 'center',
    margin: '30 0',
  },
  registrationContainer: {
    backgroundColor: '#5FB49C',
    width: '100%',
    height: '800',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      height: '400',
      justifyContent: 'space-around',
    },
  },
  hero: {
    position: 'relative',
    flexDirection: 'row',
    height: '768px',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
    padding: '100',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 0px',
      flexDirection: 'column',
      height: '300px',
    },
    backgroundColor: '#1B1B1B',
    textAlign: 'left',
  },
  bannerOverlap: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    height: '80',
    width: '60%',
    flexDirection: 'column',
    borderRadius: '10px',
    position: 'absolute',
    bottom: '-40',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      borderRadius: '0px',
      height: '110',
    },
  },
  bannerHeader: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    alignSelf: 'center',
    backgroundColor: 'white',
    height: '80',
    width: '60%',
    flexDirection: 'column',
    border: '10px solid',
    borderRadius: '10px',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderColor: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      borderRadius: '0px',
      height: '110',
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '100%',
    justifyContent: props => (props.justifyContent ? props.justifyContent : 'space-around'),
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      marginTop: '10',
    },
    marginTop: '31',
    width: '100%',
  },
  rowMobile: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '680',
    justifyContent: props => (props.justifyContent ? props.justifyContent : 'space-around'),
    marginTop: '20',
    marginBottom: '20',
    width: '100%',
    alignSelf: 'center',
  },
  carouselRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: props => (props.justifyContent ? props.justifyContent : 'space-around'),
    marginTop: '31',
    width: '90%',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '300',
    alignItems: 'center',
    marginTop: '50',
    paddingRight: '50',
    maxWidth: '400',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80%',
      paddingRight: '0',
      justifyContent: 'space-around',
      marginTop: '0',
      height: '100%',
    },
  },
  panelContainer: {
    backgroundImage: props => (props.backgroundImage ? props.backgroundImage : 'none'),
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: '60% 80%',
    display: 'flex',
    flexDirection: props => (props.flexDirection ? props.flexDirection : 'row'),
    padding: props => (props.xtraPadding ? '200 100' : '100 100'),
    justifyContent: 'space-around',
    backgroundColor: props => (props.inverted ? '#1B1B1B' : 'white'),
    [theme.breakpoints.down('sm')]: {
      padding: props => (props.xtraPadding ? '20 20' : '20 10'),
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '30',
    width: '100%',
    zIndex: 0,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      marginBottom: '0',
    },
  },

  middleText: {
    textAlign: props => props.textAlign || 'center',
    display: 'flex',
    width: '270',
    marginTop: '10px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  panelBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: props => (props.alignItems ? props.alignItems : 'center'),
    alignContent: 'flex-start',
    maxWidth: '350',
    textAlign: props => (props.textAlign ? props.textAlign : 'left'),
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80%',
      padding: '20px 0px',
    },
  },
  containText: {
    display: 'inline-block',
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'center',
    },
  },
  largeButton: {
    padding: '20',
  },
  contactButton: {
    textDecoration: 'none',
    color: 'white',
    backgroundColor: '#2C6DB5',
    height: '100%',
  },
  contactContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    height: '80%',
  },
  heroContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    alignContent: 'center',
    width: 'auto',
    color: props => (props.inverted ? 'white' : '#7284A8'),
    zIndex: '100',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  heroContent: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'space-between',
    height: '200',
  },
  panel: {
    backgroundColor: props => (props.inverted ? '#1B1B1B' : 'white'),
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    padding: '50',
    [theme.breakpoints.down('lg')]: {
      padding: '40px 100px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '10px',
    },
  },
  panelContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignSelf: 'center',
    textAlign: 'center',
    color: 'black',
    maxWidth: '800px',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: '10px',
    padding: '50px',
    [theme.breakpoints.down('sm')]: {
      padding: '20px',
    },
  },
  divider: {
    backgroundColor: '#0E7C7B',
    width: '100%',
    height: '4px',
    borderRadius: '8px',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  },
  input: {
    color: props => (props.inverted ? 'white !important' : '#000042 !important'),
    border: '1px solid white',
    width: '100%',
  },
  button: {
    width: '100%',
    maxWidth: '200px',
    margin: '10px 0px',
    [theme.breakpoints.down('xl')]: {
      maxWidth: '150px',
    },
  },
  registerButtons: {
    textTransform: 'none',
  },
  identityContainer: ({ width }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    padding: theme.spacing(1),
    margin: '0 auto',
    maxWidth: width < 500 ? width - 48 : 500 - 48,
  }),
  supportContainer: () => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    padding: theme.spacing(3),
    margin: '40px auto',
    width: '40%',
    [theme.breakpoints.down('sm')]: {
      width: '75%',
    },
  }),
  headerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    padding: '2rem',
  },
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    outline: 'none',
    border: `5px solid ${theme.palette.primary}`,
  },
  select: {
    color: '#000042 !important',
    border: '1px solid white',
    paddingLeft: '10px',
    width: '100%',
  },
  loader: {
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    zIndex: '100',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  contentBlock: {
    padding: '50px',
  },
  contentBlockFull: {
    display: 'flex',
    flexDirection: 'column',
    padding: '50px',
    justifyContent: 'center',
    textAlign: 'center',
  },
  cardImage: {
    height: 350,
    width: 350,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '75%',
    padding: '10px',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    width: '100%',
    padding: '50px',
    backgroundColor: 'white',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
  },
  carouselBox: {
    display: 'flex',
    maxHeight: '550',
    margin: '50 80 50 50',
    backgroundColor: 'white',
    borderRadius: '20px',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 0px',
      maxHeight: 'auto',
      borderRadius: '5px',
    },
  },
  carouselContainer: {
    display: 'flex',
    flex: '1',
    width: '100%',
    flexDirection: 'column',
    textAlign: 'left',
    padding: '50 0 50 50',
  },
  carouselPicContainer: {
    display: 'flex',
    flex: '1',
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
  },
  bold: {
    fontWeight: 'bold',
  },
  tableContent: {
    padding: theme.layout.tableContentPadding,
  },
  formControl: {
    marginTop: 0,
    width: '100%',
  },
  hidden: {
    display: 'none',
  },
  pointer: {
    cursor: 'pointer',
  },
  editable: ({ isActive }) => ({
    maxWidth: 600,
    background: isActive ? theme.palette.grey[200] : 'inherit',
    paddingLeft: 16,
    paddingRight: 16,
    marginBottom: 8,
    '&:hover': {
      background: theme.palette.grey[200],
      cursor: 'pointer',
    },
  }),
  mobileContent: {
    minWidth: 288,
  },
  submitProgress: {
    color: theme.palette.primary,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -(theme.layout.progressSize / 2),
    marginLeft: -(theme.layout.progressSize / 2),
  },
  alert: {
    fontSize: '1rem',
  },
  link: {
    textAlign: 'center',
    textDecoration: 'underline',
    padding: '5px',
    cursor: 'pointer',
  },
  media: {
    width: '100%',
    height: '100%',
  },
  homeBackgroundSmall: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.dark,
    padding: '100px 40px',
  },
  registerBackground: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundPosition: 'right',
    backgroundRepeat: 'no-repeat',
  },
  registerContainer: {
    overflow: 'hidden',
    height: '30%',
    alignSelf: 'center',
    justifyContent: 'flex-end',
    backgroundColor: '#FFF',
    borderRight: 'none',
    border: 'none',
    borderRadius: '10px',
    paddingLeft: '20px',
  },

  registerContent: {
    backgroundColor: '#FFF',
  },
  registerSuccess: {
    overflow: 'hidden',
    justifyContent: 'center',
    height: '100%',
    alignSelf: 'center',
    backgroundColor: '#FFF',
    [theme.breakpoints.down('lg')]: {
      border: 'none',
      height: '100%',
      textAlign: 'left',
    },
    padding: '50px',
    textAlign: 'center',
  },
  formBox: {
    padding: '10px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formRegister: {
    padding: '20px 0',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
  },
  titleContainer: {
    width: '120%',
    borderRadius: '20px 0px 0px 20px',
    background: 'white',
    border: '3px solid white',
    [theme.breakpoints.up('md')]: {
      borderRadius: '20px 0px 0px 20px',
      border: '3px solid white',
      borderLeft: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      borderRadius: '20px',
      border: '3px solid white',
      borderLeft: '3px solid white',
    },
  },
  registrationPaper: {
    paddingTop: '10px',
    paddingBottom: '30px',
    paddingLeft: '40px',
    paddingRight: '40px',
  },
  registrationBtnContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  registrationFormLogoContainer: {
    width: '70',
    height: '70',
    '& .imgLogo': {
      width: 170,
      height: 170,
      display: 'block',
      margin: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  registrationDivider: {
    backgroundColor: '#0E7C7B',
    width: '40%',
    height: '4px',
    alignSelf: 'center',
    marginBottom: '10px',
    marginTop: '5px',
    borderRadius: '8px',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  registrationPhysicianContainer: {
    height: '100%',
  },
  registrationFieldsContainer: {
    marginTop: '30px',
    paddingLeft: '125px',
    paddingRight: '125px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '50px',
      paddingRight: '50px',
    },
  },
  registrationLoader: {
    width: 'auto',
    margin: '0px',
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    zIndex: '100',
    paddingLeft: '10px',
    paddingRight: '10px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobileSubmitButtonHeight: {
    [theme.breakpoints.down('sm')]: {
      height: '100px',
      width: '100px',
    },
  },
  betaBadge: {
    '& .MuiBadge-badge': {
      right: 6,
      top: 1,
      border: `1px solid ${theme.palette.info.main}`,
      padding: '0 4px',
      fontSize: '.5rem',
      backgroundColor: `${theme.palette.info.main}`,
      color: 'white',
    },
  },
  betaText: {
    border: `1px solid ${theme.palette.info.main}`,
    padding: '0 4px',
    fontSize: '.5rem',
    backgroundColor: `${theme.palette.info.main}`,
    color: 'white',
  },
  dialogContentContainer: {
    padding: '30px 30px 0px 30px',
  },
  tablePaper: {
    width: '100%',
    overflowX: 'auto',
  },
  tableContainer: {
    width: '100%',
    overflowX: 'auto', // Enable horizontal scrolling on smaller screens
  },
  '@media (max-width: 600px)': {
    tableCell: {
      display: 'block',
      paddingLeft: '50%',
      position: 'relative',
      textAlign: 'right',
      whiteSpace: 'normal',
      '&::before': {
        content: 'attr(data-label)',
        position: 'absolute',
        left: 0,
        top: 0,
        width: '50%',
        paddingLeft: '10px',
        fontWeight: 'bold',
        textAlign: 'left',
      },
    },
  },
}))
