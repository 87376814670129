import * as React from 'react'
import NumberFormat from 'react-number-format'
import Text from 'components/DialogField/Text'
import Decimal from 'decimal.js-light'
import { checkEmpty, isDefined } from 'data/helpers'

const checkMinMax = (min, max, value) =>
  !checkEmpty(value)
    ? isDefined(min) && new Decimal(value).lessThan(min)
      ? min
      : isDefined(max) && new Decimal(value).greaterThan(max)
      ? max
      : value
    : value

function WholeNumberFormat({
  inputRef,
  onChange,
  min,
  max,
  decimalScale = 0,
  thousandSeparator = undefined,
  ...other
}) {
  return (
    <NumberFormat
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: checkMinMax(min, max, values.value),
          },
        })
      }}
      decimalScale={decimalScale}
      allowNegative={false}
      thousandSeparator={thousandSeparator}
      isNumericString
      {...other}
    />
  )
}

export default function WholeNumber({
  InputProps = undefined,
  inputProps = undefined,
  min = undefined,
  max = undefined,
  thousandSeparator = undefined,
  decimalScale = 0,
  onChange = undefined,
  ...other
}) {
  const handleChange = React.useCallback(
    event => {
      onChange && onChange({ target: { value: Number(event.target.value) } }) // Ensure a number is sent instead of a string
    },
    [onChange],
  )
  return (
    <Text
      InputProps={{
        inputComponent: WholeNumberFormat,
        ...InputProps,
      }}
      inputProps={{
        min,
        max,
        thousandSeparator,
        decimalScale,
        ...inputProps,
      }}
      onChange={handleChange}
      {...other}
    />
  )
}
