import { UPDATE_CANDIDATE } from 'data/constants'
import { isFunction } from 'data/helpers'
import { GET_CANDIDATE_LOADING, GET_CANDIDATE_SUCCESS, GET_CANDIDATE_ERROR} from 'data/candidate/actions'

export default function legacyCandidateReducer(state = {}, action) {
  const { payload } = action
  switch (action.type) {
    case UPDATE_CANDIDATE:
      return isFunction(action.payload) ? action.payload(state) : action.payload
    case GET_CANDIDATE_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_CANDIDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        id: payload.id,
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        phone: payload.phone,
        city: payload.city,
        state: payload.state,
        profileImage: payload.profileImage,
        npiNumber: payload.npiNumber,
        about: payload.about,
        preferredCity: payload.preferredCity,
        preferredStates: payload.preferredStates,
        residency: payload.residency,
        residencyStart: payload.residencyStart,
        residencyEnd: payload.residencyEnd,
        resume: payload.resume,
        showEmail: payload.showEmail,
        showPhone: payload.showPhone,
        specialtyId: payload.specialtyId,
        subspecialtyId: payload.subspecialtyId,
        aboutRaw: payload.aboutRaw,
        setupSeen: payload.setupSeen,
      }
    case GET_CANDIDATE_ERROR:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}
