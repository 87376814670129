import React from 'react'
import PropTypes from 'prop-types'
import AddIcon from '@mui/icons-material/AddCircle'
import EditIcon from '@mui/icons-material/Edit'
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff'
import NotificationAddIcon from '@mui/icons-material/NotificationAdd'
import { Button, IconButton, Tooltip, Grid, Typography, Fab, Link } from '@mui/material'
import { useDialogState } from 'data/dialog/actions'
import { checkEmpty } from 'data/helpers'

export const renderDialogModule = ({ default: Dialog, dialogId, ...props }) =>
  Dialog && <Dialog id={dialogId} {...props} />

renderDialogModule.propTypes = {
  default: PropTypes.func,
  dialogId: PropTypes.string,
  props: PropTypes.object,
}

export const EditDialogButton = props => (
  <DialogButton
    variant='iconbutton'
    size='small'
    Icon={EditIcon}
    type='edit'
    tooltip='Edit'
    placement='left'
    {...props}
  />
)

export const DeleteDialogButton = props => (
  <DialogButton
    size='small'
    variant='outlined'
    color={props.isDeleted ? 'success' : 'error'}
    placement='left'
    type='edit'
    {...props}
  />
)

export const NotificationEnableDialogButton = props => (
  <DialogButton
    size='small'
    variant='iconbutton'
    Icon={props.isDeleted ? NotificationAddIcon : NotificationsOffIcon}
    placement='left'
    type='edit'
    {...props}
  />
)
// Pass a dialog via Module or Dialog + id or just id if the dialog is mounted elsewhere
export default function DialogButton({
  id: Id = undefined,
  Module = undefined,
  mount = true,
  tooltip = null,
  placement = 'bottom',
  variant = 'contained',
  size = 'large',
  color = 'primary',
  Icon = AddIcon,
  initialValues = {},
  disabled = false,
  children,
  onClick,
  ...other
}) {
  const id = Id ? Id : Module && Module.dialogId
  const [, setDialogState] = useDialogState(id)
  const handleUpdate = React.useCallback(
    e => {
      e.stopPropagation()
      setDialogState(prev => ({
        ...prev,
        type: 'add',
        open: true,
        initialValues,
        ...other,
      }))
      onClick && onClick(e)
    },
    [setDialogState, onClick, JSON.stringify(other), JSON.stringify(initialValues)],
  )
  const renderButton = variant => {
    switch (variant) {
      case 'iconbutton':
        return (
          <span>
            <IconButton onClick={handleUpdate} size='small'>
              <Icon />
            </IconButton>
          </span>
        )
      case 'text':
        return (
          <span>
            <Button
              fullWidth={true}
              variant='text'
              size={size}
              color={color}
              onClick={handleUpdate}
              disabled={disabled}
            >
              {children}
            </Button>
          </span>
        )
      case 'contained':
        return (
          <span>
            <Button
              fullWidth={true}
              variant='contained'
              size={size}
              color={color}
              onClick={handleUpdate}
              disabled={disabled}
            >
              {children}
            </Button>
          </span>
        )
      case 'link':
        return (
          <span>
            <Link
              sx={
                disabled
                  ? { pointerEvents: 'none', color: 'gray' }
                  : { cursor: 'pointer', color: color ? color : '#fff' }
              }
              onClick={handleUpdate}
            >
              {children}
            </Link>
          </span>
        )
      case 'outlined':
        return (
          <span>
            <Button
              fullWidth={true}
              variant='outlined'
              size={size}
              color={color}
              onClick={handleUpdate}
              disabled={disabled}
            >
              {children}
            </Button>
          </span>
        )
      default:
        return (
          <span>
            <Fab size='small' color={color} onClick={handleUpdate}>
              <Icon />
            </Fab>
          </span>
        )
    }
  }

  return (
    <Grid key={tooltip} item>
      {mount && Module && renderDialogModule(Module)}
      <Tooltip
        placement={placement}
        title={
          checkEmpty(tooltip) ? (
            tooltip
          ) : (
            <Typography variant='body1' color='inherit'>
              {tooltip}
            </Typography>
          )
        }
      >
        {renderButton(variant)}
      </Tooltip>
    </Grid>
  )
}

DialogButton.propTypes = {
  id: PropTypes.string,
  Module: PropTypes.shape(renderDialogModule.propTypes),
  tooltip: PropTypes.string,
  placement: PropTypes.string,
  variant: PropTypes.oneOf([
    'iconbutton',
    'fab',
    'contained',
    'link',
    'text',
    'outlined',
    undefined,
  ]),
  initialValues: PropTypes.object,
}
