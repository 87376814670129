import React, { useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import Button from '@mui/material/Button'
import EmployerUserDrawer from 'components/Drawers/EmployerUserDrawer'

const AddEmployerUserComponent = () => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Button variant='outlined' onClick={handleOpen} startIcon={<AddIcon />}>
        Add Employer User
      </Button>
      <EmployerUserDrawer open={open} onClose={handleClose} />
    </>
  )
}

export default AddEmployerUserComponent
