import React from 'react'
import { Box, Button } from '@mui/material'

const ResumeUpload = ({ id, handleChange, label, name, loading }) => (
  <Box>
    <input
      type='file'
      id={id} // Unique ID here
      name={name}
      onChange={handleChange}
      style={{ display: 'none' }}
    />
    <label htmlFor={id}>
      <Button component='span' size='medium' variant='contained' color='primary' disabled={loading}>
        {label}
      </Button>
    </label>
  </Box>
)

export default ResumeUpload
