import React from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  TextField,
  Typography,
  MenuItem,
  InputAdornment,
  IconButton,
  useTheme,
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import { generateAlertName } from './helpers'
import { filterDefinitions } from './filterDefinitions'

export const AlertsButton = ({ availableAlerts }) => {
  const theme = useTheme()
  const [searchParams, setSearchParams] = useSearchParams()

  const handleChange = event => {
    setSearchParams({
      userFilterAlertId: event.target.value.id,
    })
  }

  const handleClear = () => {
    setSearchParams({})
  }

  const filterDefinition = filterDefinitions.find(filter => filter.name === 'userFilterAlertId')

  const options = availableAlerts.map(item => ({
    id: item.id,
    name: generateAlertName(item.userFilterName, item.createdAt),
    queryString: filterDefinition.queryStringKey,
  }))

  const appliedAlert =
    options.find(item => item.id.toString() === searchParams.get('userFilterAlertId')) || {}

  const isDisabled = availableAlerts.length === 0
  const hasSelection = Boolean(appliedAlert.name)

  return (
    <>
      {availableAlerts.length > 0 ? (
        <TextField
          onChange={handleChange}
          id='select-alerts'
          size='small'
          select
          fullWidth
          disabled={isDisabled}
          label={hasSelection ? 'Viewing the following generated alert' : 'Select Previous Alert'}
          variant='outlined'
          placeholder='Alerts'
          value={hasSelection ? appliedAlert : ''}
          InputLabelProps={{
            shrink: hasSelection ? true : undefined, // Shrink label when an alert is selected
            style: {
              color: isDisabled
                ? theme.palette.text.disabled
                : hasSelection
                  ? theme.palette.primary.dark
                  : 'white',
              fontSize: '0.7rem',
            },
          }}
          sx={{
            '& .MuiSvgIcon-root': {
              color: isDisabled
                ? theme.palette.text.disabled
                : hasSelection
                  ? theme.palette.primary.dark
                  : '#FFF',
            },
            '& .MuiFilledInput-root, & .MuiOutlinedInput-root': {
              backgroundColor: hasSelection
                ? 'white' // Set background to white when a filter is selected
                : isDisabled
                  ? theme.palette.action.disabledBackground
                  : 'transparent',
              borderColor: isDisabled ? theme.palette.text.disabled : 'white',
              '&:hover': {
                backgroundColor: hasSelection
                  ? 'white'
                  : isDisabled
                    ? theme.palette.action.disabledBackground
                    : 'transparent',
              },
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: isDisabled ? theme.palette.text.disabled : 'white',
            },
            '& .MuiInputBase-input': {
              color: isDisabled
                ? theme.palette.text.disabled
                : hasSelection
                  ? theme.palette.primary.dark // Set text color when a filter is selected
                  : 'white',
              fontSize: '0.8rem',
              textAlign: 'left',
              paddingLeft: '10px',
            },
            '& .MuiIconButton-root': {
              padding: '4px',
            },
            '& .MuiInputAdornment-root': {
              display: 'flex',
              alignItems: 'center',
            },
            // Make the label (legend) visible when shrunk
            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline legend': {
              backgroundColor: 'transparent',
              overflow: 'visible',
            },
            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline legend span': {
              opacity: hasSelection ? 1 : 0,
              color: theme.palette.warning.main,
              fontSize: '.5rem',
              backgroundColor: 'transparent',
            },
            '& .MuiInputLabel-root': {
              visibility: !hasSelection ? 'visible' : 'hidden', // Hide the label when no selection
              color: isDisabled
                ? theme.palette.text.disabled
                : hasSelection
                  ? theme.palette.primary.dark
                  : 'white',
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: isDisabled
                ? theme.palette.text.disabled
                : hasSelection
                  ? theme.palette.primary.dark
                  : 'white',
            },
          }}
          InputProps={{
            endAdornment: hasSelection ? (
              <InputAdornment position='end'>
                <IconButton onClick={handleClear} size='small'>
                  <ClearIcon
                    fontSize='small'
                    sx={{
                      color: isDisabled
                        ? theme.palette.text.disabled
                        : hasSelection
                          ? theme.palette.primary.dark
                          : 'white',
                    }}
                  />
                </IconButton>
              </InputAdornment>
            ) : (
              <InputAdornment position='end'>
                <NotificationsNoneIcon
                  sx={{
                    color: isDisabled ? theme.palette.text.disabled : 'white',
                    fontSize: '1.2rem',
                  }}
                />
              </InputAdornment>
            ),
          }}
          SelectProps={{
            IconComponent: () => null,
            renderValue: selected => {
              if (!selected) {
                return null // Allow the label to show when no selection
              }
              return (
                <Typography
                  sx={{
                    color: isDisabled
                      ? theme.palette.text.disabled
                      : hasSelection
                        ? theme.palette.primary.dark // Set text color when an alert is selected
                        : 'white',
                    fontSize: '.8rem',
                    textTransform: 'none',
                    textAlign: 'left',
                    width: '100%',
                    paddingLeft: '10px',
                  }}
                >
                  {appliedAlert.name || 'Alerts'}
                </Typography>
              )
            },
          }}
        >
          {options.map((item, index) => (
            <MenuItem id={`saved-alert-${index}`} key={index} value={item}>
              <Typography sx={{ color: theme.palette.primary.dark, fontSize: '.8rem' }}>
                {item.name}
              </Typography>
            </MenuItem>
          ))}
        </TextField>
      ) : null}
    </>
  )
}

export default AlertsButton
