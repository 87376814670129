import * as React from 'react'
import { TextField, IconButton } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

export const SearchBar = ({ setSearchQuery }) => (
  <form>
    <TextField
      id='search-bar'
      className='text'
      onInput={e => {
        setSearchQuery(e.target.value)
      }}
      variant='outlined'
      placeholder='Search...'
      size='small'
      InputProps={{
        startAdornment: (
          <IconButton type='submit' aria-label='search'>
            <SearchIcon />
          </IconButton>
        ),
      }}
    />
  </form>
)
