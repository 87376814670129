import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Typography, IconButton } from '@mui/material'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import MenuIcon from '@mui/icons-material/Menu'
import { useStyles } from 'common/styles'
import { JoyrideContext } from 'components/JoyRide/JoyrideContext'

export function ButtonMenuList({ menuItems, children }) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const { currentStep } = useContext(JoyrideContext)

  useEffect(() => {
    if (currentStep === '.joyride_profile_header' || currentStep === '.joyride_header_alerts') {
      if (!open) {
        setOpen(true)
      }
    } else {
      setOpen(false)
    }
  }, [currentStep])

  const handleToggle = () => setOpen(prevOpen => !prevOpen)

  const handleClose = event => {
    if (
      currentStep !== '.joyride_profile_header' &&
      currentStep !== '.joyride_header_alerts' &&
      anchorRef.current &&
      !anchorRef.current.contains(event.target)
    ) {
      setOpen(false)
    }
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  const prevOpen = React.useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  const Component = children ? 'div' : IconButton

  return (
    <>
      <div className='joyride_profile_header'>
        <Component
          ref={anchorRef}
          onClick={handleToggle}
          aria-controls='menu-list-grow'
          aria-haspopup='true'
        >
          {children ? children : <MenuIcon />}
        </Component>
        <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper id='menu-list-grow'>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    onKeyDown={handleListKeyDown}
                    style={{ padding: '0' }}
                  >
                    {menuItems.map((b, i) => (
                      <MenuItem
                        key={i}
                        classes={{ selected: classes.menuItemSelected }}
                        selected={b.active}
                        onClick={e => {
                          handleClose(e)
                          b.onClick && b.onClick(e)
                        }}
                      >
                        {b.label === 'Alerts' ? (
                          <div className='joyride_header_alerts'>
                            <Typography variant='button'>{b.label}</Typography>
                          </div>
                        ) : (
                          <Typography variant='button'>{b.label}</Typography>
                        )}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </>
  )
}

ButtonMenuList.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      active: PropTypes.bool,
    }),
  ),
}

export default ButtonMenuList
