import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Typography, Divider, Box, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import CardMedia from '@mui/material/CardMedia'
import Card from '@mui/material/Card'
import { useHandleChangeRoute } from 'data/hooks'
import { fetchGetJobCount } from 'src/redux/home'
import BackgroundVideo from 'static/videos/mystethi-video-overlay1.mp4'
import BackgroundVideoMobile from 'static/videos/mystethi-video-overlay_mobile.mp4'
import { useStyles } from 'common/styles'
import GenericCTABox from './GenericCTABox'

export default function TopSection() {
  const dispatch = useDispatch()
  const handleChangeRoute = useHandleChangeRoute()
  const classes = useStyles({ inverted: 'true' })
  const theme = useTheme()
  const below_sm = useMediaQuery(theme.breakpoints.down('sm'))
  const video = below_sm ? BackgroundVideoMobile : BackgroundVideo
  const { jobCount } = useSelector(state => state.home)
  const jobCountDisplay = jobCount.toLocaleString()

  React.useEffect(() => {
    dispatch(fetchGetJobCount())
  }, [])

  const onPhysicianClick = () => {
    handleChangeRoute('/register/physicians')()
  }
  const onHospitalClick = () => {
    handleChangeRoute('/register/hospitals')()
  }

  return (
    <>
      <Card
        sx={{
          position: 'relative',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
        }}
      >
        <CardMedia component='video' src={video} className={classes.media} autoPlay muted loop />
        <Card
          sx={{
            borderRadius: '20px',
            position: 'absolute',
            top: { xs: '50%', sm: '50%', md: '40%' }, // Percentage from the top for different breakpoints
            left: '50%',
            transform: 'translate(-50%, -50%)', // Center horizontally
            width: { xs: '95%', sm: '90%', md: '50%' }, // Responsive widths
            textAlign: 'center',
            margin: 'auto',
            padding: { sx: '30px 30px', sm: '30px' },
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            boxShadow: `0 4px 20px #0E7C7B`, // Green shadow, larger size
            transition: 'box-shadow 0.3s ease-in-out',
            '&:hover': {
              boxShadow: '0 8px 40px #0E7C7B', // Larger shadow on hover
            },
          }}
        >
          <Grid container direction='column' spacing={1} sx={{ margin: '20px 0px' }}>
            <Grid item sx={{ paddingTop: '10px' }}>
              <Typography variant='h4'>Connecting Doctors Directly to Hospitals</Typography>
              <Divider
                variant='fullWidth'
                sx={{
                  height: '1px',
                  margin: '5px 0px',
                  backgroundColor: 'white',
                  display: { xs: 'none', sm: 'block' },
                }}
              />
            </Grid>
            <Grid item>
              <Box
                sx={{
                  display: 'flex', // Flexbox for horizontal layout
                  flexDirection: { xs: 'column', sm: 'row' },
                  justifyContent: 'stretch', // Center horizontally
                  alignItems: 'center', // Stretch all children vertically
                  gap: '20px', // Space between boxes
                  padding: '24px',
                }}
              >
                <GenericCTABox
                  title='For Providers'
                  buttonText='Browse Jobs'
                  description='Perfect for Physicians, CRNAs, and Advanced Practice Providers'
                  handleClick={onPhysicianClick}
                />
                <GenericCTABox
                  title='For Hospitals'
                  description='Perfect for Healthcare Systems, Hospitals, Nursing Programs, and Private Practices'
                  buttonText='Post a Job'
                  handleClick={onHospitalClick}
                />
              </Box>
            </Grid>
            <Grid item>
              <Typography variant='h5' sx={{ color: 'white' }}>
                <b style={{ color: `${theme.palette.primary.main}` }}>{jobCountDisplay}</b> jobs
                posted and counting!
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Card>
      <Grid
        sx={{ display: { sm: 'none', xs: 'none' } }}
        container
        direction='column'
        justifyContent='center'
        alignItems='center'
        alignContent='center'
        className={classes.homeBackgroundSmall}
      >
        <Grid item>
          <Typography align='center' variant='h3'>
            Connecting Providers Directly to Hospitals
          </Typography>
          <Divider variant='fullWidth' style={{ margin: '10px 0px' }} />
        </Grid>
        <Grid item>
          <Grid container direction='row' spacing={5} justifyContent='center' alignItems='center'>
            <GenericCTABox />
          </Grid>
        </Grid>
        <Grid item>
          <Typography align='center' variant='h5' sx={{ color: 'white' }}>
            <b style={{ color: 'white' }}>{jobCountDisplay}</b> jobs posted and counting!
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}
