import * as React from 'react'
import MultiChipSelect from 'components/MultiChipSelect/MultiChipSelect'

// This converts the chip items to an array of string values
const removeFormatDefault = event => {
  event.target.value = event.target.value.map(i => i.value)
  return event
}

const MultiChip = ({ variant = 'standard', removeFormat = removeFormatDefault, ...other }) => (
  <MultiChipSelect variant={variant} removeFormat={removeFormat} {...other} />
)

export default MultiChip
