import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { IconButton, Icon, Snackbar as MuiSnackBar } from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import { clearSnackbar } from 'data/snackbar/actions.js'

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}

export default function Snackbar() {
  const dispatch = useDispatch()
  // snackbarType should be one of the severity types for MuiAlert component: https://material-ui.com/components/alert/#alert
  const snackbarType = useSelector(state => state.snackbar.snackbarType)
  const snackbarMessage = useSelector(state => state.snackbar.snackbarMessage)
  const snackbarOpen = useSelector(state => state.snackbar.snackbarOpen)

  function handleClose() {
    clearSnackbar(dispatch)
  }

  return (
    <MuiSnackBar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={snackbarOpen}
      autoHideDuration={4000}
      onClose={() => handleClose()}
      aria-describedby='client-snackbar'
      action={[
        <IconButton
          key='close'
          aria-label='close'
          color='inherit'
          onClick={() => handleClose()}
          size='large'
        >
          <Icon>close</Icon>
        </IconButton>,
      ]}
    >
      <div>
        <Alert onClose={() => handleClose()} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </div>
    </MuiSnackBar>
  )
}
