import React from 'react'
import { Button, Typography, useTheme, Grid } from '@mui/material'
const GenericCTABox = ({ title, description, buttonText, handleClick }) => {
  const theme = useTheme()

  return (
    <Grid
      container
      direction='column'
      justifyContent='space-between'
      alignItems='center'
      sx={{
        flexGrow: 1,
        alignSelf: 'stretch',
        padding: { xs: '10px', sm: '20px' },
        width: '100%',
        maxWidth: '500px',
        backgroundColor: theme.palette.primary.light,
        textAlign: 'center',
        borderRadius: '8px',
        rowSpacing: {
          xs: 1, // Extra-small screens
          sm: 2, // Small screens
        },
      }}
    >
      <Grid item>
        <Typography variant='h6'>{title}</Typography>
      </Grid>
      <Grid item>
        <Typography variant='caption'>{description}</Typography>
      </Grid>
      <Grid item>
        <Button variant='contained' color='primary' onClick={handleClick}>
          {buttonText}
        </Button>
      </Grid>
    </Grid>
  )
}

export default GenericCTABox
