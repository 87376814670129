import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Button, Typography, useTheme, CircularProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import * as EditHospitalDialog from 'components/Dialog/EditHospitalDialog'
import DialogButton from 'components/Dialog/DialogButton'
import { useEmployer } from 'data/employer/actions'
import { useIsUser } from 'pages/Identity/Login'
import { showSnackbar } from 'data/snackbar/actions'
import { snackbarTypes } from 'data/constants'

const variant = 'contained'
const buttonSize = 'medium'

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      padding: '6px 16px', // Adjusted padding to make buttons more uniform
      minWidth: '160px', // Ensure both buttons have the same width
      minHeight: '36px', // Set a minimum height for consistency
      lineHeight: '1.5', // Control line-height to ensure consistency between buttons
      fontSize: '0.875rem', // Ensure the font size is consistent
    },
    hidden: {
      display: 'none',
    },
    submitProgress: {
      color: 'white',
    },
    editHospitalPictureWrapper: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
    },
    maxFileText: {
      marginTop: '5px',
      fontSize: '.8em',
    },
  }),
)

export default function Buttons({
  spacing = 1,
  marginTop = undefined,
  alignItems = 'flexStart',
  justify = 'center',
  loading,
}) {
  const { isEmployerAdmin } = useIsUser()

  return !isEmployerAdmin ? (
    <></>
  ) : (
    <Grid
      container
      spacing={spacing}
      style={{ marginTop }}
      alignItems={alignItems}
      justifyContent={justify}
    >
      <Grid item>
        <UploadPhotoButton loading={loading} />
      </Grid>
      <Grid item>
        <EditProfileButton loading={loading} />
      </Grid>
    </Grid>
  )
}

export const EditProfileButton = () => {
  const classes = useStyles()
  const employerUser = useSelector(state => state.employerUser) || {}

  return (
    <DialogButton
      Module={EditHospitalDialog}
      className={classes.button}
      size={buttonSize} // Set same size for Edit button
      tooltip=''
      variant={variant}
      employerId={employerUser.employerId}
    >
      Edit Hospital Profile
    </DialogButton>
  )
}

export const UploadPhotoButton = ({ loading, buttonId = 'upload-file' }) => {
  const dispatch = useDispatch()
  const classes = useStyles({ inverted: true })
  const [{ id, name }, , setUser] = useEmployer() // Existing upload functionality
  const [error, setError] = React.useState(null)
  const { layout } = useTheme()

  const handleFileChange = React.useCallback(
    e => {
      setError(null)
      const file = e.target.files?.[0] // Select the first file

      if (!file) {
        setError('No file selected. Please try again.')
        return
      }

      if (file.size > 5 * 1024 * 1024) {
        showSnackbar(dispatch, 'File exceeds 5 MB upload limit.', snackbarTypes.ERROR)
        return
      }

      // Use existing `setUser` functionality for uploading
      setUser({
        id,
        name,
        imageFile: file,
      })
    },
    [id, name, setUser, dispatch],
  )

  const buttonText = loading ? 'Uploading...' : 'Upload Hospital Photo'

  return (
    <div className={classes.editHospitalPictureWrapper}>
      {/* Error Display */}
      {error && <Typography color='error'>{error}</Typography>}

      {/* Hidden input for file selection */}
      <input
        id={buttonId}
        type='file'
        accept='image/*'
        className={classes.hidden}
        onChange={handleFileChange} // Handle file selection
      />

      {/* Label and Button */}
      <label htmlFor={buttonId}>
        <Button size='medium' component='span' variant='contained'>
          {loading ? (
            <CircularProgress size={layout.progressSize} className={classes.submitProgress} />
          ) : (
            buttonText
          )}
        </Button>
      </label>

      <Typography style={{ color: 'white' }}>
        <span className={classes.maxFileText}>Max file size: 5 MB</span>
      </Typography>
    </div>
  )
}
