import React from 'react'
import { Box, Container, Grid, Typography } from '@mui/material'

const testimonials = [
  {
    name: 'Rob',
    message:
      'You have been a huge help through our relocation. Thank you very much for picking Paul and for following up throughout. Much appreciated.',
  },
  {
    name: 'Frank and Lynn',
    message:
      'We love our new place in California. Lisa is a terrific realtor and we would recommend her to anyone. Thanks to you for continuing to provide realtors in our search.',
  },
  {
    name: 'Emily',
    message:
      'We feel so welcomed and impressed with the amount of support and care that we have received already from all of you!',
  },
  {
    name: 'Danielle',
    message:
      'I wanted to send my appreciation to you and your firm. Your connections were so supportive.',
  },
]

export const Testimonials = () => {
  return (
    <Box sx={{ width: '100%', py: 10, backgroundColor: '#f7f7f7' }}>
      <Container>
        <Typography variant='h4' sx={{ textAlign: 'center', color: '#008080', mb: 5 }}>
          What LeadingRE Customers Are Saying
        </Typography>
        <Grid container spacing={4} justifyContent='center'>
          {testimonials.map((testimonial, index) => (
            <Grid item xs={12} md={5.5} key={index} sx={{ marginBottom: '40px' }}>
              {' '}
              {/* Ensures two testimonials per row on larger screens */}
              <Box
                sx={{
                  padding: 3,
                  borderRadius: '10px',
                  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                  backgroundColor: '#ffffff',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '100%',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: '0px 8px 30px rgba(0, 0, 0, 0.2)',
                  },
                }}
              >
                <Typography variant='body1' sx={{ mb: 2, fontStyle: 'italic', color: '#555' }}>
                  "{testimonial.message}"
                </Typography>
                <Typography
                  variant='subtitle1'
                  sx={{ textAlign: 'right', fontWeight: 'bold', color: '#008080' }}
                >
                  - {testimonial.name}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  )
}

export default Testimonials
