import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  CircularProgress,
  Button,
  TextField,
  Divider,
  Grid,
  Paper,
  Typography,
  useTheme
} from '@mui/material'
import useComponentSize from '@rehooks/component-size'
import { useContentHeight } from 'data/hooks'
import { useWidth, useLandscape } from 'data/layout/actions'
import { useStyles } from 'common/styles'
import { fetchResendConfirmationLink } from 'src/redux/identity'
import ValidationErrorMessages from 'components/ValidationErrorMessages'

export default () => {
  const width = useWidth()
  const landscape = useLandscape()
  let componentRef = React.useRef(null)
  let componentSize = useComponentSize(componentRef)
  const marginTop = (useContentHeight() - (componentSize.height + 8)) / 2
  const classes = useStyles({ landscape, marginTop, width })
  const { layout } = useTheme()

  const [state, setState] = React.useState({ email: '' })
  const dispatch = useDispatch()
  const loading = useSelector(state => state.identity.loading)
  const errors = useSelector(state => state.identity.errors) || {}

  const handleChange = (e) => {
    const {id, value} = e.target
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }))
  }

  const handleSubmit = () => {
    if (!state.email) {
      return
    }

    setState({ email: '' })
    dispatch(fetchResendConfirmationLink(state))
  }

  const handleEnterKey = (e) => {
    if (e.key == 'Enter') {
      e.preventDefault()
      handleSubmit()
    }
  }

  const handleSubmitClick = (e) => {
    e.preventDefault()
    handleSubmit()
  }

  return (
    <Paper ref={componentRef} className={classes.identityContainer} onKeyUp={handleEnterKey}>
      <Grid container alignItems='center' spacing={1}>
        <Grid item xs={12}>
          <Typography align='left' variant='h5'>
            Resend Confirmation Link
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      <Grid
        container
        alignItems='center'
        spacing={landscape ? 3 : 1}
        style={{ paddingLeft: 8, paddingRight: 8 }}
      >
        {loading && (
          <CircularProgress size={layout.progressSize} className={classes.submitProgress} />
        )}
        <Grid item xs={landscape ? 6 : 12}>
          <TextField
            variant="standard"
            id='email'
            label='Email'
            placeholder='Enter your email address'
            value={state.email}
            margin='normal'
            className={classes.input}
            InputProps={{
              className: classes.input,
            }}
            onChange={handleChange}
            disabled={loading} />
        </Grid>
        <Grid item xs={landscape ? 6 : 12}>
          <Grid container justifyContent='center' alignItems='right' spacing={5}>
            {landscape && (
              <Grid item xs={12}>
                <div style={{ height: 36 }} />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
            >
              <Button
                variant='contained'
                color='secondary'
                className={classes.button}
                onClick={handleSubmitClick}
                disabled={loading}
                error={!!errors['Email']}
                helperText={<ValidationErrorMessages errors={errors['Email']} />}
              >
                Resend
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}
