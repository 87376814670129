import HeaderUserInfo from 'components/Layout/Header/HeaderUserInfo'

export const candidateButtons = () => {
  const headers = [
    { Component: HeaderUserInfo, id: 'userInfo', type: 'rightstatic', width: 160 },
    { label: 'Jobs', link: '/jobs', width: 64 },
    {
      label: 'Track Jobs',
      link: '/applications',
      width: 64,
      className: 'joyride_myjobs',
      isBeta: true,
    },
    {
      label: 'Housing',
      link: '/housing',
      width: 64,
      className: 'joyride_housing',
      isBeta: true,
      badgeContent: 'New!',
    },
  ]
  return headers
}

export const adminButtons = [
  { label: 'Employers', link: '/super/employers', width: 110 },
  { label: 'Employer Users', link: '/super/employer_users', width: 80 },
  { label: 'Jobs', link: '/super/jobs', width: 110 },
  { label: 'Candidates', link: '/super/candidates', width: 80 },
  { label: 'Partner Interest', link: '/super/partner_interest', width: 80 },
  { label: 'Job Interest', link: '/super/job_interest', width: 80 },
]

export const publicButtons = handleRegister => {
  const headers = [
    { label: 'Jobs', link: '/jobs', width: 64 },
    { label: 'Health Systems', link: '/register/hospitals', width: 64 },
    { label: 'Housing', link: '/housing', width: 64, isBeta: true, badgeContent: 'New!' },
    { label: 'Sign In', link: '/login', variant: 'outlined', width: 85, type: 'collapse' },
    {
      label: 'Sign Up',
      type: 'collapse',
      link: '/register/physicians',
      variant: 'contained',
      onClick: handleRegister,
      width: 100,
    },
  ]
  return headers
}

export const hospitalButtons = [
  { label: 'Jobs', link: '/jobs', width: 64 },
  { label: 'Providers', link: '/physicians', width: 64 },
  { Component: HeaderUserInfo, id: 'userInfo', type: 'rightstatic', width: 160 },
]

export const hospitalButtonsV1 = [
  { label: 'Jobs', link: '/hospitals/jobs', width: 64 },
  { Component: HeaderUserInfo, id: 'userInfo', type: 'rightstatic', width: 160 },
]

export const profileHeaderButtons = ({ isEmployerAdmin, isEmployerUser, handleLogout }) => {
  const profileLink = isEmployerUser
    ? { label: 'My Profile', link: `/users/employer_user/profile` }
    : { label: 'My Profile', link: `/users/candidate/profile` }
  return [
    profileLink,
    {
      label: 'Hospital',
      link: '/hospitals/profile',
      hidden: !(isEmployerAdmin || isEmployerUser),
    },
    {
      label: 'Users',
      link: '/admin/users',
      hidden: !isEmployerAdmin,
    },
    {
      label: 'Filters',
      link: '/users/filters',
    },
    {
      label: 'Alerts',
      link: '/users/alerts',
    },
    { label: 'Logout', onClick: handleLogout },
  ]
}
