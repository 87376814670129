import React, { useState } from 'react'
import { Box, Typography, Alert, List, ListItem } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import CSVReader from 'react-csv-reader'
import { convertToRaw } from 'draft-js'
import { convertFromHTML } from 'draft-convert'
import {
  PRACTICE_TYPE_ENUM,
  AREA_ENUM,
  SHIFT_TYPE_ENUM,
  SALARY_TYPE_ENUM,
  CANDIDATE_TYPE_ENUM,
} from 'data/enums'
import GenericDrawer from './GenericComponents/GenericDrawer'
import { fetchPostJobCSV } from 'src/redux/csv'

const parseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
}

const TRUE_VALUES = ['yes', 'Yes', 'YES', 'Y', 'y', 't', 'T', 'True', 'true', 'TRUE']

const getEnumKey = (object, value) => Object.keys(object).find(key => object[key] === value)

const getValue = (row, key) => (row[key] && row[key].trim ? row[key].trim() : row[key] || null)

const JobCSVUploadDrawer = ({ open, onClose }) => {
  const dispatch = useDispatch()
  const [errors, setErrors] = useState([])
  const [successMessage, setSuccessMessage] = useState('')
  const loading = useSelector(state => state.csv.loading)

  const handleFileLoad = data => {
    setErrors([])
    setSuccessMessage('')

    const transformedData = data.map(row => {
      const candidateType = getValue(row, 'CandidateType')
      const practiceType = getValue(row, 'PracticeType')
      const areaType = getValue(row, 'Area')
      const employerName = getValue(row, 'EmployerName')
      const employerUserEmail = getValue(row, 'EmployerUserEmail')
      const recruiterName = getValue(row, 'RecruiterName')
      const title = getValue(row, 'Title')
      const city = getValue(row, 'City')
      const state = getValue(row, 'State')
      const salary = getValue(row, 'Salary')

      const specialties = getValue(row, 'SpecialtyNames')
        ? getValue(row, 'SpecialtyNames')
            .split(',')
            .map(s => s.trim())
        : []
      const subspecialties = getValue(row, 'SubspecialtyNames')
        ? getValue(row, 'SubspecialtyNames')
            .split(',')
            .map(s => s.trim())
        : []

      const link = getValue(row, 'Link')
      const pslfCompatible = getValue(row, 'PslfCompatible')
      const loanForgiveness = getValue(row, 'LoanForgiveness')
      const academicType = getValue(row, 'Academic')
      const visas = getValue(row, 'Visas')
      const aboutHtml = getValue(row, 'AboutHTML')
      const aboutRaw = getValue(row, 'AboutRaw')
      const salaryMin = getValue(row, 'SalaryMin')
      const salaryMax = getValue(row, 'SalaryMax')
      const salaryType = getValue(row, 'SalaryType')
      const shiftType = getValue(row, 'ShiftType')

      return {
        employerName,
        employerUserEmail,
        recruiterName,
        candidateTypeId: Number(getEnumKey(CANDIDATE_TYPE_ENUM, candidateType)),
        title,
        city,
        state,
        areaType: getEnumKey(AREA_ENUM, areaType),
        practiceType: getEnumKey(PRACTICE_TYPE_ENUM, practiceType),
        shiftType: getEnumKey(SHIFT_TYPE_ENUM, shiftType),
        salary: salary ? parseFloat(salary) : null,
        salaryMin: salaryMin ? parseFloat(salaryMin) : null,
        salaryMax: salaryMax ? parseFloat(salaryMax) : null,
        salaryType: getEnumKey(SALARY_TYPE_ENUM, salaryType),
        specialtyNames: specialties,
        subspecialtyNames: subspecialties,
        link,
        pslfCompatible: TRUE_VALUES.includes(pslfCompatible),
        loanForgiveness: TRUE_VALUES.includes(loanForgiveness),
        academicType,
        visas,
        about: aboutHtml ? JSON.stringify(convertToRaw(convertFromHTML(aboutHtml))) : null,
        aboutRaw: aboutRaw || (aboutHtml ? aboutHtml.replace(/<[^>]*>/g, '') : null),
        aboutHtml,
      }
    })

    dispatch(fetchPostJobCSV({ jobs: transformedData }))
      .then(response => {
        if (response && response.errors) {
          const formattedErrors = Object.entries(response.errors).flatMap(([key, messages]) => {
            return messages.map(message => `${key}: ${message}`)
          })
          setErrors(formattedErrors)
        } else {
          setSuccessMessage('CSV uploaded successfully.')
        }
      })
      .catch(() => {
        setErrors(['An error occurred while uploading the CSV.'])
      })
  }

  return (
    <GenericDrawer
      open={open}
      onClose={onClose}
      title='Upload CSV for Jobs'
      submitLabel='Close'
      cancelLabel='Cancel'
      onSubmit={onClose}
    >
      <Box>
        <CSVReader
          cssClass='csv-reader-input'
          label='Select CSV for jobs'
          onFileLoaded={handleFileLoad}
          onError={() => setErrors(['Failed to read CSV file.'])}
          parserOptions={parseOptions}
        />

        {loading && <Typography variant='body2'>Uploading...</Typography>}

        {successMessage && (
          <Alert severity='success' sx={{ mt: 2 }}>
            {successMessage}
          </Alert>
        )}

        {errors.length > 0 && (
          <Box sx={{ mt: 2 }}>
            <Typography variant='h6'>Errors:</Typography>
            <List>
              {errors.map((error, index) => (
                <ListItem key={index}>
                  <Typography variant='body2' color='error'>
                    {error}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </Box>
    </GenericDrawer>
  )
}

export default JobCSVUploadDrawer
