import axios from 'axios'
import { showSnackbar, snackbarTypes } from 'src/redux/snackbar'
import { fetchGetCandidate } from './candidate'
import { fetchGetEmployerUser } from './employerUser'
import { fetchTrackEvent, ProductAnalyticsEventTypes } from 'src/redux/productAnalytics'

export const LOGIN_LOADING = 'identity/LOGIN_LOADING'
export const LOGIN_SUCCESS = 'identity/LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'identity/LOGIN_FAILURE'
export const LOGOUT_LOADING = 'identity/LOGOUT_LOADING'
export const LOGOUT_SUCCESS = 'identity/LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'identity/LOGOUT_FAILURE'
export const RESEND_CONFIRMATION_LINK_LOADING = 'identity/RESEND_CONFIRMATION_LINK_LOADING'
export const RESEND_CONFIRMATION_LINK_SUCCESS = 'identity/RESEND_CONFIRMATION_LINK_SUCCESS'
export const RESEND_CONFIRMATION_LINK_FAILURE = 'identity/RESEND_CONFIRMATION_LINK_FAILURE'
export const SEND_RESET_PASSWORD_LINK_LOADING = 'identity/SEND_RESET_PASSWORD_LINK_LOADING'
export const SEND_RESET_PASSWORD_LINK_SUCCESS = 'identity/SEND_RESET_PASSWORD_LINK_SUCCESS'
export const SEND_RESET_PASSWORD_LINK_FAILURE = 'identity/SEND_RESET_PASSWORD_LINK_SUCCESS'
export const RESET_PASSWORD_LOADING = 'identity/RESET_PASSWORD_LOADING'
export const RESET_PASSWORD_SUCCESS = 'identity/RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILURE = 'identity/RESET_PASSWORD_FAILURE'

export const loginLoading = () => ({ type: LOGIN_LOADING })
export const loginSuccess = (payload, meta) => ({ type: LOGIN_SUCCESS, payload, meta })
export const loginFailure = payload => ({ type: LOGIN_FAILURE, payload })
export const logoutLoading = () => ({ type: LOGOUT_LOADING })
export const logoutSuccess = meta => ({ type: LOGOUT_SUCCESS, meta })
export const logoutFailure = payload => ({ type: LOGOUT_FAILURE, payload })
export const resendConfirmationLinkLoading = () => ({ type: RESEND_CONFIRMATION_LINK_LOADING })
export const resendConfirmationLinkSuccess = () => ({ type: RESEND_CONFIRMATION_LINK_SUCCESS })
export const resendConfirmationLinkFailure = payload => ({
  type: RESEND_CONFIRMATION_LINK_FAILURE,
  payload,
})
export const sendResetPasswordLinkLoading = () => ({ type: SEND_RESET_PASSWORD_LINK_LOADING })
export const sendResetPasswordLinkSuccess = () => ({ type: SEND_RESET_PASSWORD_LINK_SUCCESS })
export const sendResetPasswordLinkFailure = payload => ({
  type: SEND_RESET_PASSWORD_LINK_FAILURE,
  payload,
})
export const resetPasswordLoading = () => ({ type: RESET_PASSWORD_LOADING })
export const resetPasswordSuccess = () => ({ type: RESET_PASSWORD_SUCCESS })
export const resetPasswordFailure = payload => ({ type: RESET_PASSWORD_FAILURE, payload })

export const fetchLogin = data => {
  return async dispatch => {
    dispatch(loginLoading())
    try {
      const response = await axios({
        url: '/api/identity/login',
        method: 'POST',
        data: data,
      })
      dispatch(showSnackbar('Successfully signed in.', snackbarTypes.SUCCESS))
      if (response.data.identity.candidateId) {
        dispatch(fetchGetCandidate(response.data.identity.candidateId))
        dispatch(
          fetchTrackEvent({
            eventName: 'physician_login_success',
            eventType: ProductAnalyticsEventTypes.ACTION,
            userId: response.data.identity.email,
          }),
        )
      } else {
        dispatch(fetchGetEmployerUser(response.data.identity.employerUserId))
        dispatch(
          fetchTrackEvent({
            eventName: 'recruiter_login_success',
            eventType: ProductAnalyticsEventTypes.ACTION,
            userId: response.data.identity.email,
            employerId: response.data.identity.employerId,
          }),
        )
      }

      dispatch(loginSuccess(response.data))
    } catch (error) {
      dispatch(loginFailure(error.response))
    }
  }
}

export const fetchLogout = () => {
  return async dispatch => {
    dispatch(logoutLoading())
    try {
      await axios({
        url: '/api/identity/logout',
        method: 'POST',
      })
      dispatch(logoutSuccess({}))
      dispatch(showSnackbar('Successfully signed out.', snackbarTypes.SUCCESS))
    } catch (error) {
      dispatch(showSnackbar('Sign out was not successful.', snackbarTypes.ERROR))
      dispatch(logoutFailure(error.response))
    }
  }
}

export const fetchResendConfirmationLink = data => {
  return async dispatch => {
    dispatch(resendConfirmationLinkLoading())
    try {
      await axios({
        url: '/api/identity/resend_confirmation_link',
        method: 'POST',
        data: data,
      })
      dispatch(
        showSnackbar('Confirmation link resent.  Please check your email.', snackbarTypes.SUCCESS),
      )
      dispatch(resendConfirmationLinkSuccess())
      return true
    } catch (error) {
      if (error.response.status !== 400) {
        dispatch(
          showSnackbar(
            'Could not resend confirmation link.  Please contact support.',
            snackbarTypes.ERROR,
          ),
        )
      }

      dispatch(resendConfirmationLinkFailure(error.response))
      return false
    }
  }
}

export const fetchSendResetPasswordLink = data => {
  return async dispatch => {
    dispatch(resetPasswordLoading())
    try {
      await axios({
        url: '/api/identity/send_reset_password_link',
        method: 'POST',
        data: data,
      })
      dispatch(
        showSnackbar('Password reset link sent.  Please check your email.', snackbarTypes.SUCCESS),
      )
      dispatch(resetPasswordSuccess())
      return true
    } catch (error) {
      if (error.response.status !== 400) {
        dispatch(
          showSnackbar(
            'Could not send password reset link.  Please contact support.',
            snackbarTypes.ERROR,
          ),
        )
      }

      dispatch(resetPasswordFailure(error.response))
      return false
    }
  }
}

export const fetchResetPassword = data => {
  return async dispatch => {
    dispatch(resetPasswordLoading())
    try {
      await axios({
        url: '/api/identity/reset_password',
        method: 'POST',
        data: data,
      })
      dispatch(showSnackbar('Password successfully reset, please login.', snackbarTypes.SUCCESS))
      dispatch(resetPasswordSuccess())
      return true
    } catch (error) {
      if (error.response.status !== 400) {
        dispatch(
          showSnackbar('Could not reset password.  Please contact support.', snackbarTypes.ERROR),
        )
      }

      dispatch(resetPasswordFailure(error.response))
      return false
    }
  }
}

const initialState = {
  loading: false,
  signedIn: false,
  resetPasswordSuccess: false,
  userId: null,
  email: null,
  candidateId: null,
  employerUserId: null,
  employerId: null,
  isEmployerAdmin: false,
  isSuper: false,
  errors: [],
  loginFailure: false,
  lockedOut: false,
}

export const identityReducer = (state = initialState, action) => {
  const { payload } = action

  switch (action.type) {
    case LOGIN_LOADING:
      return {
        ...state,
        loading: true,
        signedIn: false,
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        signedIn: true,
        userId: payload.identity.userId,
        email: payload.identity.email,
        candidateId: payload.identity.candidateId,
        employerUserId: payload.identity.employerUserId,
        employerId: payload.identity.employerId,
        isEmployerAdmin: payload.identity.isEmployerAdmin,
        isSuper: payload.identity.isSuper,
      }
    case LOGIN_FAILURE:
      return {
        ...initialState,
        errors: payload.status === 400 ? payload.data.errors : [],
        loginFailure: payload.status === 401,
        lockedOut: payload.status === 403,
      }
    case LOGOUT_LOADING:
      return {
        ...state,
        loading: true,
      }
    case LOGOUT_SUCCESS:
      return {
        ...initialState,
      }
    case LOGOUT_FAILURE:
      return {
        ...initialState,
        errors: payload.data.errors,
      }
    case RESEND_CONFIRMATION_LINK_LOADING:
      return {
        ...initialState,
        loading: true,
      }
    case RESEND_CONFIRMATION_LINK_SUCCESS:
      return {
        ...initialState,
      }
    case RESEND_CONFIRMATION_LINK_FAILURE:
      return {
        ...initialState,
        errors: payload.data.errors,
      }
    case SEND_RESET_PASSWORD_LINK_LOADING:
      return {
        ...initialState,
        loading: true,
      }
    case SEND_RESET_PASSWORD_LINK_SUCCESS:
      return {
        ...initialState,
      }
    case SEND_RESET_PASSWORD_LINK_FAILURE:
      return {
        ...initialState,
        errors: payload.data.errors,
      }
    case RESET_PASSWORD_LOADING:
      return {
        ...initialState,
        loading: true,
      }
    case RESET_PASSWORD_SUCCESS:
      return {
        ...initialState,
        resetPasswordSuccess: true,
      }
    case RESET_PASSWORD_FAILURE:
      return {
        ...initialState,
        resetPasswordSuccess: false,
        errors: payload.data.errors,
      }
    default:
      return {
        ...state,
        signedIn: !!state.userId,
      }
  }
}
