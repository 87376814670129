import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, Navigate } from 'react-router'
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import useComponentSize from '@rehooks/component-size'
import qs from 'qs'

import { useStyles } from 'common/styles'
import { useContentHeight } from 'data/hooks'
import { useWidth, useLandscape } from 'data/layout/actions'
import { fetchResetPassword } from 'src/redux/identity'
import ValidationErrorMessages from 'components/ValidationErrorMessages'

export default props => {
  const width = useWidth()
  const landscape = useLandscape()
  const componentRef = React.useRef(null)
  const componentSize = useComponentSize(componentRef)
  const marginTop = (useContentHeight() - (componentSize.height + 8)) / 2
  const classes = useStyles({ landscape, marginTop, width })
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const [state, setState] = React.useState({ password: '', confirmPassword: '' })
  const dispatch = useDispatch()
  const location = useLocation()
  const { loading, resetPasswordSuccess, errors = {} } = useSelector(state => state.identity)

  if (resetPasswordSuccess) {
    return <Navigate to='/login' />
  }

  const handleChange = e => {
    const { id, value } = e.target
    setState(prevState => ({
      ...prevState,
      [id]: value,
    }))
  }

  const handleSubmit = () => {
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
    const resetPasswordData = {
      userId: queryParams.userId,
      token: queryParams.token,
      password: state.password,
      confirmPassword: state.confirmPassword,
    }
    dispatch(fetchResetPassword(resetPasswordData))
  }

  const handleEnterKey = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleSubmit()
    }
  }

  const handleSubmitClick = e => {
    e.preventDefault()
    handleSubmit()
  }

  return (
    <div style={{ height: '100vh', backgroundColor: `${theme.palette.primary.dark}` }}>
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        sx={{ height: '100vh', backgroundColor: `${theme.palette.primary.dark}` }}
      >
        <Paper
          ref={componentRef}
          className={classes.identityContainer}
          onKeyUp={handleEnterKey}
          sx={{ padding: isSmallScreen ? '16px' : '32px', width: isSmallScreen ? '90%' : '400px' }}
        >
          <Grid container alignItems='center' spacing={1}>
            <Grid item xs={12}>
              <Typography align='left' variant='h5'>
                {props.heading}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ height: '1px' }} />
            </Grid>
          </Grid>
          <Grid
            container
            alignItems='center'
            spacing={2}
            style={{ paddingLeft: 8, paddingRight: 8 }}
          >
            {loading && <CircularProgress size={24} className={classes.submitProgress} />}
            <Grid item xs={12}>
              <TextField
                variant='standard'
                id='password'
                label='Password'
                placeholder='Enter your password'
                margin='normal'
                type='password'
                fullWidth
                className={classes.input}
                InputProps={{
                  className: classes.input,
                }}
                onChange={handleChange}
                disabled={loading}
                error={!!errors['password']}
                helperText={<ValidationErrorMessages errors={errors['password']} />}
              />
              <TextField
                variant='standard'
                id='confirmPassword'
                label='Confirm Password'
                placeholder='Confirm password'
                margin='normal'
                type='password'
                fullWidth
                className={classes.input}
                InputProps={{
                  className: classes.input,
                }}
                onChange={handleChange}
                disabled={loading}
                error={!!errors['confirmPassword']}
                helperText={<ValidationErrorMessages errors={errors['confirmPassword']} />}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent='center' spacing={2}>
                <Grid item>
                  <Button
                    variant='contained'
                    size='large'
                    onClick={handleSubmitClick}
                    disabled={loading}
                    fullWidth
                  >
                    {props.buttonText}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </div>
  )
}
