import React from 'react'
import { useWidth } from 'data/layout/actions'
import { States } from 'data/constants'
import { UserProfileDesktop, UserProfileMobile } from './index'

const employeeFields = {
  hospital: { name: 'Hospital' },
  location: { name: 'Location' },
  jobTitle: { name: 'Job Title' },
  phone: { name: 'Phone'},
  email: { name: 'Email' },
}

const mapProfileFields = (user) => {
  const jobTitle = user.jobTitle || 'Staffing Contact'
  let mappedFields = { ...employeeFields }

  mappedFields.hospital.value = `${jobTitle} at ${user.employerName}`
  mappedFields.location.value = user.city && user.state ? `${user.city}, ${States[user.state]}` : ' '
  mappedFields.jobTitle.value = user.jobTitle || ' '
  mappedFields.phone.value = user.showPhone ? user.phone : ' '
  mappedFields.email.value = user.showEmail ? user.email : ' '

  return mappedFields
}

export const EmployeeProfile = ({ user, showEditButtons }) => {
  const width = useWidth()
  const desktop = width >= 750

  return desktop ? (
    <UserProfileDesktop
      loggedInUser={user}
      fieldData={mapProfileFields(user)}
      shouldShowEditButtons={showEditButtons}
    />
  ) : (
    <UserProfileMobile
      loggedInUser={user}
      fieldData={mapProfileFields(user)}
      shouldShowEditButtons={showEditButtons}
    />
  )
}
