import React from 'react'
import { MenuItem, TextField, Box, IconButton, Tooltip } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import DOMPurify from 'dompurify'

const JobTextField = ({
  label,
  name,
  value,
  showError,
  options = null,
  setState,
  setJobAlertData = null,
  required,
  toolTipText = '', // Optional tooltip text
}) => {
  const handleChange = e => {
    const newValue = e.target.value
    setState(name, newValue)
    if (setJobAlertData !== null) {
      const optionSelected = options
        ? options.find(o => o.id === newValue)
        : { name: newValue, label: newValue }
      setJobAlertData(prevData => ({
        ...prevData,
        [name]: {
          name: optionSelected.id,
          label: label,
          value: { id: optionSelected.id, name: optionSelected.name },
        },
      }))
    }
  }

  return (
    <Box display='flex' alignItems='center'>
      <TextField
        id={name}
        select={!!options && options.length > 0}
        label={`${label}${required ? ' *' : ''}`}
        value={value || ''}
        onChange={handleChange}
        fullWidth
        size='small'
        error={showError && !value}
        helperText={showError && !value ? 'Required' : ''}
        sx={{ flex: 1 }} // Ensure the field takes full width minus icon
      >
        {options && options.length > 0
          ? options.map(o => (
              <MenuItem key={o.id} value={o.id}>
                {o.name}
              </MenuItem>
            ))
          : null}
      </TextField>

      {toolTipText && (
        <Tooltip
          title={
            <Box
              sx={{ whiteSpace: 'pre-line' }}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(toolTipText.replace(/\n/g, '<br/>')),
              }}
            />
          }
          placement='top'
          arrow
        >
          <IconButton size='small' sx={{ ml: 1, color: 'primary.main' }}>
            <InfoIcon fontSize='small' />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  )
}

export default JobTextField
