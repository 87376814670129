import React, { useState } from 'react'
import {
  Box,
  Button,
  Chip,
  Checkbox,
  Drawer,
  IconButton,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  ListItemText,
  useTheme,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ClearIcon from '@mui/icons-material/Clear'

const MobileCompositeFilterDropdown = ({ groupedFilter, onChange, selectedFilters }) => {
  const theme = useTheme()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  // Filter the grouped filters based on selectedFilters
  const selectedGroupedFilters = groupedFilter.filters.filter(
    filter => filter.filterDefinition.name && selectedFilters[filter.filterDefinition.name],
  )

  const toggleDrawer = open => () => {
    setIsDrawerOpen(open)
  }

  const handleRemoveFilter = (e, filter) => {
    e.stopPropagation()
    const groupFilters = selectedFilters[filter.filterDefinition.name] || { selectedValues: [] }
    const selectedValues = groupFilters.selectedValues.filter(
      existingFilter =>
        !(
          existingFilter.name === filter.filterDefinition.name &&
          JSON.stringify(existingFilter.value) === JSON.stringify(filter.filterValues[0])
        ),
    )

    onChange({
      filterDefinition: filter.filterDefinition,
      filterGroupName: groupedFilter.displayName,
      selectedValues,
    })
  }

  const handleFilterChange = (filterGroupName, filter) => event => {
    const groupFilters = selectedFilters[filter.filterDefinition.name] || { selectedValues: [] }
    const selectedValues = groupFilters.selectedValues

    const updatedValues = event.target.checked
      ? [...selectedValues, { name: filter.filterDefinition.name, value: filter.filterValues[0] }]
      : selectedValues.filter(
          existingFilter =>
            !(
              existingFilter.name === filter.filterDefinition.name &&
              JSON.stringify(existingFilter.value) === JSON.stringify(filter.filterValues[0])
            ),
        )

    onChange({
      filterDefinition: filter.filterDefinition,
      filterGroupName,
      selectedValues: updatedValues,
    })
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Button
        onClick={toggleDrawer(true)}
        variant='outlined'
        size='small'
        sx={{
          width: '100%',
          textTransform: 'none',
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary,
          borderRadius: '4px',
          borderColor: theme.palette.divider,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        }}
      >
        {groupedFilter.displayName}{' '}
        {selectedGroupedFilters.length > 0 ? `(${selectedGroupedFilters.length})` : ''}
      </Button>

      <Drawer
        anchor='bottom'
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: { padding: 2, maxHeight: '90vh', overflowY: 'auto' },
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 2,
          }}
        >
          <Typography variant='h6' sx={{ color: theme.palette.text.primary }}>
            {groupedFilter.displayName}
          </Typography>
          <IconButton onClick={toggleDrawer(false)}>
            <CloseIcon />
          </IconButton>
        </Box>

        <FormControl fullWidth>
          <InputLabel
            sx={{
              fontSize: '.9rem',
              color: theme.palette.text.primary,
              marginBottom: '12px', // Ensures proper spacing below the label
              '&.MuiInputLabel-shrink': {
                color: theme.palette.primary.main,
              },
            }}
            id={`label-${groupedFilter.filterGroup}`}
          >
            {groupedFilter.displayName}
          </InputLabel>
          <Select
            labelId={`label-${groupedFilter.filterGroup}`}
            value={selectedGroupedFilters.map(filter => filter.filterDefinition.name)}
            multiple
            renderValue={() => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selectedGroupedFilters.map(filter => (
                  <Chip
                    key={filter.filterDefinition.name}
                    label={filter.filterDefinition.displayName}
                    onDelete={e => handleRemoveFilter(e, filter)}
                    sx={{
                      fontSize: '.7rem',
                      backgroundColor: theme.palette.grey[200],
                      color: theme.palette.text.primary,
                      height: '24px',
                    }}
                    deleteIcon={<ClearIcon sx={{ fontSize: '12px' }} />}
                    onMouseDown={e => e.stopPropagation()} // Prevent dropdown from opening
                  />
                ))}
              </Box>
            )}
            sx={{
              backgroundColor: theme.palette.background.paper,
              color: theme.palette.text.primary,
              '& .MuiSelect-select': {
                padding: '12px 16px', // Increase padding for better appearance
                display: 'flex',
                flexWrap: 'wrap',
                gap: 0.5,
              },
            }}
          >
            {groupedFilter.filters.map(filter => {
              const isChecked = (
                selectedFilters[filter.filterDefinition.name]?.selectedValues || []
              ).some(f => f.value === '1')

              return (
                <MenuItem key={filter.filterDefinition.name} value={filter.filterDefinition.name}>
                  <Checkbox
                    checked={isChecked}
                    onChange={handleFilterChange(groupedFilter.displayName, filter)}
                  />
                  <ListItemText
                    primary={
                      <Typography
                        sx={{
                          fontSize: '.8rem',
                          color: theme.palette.text.primary,
                        }}
                      >
                        {filter.filterDefinition.displayName}
                      </Typography>
                    }
                  />
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Drawer>
    </Box>
  )
}

export default MobileCompositeFilterDropdown
