import { snackbarTypes } from 'data/constants'
import { fetchDispatch } from 'data/helpers'
import { showSnackbar } from 'data/snackbar/actions'

export const GET_SUPER_USER_LOADING = 'GET_SUPER_USER_LOADING'

export const GET_SUPER_USER_SUCCESS = 'GET_SUPER_USER_SUCCESS'

export const GET_SUPER_USER_ERROR = 'GET_SUPER_USER_ERROR'

export function getSuperUserLoading() {
  return {
    type: GET_SUPER_USER_LOADING,
  }
}

export function getSuperUserSuccess(payload) {
  return {
    type: GET_SUPER_USER_SUCCESS,
    payload,
  }
}

export function getSuperUserError() {
  return {
    type: GET_SUPER_USER_ERROR,
  }
}

export function getSuperUser(id) {
  return async (dispatch) => {
    dispatch(getSuperUserLoading())
    const response = await fetchDispatch({ path: `/api/super_users/${id}`, method: "GET" })
    if (response) {
      return dispatch(getSuperUserSuccess(response))
    }

    showSnackbar(dispatch, "An error occurred attempting to get super user information.", snackbarTypes.ERROR)
    return dispatch(getSuperUserError())
  }
}
