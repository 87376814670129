import * as React from 'react'
import { MenuItem } from '@mui/material'
import OutlinedSelect from 'components/OutlinedSelect/OutlinedSelect'

const Select = ({ items = [], variant = 'standard', ...other }) => (
  <OutlinedSelect variant={variant} {...other}>
    {items.map(item => (
      <MenuItem key={item.id} value={item.id}>
        {item.label}
      </MenuItem>
    ))}
  </OutlinedSelect>
)

export default Select
