import React from 'react'
import { Grid, Typography, Button, TextField } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useHandleChangeRoute } from 'data/hooks'
import { setCurrentUserEmail } from '../../redux/register'

const Join = () => {
  const dispatch = useDispatch()
  const [email, setEmail] = React.useState('')
  const handleChangeRoute = useHandleChangeRoute()

  const handleSubmit = () => {
    dispatch(setCurrentUserEmail(email))
    handleChangeRoute('/register/physicians')()
  }
  return (
    <Grid
      container
      justifyContent='center'
      direction='column'
      spacing={3}
      sx={{
        backgroundColor: 'black',
        textAlign: 'center',
        padding: { xs: '10px', sm: '50px' },
      }}
    >
      <Grid item>
        <Typography variant='h4' sx={{ color: 'white' }}>
          Get Started
        </Typography>
      </Grid>
      <Grid
        container
        item
        justifyContent='center'
        alignItems='center'
        alignContent='center'
        direction='row'
        spacing={2}
      >
        <TextField
          size='large'
          id='join-email'
          placeholder='Enter your email'
          onChange={e => setEmail(e.target.value)}
          sx={{
            padding: '10px 0',
            marginRight: '20px',
            width: '50%',
            '& .MuiOutlinedInput-root': {
              backgroundColor: 'white',
            },
          }}
        />
        <Button variant='contained' color='primary' onClick={handleSubmit}>
          Submit
        </Button>
      </Grid>
    </Grid>
  )
}

export default Join
