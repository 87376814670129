import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { formatPhoneNumber } from './helpers'
import { fetchTrackEvent, ProductAnalyticsEventTypes } from 'src/redux/productAnalytics'
import { fetchCreateHousingInterest } from 'src/redux/housinginterest'
import { validateEmail } from 'data/helpers'

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  message: '',
  source: 'MyStethi',
  eventType: 'General Inquiry',
}

export const useFormControls = () => {
  const dispatch = useDispatch()
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [errors, setErrors] = useState({}) // State to track validation errors
  const [formData, setFormData] = useState({
    ...initialValues,
  })

  const handleChange = e => {
    const { name, value } = e.target
    if (name === 'phone') {
      setFormData({
        ...formData,
        phone: formatPhoneNumber(value),
      })
    } else {
      setFormData({
        ...formData,
        [name]: value,
      })
    }
  }

  const validatePhoneNumber = phone => {
    const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/ // Regex for (123) 456-7890 format
    return phoneRegex.test(phone)
  }

  const handleSubmit = e => {
    e.preventDefault()

    // Validate required fields
    const newErrors = {}
    if (!formData.firstName) newErrors.firstName = 'First Name is required'
    if (!formData.lastName) newErrors.lastName = 'Last Name is required'
    if (!formData.email || !validateEmail(formData.email)) {
      newErrors.email = 'Valid Email is required'
    }
    if (!formData.phone || !validatePhoneNumber(formData.phone)) {
      newErrors.phone = 'Valid Phone Number (e.g., (123) 456-7890) is required'
    }
    if (!formData.message) newErrors.message = 'Message is required'

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors)
    } else {
      setErrors({})
      setFormSubmitted(true)
      dispatch(
        fetchCreateHousingInterest({
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          phone: formData.phone,
          message: formData.message,
        }),
      )
    }
  }

  const handleCreateAccount = () => {
    const registrationUrl = `/register/physicians?firstName=${formData.firstName}&lastName=${formData.lastName}&email=${formData.email}`
    dispatch(
      fetchTrackEvent({
        eventName: 'housing_create_account',
        eventType: ProductAnalyticsEventTypes.BUTTON_CLICK,
        userId: formData.email,
      }),
    )
    window.location.href = registrationUrl
  }

  return {
    formData,
    formSubmitted,
    errors,
    handleChange,
    handleSubmit,
    handleCreateAccount,
  }
}
