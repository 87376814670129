import { UPDATE_EMPLOYER_USER } from 'data/constants'
import { isFunction } from 'data/helpers'
import { GET_EMPLOYER_USER_LOADING, GET_EMPLOYER_USER_SUCCESS, GET_EMPLOYER_USER_ERROR} from 'data/employerUser/actions'

export default function legacyEmployerUserReducer(state = {}, action) {
  const { payload } = action
  switch (action.type) {
    case UPDATE_EMPLOYER_USER:
      return isFunction(payload) ? payload(state) : payload
    case GET_EMPLOYER_USER_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_EMPLOYER_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        id: payload.id,
        firstName: payload.user.firstName,
        lastName: payload.user.lastName,
        isEmployerAdmin: payload.isEmployerAdmin,
        email: payload.user.email,
        phone: payload.user.phone,
        city: payload.user.city,
        state: payload.user.state,
        profileImage: payload.user.profileImage,
        about: payload.about,
        jobTitle: payload.jobTitle,
        employerName: payload.employer.name,
        employerId: payload.employer.id,
        showEmail: payload.user.showEmail,
        showPhone: payload.user.showPhone,
        employerImage: payload.employer.image,
      }
    case GET_EMPLOYER_USER_ERROR:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}
