import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Grid, Typography, Button } from '@mui/material'
import { useDialogState } from 'data/dialog/actions'
import Title from 'components/Title'
import { useStyles } from 'common/styles'
import { fetchUpdateCandidateJob } from 'src/redux/candidateJob'
import { fetchTrackEvent, ProductAnalyticsEventTypes } from 'src/redux/productAnalytics'
export const dialogId = 'Job Interest'

export default function Content({ candidateId, email }) {
  const classes = useStyles()
  const [{ jobId, interestedInContact }, setState] = useDialogState(dialogId)

  const text = interestedInContact
    ? 'You were previously interested in being contacted by this job, are you sure you want to remove yourself from the list?'
    : 'By confirming your interest, we will submit a request directly to the hospital recruiter to get in touch with you based on your contact preferences.'

  const title = interestedInContact ? 'Remove this job from your list?' : 'Confirm your interest'
  const dispatch = useDispatch()

  const handleSubmit = () => {
    dispatch(
      fetchUpdateCandidateJob({
        jobId: jobId,
        candidateId: candidateId,
        interestedInContact: !interestedInContact,
      }),
    )
    dispatch(
      fetchTrackEvent({
        eventName: 'job_interest_submit_click',
        eventType: ProductAnalyticsEventTypes.BUTTON_CLICK,
        userId: email,
        eventData: { interestedInContact: !interestedInContact },
      }),
    )
    setState(prev => ({ ...prev, open: false }))
  }

  return (
    <Grid
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
      spacing={3}
      className={classes.dialogContentContainer}
    >
      <Grid item>
        <Title text={title} textAlign='left' variant='h5' marginBottom={5} />
      </Grid>
      <Grid item>
        <Typography variant='body1' color='inherit' textAlign='center'>
          {text}
        </Typography>
      </Grid>
      <Grid item>
        <Button variant='contained' size='large' onClick={handleSubmit}>
          Confirm
        </Button>
      </Grid>
      {!interestedInContact && (
        <Grid item>
          <Typography variant='caption' color='inherit' textAlign='center'>
            Please note, we cannot guarantee an outreach back if the hospital is not yet one of our
            partners, but we will do our best to get in touch with them!
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

Content.propTypes = {
  candidateId: PropTypes.number,
  interestedInContact: PropTypes.bool,
}
