import React from 'react'
import { Grid } from '@mui/material'
import FolderTabs from './FolderTabs'
import ProviderInfo from './ProviderInfo'
import HospitalInfo from './HospitaInfo'

const Info = () => {
  const [activeTab, setActiveTab] = React.useState(0)
  const tabSteps = [
    { tab: 0, label: 'For Providers', Component: <ProviderInfo /> },
    { tab: 1, label: 'For Hospitals', Component: <HospitalInfo /> },
  ]
  return (
    <Grid
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
      alignContent='center'
      style={{
        maxWidth: '960px',
        backgroundColor: 'white',
        margin: '20px auto 40px auto',
      }}
    >
      <Grid item sx={{ padding: '16px', width: '100%' }}>
        <FolderTabs activeTab={activeTab} setActiveTab={setActiveTab} tabSteps={tabSteps} />
        {tabSteps[activeTab].Component}
      </Grid>
    </Grid>
  )
}

export default Info
