import React from 'react'
import AddEmployerUserComponent from './AddEmployerUserComponent'

export const defaultProps = {
  name: 'Employer Users',
  columns: [
    { name: 'id', header: 'Id' },
    {
      name: 'isEmployerAdmin',
      header: 'Is Employer Admin',
    },
    {
      name: 'isActive',
      header: 'Is Active',
    },
    {
      name: 'verifiedAt',
      header: 'Verified At',
    },
    {
      name: 'email',
      header: 'Email',
    },
    {
      name: 'firstName',
      header: 'First Name',
    },
    {
      name: 'lastName',
      header: 'Last Name',
    },
    {
      name: 'employer',
      header: 'Account',
    },
    {
      name: 'actions',
      header: 'Actions',
    },
  ],
  addComponent: <AddEmployerUserComponent isSuper={true} />,
  searchableFields: ['email', 'firstName', 'lastName'],
}
