import { SET_USER } from 'data/constants'

const initialState = {
  user: {
    firstName: null,
    lastName: null,
    email: null,
  },
  hospital: {
    name: null,
    image: null,
    description: null,
  },
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload.user,
        hospital: action.payload.hospital,
      }
    default:
      return state
  }
}
