import React, { useState, useEffect } from 'react'
import { Grid, useMediaQuery, useTheme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { useDialogState } from 'data/dialog/actions'
import { renderDialogModule } from 'components/Dialog/DialogButton'
import GenericDialog from 'components/Dialog/GenericDialog'
import { ListItemJob } from 'components/Listing/ListItemJob'
import { ListPagination } from 'components/Listing/ListPagination'
import JobContent from 'pages/Jobs/JobContent'
import RealtorContent from 'pages/Realtors/RealtorContent'
import { ListItemPhysician } from 'components/Listing/ListItemPhysician'
import PhysicianContent from 'pages/Physicians/PhysicianContent'
import { ListItemRealtor } from './ListItemRealtor'

const useStyles = makeStyles(({ palette, layout }) =>
  createStyles({
    submitProgress: {
      color: palette.primary,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -layout.progressSizeLarge / 2,
      marginLeft: -layout.progressSizeLarge / 2,
    },
    scrollWrapper: {
      height: 'calc(100%)',
      overflow: 'scroll',
      width: '100%',
      order: '2',
    },
    candidatesWrapper: () => ({
      height: '100%',
      paddingBottom: '50px',
      paddingTop: '20px',
      overflow: 'scroll',
    }),
  }),
)

export const List = ({
  data,
  page,
  setPage,
  listType,
  isLoading = false,
  handleListItemClick,
  totalPages,
  signedIn,
  onFavoriteClick,
  setActiveItem,
}) => {
  const [scrollEvent, setScrollEvent] = useState(false)
  const [id, setId] = useState(null) // Keep track of the active item's ID
  const listInnerRef = React.useRef()
  const [, setDialogState] = useDialogState('mobileJobListing')
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const styles = useStyles({ mobile })

  useEffect(() => {
    if (data.length > 0) {
      const firstItem = data[0]
      setActiveItem(firstItem)
      setId(firstItem.id)
    }
  }, [data, setActiveItem])

  useEffect(() => {
    if (scrollEvent && page + 1 < totalPages) {
      setPage(page + 1)
      setScrollEvent(false)
    }
  }, [scrollEvent, page, totalPages])

  useEffect(() => {
    if (listInnerRef.current && !isLoading && id === null) {
      listInnerRef.current.scrollTo(0, 0)
    }
  }, [isLoading])

  const handleClick = currId => {
    const currItem = data.find(item => item.id === currId) || data[0]
    setId(currId)
    setActiveItem(currItem)
    handleListItemClick(currId)

    let MobileComponent = null
    switch (listType) {
      case 'job':
        MobileComponent = (
          <JobContent
            item={currItem}
            signedIn={signedIn}
            onFavoriteClick={onFavoriteClick}
            jobs={data}
            activeItem={currItem}
            mobile={true}
          />
        )
        break
      case 'provider':
        MobileComponent = <PhysicianContent item={currItem} signedIn={signedIn} />
        break
      case 'realtor':
        MobileComponent = <RealtorContent item={currItem} signedIn={signedIn} />
        break
    }
    if (mobile) {
      setDialogState(prev => ({
        ...prev,
        type: 'view',
        open: true,
        RenderComponent: () => MobileComponent,
      }))
    }
  }

  return (
    <>
      {renderDialogModule({ default: GenericDialog, id: 'mobileJobListing' })}
      <Grid container item ref={listInnerRef} classes={{ root: styles.candidatesWrapper }}>
        <Grid
          container
          item
          rowSpacing={3}
          columnSpacing={2}
          alignItems='flex-start'
          justifyContent={'center'}
        >
          {data.map((item, index) => (
            <Grid item xs={11} key={index}>
              {listType === 'job' && (
                <ListItemJob
                  signedIn={signedIn}
                  data={item}
                  isActive={id === item.id}
                  onClick={() => handleClick(item.id)}
                  onFavoriteClick={onFavoriteClick}
                />
              )}
              {listType === 'provider' && (
                <ListItemPhysician
                  signedIn={signedIn}
                  data={item}
                  isActive={id === item.id}
                  onClick={() => handleClick(item.id)}
                />
              )}
              {listType === 'realtor' && (
                <ListItemRealtor
                  signedIn={signedIn}
                  data={item}
                  isActive={id === item.id}
                  onClick={() => handleClick(item.id)}
                />
              )}
            </Grid>
          ))}
        </Grid>
        <Grid container item sx={{ margin: '20px 0px 50px 0px' }}>
          {totalPages > 1 && (
            <ListPagination page={page} totalPages={totalPages} setPage={setPage} />
          )}
        </Grid>
      </Grid>
    </>
  )
}
