import { sum, evalFunc } from 'data/helpers'
import { useHandleChangeRoute } from 'data/hooks'
import { fetchLogout } from 'src/redux/identity'

const defaultWidth = 120
const logoWidth = collapseLogo => (collapseLogo ? 55 : 200)

export const getWidthInfo = (toolbarButtons, width, buttonSpacing) => {
  const staticButtonContainerWidth = toolbarButtons
    .filter(b => isStatic(b) && evalFunc(b.hidden, width) !== true)
    .map(b => (b.width ? b.width : defaultWidth) + buttonSpacing * 8)
    .reduce(sum, 0)
  const buttonContainerWidth = toolbarButtons
    .filter(b => !isStatic(b) && evalFunc(b.hidden, width) !== true)
    .map(b => (b.width ? b.width : defaultWidth) + buttonSpacing * 8)
    .reduce(sum, 0)
  const collapseLogo = width < staticButtonContainerWidth + 300
  const staticContainerWidth = [logoWidth(collapseLogo), staticButtonContainerWidth].reduce(sum, 0)
  const sectionSpacing = [
    true, // Logo
    toolbarButtons.filter(b => b.type === 'static' && evalFunc(b.hidden, width) !== true).length > 0
      ? buttonSpacing
      : 0,
    toolbarButtons.filter(b => b.type === 'rightstatic' && evalFunc(b.hidden, width) !== true)
      .length > 0
      ? buttonSpacing
      : 0,
    toolbarButtons.filter(b => !isStatic(b) && evalFunc(b.hidden, width) !== true).length > 0
      ? buttonSpacing
      : 0,
  ].reduce(sum, 0)
  const collapseButtons = buttonContainerWidth > width - staticContainerWidth - sectionSpacing * 8 // Flag collapse if total button widths exceed available container width

  return {
    staticButtonContainerWidth,
    buttonContainerWidth,
    collapseLogo,
    staticContainerWidth,
    sectionSpacing,
    collapseButtons,
  }
}

export const useHandleRegister = () => {
  const handleChangeRoute = useHandleChangeRoute()
  return handleChangeRoute('/register/physicians')
}

export const isStatic = b => b.type === 'static' || b.type === 'rightstatic'

export const useHandleLogout = (dispatch, navigate) => {
  return e => {
    e.preventDefault()
    dispatch(fetchLogout())
    navigate('/')
  }
}
