import React from 'react'
import { Typography, Grid } from '@mui/material'
import Logo from 'static/images/logo.svg'

export default function HeaderLogo({ collapse = false }) {
  return (
    <Grid container spacing={2}>
      <Grid item>
        <img src={Logo} alt='Logo' />
      </Grid>
      {!collapse && (
        <Grid item>
          <Typography variant='h6'>MyStethi</Typography>
        </Grid>
      )}
    </Grid>
  )
}
