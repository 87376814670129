import React from 'react'
import { useTheme, FormControl, FormHelperText } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'

export const GenericDatePicker = ({
  value,
  handleChange,
  label,
  name,
  disabled,
  required,
  error,
}) => {
  const theme = useTheme()

  const handleDateChange = date => {
    handleChange({ target: { name: name, value: date } })
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FormControl fullWidth error={error}>
        <DatePicker
          disabled={disabled}
          label={label}
          name={name}
          format='M/YYYY'
          views={['year', 'month']}
          value={value ? dayjs(value) : null}
          onChange={handleDateChange}
          slotProps={{
            field: { shouldRespectLeadingZeros: true },
            textField: { size: 'small', error: error, required: required },
          }}
          sx={{
            '& .MuiOutlinedInput-input.Mui-disabled': {
              WebkitTextFillColor: theme.palette.primary.dark,
            },
            '& .MuiOutlinedInput-root.Mui-disabled': {
              '& fieldset': { border: 'none' },
            },
          }}
        />
        {error && <FormHelperText>Please select a date</FormHelperText>}
      </FormControl>
    </LocalizationProvider>
  )
}

export default GenericDatePicker
