import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(theme =>
  createStyles({
    cardContainer: {
      minWidth: 700,
    },
    aboutContainer: {
      minWidth: 300,
    },
    mobileAboutContainer: ({ flexCol }) => ({
      ...flexCol,
      width: '100%',
      height: '100%',
      textAlign: 'center',
      marginTop: '10px',
      backgroundColor: theme.palette.primary.contrastText,
    }),
    mobileContent: ({ flexCol }) => ({
      ...flexCol,
      justifyContent: 'center',
      width: '100vw',
      padding: '0 40px',
      backgroundColor: theme.palette.primary.contrastText,
      paddingLeft: theme.layout.mobileLRPadding,
      paddingRight: theme.layout.mobileLRPadding,
    }),
    header: ({ flexRow, headerHeight, BackgroundImage }) => ({
      ...flexRow,
      height: headerHeight,
      justifyContent: 'flex-end',
      width: '100%',
      background: 'black',
      backgroundSize: 'cover',
      backgroundImage: BackgroundImage ? `url(${BackgroundImage})` : undefined,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
    }),
    card: () => ({
      padding: 30,
      backgroundColor: '#F5F5F5',
    }),
    profileWrapper: {
      background: 'white',
      width: '100%',
    },
    profileContainer: ({ flexRow }) => ({
      ...flexRow,
      justifyContent: 'center',
      marginTop: '10px'
    }),
    mobilePhoto: ({ headerHeight }) => ({
      marginTop: `-${headerHeight > 175 ? headerHeight * 0.3 : headerHeight * 0.6}px`,
      display: 'flex',
      placeContent: 'center',
    }),
    divider: {
      backgroundColor: theme.palette.secondary.main,
      width: '50%',
      height: '4px',
      borderRadius: '8px',
      marginBottom: '10px',
    },
    dividerMobile: {
      backgroundColor: theme.palette.secondary.main,
      height: '4px',
      borderRadius: '8px',
      marginBottom: '10px',
    },
    media: ({ height }) => ({
      height,
      width: height,
    }),
    content: {
      padding: 0,
    },
    photoCardContainer: {
      // width: 164,
    },
    photoCard: ({ height }) => ({
      height,
      width: height,
      borderRadius: 8,
      marginBottom: 10,
    }),
    button: {
      textAlign: 'center',
    },
    hidden: {
      display: 'none',
    },
    uploadPhotoButtonWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    resumeButton: {
      textDecoration: 'underline',
      color: 'blue',
      cursor: 'pointer',
    },
    leftField: {
      backgroundColor: theme.palette.primary.light,
      borderTop: `2px solid ${theme.palette.secondary.contrastText}`,
      paddingTop: '.5rem',
      paddingBottom: '.5rem',
      paddingLeft: '1rem',
      color: theme.palette.primary.accent,
      fontWeight: 'bold',
    },
    rightField: {
      backgroundColor: theme.palette.secondary.contrastText,
      borderTop: `2px solid ${theme.palette.primary.light}`,
      paddingTop: '.5rem',
      paddingBottom: '.5rem',
      paddingLeft: '1rem',
      paddingRight: '.5rem',
    },
    contactInfoContainer: ({ flexRow }) => ({
      ...flexRow,
      justifyContent: 'space-between',
      padding: '20px 0 0 30px'
    }),
    contactInfo: ({ flexRow }) => ({
      ...flexRow,
      gap: 10,
      alignItems: 'center',
    }),
    icon: {
      fontSize: 15,
    },
    iconText: {
      paddingLeft: '5px',
    },
    fieldContainer: ({ mobile }) => (mobile ? {
      paddingBottom: 20,
    } : null),
    mobileFieldWrapper: ({ flexCol }) => ({
      ...flexCol,
      marginTop: 16,
      padding: 20,
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
      border: '3px solid rgb(158, 120, 143)',
      backgroundColor: '#F5F5F5'
    }),
    rowContainer: ({ flexRow }) => ({ ...flexRow }),
    colContainer: ({ flexCol }) => ({ ...flexCol }),
  }),
)

export default useStyles
