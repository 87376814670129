import * as React from 'react'
import PropTypes from 'prop-types'
import { Chip, MenuItem } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import OutlinedSelect from 'components/OutlinedSelect/OutlinedSelect'

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    formControl: {
      minWidth: '336px',
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: '2px',
    },
  }),
)

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: `${ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP}px`,
      width: '250px',
    },
  },
}

export default function MultiChipSelect(props) {
  const classes = useStyles(props)
  const { value, disabled, items, className, onChange, removeFormat, ...other } = props

  const handleChange = event => (removeFormat ? onChange(removeFormat(event)) : onChange(event))

  const handleDelete = (event, chipToDelete) => {
    event.target.value = value.filter(chip => chip.value !== chipToDelete.key)
    if (removeFormat) {
      onChange(removeFormat(event))
    } else {
      onChange(event)
    }
  }

  return (
    <OutlinedSelect
      className={className ? className : classes.formControl}
      disabled={disabled}
      onChange={handleChange}
      multiple
      value={value}
      renderValue={selected => (
        <div className={classes.chips}>
          {selected.map(item => (
            <Chip
              key={item.value}
              label={item.label}
              className={classes.chip}
              onDelete={
                !disabled &&
                (e =>
                  handleDelete(e, {
                    key: item.value,
                    label: item.label,
                  }))
              }
            />
          ))}
        </div>
      )}
      MenuProps={MenuProps}
      {...other}
    >
      {items.map(item => (
        <MenuItem key={item.value} value={item}>
          {item.label}
        </MenuItem>
      ))}
    </OutlinedSelect>
  )
}

MultiChipSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
        PropTypes.object,
      ]),
      label: PropTypes.string,
    }),
  ),
  className: PropTypes.string,
  onChange: PropTypes.func,
  removeFormat: PropTypes.func,
}
