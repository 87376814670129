import React from 'react'
import { formatDate } from 'data/helpers'
import { useWidth } from 'data/layout/actions'
import { States } from 'data/constants'
import { UserProfileDesktop, UserProfileMobile } from './index'

const physicianFields = {
  location: { name: 'Location' },
  preferredCity: { name: 'Preferred City' },
  preferredState: { name: 'Preferred State' },
  specialty: { name: 'Specialty' },
  subspecialty: { name: 'Subspecialty' },
  residency: { name: 'Residency' },
  residencyDates: {name: 'Residency Dates'},
  npi: { name: 'NPI' }
}

const mapProfileFields = (user) => {
  let mappedFields = { ...physicianFields }
  if(user) {
    mappedFields.location.value = user.city && user.state ? `${user.city}, ${States[user.state]}` : null
    mappedFields.preferredCity.value = user.preferredCity
    mappedFields.preferredState.value = States[user.preferredState]
    mappedFields.specialty.value = user.specialties ? user.specialties[0].name : null
    mappedFields.subspecialty.value = user.subspecialties && user.subspecialties.length > 0 ? user.subspecialties[0].name : ''
    mappedFields.residency.value = user.residency
    mappedFields.residencyDates.value =
      user.residencyStart && user.residencyEnd
        ? `${formatDate(user.residencyStart)} to ${formatDate(user.residencyEnd)}`
        : null
    mappedFields.npi.value = user.npiNumber
  }
  return mappedFields
}

export const PhysicianProfile = ({ user, showEditButtons = false }) => {
  const width = useWidth()
  const desktop = width >= 750

  return desktop ? (
    <UserProfileDesktop
      loggedInUser={user}
      fieldData={mapProfileFields(user)}
      shouldShowEditButtons={showEditButtons}
    />
  ) : (
    <UserProfileMobile
      loggedInUser={user}
      fieldData={mapProfileFields(user)}
      shouldShowEditButtons={showEditButtons}
    />
  )
}
