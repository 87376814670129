import * as React from 'react'
import { snackbarTypes, UPDATE_EMPLOYER } from 'data/constants'
import { fetchDispatch, checkEmpty, imageAddress } from 'data/helpers'
import { useSelector, useDispatch } from 'react-redux'
import { useDispatchPayload } from 'data/hooks'
import { showSnackbar } from 'data/snackbar/actions'

export function useEmployer(formatter = undefined) {
  const dispatch = useDispatch()
  const dispatchPayload = useDispatchPayload()
  const employer = useSelector(s => (formatter ? formatter(s.employer) : s.employer) || {})

  // Updates the redux store with new employer user information
  const onResponse = React.useCallback(
    ({ employer }) => dispatchPayload(UPDATE_EMPLOYER, { ...employer, loading: false }),
    [dispatchPayload],
  )

  // Gets the employer user information from the server and updates the redux store with the results.  If no id is specified, use the current employer user id
  const getEmployer = React.useCallback(
    id => {
      dispatchPayload(UPDATE_EMPLOYER, prev => ({ ...prev, loading: true }))
      fetchDispatch({ path: `/api/employers/${id}`, method: "GET", onResponse: onResponse, })
    },
    [onResponse],
  )

  // Updates the database with new employer user information, closes dialog state or handles errors, and updates the redux store with the response
  const setEmployer = React.useCallback(
    (values, setState, OnResponse) => {
      dispatchPayload(UPDATE_EMPLOYER, prev => ({ ...prev, loading: true }))
      fetchDispatch({
        path: `/api/employers/${values.id}`,
        values,
        setState,
        onResponse: json => {
          showSnackbar(dispatch, "Successfully updated your profile.", snackbarTypes.SUCCESS)
          onResponse(json) // Call internal onResponse to update the redux store
          OnResponse && OnResponse(json) // Call external OnResponse to perform any other actions necessary. I.E change route, signInSuccess, etc
        },
        onError: () => {
          showSnackbar(dispatch, "An error occurred while updating your profile.", snackbarTypes.ERROR)
        },
        formData: true,
        method: "PUT",
      })
    },
    [onResponse],
  )

  const createEmployer = React.useCallback(
    (values, setState, OnResponse) => {
      fetchDispatch({
        path: '/api/employers',
        method: "POST",
        values,
        setState,
        onResponse: json => {
          showSnackbar(dispatch, "Successfully created account.", snackbarTypes.SUCCESS)
          onResponse(json) // Call internal onResponse to update the redux store
          OnResponse && OnResponse(json) // Call external OnResponse to perform any other actions necessary. I.E change route, signInSuccess, etc
        },
        onError: () => {
          showSnackbar(dispatch, "An error occurred while creating account.", snackbarTypes.ERROR)
        },
      })
    },
    [onResponse],
  )

  return [employer, getEmployer, setEmployer, createEmployer]
}

export function useEmployerBackground() {
  const [{ image }] = useEmployer()
  const { key, fileName } = checkEmpty(image) ? {} : image
  const BackgroundImage = !checkEmpty(key)
    ? imageAddress(key, fileName)
    : undefined
  return BackgroundImage
}
