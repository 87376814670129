import { get as _get, has as _has, merge as _merge } from 'lodash'

import DEFAULT_CONFIG from 'default-config'
import ENVIRONMENT_CONFIG from 'environment-config'

const MERGED_CONFIG = _merge({}, DEFAULT_CONFIG, ENVIRONMENT_CONFIG)

export function get(path, defaultValue) {
  return _get(MERGED_CONFIG, path, defaultValue)
}

export function has(path) {
  return _has(MERGED_CONFIG, path)
}
