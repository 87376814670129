import React from 'react'

export default (props) => {
  if (!props.errors) {
    return null
  }

  return (
    <div>
      {props.errors.map(error => {
        return <div>{error}</div>
      })}
    </div>
  )
}
