import React from 'react'
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText,
  IconButton,
  Tooltip,
  useTheme,
  Box,
} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import DOMPurify from 'dompurify'

const GenericRadioGroup = ({
  value,
  label,
  name,
  handleChange,
  options,
  disabled,
  required,
  error,
  toolTipText = '',
}) => {
  const theme = useTheme()

  const handleRadioChange = event => {
    handleChange(event)
  }

  return (
    <FormControl
      variant='outlined'
      required={required}
      error={error}
      disabled={disabled}
      sx={{
        marginBottom: theme.spacing(1), // Reduced bottom margin
        width: '100%', // Ensure consistent width for alignment
      }}
    >
      {label && (
        <FormLabel
          id={`${name}-label`}
          component='legend'
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(0.5), // Slightly reduced spacing between label and options
            color: theme.palette.primary.main, // Set label color to primary theme color
          }}
        >
          {label}

          {toolTipText && (
            <Tooltip
              title={
                <Box
                  sx={{ whiteSpace: 'pre-line' }}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      toolTipText.replace(/\n/g, '<br/>'), // Convert line breaks to <br/> tags
                    ),
                  }}
                />
              }
              placement='top'
              arrow
            >
              <IconButton
                size='small'
                sx={{
                  ml: 1,
                  color: theme.palette.primary.main,
                  padding: 0, // Remove extra padding around the icon
                }}
              >
                <InfoIcon fontSize='small' />
              </IconButton>
            </Tooltip>
          )}
        </FormLabel>
      )}

      <RadioGroup
        row
        aria-labelledby={`${name}-label`}
        name={name}
        value={value || ''}
        onChange={handleRadioChange}
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {options.map(option => (
          <FormControlLabel
            key={option.id}
            value={option.id}
            control={<Radio />}
            label={option.name}
            sx={{
              marginRight: theme.spacing(2), // Add consistent spacing between options
              '& .MuiTypography-root': {
                color: theme.palette.text.primary,
              },
            }}
          />
        ))}
      </RadioGroup>

      {error && required && (
        <FormHelperText sx={{ color: theme.palette.error.main }}>
          Please select an option
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default GenericRadioGroup
