import * as React from 'react'
import WholeNumber from 'components/DialogField/WholeNumber'
import Decimal from 'decimal.js-light'
import { InputAdornment } from '@mui/material'
import { isEmpty } from 'data/helpers'

export default function Money({ value, onChange, InputProps, ...props }) {
  const handleChange = React.useCallback(
    event => {
      const newValue = new Decimal(event.target.value).toNumber()
      onChange && onChange({ target: { value: newValue } }) // Ensure a number is sent instead of a string
    },
    [onChange],
  )
  var adjustedValue = isEmpty(value) ? value : new Decimal(value).toNumber()
  return (
    <WholeNumber
      value={adjustedValue}
      decimalScale={2}
      onChange={handleChange}
      InputProps={{
        startAdornment: <InputAdornment position='start'>$</InputAdornment>,
        ...InputProps,
      }}
      {...props}
    />
  )
}
