import React from 'react'
import { Pagination, Stack, Grid } from '@mui/material'

export const ListPagination = ({ totalPages, setPage, page }) => {
  const handleChange = (event, value) => {
    setPage(value - 1)
  }
  return (
    <Grid container item justifyContent='center' xs={12} sx={{ marginBottom: '10px' }}>
      <Stack spacing={2}>
        <Pagination page={page + 1} count={totalPages} shape='rounded' onChange={handleChange} />
      </Stack>
    </Grid>
  )
}
