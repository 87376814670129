import React from 'react'
import { useTheme } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router'
import { getContentHeight, getContentHeightWithJobFilters } from 'data/helpers'
export const useContentHeight = () => {
  const theme = useTheme()
  return useSelector(state => getContentHeight(state, theme))
}

export const useContentHeightWithJobFilters = () => {
  const theme = useTheme()
  return useSelector(state => getContentHeightWithJobFilters(state, theme))
}

export function useDispatchPayload() {
  const dispatch = useDispatch()
  return React.useCallback((type, payload) => dispatch({ type, payload }), [dispatch])
}

export const useHeight = () => useSelector(state => state.layout.height)

export const useHeaderHeight = (min = 350) => Math.min(min, useHeight() * 0.45)

export const useHandleChangeRoute = () => {
  const changeRoute = useChangeRoute()
  return React.useCallback(route => () => changeRoute(route), [changeRoute])
}

export const useChangeRoute = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  return React.useCallback(
    route => {
      pathname !== route && navigate && navigate(route)
    },
    [navigate, pathname],
  )
}
