import React from 'react'
import {
  Container,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  DialogTitle,
  List,
  ListItem,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useDispatch } from 'react-redux'
import { useHandleChangeRoute } from 'data/hooks'
import { fetchTrackEvent, ProductAnalyticsEventTypes } from 'src/redux/productAnalytics'

export const PhysicianSignUpDialog = ({ openDialog, handleDialogClose, feature }) => {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const dispatch = useDispatch()
  const benefits = [
    '💸 Completely free for medical professionals!',
    '📧 Receive job alerts directly to your inbox',
    '🔍 Easily filter jobs based on your preferences',
    '📞 Access detailed job contact information',
    '📋 Keep track of conversations with multiple employers',
  ]

  const featureText = {
    favorite: 'saving jobs to your favorites list.',
    contact: 'contacting recruiters.',
    save: 'saving filters and creating email alerts.',
    more: 'gaining access to additional filters to help narrow down your job search.',
  }

  const handleChangeRoute = useHandleChangeRoute()
  const handleClick = () => {
    dispatch(
      fetchTrackEvent({
        eventName: 'physician_signup_dialog_click',
        eventType: ProductAnalyticsEventTypes.BUTTON_CLICK,
        eventData: { fromFeature: feature },
      }),
    )
    handleChangeRoute('/register/physicians')()
  }
  return (
    <Dialog
      open={openDialog}
      onClose={handleDialogClose}
      maxWidth='xs'
      fullWidth
      PaperProps={{
        sx: {
          padding: mobile ? '0rem' : '2rem', // More padding around the dialog
          position: 'relative', // Allows positioning of the close button outside the header
        },
      }}
    >
      <IconButton
        onClick={handleDialogClose}
        edge='end'
        size='small'
        sx={{ position: 'absolute', top: '10px', right: '10px' }} // Move X outside the header
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle>
        <Typography
          variant='h5'
          component='div'
          align='center'
          sx={{ textDecoration: 'underline' }}
        >
          Unlock Full Access by Signing Up!
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Container style={{ textAlign: 'center' }}>
          <Typography variant='body1' gutterBottom>
            Sign up to start {featureText[feature]} Additional benefits include:
          </Typography>
          <List>
            {benefits.map((benefit, index) => (
              <ListItem key={index} disableGutters>
                {benefit}
              </ListItem>
            ))}
          </List>
          <Typography variant='body2' color='textSecondary' gutterBottom>
            Take full advantage of personalized job opportunities and stay organized in your job
            search.
          </Typography>
        </Container>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', paddingBottom: '2rem' }}>
        <Button variant='contained' color='primary' size='large' onClick={handleClick}>
          Join Now
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PhysicianSignUpDialog
