import { createRouterReducer, createRouterMiddleware } from '@lagunovsky/redux-react-router'
import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { logger } from 'redux-logger'
import reducers from 'src/redux'
import { LOGOUT_SUCCESS, LOGOUT_FAILURE } from 'src/redux/identity'

export default (history, initialState = window.__INITIAL_STATE__) => {
  const windowIfDefined = typeof window === 'undefined' ? null : window // Build middleware. These are functions that can process the actions before they reach the store.
  const devToolsExtension = windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__ // If devTools is installed, connect to it

  const appReducer = combineReducers(
    Object.assign({}, reducers, { router: createRouterReducer(history) }),
  )

  const rootReducer = (state, action) => {
    switch (action.type) {
      case LOGOUT_SUCCESS:
        return appReducer(
          {
            layout: state.layout,
          },
          action,
        )
      case LOGOUT_FAILURE:
        return appReducer(
          {
            layout: state.layout,
          },
          action,
        )
      default:
        return appReducer(state, action)
    }
  }

  const createStoreWithMiddleware = compose(
    applyMiddleware(
      thunkMiddleware,
      logger,
      createRouterMiddleware(history),
    ),
    devToolsExtension ? devToolsExtension() : f => f,
  )(createStore)

  const store = createStoreWithMiddleware(rootReducer, initialState) // Combine all reducers and instantiate the app-wide store instance

  return store
}
