export const defaultProps = {
  name: 'Partner Interest Requests',
  columns: [
    { name: 'id', header: 'Id' },
    { name: 'firstName', header: 'First Name' },
    { name: 'lastName', header: 'Last Name' },
    { name: 'email', header: 'Email' },
    { name: 'phone', header: 'Phone' },
    {
      name: 'hospital',
      header: 'Hospital',
    },
    {
      name: 'message',
      header: 'Message',
    },
    {
      name: 'createdAt',
      header: 'Created',
    },
  ],
  searchableFields: ['email', 'firstName', 'lastName', 'hospital'],
  externalLink: {
    link: 'https://docs.google.com/spreadsheets/d/1fBSmHsVHDhoRZBr3DOrIIpNBrEapWaLw6btA9YYljVQ/edit#gid=549454791',
    title: 'Partner Outreach Logging Sheet ',
  },
}
