import { UPDATE_EMPLOYER } from 'data/constants'
import { isFunction } from 'data/helpers'

export default function legacyEmployerReducer(state = {}, action) {
  switch (action.type) {
    case UPDATE_EMPLOYER:
      return isFunction(action.payload) ? action.payload(state) : action.payload
    default:
      return state
  }
}
