import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, FormControlLabel, Checkbox } from '@mui/material'
import { isEqual } from 'lodash'
import GenericMultipleSelect from 'components/UserFields/GenericMultipleSelect'
import GenericMultiSelectCheckboxField from 'components/UserFields/GenericMultiSelectCheckboxField'
import { getStatesOptions } from 'components/UserFields/helpers'
import GenericChipField from 'components/UserFields/GenericChipField'
import GenericRadioGroup from 'components/UserFields/GenericRadioGroup'
import {
  getPracticeTypeOptions,
  AREA_ICONS,
  getAreaOptions,
  getAcademicOptions,
  getRelocationPreferenceTypeOptions,
} from 'components/UserFields/helpers'
import PreferencesImage from 'static/images/preferences-image.png'
import { PRACTICE_TYPE_HELPER_TEXT, ACADEMIC_TYPE_HELPER_TEXT } from 'data/fieldHelperText'

const PreferencesStep = ({ formData, setFormData, onNext, onBack, candidate, user, step }) => {
  const [errors, setErrors] = useState({})
  const [hasLocationPreference, setHasLocationPreference] = useState(false)
  const [initialFormData, setInitialFormData] = useState({})

  const stateOptions = getStatesOptions()

  useEffect(() => {
    if (!candidate.loading) {
      const initialData = {
        preferredStates: candidate.preferredStates || [],
        areaTypePreference: candidate.areaTypePreference || '',
        preferredPracticeTypes: candidate.preferredPracticeTypes || [],
        relocationPreference: candidate.relocationPreference,
        preferredAreaTypes: candidate.preferredAreaTypes || [],
        academicPreference: candidate.academicPreference,
      }
      setFormData(initialData)
      setInitialFormData(initialData)
      setHasLocationPreference(candidate.preferredStates.length > 0)
    }
  }, [candidate, setFormData])

  const handleInputChange = event => {
    const { name, value, type, checked } = event.target
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    })
  }

  const handleLocationPreferenceChange = event => {
    setHasLocationPreference(event.target.checked)
    if (!event.target.checked) {
      setFormData(prev => ({ ...prev, preferredStates: [] }))
    }
  }

  const handleValidation = () => {
    const newErrors = {}
    if (
      hasLocationPreference &&
      (!formData.preferredStates || formData.preferredStates.length === 0)
    ) {
      newErrors.preferredStates = 'Preferred states cannot be empty'
    }
    if (!formData.relocationPreference) newErrors.relocationPreference = true
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = () => {
    if (handleValidation()) {
      const dataToSubmit = {
        id: user.candidateId,
        relocationPreference: formData.relocationPreference,
        preferredPracticeTypes: formData.preferredPracticeTypes || [],
        preferredAreaTypes: formData.preferredAreaTypes || [],
        academicPreference: formData.academicPreference,
        preferredStates: hasLocationPreference ? formData.preferredStates : [],
      }
      const submitForm = !isEqual(formData, initialFormData)
      onNext({ dataToSubmit, step, submitForm })
    }
  }

  return (
    <Box padding={2} maxWidth={600} margin='auto'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            component='img'
            src={PreferencesImage}
            alt='Preferences'
            sx={{
              width: '40%',
              height: 'auto',
              marginBottom: 1,
              display: 'block',
              mx: 'auto',
            }}
          />
          <Box border={1} borderColor='grey.300' borderRadius={1} padding={2} mt={1}>
            {/* Location Preference */}
            <Grid container spacing={3} alignItems='center'>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={hasLocationPreference}
                      onChange={handleLocationPreferenceChange}
                    />
                  }
                  label='I have a location preference'
                  sx={{ alignItems: 'center' }}
                />
              </Grid>
              {hasLocationPreference && (
                <Grid item xs={12}>
                  <GenericMultipleSelect
                    label='Preferred States'
                    name='preferredState'
                    value={formData.preferredStates || []}
                    handleChange={e =>
                      setFormData({ ...formData, preferredStates: e.target.value })
                    }
                    options={stateOptions}
                    sx={{ width: '100%' }}
                    error={Boolean(errors.preferredStates)}
                    helperText={errors.preferredStates}
                  />
                </Grid>
              )}
            </Grid>
            {/* Willing to Relocate */}
            <Grid container spacing={3} alignItems='center' mt={1}>
              <Grid item xs={12}>
                <GenericRadioGroup
                  value={formData.relocationPreference}
                  label='Willing to Relocate'
                  name='relocationPreference'
                  handleChange={handleInputChange}
                  options={getRelocationPreferenceTypeOptions()}
                  required={true}
                  error={errors.relocationPreference}
                />
              </Grid>
            </Grid>{' '}
            {/* Area Type Preference */}
            <Grid container spacing={3} alignItems='center' mt={1}>
              <Grid item xs={12}>
                <GenericChipField
                  value={formData.preferredAreaTypes}
                  label='Area Type Preference'
                  name='preferredAreaTypes'
                  icons={AREA_ICONS}
                  options={getAreaOptions()}
                  handleChange={handleInputChange}
                />
              </Grid>
            </Grid>
            {/* Practice Type Preference */}
            <Grid container spacing={3} alignItems='center' mt={1}>
              <Grid item xs={12}>
                <GenericMultiSelectCheckboxField
                  label='Practice Type Preference'
                  name='preferredPracticeTypes'
                  value={formData.preferredPracticeTypes || []}
                  options={getPracticeTypeOptions()}
                  handleChange={handleInputChange}
                  toolTipText={PRACTICE_TYPE_HELPER_TEXT}
                />
              </Grid>
            </Grid>
            {/* Academic Preference */}
            <Grid container spacing={3} alignItems='center' mt={1}>
              <Grid item xs={12}>
                <GenericRadioGroup
                  value={formData.academicPreference}
                  toolTipText={ACADEMIC_TYPE_HELPER_TEXT}
                  label='Preferred Academic Setting'
                  name='academicPreference'
                  handleChange={handleInputChange}
                  options={getAcademicOptions()}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      {/* Navigation Buttons */}
      <Box
        sx={{
          width: '100%',
          paddingTop: '16px',
          paddingBottom: '16px',
          mt: 3,
          display: 'flex',
          justifyContent: 'center',
          gap: 2, // Ensures the buttons have a gap between them
          pb: {
            xs: 'calc(env(safe-area-inset-bottom, 20px) + 100px)', // Add safe-area padding on mobile
            sm: '20px', // Standard padding for larger screens
          },
        }}
      >
        <Button
          onClick={onBack}
          variant='outlined'
          color='primary'
          sx={{
            width: 'auto',
            maxWidth: '200px',
          }}
        >
          Back
        </Button>
        <Button
          onClick={handleSubmit}
          variant='contained'
          color='primary'
          sx={{
            width: 'auto',
            maxWidth: '200px',
          }}
        >
          Next
        </Button>
      </Box>
    </Box>
  )
}

export default PreferencesStep
