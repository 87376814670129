import React, { useState, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Typography } from '@mui/material'
import JoyRideStepper from 'components/JoyRide'
import FeatureIntroDialog from 'components/Dialog/FeatureIntroDialog'
import DefaultFiltersSetupDialog from 'components/Dialog/FeatureIntroDialog/DefaultFiltersSetupDialog'
import { useTheme, useMediaQuery } from '@mui/material'
import { JoyrideContext } from 'components/JoyRide/JoyrideContext'
import { fetchTrackEvent, ProductAnalyticsEventTypes } from 'src/redux/productAnalytics'
import { fetchCreateUserTutorialView } from 'src/redux/users'
import { USER_TUTORIAL_VIEW_ENUM } from 'src/data/enums'

const JobsPageTutorial = () => {
  const [showSavedFilterModal, setShowSavedFilterModal] = useState(true) // To show SavedFilterDialog first
  const [showIntroModal, setShowIntroModal] = useState(false) // To show FeatureIntroDialog after filter setup
  const [runTour, setRunTour] = useState(false) // Controls if the tour should start
  const { setCurrentStep } = useContext(JoyrideContext)
  const dispatch = useDispatch()
  const tutorialName = 'CandidateJobIntro'
  const tutorialId = USER_TUTORIAL_VIEW_ENUM[tutorialName]
  const theme = useTheme()
  const below_lg = useMediaQuery(theme.breakpoints.down('md'))

  // Candidate and identity data from the Redux store
  const candidate = useSelector(state => state.candidate)
  const { email, userId, candidateId } = useSelector(state => state.identity)

  // Transition from SavedFilterDialog to FeatureIntroDialog
  const handleSavedFilterComplete = () => {
    setShowSavedFilterModal(false)
    setShowIntroModal(true) // Show the FeatureIntroDialog next
  }

  // Start the Joyride tour when "Show me around" is clicked
  const startTour = () => {
    setShowIntroModal(false) // Close the FeatureIntroDialog
    setRunTour(true) // Start the Joyride tour
    // Track the event and mark the tour as seen
    dispatch(
      fetchTrackEvent({
        eventName: `tour_${tutorialName}_start`,
        eventType: ProductAnalyticsEventTypes.TOUR_EVENT,
        userId: email,
      }),
    )
  }

  const skipTour = () => {
    setShowIntroModal(false)
    setRunTour(false)
    dispatch(
      fetchCreateUserTutorialView({
        userId: userId,
        tutorial: tutorialId,
        tutorialName: tutorialName,
        candidateId: candidateId,
      }),
    )
    dispatch(
      fetchTrackEvent({
        eventName: `tour_${tutorialName}_skip`,
        eventType: ProductAnalyticsEventTypes.TOUR_EVENT,
        userId: email,
      }),
    )
  }

  const steps = [
    {
      target: '.joyride_job_list',
      content: (
        <Typography variant='body1'>
          Here you can view all available jobs. Click on a job to see more details and find the
          perfect fit for you.
        </Typography>
      ),
      disableBeacon: true,
    },
    {
      target: '.joyride_favorite',
      content: (
        <Typography variant='body1'>
          Add favorite jobs to save for later. Filter for them in the header for easy access in the
          future.
        </Typography>
      ),
      disableBeacon: true,
    },
    {
      target: '.joyride_job_content',
      content: (
        <Typography variant='body1'>
          This section shows the details of the selected job. If you need more information, check
          the hospital's link usually provided in the post.
        </Typography>
      ),
      disableBeacon: true,
    },
    {
      target: '.joyride_contact_recruiter',
      content: (
        <Typography variant='body1'>
          Get in touch with recruiters directly via email. Remember, there are no third-party
          recruiters here, so contact the hospital recruiter listed for more information.
        </Typography>
      ),
      disableBeacon: true,
      placement: 'top',
      disableScrolling: true,
    },
    {
      target: '.joyride_job_interest',
      content: (
        <Typography variant='body1'>
          Short on time? Click this button, and MyStethi will reach out to the appropriate recruiter
          on your behalf.
        </Typography>
      ),
      disableBeacon: true,
    },
    {
      target: '.joyride_rate_job',
      content: (
        <Typography variant='body1'>
          Help us improve! If you find inaccurate information in a job post, let us know, and we'll
          make sure to update it.
        </Typography>
      ),
      disableBeacon: true,
    },
    {
      target: '.joyride_add_job_notes',
      content: (
        <Typography variant='body1'>
          Want to keep track of the jobs you're applying to? Add notes for quick reference in the
          future.
        </Typography>
      ),
      disableBeacon: true,
    },
    {
      target: '.joyride_myjobs',
      content: (
        <Typography variant='body1'>
          Access and organize your jobs in progress here. You can add jobs outside of the MyStethi
          platform as well for easy tracking!
        </Typography>
      ),
      disableBeacon: true,
    },
    {
      target: '.joyride_filters',
      content: (
        <Typography variant='body1'>
          Use these filters to narrow down your job search to match your preferences.
        </Typography>
      ),
      disableBeacon: true,
      placement: 'bottom',
    },
    {
      target: '.joyride_top_level_filters',
      content: (
        <Typography variant='body1'>
          We default the top level filters to your specific profession and specialty. Feel free to
          change this at any time!
        </Typography>
      ),
      disableBeacon: true,
      placement: 'bottom',
    },
    {
      target: '.joyride_more_filters',
      content: (
        <Typography variant='body1'>
          Click here to gain access to even more ways to filter!
        </Typography>
      ),
      disableBeacon: true,
    },
    {
      target: '.joyride_save_filters',
      content: (
        <Typography variant='body1'>
          Save your filters for quick access later. You can also set up email notifications for new
          jobs that match your criteria.
        </Typography>
      ),
      disableBeacon: true,
    },
    {
      target: '.joyride_saved_filters',
      content: (
        <Typography variant='body1'>
          Access your saved filters here for faster searches in the future.
        </Typography>
      ),
      disableBeacon: true,
    },
    {
      target: '.joyride_housing',
      content: (
        <Typography variant='body1'>
          Ready to make your move? Connect with top real estate agents worldwide and enjoy exclusive
          financial rebates as a MyStethi user.
        </Typography>
      ),
      disableBeacon: true,
    },
    {
      target: '.joyride_profile_header',
      content: (
        <Typography variant='body1'>
          Manage your profile and settings here. Don't for get to add details about yourself to make
          your profile more engaging. You can also manage your privacy settings here.
        </Typography>
      ),
      disableBeacon: true,
      placement: 'left',
    },
    {
      target: '.joyride_header_alerts',
      content: (
        <Typography variant='body1'>
          Manage and view your email alerts here. <br />
          Happy searching!
        </Typography>
      ),
      disableBeacon: true,
      placement: 'left',
    },
  ]
  const handleJoyrideCallback = ({ step }) => {
    setCurrentStep(step.index)
  }

  // Show nothing until candidate data is loaded
  if (!candidate) {
    return null // Render nothing while candidate data is loading
  }
  return (
    !below_lg && (
      <>
        {/* First, show the SavedFilterDialog */}
        <DefaultFiltersSetupDialog
          open={showSavedFilterModal}
          onClose={handleSavedFilterComplete} // Move to intro after saving filter
          onSkip={handleSavedFilterComplete} // Also move to intro if they skip
        />

        {/* After SavedFilterDialog, show the FeatureIntroDialog */}
        <FeatureIntroDialog
          open={showIntroModal}
          onClose={startTour} // Call startTour when "Show me Around" is clicked
          onSkip={skipTour} // Call skipTour if the user skips the tour
          header={`🎉 You're all set!`}
          content={`Would you like to take a quick tour of MyStethi?`}
          buttonText='Show me around'
        />

        {/* Start the Joyride tour if runTour is true */}
        {runTour && (
          <JoyRideStepper
            stepperSteps={steps}
            tutorialName={tutorialName}
            callback={handleJoyrideCallback}
            run={runTour} // This prop tells Joyride to run the tour
            onSuccess={() => {
              dispatch(
                fetchCreateUserTutorialView({
                  userId: userId,
                  tutorial: tutorialId,
                  tutorialName: tutorialName,
                  candidateId: candidateId,
                }),
              )
            }}
          />
        )}
      </>
    )
  )
}

export default JobsPageTutorial
