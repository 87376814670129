import React from 'react'
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  useTheme,
} from '@mui/material'
import DoneOutlineIcon from '@mui/icons-material/DoneOutline'

const ProviderInfo = () => {
  const theme = useTheme()
  return (
    <Grid
      container
      direction='column'
      justifyContent='flex-start'
      sx={{
        border: '1px solid',
        borderTop: 'none',
        borderRadius: '0 0 20 20',
        backgroundColor: theme.palette.primary.light,
        padding: { xs: '20px', sm: '50px' },
      }}
    >
      <Grid item>
        <Typography variant='h6'>Welcome Medical Professionals</Typography>
        <Divider sx={{ height: '1px', width: '50%' }} />
      </Grid>
      <Grid item>
        <Typography variant='body1'>
          Your dedicated hub, crafted by and for healthcare professionals. Our mission is to provide
          a comprehensive, spam-free list of medical jobs.
        </Typography>
      </Grid>
      <Grid item>
        <List>
          <ListItem>
            <ListItemIcon>
              <DoneOutlineIcon style={{ color: `${theme.palette.secondary.main}` }} />
            </ListItemIcon>
            <ListItemText primary='Free to Use' />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <DoneOutlineIcon style={{ color: `${theme.palette.secondary.main}` }} />
            </ListItemIcon>
            <ListItemText primary='Simple, Intuitive Interface' />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <DoneOutlineIcon style={{ color: `${theme.palette.secondary.main}` }} />
            </ListItemIcon>
            <ListItemText primary='No Third-Party Recruiters' />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <DoneOutlineIcon style={{ color: `${theme.palette.secondary.main}` }} />
            </ListItemIcon>
            <ListItemText primary='Control Over Contact Preferences' />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <DoneOutlineIcon style={{ color: `${theme.palette.secondary.main}` }} />
            </ListItemIcon>
            <ListItemText primary='Developed by Medical Providers like Yourself' />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  )
}

export default ProviderInfo
