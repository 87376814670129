import React, { useState, useEffect } from 'react'
import { Box, Button, Grid, Alert } from '@mui/material'
import { isEqual } from 'lodash'
import { TextEditor } from 'components/TextEditor/TextEditor'
import { useGlobalNavigationBlock } from '../useGlobalNavigationBlock'
import GenericMultiLineTextField from 'components/UserFields/GenericMultiLineTextField'
import { getChangedFields } from '../helpers'

const AboutYouSection = ({ formData, onSave }) => {
  const [localFormData, setLocalFormData] = useState({
    ...formData,
  })
  const [initialData, setInitialData] = useState(formData)
  const [hasChanges, setHasChanges] = useState(false)
  useGlobalNavigationBlock(hasChanges)

  useEffect(() => {
    setLocalFormData({
      ...formData,
    })
    setInitialData(formData)
    setHasChanges(false)
  }, [formData])

  const handleInputChange = event => {
    const { name, value } = event.target
    setLocalFormData(prev => ({ ...prev, [name]: value }))
    setHasChanges(!isEqual(localFormData, initialData))
  }

  const handleEditorChange = content => {
    setLocalFormData(prev => ({ ...prev, about: content.about, aboutRaw: content.aboutRaw }))
    setHasChanges(!isEqual(localFormData, initialData))
  }


  const handleSave = () => {
    const changedFields = getChangedFields(localFormData, initialData)

    if (Object.keys(changedFields).length > 0) {
      onSave({
        ...changedFields,
        id: formData.id,
      })
      setInitialData(localFormData)
      setHasChanges(false)
    }
  }

  return (
    <Box>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {hasChanges && (
          <Grid item xs={12}>
            <Alert severity='info' sx={{ mb: 2 }}>
              You have unsaved changes in this section. Please save before navigating away.
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid item xs={12}>
            <TextEditor
              label='About You'
              rows={8}
              initialValue={localFormData.about || ''}
              placeholder='Share your journey, skills, and values that set you apart. Highlight accomplishments and experiences. Use paragraphs or bullet points to make it recruiter-friendly!'
              onChange={handleEditorChange}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <GenericMultiLineTextField
            name='aboutJob'
            label='Describe your ideal job'
            placeholder='Describe your ideal role, work environment, location, and career goals. Be specific to help recruiters find the best match for you.'
            value={localFormData.aboutJob}
            handleChange={handleInputChange}
          />
        </Grid>
      </Grid>

      <Button
        variant='contained'
        color='primary'
        onClick={handleSave}
        disabled={!hasChanges}
        sx={{ mt: 3 }}
      >
        Save
      </Button>
    </Box>
  )
}

export default AboutYouSection
