import React, { createContext, useState } from 'react'

export const JoyrideContext = createContext()

export const JoyrideProvider = ({ children }) => {
  const [currentStep, setCurrentStep] = useState('')

  return (
    <JoyrideContext.Provider value={{ currentStep, setCurrentStep }}>
      {children}
    </JoyrideContext.Provider>
  )
}
