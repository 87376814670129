import React from 'react'
import {
  CircularProgress,
  Button,
  TextField,
  Divider,
  Grid,
  Paper,
  Typography,
  useTheme,
  Link,
} from '@mui/material'
import { size } from 'lodash'
import useComponentSize from '@rehooks/component-size'
import { useSelector } from 'react-redux'
import { useContentHeight, useHandleChangeRoute } from 'data/hooks'
import { useWidth } from 'data/layout/actions'
import { useStyles } from 'common/styles'
import { useHandleSendContactEmail } from 'data/contact/actions'
import { validateEmail } from 'data/helpers'

export default function ContactUs() {
  const buttonRef = React.useRef(null)
  const ref = React.useRef(null)
  const width = useWidth()
  let componentSize = useComponentSize(ref)
  const marginTop = (useContentHeight() - (componentSize.height + 8)) / 2
  const classes = useStyles({ marginTop, width })

  const { contactFormSubmitted } = useSelector(state => state.contact)

  const [values, setValues] = React.useState({ firstName: null, email: null })
  const [btnDisabled, setBtnDisabled] = React.useState(true)
  const [{ errors, loading }, setState] = React.useState({
    errors: {},
    loading: false,
  })

  const handleChangeRoute = useHandleChangeRoute()
  const handleSendContactEmail = useHandleSendContactEmail()

  const handleChange = React.useCallback(
    name => e => {
      const value = e && e.target && e.target.value
      if (name === 'email') {
        if (!validateEmail(value)) {
          errors.email = 'Invalid email format.'
        } else {
          delete errors.email
        }
      }
      setValues(prev => ({ ...prev, [name]: value }))
      setState(prev => ({ ...prev, errors: errors }))
    },
    [setState, setValues],
  )

  const handleBlur = () => {
    if (!values.email) {
      setBtnDisabled(true)
      errors.email = 'Email is Required'
    } else if (!validateEmail(values.email)) {
      errors.email = 'Invalid email format.'
      setBtnDisabled(true)
    } else if (values.email) {
      delete errors.email
    }
    if (!values.firstName) {
      setBtnDisabled(true)
      errors.firstName = 'First name is Required'
    } else if (values.firstName) {
      delete errors.firstName
    }
    if (!values.lastName) {
      setBtnDisabled(true)
      errors.lastName = 'Last name is Required'
    } else if (values.lastName) {
      delete errors.lastName
    }
    if (size(errors) === 0) {
      setBtnDisabled(false)
    }
    setState(prev => ({ ...prev, errors: errors }))
  }

  const { layout } = useTheme()
  if (contactFormSubmitted) {
    return (
      <Paper ref={ref} className={classes.supportContainer}>
        <Grid container alignItems='center' spacing={1} direction='column'>
          <Grid item xs={12}>
            <Typography align='center' variant='h4'>
              Thanks for contacting us!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        <Grid
          container
          alignItems='center'
          spacing={1}
          style={{ padding: '5px 5px', textAlign: 'center' }}
        >
          <Grid item xs={12}>
            <Typography variant='body2'>Someone will reply back to you within 48 hours!</Typography>
            <Link
              onClick={handleChangeRoute('/')}
              color='inherit'
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              underline='hover'
            >
              <Typography variant='body1'>Go to home page</Typography>
            </Link>
          </Grid>
        </Grid>
      </Paper>
    )
  } else {
    return (
      <Grid container justifyContent='center' spacing={1} direction='column'>
        <Paper ref={ref} className={classes.supportContainer} elevation={4}>
          <Grid item xs={12} direction='column'>
            <Typography align='center' variant='h5'>
              Send MyStethi a message!
            </Typography>
            <Divider />
          </Grid>
          <Grid container alignItems='center' rowSpacing={2} direction='column'>
            {loading && (
              <CircularProgress size={layout.progressSize} className={classes.submitProgress} />
            )}
            <Grid item>
              <Typography variant='body1'>
                <p>
                  Interested in learning more? Send us a message, and we'll get back to you within
                  48 hours
                </p>
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                variant='outlined'
                id='email'
                label='Email'
                required
                fullWidth
                placeholder='Enter your email'
                type='email'
                onBlur={handleBlur}
                onChange={handleChange('email')}
                error={errors['email'] !== undefined}
                helperText={errors['email'] || ' '}
              />
              <TextField
                variant='outlined'
                id='firstName'
                label='First Name'
                required
                fullWidth
                placeholder='First Name'
                onBlur={handleBlur}
                onChange={handleChange('firstName')}
                error={errors['firstName'] !== undefined}
                helperText={errors['firstName'] || ' '}
              />
              <TextField
                variant='outlined'
                id='lastName'
                label='Last Name'
                required
                fullWidth
                placeholder='Last Name'
                onBlur={handleBlur}
                onChange={handleChange('lastName')}
                error={errors['lastName'] !== undefined}
                helperText={errors['lastName'] || ' '}
              />
              <TextField
                id='message'
                required
                label='More information'
                placeholder='Connect with Us!'
                multiline
                rows={3}
                maxRows={10}
                variant='outlined'
                onBlur={handleBlur}
                onChange={handleChange('message')}
                fullWidth
                error={errors['message'] !== undefined}
                helperText={errors['message'] || ' '}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                ref={buttonRef}
                variant='contained'
                color='secondary'
                onClick={handleSendContactEmail({ values, setState })}
                disabled={btnDisabled}
              >
                Submit
              </Button>
            </Grid>
            <Grid item>
              <Typography variant='body2'>
                If you prefer, you can email us directly at{' '}
                <a href={'mailto:contact@mystethi.com?subject=MyStethi Support'}>
                  contact@mystethi.com
                </a>
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    )
  }
}
