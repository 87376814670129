import { showSnackbar, snackbarTypes } from 'src/redux/snackbar'
import { fetchDispatch } from 'data/helpers'

export const GET_NEWSLETTER_SUBSCRIPTIONS_LOADING =
  'newsletterSubscription/GET_NEWSLETTER_SUBSCRIPTION_LOADING'
export const GET_NEWSLETTER_SUBSCRIPTIONS_SUCCESS =
  'newsletterSubscription/GET_NEWSLETTER_SUBSCRIPTION_SUCCESS'
export const GET_NEWSLETTER_SUBSCRIPTIONS_FAILURE =
  'newsletterSubscription/GET_NEWSLETTER_SUBSCRIPTION_FAILURE'

export const getNewsLetterSubscriptionsLoading = () => ({
  type: GET_NEWSLETTER_SUBSCRIPTIONS_LOADING,
})
export const getNewsLetterSubscriptionsSuccess = payload => ({
  type: GET_NEWSLETTER_SUBSCRIPTIONS_SUCCESS,
  payload,
})
export const getNewsLetterSubscriptionsFailure = payload => ({
  type: GET_NEWSLETTER_SUBSCRIPTIONS_FAILURE,
  payload,
})

export const fetchAllNewsletterSubscriptions = () => {
  return async dispatch => {
    dispatch(getNewsLetterSubscriptionsLoading())
    try {
      const response = await fetchDispatch({
        path: '/api/newsletter_subscriptions/list',
        method: 'GET',
      })

      dispatch(getNewsLetterSubscriptionsSuccess(response))
    } catch (error) {
      dispatch(
        showSnackbar(
          'Sorry! We encountered an error loading newsletter subscriptions',
          snackbarTypes.ERROR,
        ),
      )
      dispatch(getNewsLetterSubscriptionsFailure(error.response))
    }
  }
}

const initialState = {
  loading: false,
  errors: [],
  newsletterSubscriptions: [],
}

export const newsletterSubscriptionReducer = (state = initialState, action) => {
  const { payload } = action

  switch (action.type) {
    case GET_NEWSLETTER_SUBSCRIPTIONS_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_NEWSLETTER_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        errors: [],
        loading: false,
        newsletterSubscriptions: payload.newsletterSubscriptions,
      }
    case GET_NEWSLETTER_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        loading: false,
      }
    default:
      return {
        ...state,
      }
  }
}
