import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { FormHelperText, Box, FormLabel, useTheme } from '@mui/material'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

export const TextEditor = ({
  initialValue,
  isReadOnly,
  onChange,
  placeholder,
  required,
  error,
  helperText,
  label,
  rows, // New rows prop
}) => {
  const theme = useTheme()
  const editorRef = useRef(null) // To store editor instance
  const isMounted = useRef(false) // Track if component is mounted

  useEffect(() => {
    isMounted.current = true

    return () => {
      isMounted.current = false
      if (editorRef.current) {
        editorRef.current
          .destroy()
          .then(() => {
            editorRef.current = null
          })
          .catch(error => {
            console.error('Error during CKEditor cleanup:', error)
          })
      }
    }
  }, [])

  const handleEditorReady = editor => {
    if (!isMounted.current) return
    editorRef.current = editor

    // Ensure initialValue is a valid string
    const validInitialValue = initialValue || '' // Fallback to an empty string if undefined or null
    editor.setData(validInitialValue)

    if (isReadOnly) {
      editor.ui.view.editable.element.style.backgroundColor = 'transparent'
      editor.ui.view.editable.element.style.borderColor = 'transparent'
      editor.ui.view.toolbar.element.style.display = 'none'
    }

    // Calculate height from rows
    const lineHeight = 20
    const height = rows * lineHeight
    editor.ui.view.editable.element.style.height = `${height}px`
  }

  return (
    <Box>
      <FormLabel
        id='editor-label'
        sx={{
          color: theme.palette.primary.main,
          display: 'block',
          mb: 1,
          fontSize: '0.875rem', // Consistent font size
          fontWeight: 500, // Match styling
        }}
      >
        {label}
        {required && <span style={{ marginLeft: '4px' }}> *</span>}
      </FormLabel>

      <CKEditor
        editor={ClassicEditor}
        data={initialValue || ''}
        config={{
          placeholder,
          toolbar: {
            shouldNotGroupWhenFull: true,
          },
        }}
        onReady={handleEditorReady}
        onChange={(event, editor) => {
          const data = editor.getData()
          onChange({
            about: data,
            aboutRaw: data,
          })
        }}
        disabled={isReadOnly}
      />

      {/* Error Handling */}
      {helperText && (
        <FormHelperText error={error} sx={{ mt: 1 }}>
          {helperText}
        </FormHelperText>
      )}
    </Box>
  )
}

TextEditor.defaultProps = {
  isReadOnly: false,
  onChange: () => {},
  initialValue: '',
  rows: 10,
}

TextEditor.propTypes = {
  isReadOnly: PropTypes.bool,
  onChange: PropTypes.func,
  initialValue: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  rows: PropTypes.number,
}
