import React from 'react'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import SchoolIcon from '@mui/icons-material/School'
import ParkIcon from '@mui/icons-material/Park'
import LocationCityIcon from '@mui/icons-material/LocationCity'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import HomeIcon from '@mui/icons-material/Home'
import LinkIcon from '@mui/icons-material/Link'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import WorkIcon from '@mui/icons-material/Work'
import LocalHospitalIcon from '@mui/icons-material/LocalHospital'
import ArticleIcon from '@mui/icons-material/Article'
import { checkEmpty, imageAddress } from 'data/helpers'
import ProfileImagePlaceholder from 'static/images/profile-image-placeholder.jpg'
import HospitalPlaceHolder from 'static/images/hospital_placeholder.svg'
import { ACADEMIC_ENUM, PRACTICE_TYPE_ENUM } from '../../data/enums'
import { toMoney } from '../../data/helpers'

export const getLocation = (city, state) => {
  let location
  if (city) {
    if (state) {
      location = city + ', ' + state
    } else {
      location = city
    }
  } else if (state) {
    location = state
  }
  return location
}

export const getSpecialty = (specialties = [], subspecialties = []) => {
  // Map the specialties to extract the 'name' field and join them into a string
  let specialtyCombo = specialties.map(specialty => specialty.name).join(' or ')

  // Map the subspecialties to extract the 'name' field and join them into a string
  if (subspecialties.length > 0) {
    const subspecialtyCombo = subspecialties.map(subspecialty => subspecialty.name).join(' or ')
    specialtyCombo = specialtyCombo + ': ' + subspecialtyCombo
  }

  return specialtyCombo
}

export const getHospitalImage = item => {
  const { key, fileName } = checkEmpty(item.image) ? {} : item.image
  const image = !checkEmpty(key) ? imageAddress(key, fileName) : HospitalPlaceHolder
  return image
}

export const getSalary = (salaryType, salaryMin, salaryMax) => {
  if (salaryMin && salaryMax) {
    return `${toMoney(salaryMin)} - ${toMoney(salaryMax)}${salaryType ? ` ${salaryType}` : ''}`
  } else if (salaryMin) {
    return `${toMoney(salaryMin)}${salaryType ? ` ${salaryType}` : ''}`
  } else if (salaryMax) {
    return `${toMoney(salaryMax)}${salaryType ? ` ${salaryType}` : ''}`
  }
  return null
}

export const getAcademic = academic => {
  if (ACADEMIC_ENUM[academic] === 'Yes') {
    return 'Is Academic'
  } else if (ACADEMIC_ENUM[academic] === 'No') {
    return 'Not Academic'
  } else if (ACADEMIC_ENUM[academic] === 'Hybrid') {
    return 'Hybrid'
  } else return 'Academic Unknown'
}

export const getJobListItems = (job, signedIn) => {
  const fields = []
  Object.keys(job).forEach(key => {
    switch (key) {
      case 'specialty':
        fields.push({
          text: getSpecialty(job.specialties, job.subspecialties),
          icon: <LocalHospitalIcon />,
          show: job.specialties.length > 0,
        })
        break
      case 'city' || 'state':
        fields.push({
          text: getLocation(job.city, job.state),
          icon: <LocationOnIcon />,
          show: getLocation(job.city, job.state) !== null,
        })
        break
      case 'loanForgiveness':
        fields.push({
          text: 'Loan Forgiveness',
          value: job.loanForgiveness,
          show: job.loanForgiveness === true,
        })
        break
      case 'pslfCompatible':
        fields.push({
          text: 'PSLF Compatible',
          value: job.pslfCompatible,
          show: job.pslfCompatible === true,
        })
        break
      case 'salary':
        fields.push({
          text: getSalary(job.salaryType, job.salaryMin, job.salaryMax),
          icon: <AttachMoneyIcon />,
          show: getSalary(job.salaryType, job.salaryMin, job.salaryMax) !== null,
        })
        break
      case 'academic':
        fields.push({
          text: getAcademic(job.academic),
          icon: <SchoolIcon />,
          show: job.academic !== 'No' && job.academic !== null,
        })
        break
      case 'area':
        fields.push({
          text: job.area,
          icon: getAreaIcon(job.area),
          show: job.area !== null,
        })
        break
      case 'practiceType':
        fields.push({
          text: PRACTICE_TYPE_ENUM[job.practiceType],
          icon: <AccessTimeIcon />,
          show: job.practiceType !== null,
        })
        break
      case 'visas':
        fields.push({
          text: job.visas?.map(visa => visa.name).join(', '),
          icon: <ArticleIcon />,
          show: Array.isArray(job.visas) && job.visas.length > 0,
        })
        break
      case 'link':
        fields.push({
          text: `${job.employerName} Job Link`,
          icon: <LinkIcon />,
          link: job.link,
          isLink: true,
          show: job.link !== null && signedIn,
        })
        break
      case 'candidateType':
        fields.push({
          text: job.candidateType?.name, // Display profession name
          icon: <WorkIcon />, // Icon for profession
          show: job.candidateType?.name !== null,
        })
        break
    }
  })

  return fields
}

export const getProfileImage = profileImage => {
  const { key, fileName } = checkEmpty(profileImage) ? {} : profileImage
  const image = !checkEmpty(key) ? imageAddress(key, fileName) : ProfileImagePlaceholder
  return image
}

export const getAreaIcon = area => {
  if (area === 'Rural') return <ParkIcon />
  else if (area === 'Urban') return <LocationCityIcon />
  else if (area === 'Suburban') return <HomeIcon />
  else return <HomeIcon />
}

export const getImage = image => {
  const { key, fileName } = checkEmpty(image) ? {} : image
  return !checkEmpty(key) ? imageAddress(key, fileName) : undefined
}

export const convertNewlinesToBreaks = text => {
  if (text) {
    return text.replace(/\n/g, '<br>')
  }
}
