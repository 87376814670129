import React from 'react'
import { Autocomplete, TextField, useTheme } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { States } from 'data/constants'

const StateField = ({ name, label, value, disabled, handleChange }) => {
  const theme = useTheme()
  const stateOptions = Object.keys(States).map(state => ({ id: state, label: States[state] }))
  const currentState = stateOptions.find(state => state.id === value)
  return (
    <Autocomplete
      disablePortal
      id={name}
      options={stateOptions}
      disabled={disabled}
      popupIcon={disabled ? null : <ArrowDropDownIcon />}
      renderInput={params => (
        <TextField
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: theme.palette.primary.dark,
            },
            '& fieldset': disabled && { border: 'none' },
          }}
          {...params}
          label={label}
        />
      )}
      value={currentState || null}
      getOptionLabel={option => option.label || ''}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      onChange={(event, newValue) => handleChange({ target: { name: name, value: newValue.id } })}
      size='small'
      fullWidth
      multiple={false}
    />
  )
}

export default StateField
