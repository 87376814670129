import React, { useState, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Grid, useTheme } from '@mui/material'
import { updateSelectedFilters, getSelectedFiltersFromQueryString } from './helpers'
import { useIdentity } from 'pages/Identity/Login'
import { useStyles } from './styles'
import { FilterHeader } from './FilterHeader'
import { fetchUserFilterAlerts, fetchFilterTypes } from '../../redux/filters'
import { filterDefinitions } from './filterDefinitions'

export const PublicFilters = ({ filterValues, listType, applyFilters }) => {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const styles = useStyles({ mobile })
  let ref = useRef(null)
  const { employerId, isEmployerAdmin, userId, employerUserId, candidateId } =
    useIdentity()
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedFilters, setSelectedFilters] = useState({})
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchFilterTypes())
  }, [])

  useEffect(() => {
    const filtersFromQuery = getSelectedFiltersFromQueryString(searchParams, filterValues)
    setSelectedFilters(prevFilters =>
      JSON.stringify(prevFilters) !== JSON.stringify(filtersFromQuery)
        ? filtersFromQuery
        : prevFilters,
    )
  }, [filterValues, searchParams])

  useEffect(() => {
    applyFilters({ searchParams, employerId, employerUserId, candidateId, isEmployerAdmin })
    Object.keys(selectedFilters).forEach(key => {
      const filterDef = filterDefinitions.find(filter => filter.name === key)
      if (filterDef && filterDef.showMoreFilters) {
        return true
      }
    })
  }, [selectedFilters])

  useEffect(() => {
    dispatch(fetchUserFilterAlerts(userId))
  }, [userId])

  const handleFilterChange = selectedFilter => {
    const { filterDefinition, selectedValues } = selectedFilter

    const newSearchParams = filterDefinition.clearOthers
      ? new URLSearchParams()
      : new URLSearchParams(searchParams)

    if (selectedValues.length > 0) {
      const valueString = filterDefinition.isYesNo
        ? selectedValues[0].value === '1'
        : selectedValues.map(x => x.value).join('|')

      newSearchParams.set(filterDefinition.queryStringKey, valueString)
    } else {
      newSearchParams.delete(filterDefinition.queryStringKey)
    }

    setSearchParams(newSearchParams)

    setSelectedFilters(
      selectedValues.length > 0 ? updateSelectedFilters(selectedFilters, selectedFilter) : {},
    )
  }

  return (
    <Grid
      container
      ref={ref}
      justifyContent='center'
      sx={{
        overflow: 'hidden',
        padding: 0,
      }}
    >
      <Grid
        container
        className={`filterWrapper ${styles.filterHeaderWrapper}`}
        sx={{ overflow: 'hidden' }}
      >
        <Grid
          container
          item
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          sx={{ maxWidth: '70em', overflow: 'hidden' }}
        >
          <FilterHeader
            listType={listType}
            filterValues={filterValues}
            handleFilterChange={handleFilterChange}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
