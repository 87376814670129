import React, { useState } from 'react'
import {
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  InputLabel,
  Box,
  Chip,
  Typography,
  Drawer,
  Button,
  IconButton,
  useTheme,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ClearIcon from '@mui/icons-material/Clear'
import { onFilterDropDownChange } from './helpers' // Ensure the import path is correct

export const MobileFilterDropdown = ({ filterDefinition, options, onChange, checked }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  checked = checked || []
  const theme = useTheme()
  let disabledProps = {}
  if (options.length === 0) {
    disabledProps = {
      disabled: true,
      sx: {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.action.disabled,
        },
        '& .MuiInputBase-input.Mui-disabled': {
          color: theme.palette.text.disabled,
        },
      },
    }
  }
  const clearFilter = () => {
    onChange({
      filterDefinition,
      selectedValues: [],
    })
  }
  const isMultiple = filterDefinition.isMultiple

  const toggleDrawer = open => () => {
    setIsOpen(open)
  }

  const handleSelectChange = event => {
    onFilterDropDownChange(event, options, filterDefinition, onChange)
    setDropdownOpen(false) // Close the dropdown after selection
  }

  const handleDelete = valueToRemove => event => {
    event.stopPropagation() // Ensure the event doesn't bubble up and open the dropdown
    const newChecked = checked.filter(item => item.name !== valueToRemove)
    onChange({
      filterDefinition,
      selectedValues: newChecked,
    })
  }

  const filtersSelectedCount = checked.length
  return (
    <div style={{ width: '100%' }}>
      <Button
        onClick={toggleDrawer(true)}
        variant='outlined'
        size='small'
        disabled={options.length === 0}
        sx={{
          width: '100%',
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary,
          textTransform: 'none',
          marginBottom: 0,
          borderRadius: '4px',
          borderColor: theme.palette.divider,
          '&:hover': {
            backgroundColor: 'white',
          },
        }}
      >
        {filterDefinition.displayName} {filtersSelectedCount > 0 ? `(${filtersSelectedCount})` : ''}
      </Button>
      <Drawer
        anchor='bottom'
        open={isOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            padding: 2,
          },
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant='h6'>{filterDefinition.displayName}</Typography>
          <IconButton onClick={toggleDrawer(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ width: '100%' }}>
          <FormControl fullWidth {...disabledProps}>
            <InputLabel
              sx={{
                fontSize: '.9rem',
                color: theme.palette.text.primary,
                '&.MuiInputLabel-shrink': {
                  color: theme.palette.primary.main,
                },
              }}
              id={`label=${filterDefinition.name}`}
            >
              {filterDefinition.displayName}
            </InputLabel>
            <Select
              labelId={`label-${filterDefinition.name}`}
              id={filterDefinition.name}
              value={checked.map(item => item.name)}
              multiple={isMultiple}
              onChange={handleSelectChange}
              displayEmpty
              defaultValue={[]}
              renderValue={selected =>
                isMultiple ? (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map(value => (
                      <Chip
                        sx={{
                          height: '24px',
                          '& .MuiChip-label': {
                            fontSize: '.9rem',
                          },
                          backgroundColor: theme.palette.primary.main,
                          color: theme.palette.primary.contrastText,
                        }}
                        key={value}
                        label={value}
                        onDelete={handleDelete(value)}
                        deleteIcon={<ClearIcon sx={{ fontSize: '12px' }} />}
                        onMouseDown={e => e.stopPropagation()} // Prevent the dropdown from opening on delete icon click
                      />
                    ))}
                  </Box>
                ) : (
                  <Typography sx={{ color: theme.palette.text.primary, fontSize: '.8rem' }}>
                    {selected[0]}
                  </Typography>
                )
              }
              sx={{
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
                '& .MuiSelect-select': {
                  backgroundColor: theme.palette.background.paper,
                  color: theme.palette.text.primary,
                  paddingRight: '16px', // Ensure equal padding
                  paddingLeft: '16px',
                },
              }}
              MenuProps={{
                onClose: () => setDropdownOpen(false), // Close the dropdown after selection
              }}
              open={dropdownOpen}
              onClose={() => setDropdownOpen(false)}
              onOpen={() => setDropdownOpen(true)}
            >
              {options.map(({ name, value }) => (
                <MenuItem
                  key={`${value}-${name}`}
                  value={name}
                  sx={{
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                  }}
                >
                  <Checkbox checked={checked.some(item => item.name === name)} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
            <Button
              onClick={clearFilter}
              variant='outlined'
              color='secondary'
              sx={{
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
              }}
            >
              Clear
            </Button>
          </Box>
        </Box>
      </Drawer>
    </div>
  )
}
