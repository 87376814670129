import axios from 'axios'
import { buildFormData } from 'src/redux/helpers'
import { showSnackbar, snackbarTypes } from 'src/redux/snackbar'
import { fetchDispatch } from 'data/helpers'
import { fetchTrackEvent, ProductAnalyticsEventTypes } from 'src/redux/productAnalytics'
import { capitalizeWords } from 'common/helpers'

export const CREATE_CANDIDATE_SUCCESS = 'candidate/CREATE_CANDIDATE_SUCCESS'
export const CREATE_CANDIDATE_FAILURE = 'candidate/CREATE_CANDIDATE_FAILURE'
export const GET_CANDIDATE_LOADING = 'candidate/GET_CANDIDATE_LOADING'
export const GET_CANDIDATE_SUCCESS = 'candidate/GET_CANDIDATE_SUCCESS'
export const GET_CANDIDATE_FAILURE = 'candidate/GET_CANDIDATE_FAILURE'
export const UPDATE_CANDIDATE_LOADING = 'candidate/UPDATE_CANDIDATE_LOADING'
export const UPDATE_CANDIDATE_SUCCESS = 'candidate/UPDATE_CANDIDATE_SUCCESS'
export const UPDATE_CANDIDATE_FAILURE = 'candidate/UPDATE_CANDIDATE_FAILURE'
export const UPDATE_CANDIDATE_FILE_LOADING = 'candidate/UPDATE_CANDIDATE_FILE_LOADING'
export const UPDATE_CANDIDATE_FILE_SUCCESS = 'candidate/UPDATE_CANDIDATE_FILE_SUCCESS'
export const UPDATE_CANDIDATE_FILE_FAILURE = 'candidate/UPDATE_CANDIDATE_FILE_FAILURE'
export const GET_CANDIDATES_LOADING = 'candidate/GET_CANDIDATES_LOADING'
export const GET_CANDIDATES_SUCCESS = 'candidate/GET_CANDIDATES_SUCCESS'
export const GET_CANDIDATES_FAILURE = 'candidate/GET_CANDIDATES_FAILURE'
export const UPDATE_CANDIDATE_USER_TUTORIAL_LOADING =
  'employerUser/UPDATE_CANDIDATE_USER_TUTORIAL_LOADING'
export const UPDATE_CANDIDATE_USER_TUTORIAL_SUCCESS =
  'employerUser/UPDATE_CANDIDATE_USER_TUTORIAL_SUCCESS'
export const UPDATE_CANDIDATE_USER_TUTORIAL_FAILURE =
  'employerUser/UPDATE_CANDIDATE_USER_TUTORIAL_FAILURE'

const mapPhysicianData = physicians =>
  physicians.map(physician => ({
    ...physician,
    firstName: physician.user.firstName,
    lastName: physician.user.lastName,
    city: physician.user.city,
    state: physician.user.state,
    profileImage: physician.user.profileImage,
    listingTitle: `${physician.user.firstName} ${physician.user.lastName}`,
    listingSubLabel:
      physician.specialties.length > 0
        ? `${physician.specialties[0].name}${physician.subspecialties?.length > 0 ? ' | ' + physician.subspecialties[0].name : ''}`
        : '',
    image: physician.user.profileImage,
    phone: physician.user.phone,
    email: physician.user.email,
    showPhone: physician.user.showPhone,
    showEmail: physician.user.showEmail,
    createdAt: physician.user.createdAt,
    lastLoginAt: physician.user.lastLoginAt,
    emailVerifiedAt: physician.user.emailVerifiedAt,
    candidateType: physician.candidateTypeInfo,
    tutorialViews: (physician.userTutorialViews || []).map(view => view.tutorial),
  }))

const cleanPhysicianData = physician => {
  let cleanedPhysician = { ...physician }
  if (cleanedPhysician.firstName) {
    cleanedPhysician.firstName = capitalizeWords(cleanedPhysician.firstName.trim())
  }
  if (cleanedPhysician.lastName) {
    cleanedPhysician.lastName = capitalizeWords(cleanedPhysician.lastName.trim())
  }
  if (cleanedPhysician.city) {
    cleanedPhysician.city = capitalizeWords(cleanedPhysician.city.trim())
  }
  if (cleanedPhysician.preferredCity) {
    cleanedPhysician.preferredCity = capitalizeWords(cleanedPhysician.preferredCity.trim())
  }
  if (cleanedPhysician.email) {
    cleanedPhysician.email = cleanedPhysician.email.trim().toLowerCase()
  }
  if (cleanedPhysician.residency) {
    cleanedPhysician.residency = capitalizeWords(cleanedPhysician.residency.trim())
  }
  return cleanedPhysician
}

export const getCandidateLoading = () => ({ type: GET_CANDIDATE_LOADING })
export const getCandidateSuccess = payload => ({ type: GET_CANDIDATE_SUCCESS, payload })
export const getCandidateFailure = payload => ({ type: GET_CANDIDATE_FAILURE, payload })
export const updateCandidateLoading = ({ photoLoading, resumeLoading }) => ({
  type: UPDATE_CANDIDATE_LOADING,
  photoLoading,
  resumeLoading,
})
export const updateCandidateSuccess = payload => ({
  type: UPDATE_CANDIDATE_SUCCESS,
  payload,
  isPhotoLoading: false,
  isResumeLoading: false,
})
export const updateCandidateFailure = payload => ({
  type: UPDATE_CANDIDATE_FAILURE,
  payload,
  isPhotoLoading: false,
  isResumeLoading: false,
})
export const getCandidatesLoading = () => ({ type: GET_CANDIDATES_LOADING })
export const getCandidatesSuccess = payload => ({ type: GET_CANDIDATES_SUCCESS, payload })
export const getCandidatesFailure = payload => ({ type: GET_CANDIDATES_FAILURE, payload })

export const updateCandidateUserTutorialLoading = () => ({
  type: UPDATE_CANDIDATE_USER_TUTORIAL_LOADING,
})
export const updateCandidateUserTutorialSuccess = tutorial => ({
  type: UPDATE_CANDIDATE_USER_TUTORIAL_SUCCESS,
  payload: tutorial,
})
export const updateCandidateUserTutorialFailure = () => ({
  type: UPDATE_CANDIDATE_USER_TUTORIAL_FAILURE,
})
export const updateCandidateFileLoading = ({ photoLoading, resumeLoading }) => ({
  type: UPDATE_CANDIDATE_FILE_LOADING,
  photoLoading,
  resumeLoading,
})
export const updateCandidateFileSuccess = payload => ({
  type: UPDATE_CANDIDATE_FILE_SUCCESS,
  payload,
})
export const updateCandidateFileFailure = payload => ({
  type: UPDATE_CANDIDATE_FILE_FAILURE,
  payload,
})

export const fetchGetCandidate = id => {
  return async dispatch => {
    dispatch(getCandidateLoading())
    try {
      const response = await axios({
        url: `/api/candidates/${id}`,
        method: 'GET',
      })
      dispatch(getCandidateSuccess(response.data.candidate))
    } catch (error) {
      dispatch(showSnackbar('Error retrieving user data.', snackbarTypes.ERROR))
      dispatch(getCandidateFailure(error.response.data))
    }
  }
}

export const fetchUpdatePassword = data => {
  return fetchUpdateCandidate(
    data,
    'Successfully updated password.',
    'An error occurred updating your password.',
  )
}

export const fetchUpdateCandidate = (data, successMessage, failureMessage, onSuccess) => {
  return async dispatch => {
    const successMsg = successMessage || 'Successfully updated profile.'
    const failureMsg = failureMessage || 'An error occurred updating your profile'
    const isPhotoUpload = !!data.profileImageFile
    const isResumeUpload = !!data.resumeFile
    if (isPhotoUpload) {
      dispatch(
        fetchUpdateCandidateFile({
          data: buildFormData({ profileImageFile: data.profileImageFile, id: data.id }),
          isPhotoUpload: true,
        }),
      )
      return
    }
    if (isResumeUpload) {
      dispatch(
        fetchUpdateCandidateFile({
          data: buildFormData({ resumeFile: data.resumeFile, id: data.id }),
          isResumeUpload: true,
        }),
      )
      return
    }
    dispatch(updateCandidateLoading({ photoLoading: isPhotoUpload, resumeLoading: isResumeUpload }))
    try {
      const response = await axios({
        url: `/api/candidates/${data.id}`,
        method: 'PUT',
        data: cleanPhysicianData(data),
      })
      if (!data.suppressSnackbar) {
        dispatch(showSnackbar(successMsg, snackbarTypes.SUCCESS))
      }
      dispatch(updateCandidateSuccess(response.data.candidate))
      dispatch(
        fetchTrackEvent({
          eventName: 'candidate_profile_update_success',
          eventType: ProductAnalyticsEventTypes.ACTION,
          userId: response.data.candidate.user.email,
        }),
      )
      if (onSuccess && onSuccess.function) {
        onSuccess.function(onSuccess.params)
      }
    } catch (error) {
      if (!data.suppressSnackbar) {
        dispatch(showSnackbar(failureMsg, snackbarTypes.ERROR))
      }
      dispatch(updateCandidateFailure(error.response.data))
      throw error
    }
  }
}

export const fetchUpdateCandidateFile = ({ data, isPhotoUpload, isResumeUpload }) => {
  return async dispatch => {
    dispatch(
      updateCandidateFileLoading({ photoLoading: isPhotoUpload, resumeLoading: isResumeUpload }),
    )
    try {
      const response = await axios({
        url: `/api/candidates/${data.get('id')}/file`,
        method: 'PUT',
        data: data,
      })
      dispatch(showSnackbar('Successfully uploaded file', snackbarTypes.SUCCESS))
      dispatch(updateCandidateFileSuccess(response.data.candidate))
      dispatch(
        fetchTrackEvent({
          eventName: 'candidate_profile_update_success',
          eventType: ProductAnalyticsEventTypes.ACTION,
          userId: response.data.candidate.user.email,
        }),
      )
    } catch (error) {
      dispatch(showSnackbar('There was an issue uploading your file', snackbarTypes.ERROR))
      dispatch(updateCandidateFileFailure(error.response.data))
      throw error
    }
  }
}

export const fetchAllCandidates = searchParams => {
  return async dispatch => {
    searchParams = new URLSearchParams(searchParams)
    dispatch(getCandidatesLoading())
    try {
      const response = await fetchDispatch({
        path: `/api/candidates?${searchParams.toString()}`,
        method: 'GET',
      })

      dispatch(getCandidatesSuccess(response))
    } catch (error) {
      dispatch(showSnackbar('Error retrieving user data.', snackbarTypes.ERROR))
      dispatch(getCandidatesFailure(error.response))
    }
  }
}

export const fetchUpdateCandidateUserTutorial = tutorial => {
  return async dispatch => {
    dispatch(updateCandidateUserTutorialLoading())
    try {
      dispatch(updateCandidateUserTutorialSuccess(tutorial))
    } catch (error) {
      dispatch(updateCandidateUserTutorialFailure())
    }
  }
}

const initialState = {
  loading: false,
  errors: [],
  registrationSubmitted: false,
  candidateTypes: [],
  filters: [],
  photoLoading: false,
}

export const candidateReducer = (state = initialState, action) => {
  const { payload, photoLoading, resumeLoading } = action
  switch (action.type) {
    case GET_CANDIDATE_LOADING:
      return {
        ...state,
        loading: true,
        photoLoading: true,
      }
    case UPDATE_CANDIDATE_LOADING:
      return {
        ...state,
        loading: true,
        photoLoading,
        resumeLoading,
      }
    case GET_CANDIDATES_LOADING:
      return {
        ...state,
        loading: true,
        photoLoading: true,
      }
    case GET_CANDIDATE_SUCCESS:
    case UPDATE_CANDIDATE_SUCCESS:
      return {
        ...state,
        errors: [],
        loading: false,
        id: payload.id,
        firstName: payload.user.firstName,
        lastName: payload.user.lastName,
        email: payload.user.email,
        phone: payload.user.phone,
        city: payload.user.city,
        state: payload.user.state,
        profileImage: payload.user.profileImage,
        npiNumber: payload.npiNumber,
        about: payload.about,
        preferredCity: payload.preferredCity,
        preferredStates: payload.preferredStates,
        residency: payload.residency,
        residencyStart: payload.residencyStart,
        residencyEnd: payload.residencyEnd,
        resume: payload.resume,
        showEmail: payload.user.showEmail,
        showPhone: payload.user.showPhone,
        specialties: payload.specialties,
        specialtyId: payload.specialties.length > 0 ? payload.specialties[0].id : null,
        subspecialties: payload.subspecialties,
        subspecialtyId:
          payload.subspecialties && payload.subspecialties.length > 0
            ? payload.subspecialties[0].id
            : null,
        photoLoading: false,
        resumeLoading: false,
        setupSeen: payload.setupSeen,
        filters: payload.userFilters,
        availabilityDate: payload.availabilityDate,
        searchStatus: payload.searchStatus,
        boardEligibility: payload.boardEligibility,
        visas: payload.visas || [],
        aboutJob: payload.aboutJob,
        licensedStates: payload.licensedStates,
        verifiedAt: payload.user.emailVerifiedAt,
        candidateType: payload.candidateTypeInfo,
        tutorialViews: (payload.userTutorialViews || []).map(view => view.tutorial),
        relocationPreference: payload.relocationPreference,
        yearsExperience: payload.yearsExperience,
        preferredPracticeTypes: payload.preferredPracticeTypes,
        preferredAreaTypes: payload.preferredAreaTypes,
        academicPreference: payload.academicPreference,
        candidateLanguages: payload.candidateLanguages || [],
      }
    case GET_CANDIDATES_SUCCESS:
      return {
        ...state,
        errors: [],
        loading: false,
        totalCandidates: payload.totalCount,
        list: mapPhysicianData(payload.candidates),
      }
    case GET_CANDIDATE_FAILURE:
    case GET_CANDIDATES_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case UPDATE_CANDIDATE_FAILURE:
      return {
        ...state,
        loading: false,
        errors: payload.errors,
        photoLoading: false,
        resumeLoading: false,
      }
    case UPDATE_CANDIDATE_USER_TUTORIAL_SUCCESS:
      return {
        ...state,
        tutorialViews: Array.isArray(state.tutorialViews)
          ? state.tutorialViews.includes(payload)
            ? state.tutorialViews // If payload already exists, return the original array
            : [...state.tutorialViews, payload] // Append payload if it doesn't exist
          : [payload], // If tutorialViews isn't an array, initialize it with the payload
      }
    case UPDATE_CANDIDATE_FILE_LOADING:
      return {
        ...state,
        photoLoading: photoLoading,
        resumeLoading: resumeLoading,
      }
    case UPDATE_CANDIDATE_FILE_SUCCESS:
      return {
        ...state,
        errors: [],
        loading: false,
        id: payload.id,
        firstName: payload.user.firstName,
        lastName: payload.user.lastName,
        email: payload.user.email,
        phone: payload.user.phone,
        city: payload.user.city,
        state: payload.user.state,
        profileImage: payload.user.profileImage,
        npiNumber: payload.npiNumber,
        about: payload.about,
        preferredCity: payload.preferredCity,
        preferredStates: payload.preferredStates,
        residency: payload.residency,
        residencyStart: payload.residencyStart,
        residencyEnd: payload.residencyEnd,
        resume: payload.resume,
        showEmail: payload.user.showEmail,
        showPhone: payload.user.showPhone,
        specialties: payload.specialties,
        specialtyId: payload.specialties.length > 0 ? payload.specialties[0].id : null,
        subspecialties: payload.subspecialties,
        subspecialtyId:
          payload.subspecialties && payload.subspecialties.length > 0
            ? payload.subspecialties[0].id
            : null,
        photoLoading: false,
        resumeLoading: false,
        setupSeen: payload.setupSeen,
        filters: payload.userFilters,
        availabilityDate: payload.availabilityDate,
        searchStatus: payload.searchStatus,
        boardEligibility: payload.boardEligibility,
        visas: payload.visas || [],
        aboutJob: payload.aboutJob,
        licensedStates: payload.licensedStates,
        verifiedAt: payload.user.emailVerifiedAt,
        candidateType: payload.candidateTypeInfo,
        tutorialViews: (payload.userTutorialViews || []).map(view => view.tutorial),
        relocationPreference: payload.relocationPreference,
        yearsExperience: payload.yearsExperience,
        preferredPracticeTypes: payload.preferredPracticeTypes,
        preferredAreaTypes: payload.preferredAreaTypes,
        academicPreference: payload.academicPreference,
        candidateLanguages: payload.candidateLanguages || [],
      }
    case UPDATE_CANDIDATE_FILE_FAILURE:
      return {
        ...state,
        loading: false,
        photoLoading: false,
        resumeLoading: false,
      }
    default:
      return {
        ...state,
      }
  }
}
